import React from 'react';
import {Component} from 'react';
import Header from './components/shared-components/header';
import LoginForm from './components/login-related/login-form';
import LogoutForm from './components/login-related/logout-form';
import Footer from './components/shared-components/footer';
import Homepage from './pages/homepage';
import Administration from './pages/administration';
import GettingStartedDetailed from './pages/gettingStartedDetailed';
import SpecialProcesses from './pages/specialProcesses';
import GameView from './pages/gameView';
import ExtraCredit from './pages/extraCredit';
import ForParents from './pages/forParents';
import AboutPage from './pages/aboutPage';
import ContactUs from './pages/contactUs';
import Activities from './pages/activities';
import SpecialActivity from './pages/specialActivity';
import {UsernameContext} from './objects/Context/username_context';
import {LoginContext} from './objects/Context/login_context';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends Component {
  constructor(props)
  {
    super(props);
    const storedUsername = sessionStorage.getItem('username');
    const storedUserRole = sessionStorage.getItem('userRole');
    if (storedUsername !== null)
    {
      this.state = {
          displayLoginMenu: false, 
          username: storedUsername, 
          loggedIn: true, 
          userRole: storedUserRole};
    }
    else
    {
      this.state = {displayLoginMenu: false, 
          username: "", 
          loggedIn: false,
      };
      console.log(`Logged in? ${this.state.loggedIn}`)
    }
  }
  render() {  

  return (
    <div id="container">
     <meta name="description" content="Money Management is an important life skill.  For many, the
        best practices of managing money are learned through costly mistakes.  This site offers
        a different approach.  Click on this link to explore a game that will teach basic money
        management concepts.  This game is intended for ages 10 through 14." />
    <Router>
      <UsernameContext.Provider value={this.state}>
          <Header toggleLoginMenu={() => this.toggleLoginMenu()} 
              onClick={() => this.handleBodyClick()} 
              username={this.state.username}
              userRole={this.state.userRole} 
              userLoggedIn={this.state.loggedIn}
          />
          {this.state.displayLoginMenu ?
            (!this.state.loggedIn ?
                  <LoginForm onSuccessfulLogin={this.loginCallback}/>
                  :
                  <LogoutForm onLogout={this.logoutCallback} username={this.state.username}/>
            )
                :
                <span/>            
          }
      </UsernameContext.Provider>
      <div id="body" onClick={() => this.handleBodyClick()}>
      <Switch>
        <Route path="/admininistration">
          <Administration />
        </Route>
        <Route path="/admin-main">
          <Administration  selected="adminMain"/>
        </Route>
        <Route path="/events-update">
          <Administration  selected="eventsUpdate"/>
        </Route>
        <Route path="/other-updates">
          <Administration  selected="otherUpdates"/>
        </Route>
        <Route path="/event-reports">
          <Administration  selected="eventReports"/>
        </Route>
        <Route path="/condition-reports">
          <Administration  selected="conditionReports"/>
        </Route>
       
        <Route path="/specialProcesses">
          <SpecialProcesses />
        </Route>
        <Route path="/special-process-main">
           <SpecialProcesses selected="specialProcessMain"/>
        </Route>
        <Route path="/background-picture-test">
           <SpecialProcesses selected="backgroundPictureTest"/>
        </Route>
        <Route path="/test-activities">
           <SpecialProcesses selected="testActivities"/>
        </Route>
        <Route path="/test-dialog">
           <SpecialProcesses selected="testDialog"/>
        </Route>
        <Route path="/display-events">
           <SpecialProcesses selected="displayEvents"/>
        </Route>
        <Route path="/test-spinner">
           <SpecialProcesses selected="testSpinner"/>
        </Route>

        <Route path="/specialActivity">
          <SpecialActivity />
        </Route>
        <Route path="/special-activity-main">
           <SpecialActivity selected="specialActivityMain"/>
        </Route>
        <Route path="/test-activities">
           <SpecialActivity selected="testActivities"/>
        </Route>
                       
          <Route path="/skills-interests">
            <GettingStartedDetailed selected="skillsInterests" />
          </Route>
          <Route path="/choose-career">
            <GettingStartedDetailed selected="chooseCareer" />
          </Route>
          <Route path="/choose-home">
            <GettingStartedDetailed selected="chooseHome" />
          </Route>
          <Route path="/choose-car">
            <GettingStartedDetailed selected="chooseCar" />
          </Route>
          <Route path="/choose-furniture">
            <GettingStartedDetailed selected="chooseFurniture" />
          </Route>
      
        <Route path="/game">
          <GameView />
        </Route>
        <Route path="/activities">
          <Activities />
        </Route>
         
        <Route path="/game-board">
          <GameView selected="gameBoard"/>
        </Route>
        <Route path="/game-choices">
          <GameView selected="gameChoices"/>
        </Route>
        <Route path="/change-skills-survey">
            <GameView selected="changeSkillsSurvey" />
        </Route>
        <Route path="/change-career">
            <GameView selected="changeCareer" />
        </Route>
        <Route path="/change-home">
          <GameView selected="changeHome"/>
        </Route>
        <Route path="/change-car">
          <GameView selected="changeCar"/>
        </Route>
        <Route path="/unlocked-features">
          <GameView selected="unlockedFeatures"/>
        </Route>
        <Route path="/setup-fun-stuff">
          <GameView selected="setupFunStuff"/>
        </Route>
        <Route path="/setup-savings">
          <GameView selected="setupSavings"/>
        </Route>
        <Route path="/setup-credit-card">
          <GameView selected="setupCreditCard"/>
        </Route>
        <Route path="/setup-time-wizard">
          <GameView selected="setupTimeWizard"/>
        </Route>
        <Route path="/earn-badge">
           <GameView selected="earnBadge"/>
        </Route>
       
        <Route path="/status-display">
          <GameView selected="statusDisplay"/>
        </Route>
        <Route path="/monthly-status">
          <GameView selected="monthlyStatus"/>
        </Route>
        <Route path="/end-game">
            <GameView selected="endGame" />
        </Route>

        <Route path="/select-activity">
           <Activities selected="selectActivity"/>
        </Route>
        <Route path="/choose-a-friend">
           <Activities selected="chooseAFriend"/>
        </Route>
        <Route path="/start-activity">
           <Activities selected="startActivity"/>
        </Route>
        <Route path="/finish-activity">
           <Activities selected="finishActivity"/>
        </Route>
           
        <Route path="/extra-credit">
          <ExtraCredit />
        </Route>
          <Route path="/extra-credit-main">
              <ExtraCredit selected="extraCreditMain" />
          </Route>
          <Route path="/level1-challenges">
             <ExtraCredit selected="level1Challenges" />
          </Route>  
          <Route path="/level2-challenges">
             <ExtraCredit selected="level2Challenges" />
          </Route>
          <Route path="/money-calculators">
             <ExtraCredit selected="moneyCalculators" />
          </Route>
          <Route path="/money-mgmt-test">
             <ExtraCredit selected="moneyMgmtTest" />
          </Route>
     
        <Route path="/for-parents">
          <ForParents />
        </Route>
          <Route path="/for-parents-main">
              <ForParents selected="forParentsMain" />
          </Route>
          <Route path="/parents-game-info">
              <ForParents selected="parentsGameInfo" />
          </Route>
          <Route path="/related-players">
              <ForParents selected="relatedPlayers" />
          </Route>  
          <Route path="/player-reports">
              <ForParents selected="playerReports" />
          </Route>
          <Route path="/discussion-topics">
              <ForParents selected="discussionTopics" />
          </Route>
                                              
        <Route path="/about">
          <AboutPage />
        </Route>
          <Route path="/about-general">
             <AboutPage selected="aboutGeneral" />
          </Route>
          <Route path="/about-for-parents">
             <AboutPage selected="aboutForParents" />
          </Route>
          <Route path="/information-sources">
             <AboutPage selected="informationSources" />
          </Route>
          <Route path="/game-objectives">
            <AboutPage selected="gameObjectives" />
          </Route>
        
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/contact-us-main">
            <ContactUs selected="contactUsMain" />
          </Route> 
          <Route path="/teacher-parent-signup">
            <ContactUs selected="teacherParentSignup" />
          </Route> 
          <Route path="/game-feedback">
            <ContactUs selected="gameFeedback" />
          </Route> 
          <Route path="/player-comments">
            <ContactUs selected="playerComments" />
          </Route> 
           
        <Route path="/">
          <LoginContext.Provider value={this.state}>
            <Homepage userLoggedIn={this.state.loggedIn}
                username={this.state.username}
                openLoginMenu={() => this.openLoginMenu()} 
              />
          </LoginContext.Provider>
        </Route>
      </Switch>
      </div>
      <Footer onClick={() => this.handleBodyClick()}/>
    </Router>
  </div>
  );
  }

  handleBodyClick()
  {
    if (this.loginMenuOpened === true){
        this.loginMenuOpened = false;
    }else{
        this.setState({displayLoginMenu: false});
    }
  }

  toggleLoginMenu()
  {
    this.setState({displayLoginMenu: !this.state.displayLoginMenu});
  }

  openLoginMenu()
  {
    this.loginMenuOpened = true;
    this.setState({displayLoginMenu: true});
    
  }

  loginCallback = (username, userRole) =>
  {
      //  this throws an error because it is being called from the render of the login_form process
      //  but changing to direct assignments does not seem to work consistently. 
      this.setState({username: username, displayLoginMenu: false, loggedIn: true, userRole: userRole});
      console.log("loginCallBack");
  }

  logoutCallback = () =>
  {
    sessionStorage.removeItem('sessionToken');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem("userRole"); 
    this.setState({username: "", displayLoginMenu: false, loggedIn: false, userRole: ""});
    console.log("logoutCallback");
  }
}

export default App;
