import {Component} from 'react';
import { withRouter } from "react-router";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import GameFeedbackDisplayModal from '../admin/game_feedback_display_modal';
import PlayerFeedbackDisplayModal from '../admin/player_feedback_display_modal';
import GameProfileDisplayModal from '../admin/game_profile_display_modal';
import JobListModal from '../admin/job_list_modal';
import SavingsOverTimeModal from '../extra_credit/savings_over_time_modal';
import LoanCalculatorModal from '../extra_credit/loan_calculator_modal';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import LeaderBoardModal from '../game-views/leader_board_modal';
import DiscussionGameData from './datasets/discussion_game_data';
import DiscussionSavingsData from './datasets/discussion_savings_data';
import DiscussionCreditCardData from './datasets/discussion_credit_card_data';
import MoneyTrain_Report_Discussion_Topics from './ForParents/Player_Reports_Discussion_Guide.pdf'
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';
import combinePlayerChoices from '../../objects/CommonUse/combine_player_choices';
import MoneyMgmtStudentTestModal from '../admin/money_mgmt_student_test_modal';
import MoneyMgmtTestModal from '../admin/money_mgmt_test_modal';
import CreditCardCalculatorModal from '../extra_credit/credit_card_calculator_modal';

class PlayerReports extends Component {
    constructor(props) {
        super(props);
        this.parentInfo = this.props.parentInfo();
        this.state={
          headers: setHeadersForFetch(),  
          loadGameData: false,
          loadSavingsData: false,
          loadCreditCardData: false,
          loadTimeWizardData: false,
          loadJobChoiceData: false,
          loadHomeChoiceData: false,
          loadCarChoiceData: false,
          waitState: false,
          calculateOnly: true,
          modalOpen: false,
          subProcess: "playerReports",
          environment: Config.dataURL.currentEnv,
        };   
        this.gameFeedback = null;
        this.playerFeedback = null;
        this.gameData = null;
        this.savingsData = null;
        this.creditCardData = null;
        this.timeWizardData = null;
        this.gameChoicesData = null;
        this.jobList = null;
        this.moneyMgmtTestData = null;
        this.openProcessName = "";
        this.gameDataLoaded = true;
    }

    reportSelected(reportType){
        if (reportType === "savings" || reportType === "loans" || reportType === "creditCard"
            || reportType === "leaderBoard"){
            //   these do not require fetch for data
        }else{
            this.setState({waitState: true, modalOpen: false});
        }
        if (reportType === "gameProfile" || reportType === "discussion"){
            this.gameDataLoaded = false;
        }else{
            this.gameDataLoaded = true;
        }
        switch (reportType){
            case "gameProfile":
                this.setState({modalOpen: true});
                this.openProcessName = "";
                this.loadGamesForAccount();
                break;
            case "leaderBoard":
                this.accountNbr = sessionStorage.getItem("userAccountNbr");
                this.openProcessName = "leaderBoard";
                this.setState({modalOpen: true});
                break;
            case "savings":
                this.openProcessName = "savingsCalculator";
                this.setState({modalOpen: true});
                break;
            case "loans":
                this.openProcessName = "loanCalculator";
                this.setState({modalOpen: true});
                break;
            case "Game":
                this.loadGameFeedback();
                break;
            case "Player":
                this.loadPlayerFeedback();
                break;
            case "jobList":
                this.loadJobList();
                break;
            case "discussion":
                this.setDiscussionData();
                break;
            case "creditCard":
                this.openProcessName = "creditCardCalculator";
                this.setState({modalOpen: true});
                break;
            case "studentTest":
                this.loadStudentTests(reportType);
                break;
            case "testAssess":
                this.loadStudentTests();
                break;
            default:
                break;
            
        }
    }
    
    closeReportModals(){
        this.openProcessName = "";
        this.setState({modalOpen: false, loadGameData: false});
    }

    closeModal(){
        this.setState({modalOpen: false});
    }
  
    render() {
        if (this.state.loadJobChoiceData ===  true && this.state.loadCarChoiceData ===  true &&
            this.state.loadHomeChoiceData ===  true && this.gameDataLoaded ===  false){
                this.allChoicesArr = combinePlayerChoices(this.gameChoiceArr, this.jobChoiceData,
                    this.homeChoiceData, this.carChoiceData);
                     this.gameDataLoaded = true;
        }
        this.gutters = "";
        if  (this.props.screenSize ===  "narrow"){
            this.gutters = "modal-95";
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
     
    return <>
           <div className={this.className}>
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h3 className="my-0 text-center">Player Reports</h3>
                    <h5 className="text-center">Discussion and Player Feedback Reports</h5>
                    <div className="mx-4 mt-2">
                          The reports available on this page can be displayed to view the
                          status and key information about the game ...OR... in conjunction
                          with the discussion guide (link provided below).
                    </div>
                    <table className="mt-2 overflow-scroll">
                    <tbody className="row-100" >
                        <tr >
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("gameProfile")}>
                                            Game Profiles
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("leaderBoard")}>
                                           Leader Board for Class
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("jobList")}>
                                           Job List
                                </button>
                            </td>
                           
                        </tr>
                        <tr>
                        <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("savings")}>
                                           Savings Calculator
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("loans")}>
                                           Loan Calculator
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("creditCard")}>
                                           Credit Card Calculator
                                </button>
                            </td>
                           
                        </tr>
                        <tr>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("Player")}>
                                            Player Comments
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("Game")}>
                                            Player Game Feedback
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("discussion")}>
                                           Game Profiles for Discussion
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("studentTest")}>
                                            Student Test Results
                                </button>
                            </td>
                            <td className="col-sm-4 text-center">
                                <button className="btn-group-wide mt-3 px-0" 
                                    onClick ={()=> this.reportSelected("testAssess")}>
                                            Test Assessment
                                </button>
                            </td>
                        </tr>
                           
                    </tbody>
                    </table>
                    <div className="mt-3 text-center">
                  
                    {this.state.waitState  || this.gameDataLoaded  === false ?
                          <h3 className="mt-5">This may take a few minutes to load.</h3>
                    :
                        null
                    }
                </div>
                  
                    {this.state.waitState || this.gameDataLoaded === false ?
                       <WaitTimeSpinner/>
                    :
                        <span>
                         {this.openProcessName ===  "leaderBoard" ?
                            <LeaderBoardModal modalOpen={this.state.modalOpen} 
                                accountNbr={this.accountNbr}
                                playerSelected={false}
                                onClose={() => this.closeModal()} 
                                gameInfo = {() => this.parentInfo}
                            />
                        :
                            <div></div>
                        }

                        {this.openProcessName ===  "savingsCalculator" ?
                            <SavingsOverTimeModal show={this.state.modalOpen}
                                gutters={this.gutters}
                                onClose={() => this.closeReportModals()}
                                calculatorSw={this.state.calculateOnly} 
                                displayInitialAmt={true}/>
                            :
                            <div></div>
                        }

                        {this.openProcessName ===  "loanCalculator"  ?
                            <LoanCalculatorModal show={this.state.modalOpen} 
                                screenSize={this.props.screenSize}
                                onClose={() => this.closeReportModals()} 
                                calculatorSw ={this.state.calculateOnly}/>
                        :
                            <div></div>
                        }

                        {this.openProcessName ===  "creditCardCalculator"  ?
                            <CreditCardCalculatorModal show={this.state.modalOpen} 
                                screenSize={this.props.screenSize}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            <div></div>
                        }

                        {this.openProcessName === "PlayerFeedback" ?
                            <PlayerFeedbackDisplayModal openModal={this.state.modalOpen} 
                                commentsOnly={true}
                                onClose={() => this.closeReportModals()} 
                                playerFeedback={this.playerFeedback}
                                
                            />
                        :
                            null
                        }

                        {this.state.loadGameData && this.state.loadSavingsData && this.state.loadCreditCardData 
                            && this.state.loadTimeWizardData && this.gameDataLoaded ?
                                <GameProfileDisplayModal openModal={this.state.modalOpen} 
                                    onClose={() => this.closeReportModals()} 
                                    parentInfo = {() => this.props.parentInfo()} 
                                    setParentInfo = {(value, propertyName) => 
                                        this.props.setParentInfo(value, propertyName)}
                                    gameData={this.gameData}
                                    savingsData={this.savingsData}
                                    creditCardData={this.creditCardData}
                                    timeWizardData={this.timeWizardData}
                                    gameChoicesData={this.allChoicesArr}
                                    screenSize={this.props.screenSize}
                                />
                        :
                            null
                        }

                        {this.openProcessName === "GameFeedback" ?
                            <GameFeedbackDisplayModal openModal={this.state.modalOpen} 
                                onClose={() => this.closeReportModals()} 
                                gameFeedback={this.gameFeedback}
                             
                            />
                        :
                            null
                        }

                        {this.openProcessName === "JobList" ?
                            <JobListModal
                                openModal={this.state.modalOpen}
                                jobListArr={this.jobList}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        {this.openProcessName === "StudentTest" ?
                            <MoneyMgmtStudentTestModal
                                openModal={this.state.modalOpen}
                                moneyMgmtTestArr={this.moneyMgmtTestData}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        {this.openProcessName === "MoneyMgmtTest" ?
                            <MoneyMgmtTestModal
                                openModal={this.state.modalOpen}
                                moneyMgmtTestArr={this.moneyMgmtTestData}
                                onClose={() => this.closeReportModals()} 
                            />
                        :
                            null
                        }

                        </span>
                    }
                      <div className="mt-2 text-center">
                        <a className="decorative light-blue-color text-pointer" 
                            href={MoneyTrain_Report_Discussion_Topics} target="_blank" 
                            rel="noreferrer">
                                View Player Reports Discussion Guide (PDF)
                        </a>
                    </div>
                                      
                </div>        
            </div>
        </>
    }

      
loadPlayerFeedback(){
    this.setState({waitState: true});
    let playerArr = (this.parentInfo.relatedPlayers).join(',');
    const funcName = "loadPlayerFeedback";
    const urlParm = "?userList=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.openProcessName = "PlayerFeedback";
                    this.sortPlayerFeedback(output.body);
                    this.setState({modalOpen: true, waitState: false});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

sortPlayerFeedback(playerFeedback){
    this.playerFeedback = [];
    for (let i=0; i<playerFeedback.length; i++){
        let sortKey = playerFeedback[i].user + playerFeedback[i].dateAdded + playerFeedback[i].timeAdded;
        let feedbackObj = {...playerFeedback[i], sortKey: sortKey};
        this.playerFeedback.push(feedbackObj);
    }
    (this.playerFeedback).sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)
}


loadGamesForAccount(){
    this.setState({loadJobChoiceData: false, loadCarChoiceData: false,
        loadHomeChoiceData: false});
    let playerArr = (this.parentInfo.relatedPlayers).join(',');
    const funcName = "loadGamesForAccount";
    const urlParm = "?userNameArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode ===  200){
                        (output.body).sort((a, b) => (a.user > b.user) ? 1 : -1)
                        this.gameData = output.body;
                        this.setGameIdArr();
                        this.loadSavings();
                        this.loadCreditCardInfo();
                        this.loadTimeWizardInfo();
                        this.loadJobChoices();
                        this.loadHomeChoices();
                        this.loadCarChoices();
                        this.setState({loadGameData: true, waitState: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

setGameIdArr(){
    this.gameIdArr = [];
    this.gameChoiceArr = [];
     for (let i=0; i<this.gameData.length; i++){
          this.gameIdArr.push(this.gameData[i]._id);
          let gameData = this.gameData[i];
          let gameChoiceObj = {gameID: gameData._id, jobID: gameData.jobInfo.careerID,
            homeID: gameData.homeInfo.homeID, carID: gameData.carInfo.carID};
          this.gameChoiceArr.push(gameChoiceObj);
    }
}

loadSavings() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadSavings";
    const urlParm = "?gameID=" + gameID + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode ===  200){
                        this.savingsData = output.body;
                        this.setState({loadSavingsData: true});
                    }else{
                        if (statusCode ===  250){
                            this.savingsData = [];
                            this.setState({loadSavingsData: true});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
}

loadCreditCardInfo() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadCreditCardInfo";
    const urlParm = "?gameID=" + gameID + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.creditCardData = output.body;
                    this.setState({loadCreditCardData: true});
                }else{
                    if (statusCode ===  250){
                        this.creditCardData = [];
                        this.setState({loadCreditCardData: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                }
            });
        };
    });
}

loadTimeWizardInfo() {
    let gameID = (this.gameIdArr).join(',');
    const funcName = "loadTimeWizard";
    const urlParm = "?gameID=" + gameID + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.timeWizardData = output.body;
                    this.setState({loadTimeWizardData: true});
                }else{
                    if (statusCode ===  250){
                        this.timeWizardData = [];
                        this.setState({loadTimeWizardData: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                }
            });
        };
    });
}

loadJobChoices(){
    let jobIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          jobIdArr.push(this.gameChoiceArr[i].jobID);
    }
    const jobIdParm = jobIdArr.join(',');
    const funcName = "jobInfo";
    const urlParm = "?jobId=" + jobIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.jobChoiceData = output.body;
                    this.setState({loadJobChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }

  
loadHomeChoices(){
    let homeIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          homeIdArr.push(this.gameChoiceArr[i].homeID);
    }
    const homeIdParm = homeIdArr.join(',');
    const funcName = "homeInfo";
    const urlParm = "?homeId=" + homeIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.homeChoiceData = output.body;
                    this.setState({loadHomeChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }
    
loadCarChoices(){
    let carIdArr=[];
    for (let i=0; i<this.gameChoiceArr.length; i++){
          carIdArr.push(this.gameChoiceArr[i].carID);
    }
    const carIdParm = carIdArr.join(',')
    const funcName = "carInfo";
    const urlParm = "?carId=" + carIdParm + "&returnType=Multiple";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', 
        headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.carChoiceData = output.body;
                    this.setState({loadCarChoiceData: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

loadGameFeedback(){
    const playerArr = (this.parentInfo.relatedPlayers).join(',');  
    const funcName = "loadGameFeedback";
    const urlParm = "?userList=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,   
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.openProcessName = "GameFeedback";
                    this.gameFeedback = output.body;
                    this.setState({modalOpen: true, waitState: false});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

loadJobList(){
    const funcName = "jobListAll";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode ===  200){
                        this.openProcessName = "JobList";
                        this.jobList = output.body;
                        this.jobList = 
                            this.jobList.sort((a, b) => (a.jobName > b.jobName) ? 1 : -1)
                        this.setState({modalOpen: true, waitState: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }
  
  loadStudentTests(reportType){
    const funcName = "loadMoneyMgmtTest";
    const playerArr = (this.parentInfo.relatedPlayers).join(',');  
    const urlParm = "?userArr=" + playerArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode ===  200){
                        this.moneyMgmtTestData = output.body;
                        this.moneyMgmtTestData = 
                            this.moneyMgmtTestData.sort((a, b) => (a.user + a.dateAdded > b.user + b.dateAdded) ? 1 : -1);
                        if (reportType === "studentTest"){
                            this.openProcessName = "StudentTest";
                        }else{
                            this.openProcessName = "MoneyMgmtTest";
                        }
                        this.setState({modalOpen: true, waitState: false});
                    }else{
                        if (statusCode ===  250){
                            this.setState({waitState: false});
                            alert ("There is no student test data for this parent/teacher.")
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
  }

setDiscussionData(){
    this.gameData = DiscussionGameData;
    this.savingsData = DiscussionSavingsData;
    this.creditCardData = DiscussionCreditCardData;
    this.setState({waitState: true, loadGameData: true, loadSavingsData: true, 
        loadCreditCardData: true, loadCarChoiceData: false, loadHomeChoiceData: false,
        loadJobChoiceData: false, loadTimeWizardData: false});
    this.setGameIdArr();
    this.loadJobChoices();
    this.loadHomeChoices();
    this.loadCarChoices();
    this.setState({waitState: false, modalOpen: true});
}


}

export default withRouter(PlayerReports);