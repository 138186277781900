import { rollForwardDate } from "./roll_forward_date_function";

export const rollForwardCash = (assetArray, expenseArray, loanArray, nbrMonths, gameDate, creditCardActive) => {

    var totalAssetAmt = 0;
    var totalExpCashAmt = 0;
    var totalLoanPayments = 0;
    var totalExpChargeAmt = 0;
    var checkDate = gameDate;
          
    for (let i=0; i<nbrMonths; i++) {
          checkDate = rollForwardDate(checkDate, 1);
          for (let j=0; j< assetArray.length; j++) {
               if (assetArray[j].assetNbrMonths != 0 &&
                   assetArray[j].assetStartDate <= checkDate ) {
                    totalAssetAmt = totalAssetAmt + +assetArray[j].assetAmt;
                    if (assetArray[j].assetNbrMonths != -1) {
                          assetArray[j].assetNbrMonths -= 1;
                    }
               }
          }
          for (let j=0; j< expenseArray.length; j++) {
               if (expenseArray[j].expNbrMonths != 0 &&
                   expenseArray[j].expStartDate <= checkDate ) {
                    if (creditCardActive === true &&
                        expenseArray[j].chargeCreditCard !== undefined &&
                        expenseArray[j].chargeCreditCard === true){
                         totalExpChargeAmt = totalExpChargeAmt + +(expenseArray[j].expAmount);
                    }else{
                         totalExpCashAmt = totalExpCashAmt + +expenseArray[j].expAmount;
                    } 
                    if (expenseArray[j].expNbrMonths != -1) {
                         expenseArray[j].expNbrMonths -= 1; 
                    }
               }
          }
          for (let j=0; j< loanArray.length; j++) {
               if (loanArray[j].loanNbrMonths != 0 &&
                   loanArray[j].loanStartDate <= checkDate ) {
                    totalLoanPayments = totalLoanPayments + parseFloat(loanArray[j].loanAmt)
                    if (loanArray[j].loanNbrMonths != -1) {
                         loanArray[j].loanNbrMonths -= 1;
                    }
               }
          }
    }
          
    let totalCashChange = (totalAssetAmt - totalExpCashAmt - totalLoanPayments).toFixed(2);
    let totalNetAmount = (totalCashChange - totalExpChargeAmt).toFixed(2);
    let totalAmtObj = {totalAssetAmt: totalAssetAmt, totalExpCashAmt: totalExpCashAmt, 
               totalExpChargeAmt: totalExpChargeAmt, totalCashChange: totalCashChange,
               totalLoanPayments: totalLoanPayments, totalNetAmt: totalNetAmount,
               newGameDate: checkDate
              };
    return totalAmtObj;
}

 


