import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from "react-player";
import '../../css/styles.css';
import './ForParents/forParents.css';

class VideoViewerModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                        }
            
    }

      
    topicSelected(topicName){
         switch (topicName){
            case "gameTrailer1":
                this.videoURL =  "https://www.youtube.com/watch?v=v4MyoS4NSAs";
                break;
            case "gameTrailer2":
                this.videoURL = "https://www.youtube.com/watch?v=qJ1lZpEI9Bw";
                break;
            case "theBasics":
                this.videoURL = "https://www.youtube.com/watch?v=DucRYrN2FrE"
                break;
            case "jobChoice":
                this.videoURL = "https://www.youtube.com/watch?v=FX9xL6h49FA"
                break;
            case "homeChoice":
                this.videoURL = "https://www.youtube.com/watch?v=9Dq68UJxDNQ"
                break;
            case "carChoice":
                this.videoURL = "https://www.youtube.com/watch?v=_3h4UKatO44";
                break;
            case "needsVsWants":
                this.videoURL = "https://youtu.be/iiJ1-uLTvlc";
                break;
            case "introduction":
                this.videoURL = "https://www.youtube.com/watch?v=4htm6QhY6ZE";
                break;
            case "jobChoiceParticipation":
                this.videoURL = "https://www.youtube.com/watch?v=ca6UrUu8c5Y";
                break;
            default:
                break;
        }
    }

            
    render() {
        this.topicSelected(this.props.topicName);
    return <>
        <Modal show={this.props.show} className=""
            onHide={() => this.props.onClose()} size="lg" animation={false}
            >
       
        <Modal.Body>
            <div className="container bg-dark">
                <ReactPlayer className="mx-auto d-block" 
                 // Disable download button
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                 // Disable right click
                    onContextMenu={e => e.preventDefault()}
                    url={this.videoURL}
                    controls={true}
                    type="video/mp4" playing 
                    onEnded = {()=>this.props.onClose()} />

            </div>
        </Modal.Body>
        </Modal>
  
        </>
    }
}
export default VideoViewerModal;