import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';

class TableDisplayLineCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    0
/*tableLine: {fieldValue: 'Current Car Loan Monthly Pay Amt', fieldType: 'text', fieldClass: 'font-weight-bold'}
             {fieldValue: '', fieldType: 'text', fieldClass: ''}
             {fieldValue: '280.57', fieldType: 'amount', fieldClass: ''}
*/
    reformatDatesAmounts(){
        this.textDanger = false;
        switch (this.props.outInfo.fieldType){
        case "date":
            this.outField = reformatDateToDisplay(this.props.outInfo.fieldValue);
            break;
        case "amount":
            if (+this.props.outInfo.fieldValue < 0){
                this.textDanger = true;
            }
            this.outField = doubleToStringMonthly(this.props.outInfo.fieldValue);
            break;
        default: 
            this.outField = this.props.outInfo.fieldValue;
            break;
      }
    }

    determineTextClass(){        
        this.className = "p-0 ";
        if (this.textDanger === true){
          this.className += " text-danger "
        }
        if (this.props.outInfo.fieldClass !== undefined){
            this.className = this.className + this.props.outInfo.fieldClass;
        }
    }

    render() {
        this.reformatDatesAmounts();
        this.determineTextClass();
                     
    return <>     
          <td className={this.className}>{this.outField}</td>
    </>
    }
}

export default TableDisplayLineCard;