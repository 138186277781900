import { Component } from 'react';
import "./GameViews/gameViews.css";
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import FunStuffDataset from './datasets/fun_stuff_dataset';
import FunStuffDisplayAmts from './datasets/fun_stuff_display_amts';
import ImageCard from '../shared-components/image_card';
import TwoColumnTableCard from './two_column_table_card';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import BackgroundDataset from './datasets/backgroundDataset';
import buildJokeCard from './objects/build_joke_card';
import ConfigFile from '../../datasets/config_file';
import { monthlyLoanAmt } from '../../objects/AssetsExpenses/loanAmtCalc_function';
import { calcNbrMonthsBtwnDates } from '../../objects/CommonUse/nbr_months_btwn_dates_calc';
import determineWhichFriendToDisplay from './objects/determine_which_friend_to_display';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import { loanBalanceCalcFunction } from '../../objects/AssetsExpenses/loan_balance_calc_function';
import { findRemoveTableItem } from '../../objects/AssetsExpenses/find_remove_table_item';
import buildArrayForDisplay from '../../objects/CommonUse/build_array_for_display';
import TableDisplayCard from '../shared-components/table_display_card';

class SetupFunStuffModal extends Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            headers: setHeadersForFetch(),
            updateEnabled: false,
            step: "",
            defaultClass: "font-weight-bold ",
            openFriendModal: false,
            funStuffArr: FunStuffDataset,
            optionIndxArr: [],
            funStuffOptionsArr: [],
            pictureArr: [],
            defaultClass: "medium-size-img ml-4 my-4 picture-border",
            currentItem: null,
            currentDate: getCurrentDateTime().date,
            conditionalBackgrounds: BackgroundDataset,
            loanParms: ConfigFile.gameBoardLoans,
            subProcess: "setupFunStuffModal",
            environment: Config.dataURL.currentEnv,
        }
        this.loanRequired = false;
        this.depreciationPct = +ConfigFile.gameBoardLoans.depreciationPct;
        this.initializeFields();
    }

    initializeFields() {
        let feature = this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "funStuff");
        for (let i = 0; i < this.state.funStuffArr.length; i++) {
            if (+feature.level >= +this.state.funStuffArr[i].level) {
                this.state.optionIndxArr.push(i);
            }
        }
        this.createPictureArrForType();
    }

    onClick(picturesList) {
        let buttonType = sessionStorage.getItem("funStuffButtonClicked");
        if (buttonType != undefined) {
            this.setUpdateType(buttonType, picturesList);
            sessionStorage.removeItem("funStuffButtonClicked");
        } else {
            this.processSelectedPicture(picturesList)
        }
    }

    setUpdateType(buttonSlctd, picturesList) {
        this.updateType = buttonSlctd;
        if (this.updateType == "decline") {
            this.saveFunStuffSelection();
        } else {
            if (this.updateType == "remove") {
                this.enterName = false;
            }
            this.processSelectedPicture(picturesList);
        }
    }

    processSelectedPicture(picturesList) {
        switch (picturesList.type) {
            case "Type":
                this.typeIndx = this.state.funStuffArr.findIndex(elem => elem.type === picturesList.pictureText);
                this.saveInfoForSelectedType();
                if (this.updateType == "remove" || this.updateType == "change") {
                    this.category = this.state.currentItem.category;
                    if (this.updateType == "remove") {
                        this.processSelectedItem();
                    } else {
                        this.createPictureArrForOptions();
                    }
                } else {
                    this.createPictureArrForOptions();
                }
                break;
            case "Option":
                let optionsArr = this.state.funStuffArr[this.typeIndx].options;
                this.optionIndx = optionsArr.findIndex(elem => elem.category === picturesList.pictureText);
                this.saveInfoForSelectedOption();
                this.createPictureArrForList();
                break;
            case "List":
                let listArr = this.funStuffOptions.list;
                this.listIndx = listArr.findIndex(elem => elem.type === picturesList.pictureText);
                this.processSelectedItem();

        }
    }

    saveInfoForSelectedType() {
        this.funStuffType = this.state.funStuffArr[this.typeIndx];
        this.inputType = this.funStuffType.input;
        this.levelName = this.funStuffType.type;
        this.levelNbr = this.funStuffType.level;
        this.modalTitle = this.funStuffType.type + " (Level " + this.funStuffType.level + ")";
        this.expItem = this.funStuffType.expItem;
        this.titleType = this.funStuffType.titleType;
        this.loanType = this.funStuffType.loanType;
        this.calcDepreciation = this.funStuffType.calcDepreciation;
        if (this.gameInfo.funStuffInfo.itemArr !== undefined){
            let funStuffItem = 
                this.gameInfo.funStuffInfo.itemArr.find(elem => elem.levelName === this.levelName);
            this.state.currentItem = funStuffItem;
        }
    }

    saveInfoForSelectedOption() {
        this.funStuffOptions = this.funStuffType.options[this.optionIndx];
        this.category = this.funStuffOptions.category;
    }

    createPictureArrForType() {
        this.state.pictureArr = [];
        for (let i = 0; i < this.state.optionIndxArr.length; i++) {
            let arrIndx = this.state.optionIndxArr[i];
            this.determineRequiredButtons(this.state.funStuffArr[arrIndx].type, arrIndx)
            let pictureObj = {
                type: "Type", imageURL: "assets/" + this.state.funStuffArr[arrIndx].imageURL,
                disable: true, imageClass: this.state.defaultClass, pictureText: this.state.funStuffArr[arrIndx].type,
                buttonType: this.funStuffButtons
            };
            this.state.pictureArr.push(pictureObj);
        }
        this.state.step = "SelectType";
    }

    determineRequiredButtons(typeName) {
        let funStuffArr = [];
        if (this.gameInfo.funStuffInfo.itemArr != undefined) {
            funStuffArr = this.gameInfo.funStuffInfo.itemArr;
        }
        let funStuffIndx = funStuffArr.findIndex(elem => elem.levelName === typeName);
        if (funStuffIndx == -1) {
            this.funStuffButtons = "AddDecline";
        } else {
            this.previousItemType = funStuffArr[funStuffIndx].type;
            this.funStuffButtons = "ChangeRemove";

        }
    }

    createPictureArrForOptions() {
        let funStuffOptions = this.state.funStuffArr[this.typeIndx].options;
        this.state.pictureArr = [];
        if (funStuffOptions.length == 1) {
            if (this.state.step != "Back") {
                this.optionIndx = 0;
                this.saveInfoForSelectedOption();
                this.createPictureArrForList();
            }
        } else {
            for (let i = 0; i < funStuffOptions.length; i++) {
                let pictureObj = {
                    type: "Option", imageURL: "assets/" + funStuffOptions[i].imageURL,
                    disable: false, imageClass: this.state.defaultClass, pictureText: funStuffOptions[i].category
                };
                this.state.pictureArr.push(pictureObj);

            }
            this.state.step = "SelectOption";
        }
    }

    createPictureArrForList() {
        this.state.pictureArr = [];
        this.newConditionName = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].condName;
        this.categoryName = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].category;
        let funStuffList = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].list;
        if (funStuffList.length == 1) {
            if (this.state.step != "Back") {
                this.listIndx = 0;
                this.processSelectedItem();
                this.state.step = "ItemSelected"
            } else {
                this.createPicturesForOptions();
            }
        } else {
            for (let i = 0; i < funStuffList.length; i++) {
                let disable = false;
                let pictureClass = this.state.defaultClass;
                if (this.state.currentItem != null &&
                    funStuffList[i].type == this.state.currentItem.type) {
                    disable = true;
                    pictureClass = this.state.defaultClass + " disabled-picture";
                }
                let pictureObj = {
                    type: "List", imageURL: "assets/" + funStuffList[i].image,
                    disable: disable, imageClass: pictureClass, pictureText: funStuffList[i].type
                };
                this.state.pictureArr.push(pictureObj);
            }
            this.state.step = "SelectItem";
        }
    }

    processSelectedItem() {
        if (this.updateType === "add" ||  this.updateType === "change"){
            this.funStuffItem = this.funStuffOptions.list[this.listIndx];
            this.ongoingAmts = this.funStuffItem.ongoingCosts;
            this.onetimeAmts = this.funStuffItem.onetimeCosts;
            this.selectItemType = this.funStuffItem.type;
            this.selectItemURL = "/assets/" + this.funStuffItem.image;
            this.itemText = this.funStuffItem.text;
        }else{
            this.ongoingAmts = {};
            this.onetimeAmts = {};
            this.selectItemURL = "/assets/" + this.state.currentItem.pictureURL;
        }
        if (this.updateType === "change" ||  this.updateType === "remove"){
            this.findReturnAmtsForCurrentItem();
        }else{
            this.returnAmts = {};
        }
        this.setTitleLit();
        this.totalMoodPoints = 0;
        switch(this.updateType){
            case "add":
                this.totalMoodPoints = 70;
                break;
            case "change":
                this.totalMoodPoints = -70;
                break;
            case "remove":
                this.totalMoodPoints = -140;
                break;
        }
        this.determineOneTimeCosts();
        this.determineOngoingCosts();
        this.state.step = "ItemSelected";
    }
  
    findReturnAmtsForCurrentItem(){
        const category = this.state.currentItem.category; 
        const currentType = this.state.funStuffArr.find(elem => elem.type === this.state.currentItem.levelName);
        const currentOptionArr = currentType.options;
        const currentOption = currentOptionArr.find(elem => elem.category === category);
        const currentListArr = currentOption.list;
        const currentList = currentListArr.find(elem => elem.type === this.state.currentItem.type);
        this.returnAmts = currentList.returnAmounts;
    }

    determineOneTimeCosts() {
        this.onetimeCostArr = [];
        this.returnCostArr = [];
        this.loanRequired = false;
        this.loanOutstandingAmt = 0;
        this.downPaymentAmt = 0;
        if (this.updateType == "change" || this.updateType == "remove") {
            if (this.loanType != undefined &&
                this.loanType != "") {
                this.calculateCurrentLoanAmt();       
            }
        }
        this.loanAmtArr = [];
        this.oneTimeCostArr = [];
        if (this.updateType == "add" || this.updateType == "change") {
            if (this.funStuffItem.purchasePrice !== undefined &&
                +this.funStuffItem.purchasePrice !== 0){
                    this.determineLoanInfo();
            }
            let typeName = this.category + "OnetimeCosts";
            const onetimeCostObj = {...this.onetimeAmts, currentCash: +this.gameInfo.gameData.currentCash,
                downPayment: this.downPaymentAmt, loanOutstandingAmt: this.loanOutstandingAmt}
            this.onetimeCostArr = buildArrayForDisplay("FunStuff", typeName, onetimeCostObj);
            for (let i=0; i<this.onetimeCostArr.length; i++){
                if (this.onetimeCostArr[i].tableLine[1].fieldValue === "-"){
                    //  table line 0 contains the main field label
                    const tableFieldLine = this.onetimeCostArr[i].tableLine[0];
                    tableFieldLine.fieldValue = 
                        tableFieldLine.fieldValue + " (" + this.funStuffItem.type + ")";
                }
            }
        }
        if (this.updateType == "change" || this.updateType == "remove") {
            if (this.updateType === "remove"){
                this.buildTableLine("Current Cash", "", +this.gameInfo.gameData.currentCash);
                this.onetimeCostArr.push(this.tableLine);
                this.findReturnAmtsForCurrentItem();
            }            
            this.sellPrice = 0;
            if (this.calcDepreciation !== undefined && this.calcDepreciation === true){
                this.calculateSellPrice();
            }
            const prevItemCategory = this.state.currentItem.category
            const typeName = prevItemCategory + "ReturnCosts";
            const returnCostObj = {...this.returnAmts, sellPrice: this.sellPrice}       
            this.returnCostArr = buildArrayForDisplay("FunStuff", typeName, returnCostObj); 
            for (let i=0; i<this.returnCostArr.length; i++){
                this.onetimeCostArr.push(this.returnCostArr[i]);
            }
        }       
                
        this.totalOneTimeAmt = 0
        /*
         tableLine
            Array(3)
                 {fieldValue: 'Current Cash', fieldType: 'text', fieldClass: 'text-left font-weight-bold'}
                 {fieldValue: '', fieldType: 'text', fieldClass: 'text-center'}
                 {fieldValue: '26259.93', fieldType: 'amount', fieldClass: 'text-right'}
        */
        this.determineTotalAmt(this.onetimeCostArr);
        this.totalOneTimeAmt = this.totalAmount;
        this.buildTableLine("Total Cash After Change", "=", +this.totalAmount);
        this.onetimeCostArr.push(this.tableLine);
    }
    
    calculateCurrentLoanAmt() {
        this.loanOutstandingAmt = 0;
        const loanObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, this.loanType, false, 
            "fullName");
        if (loanObj !== undefined && loanObj.loanNbrMonths > 0){
            this.loanOutstandingAmt = loanBalanceCalcFunction(loanObj.loanTotAmt, loanObj.loanIntRate,
                loanObj.loanAmt, loanObj.loanTerm, loanObj.loanNbrMonths);
            this.prevLoanPayment = +loanObj.loanAmt;
        }
        this.setState({loanRequired: true});
    }
    
    determineLoanInfo(){
        let downPaymentPct = this.state.loanParms.pctDownPayment;
        this.loanAmt = +this.funStuffItem.purchasePrice;
        this.totalLoanBeforeDownPayment = this.loanAmt - this.loanOutstandingAmt;
        this.downPaymentAmt = +(+this.funStuffItem.purchasePrice * +downPaymentPct).toFixed(2);
        this.totalLoanAmt = +this.totalLoanBeforeDownPayment - +this.downPaymentAmt;
        if (this.totalLoanAmt > this.gameInfo.gameData.currentCash ||
            this.totalLoanAmt > 10000){
                this.loanRequired = true;
                const boatLoanAmtObj = {downPayment: this.downPaymentAmt, 
                    boatPrice: +this.funStuffItem.purchasePrice, totalLoanAmt: this.totalLoanAmt};
                this.loanAmtArr = buildArrayForDisplay("FunStuff", "BoatLoanAmts", boatLoanAmtObj);
        }
    }

    calculateSellPrice(){
        let totNbrMonths = calcNbrMonthsBtwnDates(this.state.currentItem.startDate, 
            this.gameInfo.gameData.currentGameDate);
        let nbrYears = (+totNbrMonths / 12).toFixed(2);
        let purchasePrice = this.state.currentItem.purchasePrice;
        if (purchasePrice === undefined){
            const loanObj = findRemoveTableItem(this.gameInfo.gameData.loanTable, "boatLoan", false);
            purchasePrice = loanObj.loanTotAmt;
        }
        let reducedAmt = +purchasePrice * +this.depreciationPct * +nbrYears + 2000;
        this.sellPrice = +purchasePrice - +reducedAmt;
    }

    determineOngoingCosts() {
        let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
        this.ongoingCostArr = [];
           
        if (this.updateType == "add" || this.updateType == "change") {
            this.prevLoanPayment = 0;
            this.monthLoanAmt = 0;
            if (this.loanRequired == true) {
                this.calculateOngoingLoanAmt();
            }
           
            const typeName = this.category + "MonthlyAmts";
            const ongoingCostObj = {...this.ongoingAmts, loanPayment: this.monthLoanAmt, 
                monthIncome: +incomeObj.income, prevLoanPayment: this.prevLoanPayment}
            this.ongoingCostArr = buildArrayForDisplay("FunStuff", typeName, ongoingCostObj  );
            for (let i=0; i<this.ongoingCostArr.length; i++){
                if (this.ongoingCostArr[i].tableLine[1].fieldValue === "-"){
                    const tableFieldLine = this.ongoingCostArr[i].tableLine[0];
                    tableFieldLine.fieldValue = 
                        tableFieldLine.fieldValue + " (" + this.funStuffItem.type + ")";
                }
            }
        }
        if (this.updateType == "change" || this.updateType == "remove") {
            if (this.updateType === "remove"){
                this.buildTableLine("Current Monthly Net Income", "", +incomeObj.income);
                this.ongoingCostArr.push(this.tableLine);
            }       
            let expenseTable = this.gameInfo.gameData.expenseTable;
            for (let i = 0; i < expenseTable.length; i++) {
                if (expenseTable[i].expItem == this.expItem && expenseTable[i].expNbrMonths != 0) {
                    this.buildTableLine(expenseTable[i].expDesc, "+", +expenseTable[i].expAmount);
                    this.ongoingCostArr.push(this.tableLine);
                }
            }
        }
        this.determineTotalAmt(this.ongoingCostArr);
        this.buildTableLine("Monthly Net Income After Change", "=", this.totalAmount);
        this.buildNewOngoingCostArr();
        this.ongoingCostArr.push(this.tableLine);
    }

    calculateOngoingLoanAmt() {
        let totalLoanAmt = +this.loanAmt - +this.loanOutstandingAmt;
        let intRate = this.state.loanParms.intRate;
        let loanTerm = this.state.loanParms.loanTerm;
        let loanDesc = "Loan for " + this.funStuffItem.type;
        this.monthLoanAmt = monthlyLoanAmt(this.loanAmt, intRate, loanTerm);
        let loanNbrMonths = loanTerm * 12;
        this.newLoanObj = {
            loanType: this.loanType, loanDesc: loanDesc,
            loanAmt: this.monthLoanAmt, loanStartDate: this.gameInfo.gameData.currentGameDate,
            loanNbrMonths: loanNbrMonths, loanTotAmt: totalLoanAmt,
            loanIntRate: intRate, loanTerm: loanTerm
        };
    }

    determineTotalAmt(costArr){
        this.totalAmount = 0;
        for (let i=0; i<costArr.length; i++){
            let amount = +costArr[i].tableLine[2].fieldValue;
            if (costArr[i].tableLine[1].fieldValue === "-"){
                    amount = -1 * amount;
            }
        this.totalAmount += +amount;
        }
    }

    buildTableLine(fieldName, fieldSign, fieldValue){
        let tableArr = [];
        let tableObj = {};
        tableObj={fieldValue: fieldName, fieldType: "text", 
            fieldClass: "text-left font-weight-bold"}
        tableArr.push(tableObj);
        tableObj={fieldValue: fieldSign, fieldType: "text", fieldClass: "text-center"};
        tableArr.push(tableObj);
        tableObj={fieldValue: fieldValue, fieldType: "amount", fieldClass: "text-right"};
        if (fieldSign === "-"){
            tableObj.fieldClass += " text-danger";
        }
        tableArr.push(tableObj);
        this.tableLine={tableLine: tableArr};
    }

    buildNewOngoingCostArr(){
        this.newOngoingCostArr = []
        /*
        tableLine
        Array(3)
             {fieldValue: 'Current Cash', fieldType: 'text', fieldClass: 'text-left font-weight-bold'}
             {fieldValue: '', fieldType: 'text', fieldClass: 'text-center'}
             {fieldValue: '26259.93', fieldType: 'amount', fieldClass: 'text-right'}
             */
        for (let i=0; i<this.ongoingCostArr.length; i++){
             const ongoingCostTableLine = this.ongoingCostArr[i].tableLine;
             if (ongoingCostTableLine[1].fieldValue === "-"){
                  const ongoingCostObj = {label: ongoingCostTableLine[0].fieldValue,
                        amount: ongoingCostTableLine[2].fieldValue}
                  this.newOngoingCostArr.push(ongoingCostObj);
             }
        }
    }

    setTitleLit() {
        switch (this.updateType) {
            case "add":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Adopt a " + this.categoryName;
                } else {
                    this.titleUpdateLit = "- Purchase a " + this.categoryName;
                }
                break;
            case "change":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Surrender your " + this.state.currentItem.category + " and Adopt a new " + this.categoryName;
                } else {
                    this.titleUpdateLit = "- Sell your current " + this.state.currentItem.category + " and Purchase a new " + this.categoryName;
                }
                break;
            case "remove":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Surrender your " + this.state.currentItem.category;
                } else {
                    this.titleUpdateLit = "- Sell your " + this.state.currentItem.category;
                }
                break;
        }
    }

    backButton() {
        switch (this.state.step) {
            case "SelectType":
                this.props.onClose("back");
                break;
            case "SelectOption":
                this.state.step = "Back";
                this.createPictureArrForType();
                break;
            case "SelectItem":
                this.state.step = "Back";
                this.createPictureArrForOptions();
                break;
            case "ItemSelected":
                this.state.step = "Back";
                this.createPictureArrForList();
                break;
        }
        // if the next step is still back after this, then exit to the unlocked features selection
        if (this.state.step == "Back") {
            this.props.history.push('/game-board');
        }
    }

    displayFriendModal() {
        this.state.openFriendModal = true;
        this.askAFriendInfo = { topic: "setupSavings" }
    }

    closeFriendModal() {
        this.state.openFriendModal = false;
    }

    render() {
        if (this.state.step == "SelectType" || this.state.step == "SelectOption" || 
            this.state.step == "SelectItem") {
            this.pictureDisplay = this.state.pictureArr.map((picturesList, key) =>
                <ImageCard imageInfo={picturesList} key={key}
                    disabled={this.state.disabled}
                    onClick={() => this.onClick(picturesList)}
                />
            );
        }
        if (this.state.step == "ItemSelected") {
            this.displayLoanAmts = this.loanAmtArr.map((amountArr,key) =>
                <TableDisplayCard key={key}  outInfo={amountArr}  />
            );
            this.displayOngoingAmts = this.ongoingCostArr.map((amountArr,key) =>
                <TableDisplayCard key={key}  outInfo={amountArr}  />
            );
            this.displayOnetimeAmts = this.onetimeCostArr.map((amountArr,key) =>
                <TableDisplayCard key={key}  outInfo={amountArr}  />
            );
        }
        this.gutters = setModalSize(this.props.screenSize);
       
    return <>

        <Modal show={this.props.show} onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
            dialogClassName={this.gutters} animation={false} >
            <Modal.Header className="backgroundTopBottom" closeButton>
                <Modal.Title className="text-center">Select Fun Stuff&nbsp; {this.titleUpdateLit}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-4 my-4">
                    {this.state.step == "SelectType" || this.state.step == "SelectOption" || this.state.step == "SelectItem" ?
                        <div>
                            {this.state.step == "SelectType" ?
                                <div>Click on the button below the picture to select an item.</div>
                            :
                                <div>Click on the picture to select the fun stuff item that you would like to get.</div>
                            }
                            <div className="cursor-is-pointer">
                                {this.pictureDisplay}
                            </div>
                        </div>
                    :
                        null
                    }
                </div>

                {this.state.step == "ItemSelected" ?
                    <span>
                        {this.updateType != "remove"  && this.itemText != "" ?
                            <div>{this.itemText}</div>
                        :
                            null
                        }
                        {this.inputType == "name" && this.updateType != "remove" ?
                            <div className="mt-2">
                                <label className="font-weight-bold" htmlFor="itemName">
                                        Pick a Name:&nbsp;&nbsp;</label>
                                <input type="text" className="col-med" id="itemName" name="itemName"></input>
                                <span className="text-danger">
                                        &nbsp;{this.nameError}</span>
                            </div>
                        :
                            null
                        }
                        <div className="text-left font-weight-bold ml-4 mx-0">
                                Total Mood Point Change:&nbsp; &nbsp; {this.totalMoodPoints}
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-7">
                                <table className="table-hover table-condensed table-wide">
                                    <tbody>
                                        {this.loanRequired === true ?
                                            <span>
                                                <tr>
                                                    <td className="mt-2 text-center font-weight-bold" colSpan='3'>
                                                    Loan Amounts
                                                    </td>
                                                </tr>
                                                {this.displayLoanAmts}
                                                <tr>
                                                    <td className="text-white font-weight-bold" colSpan="3">xxxxxx</td>
                                                </tr>
                                            </span>
                                        :
                                                null
                                        }
                                        <tr>
                                            <td className="mt-2 text-center font-weight-bold" colSpan='3'>
                                                Ongoing (Monthly) Costs
                                            </td>
                                        </tr>
                                            {this.displayOngoingAmts}
                                        <tr>
                                            <td className="text-white font-weight-bold" colSpan="3">xxxxxx</td>
                                        </tr>
                                        <tr>
                                            <td className="mt-2 text-center font-weight-bold"  colSpan='3'>
                                                One-Time Costs
                                            </td>
                                            </tr>
                                            {this.displayOnetimeAmts}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-2"></div>
                            <div className="col-sm-3">
                            <div className="mt-4 text-white">xxxxxxx</div>
                            <div className="mt-4 text-white">xxxxxxx</div>
                            <img
                                src={this.selectItemURL}
                                className="funstuff-item-picture picture-border picture-container text-center" 
                                alt="image" />
                            
                                <div className="font-weight-bold text-center">
                                    {this.selectItemType}
                                </div>
                            </div>
                        </div>
                    </span>
                :
                    null
                }


            </Modal.Body>
            <Modal.Footer className="backgroundTopBottom">
                <div className={this.msgClass}>{this.message}</div>
                    {this.state.step == "ItemSelected" ?
                        <span>
                            <Button className="mr-2" variant="secondary" onClick={() => this.editFormFields()} >
                                Submit
                            </Button>

                        </span>
                        :
                        null
                    }
                    <Button variant="dark" onClick={() => this.backButton()}>
                        Back
                    </Button>
                    <Button variant="primary" onClick={() => this.props.onClose("none")}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    }

    editFormFields() {
        this.validData = true;
        if (this.updateType != "remove") {
            if (this.inputType == "name") {
                this.itemName = document.getElementById("itemName").value;
                this.nameError = "";
                if (this.itemName == "") {
                    this.validData = false;
                    this.nameError = "Name cannot be blank.";
                }
            }
        }
        if (this.validData == true) {
            this.saveFunStuffSelection();
        }
        // create new router to update unlocked, icon, condition, cash, mood pts, expense, 
        // add mood point update to the unlocked features update (make chgs to other processes that use this router)
        // consider/add cost of getting rid of an item (both for change and remove)
    }

    saveFunStuffSelection() {
        if (this.updateType !== "decline"){
            this.updatePlayerGameInfo();
            this.updateFunStuffData();
            this.updatePictureInfo();
            this.props.checkUnlockedFeatures();
        }
        this.props.onClose("complete");
    }

    updatePlayerGameInfo() {
        let cashAmt = (+this.totalOneTimeAmt).toFixed(2);
        let moodPoints = this.gameInfo.gameData.currentMoodPoints;
        this.newExpenseTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable));
        this.newLoanTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.loanTable));
       
        moodPoints = (+moodPoints + +this.totalMoodPoints);
        this.updateConditionTable();
        this.createNewExpenseTableObject();
        this.createNewLoanTableObject();
        this.updateUnlockedFeatures();
        this.gameInfo.gameData.currentCash = cashAmt;
        this.gameInfo.gameData.currentMoodPoints = moodPoints;
        this.gameInfo.gameData.expenseTable = this.newExpenseTable;
        this.gameInfo.gameData.loanTable = this.newLoanTable;
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        this.bodyObj = {
            "currentCash": this.gameInfo.gameData.currentCash,
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "expenseTable": this.gameInfo.gameData.expenseTable,
            "loanTable": this.gameInfo.gameData.loanTable,
            "conditionsTable": this.gameInfo.gameData.conditionsTable,
            "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
            "iconTable": this.gameInfo.gameData.iconTable,
        };
        const funcName = "updateGameInfoGeneral";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    // "update of game general Info was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
    
    }
    
    updateConditionTable() {
        if (this.updateType == "remove" || this.updateType == "change") {
            let maxIndx = this.gameInfo.gameData.conditionsTable.length - 1;
            for (let i = maxIndx; i >= 0; i--) {
                if (this.gameInfo.gameData.conditionsTable[i].condName == this.state.currentItem.condName) {
                    this.gameInfo.gameData.conditionsTable.splice(i, 1);
                    break;
                }
            }
        }
        if (this.updateType == "add" || this.updateType == "change") {
            let eventCondition = 
                this.gameInfo.eventConditions.find(elem => elem.condName == this.funStuffItem.condName);    
            let conditionObj = { condName: this.funStuffItem.condName,
                conditionClass: eventCondition.conditionClass, duration: -1 };
            this.gameInfo.gameData.conditionsTable.push(conditionObj);
        }
    }

    createNewExpenseTableObject() {
        for (let i = 0; i < this.newExpenseTable.length; i++) {
            if (this.newExpenseTable[i].expItem == this.expItem &&
                +this.newExpenseTable[i].expNbrMonths != 0) {
                this.newExpenseTable[i].expNbrMonths = 0;
            }

        }
        for (let i = 0; i < this.newOngoingCostArr.length; i++) {
            this.newExpenseObj = {
                expItem: this.expItem, expDesc: this.newOngoingCostArr[i].label,
                expAmount: this.newOngoingCostArr[i].amount, expStartDate: this.gameInfo.gameData.currentGameDate, 
                expNbrMonths: "-1", chargeCreditCard: true
            };
            this.newExpenseTable.push(this.newExpenseObj);
        }
    }

    createNewLoanTableObject() {
        for (let i = 0; i < this.newLoanTable.length; i++) {
            if (this.newLoanTable[i].loanType == this.loanType &&
                +this.newLoanTable[i].loanNbrMonths != 0) {
                this.newLoanTable[i].loanNbrMonths = 0;
            }
        }
        if (this.loanRequired === true){
            this.newLoanTable.push(this.newLoanObj);
        }
    }
 
    updateUnlockedFeatures(){
        this.featureIndx = this.gameInfo.gameData.unlockedFeatures.findIndex(elem => elem.featureName === "funStuff");
        let unlockedFeaturesObj = this.gameInfo.gameData.unlockedFeatures[this.featureIndx];
        if (+this.levelNbr < unlockedFeaturesObj.level){
             // if updating a level prior to current level, then no change should be made to unlocked features or icons!!!
        }else{
            let activeInactive = "none";
            if (+this.levelNbr === unlockedFeaturesObj.level){
                if (this.updateType === "add" || this.updateType === "change") {
                    activeInactive = "active";
                    this.updateIconTable();
                }
                if (this.updateType === "remove"){
                    activeInactive = "inactive"
                }
            }
            unlockedFeaturesObj.setup = activeInactive;
            this.gameInfo.gameData.unlockedFeatures[this.featureIndx] = unlockedFeaturesObj;
        }
    }

    updateIconTable(){
        let iconTable = this.gameInfo.gameData.iconTable;
        let startIdx = iconTable.length - 1;
        for (let i = startIdx; i >= 0; i--) {
            if (iconTable[i].iconName == "funStuffUnlocked" ||
                iconTable[i].iconName == "funStuffReminder" ||
                iconTable[i].iconName == "funStuffNewLevel") {
                iconTable.splice(i, 1);
                break;
            }
        }
        this.gameInfo.gameData.iconTable = iconTable;
    }

    updateFunStuffData() {
        if (this.gameInfo.funStuffInfo.gameID == undefined) {
            this.newFunStuffEntry = true;
        } else {
            this.newFunStuffEntry = false;
        }
        this.setBodyObj();
        this.updateFunStuff();

    }

    setBodyObj() {
        var dateCreated;
        if (this.newFunStuffEntry == true) {
            dateCreated = this.state.currentDate;
        } else {
            dateCreated = this.gameInfo.funStuffInfo.dateCreated;
        }

        if (this.updateType == "change" || this.updateType == "remove") {
     //       this.funStuffItem = "";
            let maxIndx = this.gameInfo.funStuffInfo.itemArr.length - 1;
            for (let i = maxIndx; i >= 0; i--) {
                if (this.gameInfo.funStuffInfo.itemArr[i].levelName == this.levelName) {
                    this.gameInfo.funStuffInfo.itemArr.splice(i, 1);
                }
            }
        }
        this.gameID = this.gameInfo.gameData._id;
        if (this.updateType == "add" || this.updateType == "change") {
            if (this.gameInfo.funStuffInfo.itemArr == undefined) {
                let funStuffObj = {
                    gameID: this.gameID,
                    itemArr: [],
                    dateAdded: dateCreated,
                    dateUpdated: ""
                }
                this.gameInfo.funStuffInfo = funStuffObj;
            }
            this.funStuffItem = {
                levelName: this.levelName,
                category: this.categoryName,
                type: this.funStuffItem.type,
                name: this.itemName,
                condName: this.funStuffItem.condName,
                pictureLabel: this.funStuffItem.label,
                pictureURL: this.funStuffItem.image,
                backgroundURL: this.funStuffItem.backgroundURL,
                pictureClass: this.funStuffItem.imageClass,
                startDate: this.gameInfo.gameData.currentGameDate,
            }
            this.gameInfo.funStuffInfo.itemArr.push(this.funStuffItem);
        }
        this.gameInfo.funStuffInfo.dateUpdated = this.state.currentDate;
        this.props.setGameInfo(this.gameInfo.funStuffInfo, 'funStuffInfo');
    }

    updateFunStuff() {
        const funcName = "updateFunStuff";
        const urlParm = "?gameID=" + this.gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(this.gameInfo.funStuffInfo)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        // "update fun stuff was successful"
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.gameInfo.funStuffInfo};
                        this.props.processError(errorObj);
                    }
                });
            }
            });
    }

    updatePictureInfo() {
        if (this.updateType === "add" || this.updateType === "change"){
            const pictureLabel = "funStuff-" + this.funStuffItem.pictureLabel;
            const URLValue = "./assets" + this.funStuffItem.backgroundURL;
            if (this.gameInfo.pictureInfo[pictureLabel] == undefined){
                let pictureObj = {[pictureLabel]: URLValue};
                this.gameInfo.pictureInfo = { ...this.gameInfo.pictureInfo, ...pictureObj};
                this.gameInfo.pictureInfo.funStuffInfo = true;
            }else{
                this.gameInfo.pictureInfo[pictureLabel] = URLValue;
            }
        }else{
            const currentPictureLabel = "funStuff-" + this.state.currentItem.pictureLabel;
            this.gameInfo.pictureInfo[currentPictureLabel] = "";
        }
        this.props.setGameInfo(this.gameInfo.pictureInfo, 'pictureInfo');
    }

}

export default withRouter(SetupFunStuffModal);