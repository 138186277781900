const friendHaveFunActivityDialogDataset = [
        {activityPreference: "1", commentTable: [
                "I'm not sure I have time for this right now.  Also, I don't really have " +
                        "extra money.  I will have to say no, but thanks for asking!",
                "I've been trying to save as much money as I can right now, so I don't " +
                        "want to go out. Thanks for asking."
        ]},

        {activityPreference: "2", commentTable: [
                "I guess that would be OK.  I just got my allowance from my parents so I can afford it.",
                "Hmmmm... well I could come along I guess if you want some company.",
                "Sometimes these places are really boring, but I'm happy to give it a try. "
        ]},
                    
        {activityPreference: "3", commentTable: [
                "Sure, I'll come along. But I might need to leave a bit early.",
                "Yeah, I can go, but I am a little short of cash so I may not be able to afford any 'extras'.",
        ]},

        {activityPreference: "4", commentTable: [
                "Sure I'll come along!  I'm always up for doing something fun.",
                "Absolutely, I think this sounds like it would be a lot of fun." ,
        ]},
]
 
export default friendHaveFunActivityDialogDataset;