export const MapDataToColumnsDataset = [
        //  when field name is preceded by '###', it means that the value in this field is the name of
        //  a field that should come from the fieldValue array
        {datasetName: "default", nbrColumns: 3, 
            columnArr: [{fieldName: "textLine", fieldType: "text", class: "text-left font-weight-bold"},
                        {fieldName: "plusMinus", fieldType: "text", class: "text-center", condClass: "text-center text-danger"},
                        {fieldName: "amount", fieldType: "amount", class: "text-right", condClass: "text-right text-danger"}],
            classCondArr:  ["plusMinus", "amount"]
        },
     
]

export default MapDataToColumnsDataset;