import "./EndGame/end-game.css"
import { Button } from "react-bootstrap";
import {Component} from 'react';
import { withRouter } from "react-router";
import ReactPlayer from "react-player";
import LeaderBoardModal from '../game-views/leader_board_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {calculateMoodRetirementScores} from '../game-views/objects/calculate_mood_retirement_scores';
import MoodRetirementStatusModal from "../game-views/mood_retirement_status_modal";
import { standardNumberFormat } from "../../objects/CommonUse/double_functions";
import {getCurrentDateTime} from "../../objects/CommonUse/get_current_date_time";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { calcMonthlyIncome } from "../../objects/CommonUse/calcMonthlyIncome";
import FinancialAdvisorModal from "../shared-components/financial_advisor_modal";
import ReasonForGameEndText from "./text/reason_for_game_end_text";
import replaceTextInString from "../../objects/CommonUse/replace_text_in_string";

class EndGame extends Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            callRender: false,
            modalOpen: false,
            headers: setHeadersForFetch(),
            loadingActivityTracker: true,
            accountNbr: "game",
            updateLeaderBoard: false,
            dateObj: getCurrentDateTime(),
            subProcess: "endGame",
            environment: Config.dataURL.currentEnv,
            archiveData: false,
            gameArchived: false,
            creditCardArchived: false,
            savingsArchived: false,
            funStuffArchived: false,
            gamePurchasesArchived: false,
            activityTrackerArchived: false,
            deleteData: false,
            gameDeleted: false,
            creditCardDeleted: false,
            savingsDeleted: false,
            funStuffDeleted: false,
            timeWizardDeleted: false,
            gamePurchasesDeleted: false,
            activityTrackerDeleted: false,
        
            openFinModal: true,
        }
        this.finishType = "fullGame";
        this.activityTracker = null;
        this.status = "InitialDisplay";
        this.moodScore = 0;
        this.retirementScore = 0;
        this.combinedScore = 0;
        this.archivesInitiated = false;
        this.deletesInitiated = false;
        this.loadingActivityTracker = false;
        this.activityTrackerArchiveCount = 0;
        this.returnToHomePageInitiated = false;
        this.totalNbrGameYears = (this.props.totalNbrGameMonths / 12).toFixed(0);
        this.initialProcess();
    }    

    initialProcess(){
        this.determineEndOfGameText();
        this.calculateFinalScores();
        this.loadActivityTracker();
        if (this.props.endGameReason !== "TimeElapsed"){
             this.finishType = "forceEnd";
        }
    }

    determineEndOfGameText(){
        const endGameTextObj = ReasonForGameEndText.find(elem => elem.reason === this.props.endGameReason);
        this.endGameTitle = endGameTextObj.title;
        this.endGameText = endGameTextObj.text;
        if (this.endGameText.includes("||")){
            let replaceObj = { string: this.totalNbrGameYears };
            this.endGameText =
                replaceTextInString(this.endGameText, replaceObj, "totalNbrGameYears", "string");
        }
    }

    calculateFinalScores(){
        let gameScores = calculateMoodRetirementScores(this.gameInfo.gameData.currentMoodPoints,
            this.gameInfo.gameData.currentCash, this.gameInfo.savingsInfo.totalSavingsAmt,
            this.gameInfo.gameData.spendingHistory, this.gameInfo.gameData.gameNbrMonths,
            this.props.totalNbrGameMonths);
        this.moodScore = (+gameScores.moodScore).toFixed(0);
        this.retirementScore = (+gameScores.retirementScore).toFixed(0);
        this.combinedScore = +gameScores.combinedScore;
    }

    loadActivityTracker(){
        const funcName = "loadAllGameActivityTracker";
        let gameID = this.gameInfo.gameData._id;
        const urlParm = "?gameID=" + gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
             {method:'GET', headers: this.state.headers})
             .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        // fetch of game activity tracker was successful;
                        this.activityTracker = output.body;
                        this.loadingActivityTracker = true;
                    }else{
                        if (statusCode === 250){
                            // fetch successful but no record found;
                            this.activityTracker = "noData";
                            this.loadingActivityTracker = true;
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
                }
        });
    }

    displayMoodRetirementModal(){
        this.status = "DisplayMoodRetirement";
        this.setState({modalOpen: true});
    }

    render() {
        if (this.state.archiveData === true & this.archivesInitiated === false){
                this.archiveGameRelatedCollections();
        }
       
        if (this.deletesInitiated === false && this.state.gameArchived === true &&
            this.state.creditCardArchived === true && this.state.savingsArchived === true &&
            this.state.funStuffArchived === true && this.state.activityTrackerArchived === true &&
            this.state.gamePurchasesArchived === true){
                this.deleteGameRelatedCollections();
        }

        if (this.returnToHomePageInitiated === false && this.state.gameDeleted === true &&
            this.state.creditCardDeleted === true && this.state.savingsDeleted === true &&
            this.state.funStuffDeleted === true && this.state.timeWizardDeleted === true &&
            this.state.activityTrackerDeleted === true && this.state.gamePurchasesDeleted === true){
                this.returnToHomePage();
        }

        let screenParms = setScreenSizeParameters(this.props.screenSize, "endGame");
        this.className = screenParms.columnClass;
           
    return <>
             <div className={this.className}>
                <div className="card mt-3 mr-3 rounded-corners-lg text-center height-max">
                    <h3 className="mt-2">Finish Playing Your Game</h3> 
                    <div className="mt-4">
                        <h4>{this.endGameTitle}</h4>
                        <div className="mx-4 mt-2 text-left">
                            {this.endGameText}                       
                        </div>
                    </div>
                
                    <div className="mt-4 mx-4 ">
                        <h4>Final Game Scores</h4>
                        <table className="row justify-content-center gameChoiceModalHdrFtrBackground ">
                            <tbody>
                                <tr>
                                    <th className="col-sm-8 text-left">Game Item</th>
                                    <th className="col-sm-4 text-right">Score</th>
                                </tr>
                                <tr>
                                    <td className="col-sm-8 text-left">Mood Score</td>
                                    <td className="col-sm-4 text-right">{standardNumberFormat(this.moodScore)}</td>
                                </tr>
                                <tr>
                                    <td className="col-sm-8 text-left">Retirement score</td>
                                    <td className="col-sm-4 text-right">{standardNumberFormat(this.retirementScore)}</td>
                                </tr>
                                <tr>
                                    <th className="col-sm-8 text-left">Combined Score</th>
                                    <th className="col-sm-4 text-right">{standardNumberFormat(this.combinedScore)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.status === "DisplayMoodRetirement" ?
                        <MoodRetirementStatusModal modalOpen={this.state.modalOpen} 
                            onClose={(moodRetirementScoreObj) => this.saveScores(moodRetirementScoreObj)}
                            gameInfo = {() => this.props.gameInfo()}
                        />
                    :
                        null
                    }
                    {this.status === "DisplayLeaderBoard" ?
                        <div>
                            <LeaderBoardModal modalOpen={this.state.modalOpen} 
                                playerSelected={true}    
                                accountNbr={this.state.accountNbr}
                                finishType={this.finishType}
                                onClose={(updateLeaderBoard) => this.closeLeaderBoardModal(updateLeaderBoard)} 
                                gameInfo = {() => this.props.gameInfo()}
                            />
                        </div>
                    :
                        null
                    }       
                    {this.status === "DisplayVideo" ?
                        <span className="mt-0 ml-0">  
                            <div className="videoDisplay">
                                <ReactPlayer className="videoDisplay" 
                                    url="./assets/video.mp4" type="video/mp4" playing 
                                    onClick ={()=> this.turnVideoOff()}
                                    onEnded = {()=>this.turnVideoOff()}
                                />
                           </div>
                       </span>
                    :
                        <div className="mt-4">   
                            <img src="./assets/sidebar_icon.png" className="train_icon_endgame_img mr-5"
                                alt="train"/>   
                        </div>  
                    }
                    
                    {this.status === "InitialDisplay" ?
                        <div className= "text-right mt-4">
                            <Button variant="primary"  onClick={() => this.displayMoodRetirementModal()}>
                                End My Game
                            </Button>
                            {this.props.endGameReason === "PlayersChoice" ?
                                <Button className="mx-2" variant="secondary"  onClick={() => this.goToGameBoard()}>
                                    Back to GameBoard
                                </Button>
                            :
                                null
                            }
                        </div>
                    :   
                            null
                    }

                    {this.status === "FinancialAdvice"  ?
                        <FinancialAdvisorModal 
                            modalOpen = {this.state.openFinModal}
                            gameInfo = {() => this.props.gameInfo()}
                            adviceType = {"endGame"}
                            monthlyTotalAmt ={this.monthlyTotalAmt}
                            onClose = {() => this.closeFinancialAdvisorModal()}
                        />
                    :
                        null
                    }

                    {this.status === "DisplayTrainVideo" ?
                       <WaitTimeSpinner />
                    :
                        null
                    }
                </div>
                  
            </div>
      </>
    }

turnVideoOff(){
    this.status = "displayTrainVideo";
    this.setState({modalOpen: true});
}

goToGameBoard(){
    this.props.history.push('/game-board');
}

saveScores(scoreObj){
    this.retirementScore = scoreObj.retirementScore;
    this.moodScore = scoreObj.moodScore;
    this.combinedScore = scoreObj.combinedScore;
    this.closeModal("MoodRetirement");
}

closeModal(){
     if (this.status === "DisplayMoodRetirement"){
        this.status = "DisplayLeaderBoard";
        this.setState({modalOpen: true});
     }else{
        this.setState({modalOpen: false});
     }
}

closeLeaderBoardModal(updateLeaderBoard){
    if (updateLeaderBoard === true){
        this.updateLeaderBoard();
    }
    this.monthlyIncomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
        this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
    this.monthlyTotalAmt = this.monthlyIncomeObj.income;
    this.status = "FinancialAdvice";
    this.setState({callRender: true});
}

closeFinancialAdvisorModal(){
    this.status = ""
    // the following turns off the fireworks video... if decide to add back, need to determine
    // when it should be displayed (current seems way too late in the end game process)
    //  if (this.props.endGameReason === "TimeElapsed"){
    //      this.status = "DisplayVideo"
    // }else{
    this.status = "DisplayTrainVideo";
    // }
    this.setState({archiveData: true});
}

archiveGameRelatedCollections(){
    this.archivesInitiated = true;
    this.saveArchivedGame();
    if (this.activityTracker === "noData"){
        this.setState({activityTrackerArchived: true});
    }else{
        this.activityTrackerCount = this.activityTracker.length;
        if (this.activityTrackerArchiveCount < this.activityTrackerCount){
            for (let i=0; i<this.activityTrackerCount; i++){
                let activityTracker = this.activityTracker[i];
                this.saveArchivedActivityTracker(activityTracker);
            }
        }
    }
    if (this.gameInfo.creditCardInfo.gameID === undefined){
        this.setState({creditCardArchived: true});
    }else{
        this.saveArchivedCreditCardInfo();
    }
    if (this.gameInfo.savingsInfo.gameID === undefined){
        this.setState({savingsArchived: true});
    }else{
        this.saveArchivedSavingsAmounts();
    }
    if (this.gameInfo.funStuffInfo.gameID === undefined){ 
        this.setState({funStuffArchived: true});
    }else{   
        this.saveArchivedFunStuff();
    }
    if (this.gameInfo.gamePurchases.gameID === undefined){ 
        this.setState({gamePurchasesArchived: true});
    }else{   
        this.saveArchivedGamePurchases();
    }
    
}

deleteGameRelatedCollections(){
    this.deletesInitiated = true;
    this.deleteGame();
    if (this.activityTracker === "noData"){
        this.setState({activityTrackerDeleted: true});
    }else{    
        this.deleteActivityTracker();
    }
    if (this.gameInfo.creditCardInfo.gameID === undefined){
        this.setState({creditCardDeleted: true});
    }else{
        this.deleteCreditCardInfo();
    }
    if (this.gameInfo.savingsInfo.gameID === undefined){
        this.setState({savingsDeleted: true});
    }else{
        this.deleteSavingsAmounts();
    }
    if (this.gameInfo.funStuffInfo.gameID === undefined){
        this.setState({funStuffDeleted: true});
    }else{    
        this.deleteFunStuff();
    }
    if (this.gameInfo.timeWizardInfo.gameID === undefined){
        this.setState({timeWizardDeleted: true});
    }else{
        this.deleteTimeWizard();
    }
    if (this.gameInfo.gamePurchases.gameID === undefined){
        this.setState({gamePurchasesDeleted: true});
    }else{    
        this.deleteGamePurchases();
    }
}

returnToHomePage(){
    this.returnToHomePageInitiated = true;
    alert("Your game has ended...  thanks for playing!!! " +
        "Click on the 'Game' button if you want to " +
            "start a new game.")
    this.props.history.push('/Homepage');
}

updateLeaderBoard(){
    let currentDateTime = getCurrentDateTime();
    const nbrMonthsPlayed = +this.props.totalNbrGameMonths - +this.gameInfo.gameData.gameNbrMonths;
    let bodyObj = {
        user: this.gameInfo.gameData.user,
        startGameDate: this.gameInfo.gameData.startGameDate,
        moodScore: this.moodScore,
        retirementScore: this.retirementScore,
        combinedScore: this.combinedScore,
        nbrGameMonths: nbrMonthsPlayed,
        account: "game",
        dateUpdated: currentDateTime.date,
    };
    const funcName = "saveLeaderBoard";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
               //  update was successful
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

saveArchivedGame() {
    this.gameID = this.gameInfo.gameData._id;
    if (this.gameInfo.gameData.gameTemplate === undefined){
        this.gameInfo.gameData = {...this.gameInfo.gameData, gameTemplate: "none"};
    }
    let timeWizardInfo = {};
    if (this.gameInfo.timeWizardInfo !== undefined){ 
        timeWizardInfo = this.gameInfo.timeWizardInfo;
    }
    this.bodyObj = { ...this.gameInfo.gameData, timeWizardInfo: timeWizardInfo};
    const funcName = "saveArchivedGameData";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
            {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.setState({gameArchived: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
                }
            });
}

saveArchivedActivityTracker(activityTracker){
    const bodyObj = {...activityTracker, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedActivityTracker";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                   this.activityTrackerArchiveCount += 1;
                   if (this.activityTrackerArchiveCount >= this.activityTrackerCount){ 
                        this.setState({activityTrackerArchived: true});
                   }
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

saveArchivedCreditCardInfo(){
    let bodyObj = {...this.gameInfo.creditCardInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedCreditCardInfo";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.setState({creditCardArchived: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

saveArchivedSavingsAmounts(){
    let bodyObj = {...this.gameInfo.savingsInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedSavingsAmounts";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({savingsArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}


saveArchivedFunStuff(){
    let bodyObj = {...this.gameInfo.funStuffInfo, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedFunStuff";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({funStuffArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

saveArchivedGamePurchases(){
    let bodyObj = {...this.gameInfo.gamePurchases, user: this.gameInfo.gameData.user};
    const funcName = "saveArchivedGamePurchases";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
        method:'POST',
        headers: this.state.headers,
        body: JSON.stringify(bodyObj)
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({gamePurchasesArchived: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteActivityTracker(){
    const funcName = "deleteActivityTracker";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
            method:'DELETE',
            headers: this.state.headers
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.setState({activityTrackerDeleted: true})
                }else{
                    const parmObj={gameID: this.gameID};
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: parmObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

deleteCreditCardInfo(){
    const funcName = "deleteCreditCardInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({creditCardDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}

deleteSavingsAmounts(){
    const funcName = "deleteSavingsInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({savingsDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteFunStuff(){
    const funcName = "deleteFunStuffInfo";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({funStuffDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteTimeWizard(){
    const funcName = "deleteTimeWizard";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({timeWizardDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteGamePurchases(){
    const funcName = "deleteGamePurchases";
    const urlParm = "?gameID=" + this.gameID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({gamePurchasesDeleted: true});
            }else{
                const parmObj={gameID: this.gameID};
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: parmObj};
                this.props.processError(errorObj);
            }
        });
        }
    });
}   

deleteGame() {
    const funcName = "endGame";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
    {
            method:'DELETE',
            headers: this.state.headers
    })
    .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.setState({gameDeleted: true});
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message};
                this.props.processError(errorObj);
            }
        });
        }
    });
}
   
}
export default withRouter(EndGame);