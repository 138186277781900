const carGameChoiceDialogDataset = [
          {carType: 100, ageNbr: 100, commentTable: [
               {friendName: "Jennifer",  comment: "This is not a car I would buy.  It is small which is nice, " +
               "but it is pretty old."},
               {friendName: "Charlie", comment: "I don't know a lot about cars... but...If it starts up and doesn't " +
               "make any strange noises, seems like a good deal to me."}, 
               {friendName: "Cindy", comment: "It is certainly a good price.  But it is pretty old, I think you  " +
               "might end up paying a lot for maintaining this car." },
               {friendName: "Cameron", comment: "I wouldn't buy this car, it is too small and way too old.  I " +
               "mean, you can't impress people with a car that looks like that!"}]
          },
          {carType: 100, ageNbr: 200, commentTable: [
               {friendName: "Jennifer",  comment: "This car isn't too bad.  I like that it is small, but it is " +
               "definitely not a new car."},
               {friendName: "Charlie", comment: "This car looks pretty good. It has 4 tires and steering wheel. " +
               "What else do you need?"}, 
               {friendName: "Cindy", comment: "This seems like a smart choice.  The price is not too bad also " +
               "it is a smaller car so it should get good gas mileage.  It also comes with a good warranty." },
               {friendName: "Cameron", comment: "This would not be my choice. I mean, it's not a very classy car " +
               "do you think?"}]
          },
          {carType: 100, ageNbr: 300, commentTable: [
               {friendName: "Jennifer",  comment: "I like this car.  It is small and brand new. " +
               "That way you can get a dealer's warranty which might come in handy."},
               {friendName: "Charlie", comment: "This one is really shiny.  It also smells really nice inside. " +
               "I like that new car smell.  Don't you?"}, 
               {friendName: "Cindy", comment: "I don't know about buying a brand new car.  The value of this car will go down " +
               "a lot just by driving this off the lot.  Used cars hold their value a lot longer." },
               {friendName: "Cameron", comment: "This isn't too bad but it is still not a car that will impress " +
               "people."}]
          },
          {carType: 200, ageNbr: 100, commentTable: [
               {friendName: "Jennifer",  comment: "This car is too big for me.  Plus, it is old and will " +
               "probably break down tomorrow."},
               {friendName: "Charlie", comment: "I like the color of this car.  It isn't new but it is new " +
               "enough don't you think?"}, 
               {friendName: "Cindy", comment: "This looks like a good price for the car.  It is older so perhaps " +
               "you should check to see if it comes with some kind of warranty." },
               {friendName: "Cameron", comment: "This looks like a car that my grandmother would buy.  It looks " +
               "really clunky to me!"}]
          },
          {carType: 200, ageNbr: 200, commentTable: [
               {friendName: "Jennifer",  comment: "This car is relatively new, but it is too big " +
               "for my taste."},
               {friendName: "Charlie", comment: "This car looks pretty good. It has 4 tires and steering wheel. " +
               "What else do you need?"}, 
               {friendName: "Cindy", comment: "I like this car.  It has a little extra room if you have " +
               "passengers, plus it isn't very old so it comes with a lot of newer features." },
               {friendName: "Cameron", comment: "At least this car is newer, but it isn't a car that would " +
               "impress people, right?"}]
          },
          {carType: 200, ageNbr: 300, commentTable: [
               {friendName: "Jennifer",  comment: "This is not a car that I would choose. I mean, it is brand new "  +
               "but I like cars that are smaller.  Smaller cars are easier to drive and park."},
               {friendName: "Charlie", comment: "The tires on this car look really cool! Also, did you notice it " +
               "has blue tooth?"}, 
               {friendName: "Cindy", comment: "This car seems pretty expensive.  Probably because it is brand new. " +
               "I don't think this is a good choice." },
               {friendName: "Cameron", comment: "Well, this car is brand new.  But I can't say that I like the way " +
               "it looks. It certainly is not a car that I would buy."}]
          },
          {carType: 300, ageNbr: 100, commentTable: [
               {friendName: "Jennifer",  comment: "I wouldn't buy this car.  It is too big and too old. "},
               {friendName: "Charlie", comment: "Actually, an SUV could be sort of fun.  We could go off-roading " +
               "together. "}, 
               {friendName: "Cindy", comment: "An SUV might be a good choice when driving in snow or rain.  But " +
               "it won't get very good gas mileage and this one is sort of old."},
               {friendName: "Cameron", comment: "An SUV could be sort of cool.  But this one is really too old."}]
          },
          {carType: 300, ageNbr: 200, commentTable: [
               {friendName: "Jennifer",  comment: "I guess this isn't too bad.  It is still too big for my taste " +
               "but at least it's not that old."},
               {friendName: "Charlie", comment: "I like this one.  It is almost brand new and I sort of like the " +
               "color.  I really like the idea of going off-roading sometime."}, 
               {friendName: "Cindy", comment: "This is not a bad choice if you are going to get An SUV.  It still " +
               "might not get as good a gas mileage as a smaller car, but it will get " +
               "better gas mileage than an old SUV."},
               {friendName: "Cameron", comment: "This SUV is not bad, but I think a brand new SUV is the ticket!"}]
          },
          {carType: 300, ageNbr: 300, commentTable: [
               {friendName: "Jennifer",  comment: "I like the new car smell on this one, but I wouldn't buy it. " +
               "It's too expensive and way too big for me."},
               {friendName: "Charlie", comment: "This could work.  Brand new and an SUV, what's not to like?"}, 
               {friendName: "Cindy", comment: "Hmmmm... can't say that I am a fan of a brand new car.  They " +  
               "go down in value the minute you drive them off the lot."},
               {friendName: "Cameron", comment: "Good choice!!  The only thing better than this would be a sports " +
                "car!"}]
          },
          {carType: 400, ageNbr: 100, commentTable: [
               {friendName: "Jennifer",  comment: "This is a great small car.  But seems a little pricey, especially " +
               "given that it's pretty old."},
               {friendName: "Charlie", comment: "I'm not a fan of sports cars.  My best friend had one in high school " +
               "and got in a bad accident cause he was racing around. He ended up being OK but the car was totalled."}, 
               {friendName: "Cindy", comment: "For me, this would be a hard 'No'. Sports cars really are not " +  
               "practical!"},
               {friendName: "Cameron", comment: "Now this is a cool car!!  But it is too old to be a good choice."}]
          },
          {carType: 400, ageNbr: 200, commentTable: [
               {friendName: "Jennifer",  comment: "This car looks really cool!  But did you see the price? " +
               "I guess if you can afford it, right?"},
               {friendName: "Charlie", comment: "I know this looks really neat, but I don't think a sports car " +
               "is a good idea."}, 
               {friendName: "Cindy", comment: "I can't recommend this.  It is expensive to buy and there are a lot " +
               "of extra costs with sports cars. "  },
               {friendName: "Cameron", comment: "I could go for this!  It's not brand new but it looks pretty good. " }]
          },
          {carType: 400, ageNbr: 300, commentTable: [
               {friendName: "Jennifer",  comment: "This car is definitely small which is great but it is way too " +
               "expensive."},
               {friendName: "Charlie", comment: "You might have your heart set on this car, but I just can't recommend " +
               "buying a sports car."}, 
               {friendName: "Cindy", comment: "I'd say don't buy it.  Insurance and maintenance on this thing are " +
               "going to be very expensive." },
               {friendName: "Cameron", comment: "You should buy this car.  It is amazing!! Think of how many people you would impress " +
               "when you drive by in this car! " }]
          },
          {carType: 500, ageNbr: 100, commentTable: [
               {friendName: "Jennifer",  comment: "Well, a pickup truck would be nice if you want to haul things a lot " +
               "but this one is old and really doesn't get good gas mileage. "},
               {friendName: "Charlie", comment: "Oh, I like the idea of a pickup truck... this one might be a little old " +
               "but it seems like it would be an OK purchase."}, 
               {friendName: "Cindy", comment: "Hmmmm... A pickup truck could be practical sometimes and at least this one " +
               "isn't too expensive because it is older."},
               {friendName: "Cameron", comment: "You know the problem with buying a pickup truck?  Everyone will want you " +
               "to help them move and stuff.  Plus this one is really old. " }]
          },
          {carType: 500, ageNbr: 200, commentTable: [
               {friendName: "Jennifer",  comment: "A pickup truck comes in very handy sometimes and " +
               "this one is a bit newer but the miles per gallon is still not great." },
               {friendName: "Charlie", comment: "Pickup trucks are really cool! This one is used so you will " +
               "need to get some kind of warranty protection. But it seems like a good choice to me."}, 
               {friendName: "Cindy", comment: "I think a car is really much more practical.  You can get a nicer " +
               "car and still not spend so much money."},
               {friendName: "Cameron", comment: "I'm not a big fan of pickup trucks so I can't really recommend this." +
               "Pickup trucks are so clunky. " }]
          },
          {carType: 500, ageNbr: 300, commentTable: [
               {friendName: "Jennifer",  comment: "So this truck is new and I am surprised that the mileage is better " +
               "than I would expect, but boy is it expensive." },
               {friendName: "Charlie", comment: "Wow!  This one is cool.  It even has room for passengers. " +
               "I could definitely see you driving this, right? "}, 
               {friendName: "Cindy", comment: "It might be nice to have a pickup truck sometimes, but this " +
               "is way too expensive!!"},
               {friendName: "Cameron", comment: "OK, well this is brand new so it looks pretty good.  But I really can't " +
               "say that I think a pickup truck is a good idea... unless you promise to help me move?" }]
          },

];

export default carGameChoiceDialogDataset;