import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { calculateMoodRetirementScores } from './objects/calculate_mood_retirement_scores';
import {doubleToStringMonthly, standardNumberFormat} from '../../objects/CommonUse/double_functions';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import ConfigFile from '../../datasets/config_file';


class MoodRetirementStatusModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                 };   
        this.moodScore = 0;
        this.moodPercent = 0;
        this.moodRotation = 0;
        this.moodPoints = 0;
        this.retirementScore = 0;
        this.retirementPercent = 0;
        this.retirementRotation = 0;
        this.retirementPoints = 0;
        this.currentStatusComment = "";
        this.initializeMoodRetirementGraphics(); 
    }

    initializeMoodRetirementGraphics(){
        let savings = 0;
        if (this.gameInfo.savingsInfo != undefined){
            if (this.gameInfo.savingsInfo.totalSavings != undefined){
                savings = this.gameInfo.savingsInfo.totalSavings;
            }
        }
        let totalGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
        if (this.gameInfo.timeWizardInfo.gameID !== undefined &&
            this.gameInfo.timeWizardInfo.status !== "inactive"){
                totalGameMonths = (+this.gameInfo.timeWizardInfo.gameNbrYears * 12);
        }
        let rotationObj = calculateMoodRetirementScores(this.gameInfo.gameData.currentMoodPoints,
               this.gameInfo.gameData.currentCash, savings, this.gameInfo.gameData.spendingHistory, 
               this.gameInfo.gameData.gameNbrMonths, totalGameMonths);
        let moodPercent = +rotationObj.moodPercent;
        this.moodPercent = (moodPercent).toFixed(0);    
        this.moodRotation = rotationObj.moodRotation;
        this.moodPoints = this.gameInfo.gameData.currentMoodPoints;
        this.moodScore = (+rotationObj.moodScore).toFixed(0);
        let retirementPercent = +rotationObj.retirementPercent;
        this.retirementPercent = (retirementPercent).toFixed(0);
        this.retirementRotation = rotationObj.retirementRotation;
        this.retirementPoints = rotationObj.totalSavings;
        this.retirementScore = (+rotationObj.retirementScore).toFixed(0);
        this.estimatedAge = rotationObj.estimatedAge.toFixed(0);
        this.savingsMultiplier = rotationObj.savingsMultiplier;
        this.totalRetirementSavings = rotationObj.totalRetirementSavings;
        this.avgYearlySpendingAmt = rotationObj.avgYearlySpendingAmt;
        this.combinedScore = rotationObj.combinedScore;
        this.moodRetirementScoreObj={moodScore: this.moodScore, retirementScore: this.retirementScore,
            combinedScore: this.combinedScore};
        this.totalSavings = +this.gameInfo.gameData.currentCash + +savings;
        if (this.totalSavings > this.totalRetirementSavings){
            this.currentStatusComment = " Great job!! You have saved " + 
                doubleToStringMonthly(this.totalSavings) + " which is more than the goal. " +
                "This will help you to retire on 'Easy Street'. "
        }else{
            if (+this.totalSavings === +this.totalRetirementSavings){
                this.currentStatusComment = " Great job!! You have saved " + 
                    doubleToStringMonthly(this.totalSavings) + " which is equal to the goal. " +
                    "This will help you to retire on 'Easy Street'. "
            }else{
                this.currentStatusComment = " Not there yet. You have saved " + 
                doubleToStringMonthly(this.totalSavings) + " which is less than the goal. " +
                "Keep saving as it will help you to retire on 'Easy Street'. "
            }
        }
    }

    render() {
       this.className="mood_retirement_container ";
       if (this.props.screenSize == "narrow"){
            this.className = this.className + " mood_retirement_narrow_size";
            this.arrowClass = "arrow_position_narrow";
       }else{
            this.className = this.className + " mood_retirement_wide_size";
            this.arrowClass = "arrow_position_wide"
       }
       this.gutters = setModalSize(this.props.screenSize);
      
    return <>
       
      <Modal show={this.props.modalOpen}  onHide={() => this.props.onClose(this.moodRetirementScoreObj)} 
            size="lg" dialogClassName={this.gutters} animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>
                <div >Mood and Retirement Scores</div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <span>
                <div className="font-weight-bold text-center">Combined Score: &nbsp;&nbsp; 
                    {standardNumberFormat(this.combinedScore)}</div>
                <div className="row mt-0 py-0">
                    <div className="col-sm-6">
                        <div className="text-center font-weight-bold">Mood Point Status</div>
                        <div className="text-center font-weight-bold">
                            {this.moodScore} </div>
                  
                        <div className={this.className}>
                            <img src={'./assets/arrow.png'}
                                className={this.arrowClass}  alt={'arrow'}
                                style={{transform: `rotate(${this.moodRotation}deg)`}}/>
                        </div>
                        <div className="mt-2"></div>
                        <div>Mood points are an indication of how happy you are.  In order to
                             optimize your game status, you should work to increase both your mood
                             score and retirement score.  The best way to increase your mood score
                             is to try some of the volunteer, having fun, or vacation activities.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="text-center font-weight-bold">Retirement Status</div>
                        <div className="text-center font-weight-bold">
                            {this.retirementScore}</div>
                            
                        <div className={this.className}>
                            <img src={'./assets/arrow.png'}
                                className={this.arrowClass} alt={'arrow'}
                                style={{transform: `rotate(${this.retirementRotation}deg)`}}/>
                        </div>
                      
                        <div className="p-0 mt-0 ml-1">Retirement score is calculated based on your current age and
                             spending history.  Your age in the game is {this.estimatedAge}.
                             At this age, you should have saved {this.savingsMultiplier}
                             &nbsp; times the average you spend each year.  Currently, you spend an average of &nbsp;
                             {doubleToStringMonthly(this.avgYearlySpendingAmt)} each year.  So at this point, you should have saved&nbsp; 
                             at least {doubleToStringMonthly(this.totalRetirementSavings)}.
                             {this.currentStatusComment}   
                        </div>
                    
                    </div>
                </div>
            </span>
        </Modal.Body>
        <Modal.Footer className="p-0 backgroundTopBottom">
                      <Button variant="primary"  className="mr-4" 
                            onClick={() => this.props.onClose(this.moodRetirementScoreObj)}>
                      Close
            </Button>
        </Modal.Footer>
        </Modal>
    
        </>
    }
}

export default MoodRetirementStatusModal;