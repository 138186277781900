const CarChangeDisplayAmts =[
    {type: "CarMonthlyAmts", textLineArr: [
        {textLine: "Cost of the Car Selected", plusMinus: "", amount: "VARcarCost", skipIfZero: false},
        {textLine: "Current Loan Balance", plusMinus: "+", amount: "VARtotalCurrentCarLoanAmt", skipIfZero: true},
        {textLine: "Trade-In Value of Current Car", plusMinus: "-", amount: "VARtradeInAmt", skipIfZero: false,
            classCond: true},
        {textLine: "Cash Amount Down", plusMinus: "-", amount: "VARdownPaymentAmt", skipIfZro: true,
            classCond: true},
        {textLine: "Total Amount Of New Loan", plusMinus: "=", amount: "VARloanAmt", skipIfZero: false},
        {textLine: "Current Monthly Income", plusMinus: "", amount: "VARmonthlyIncome", skipIfZero: false},
        {textLine: "Current Car Loan Monthly Pay Amt", plusMinus: "", amount: "VARcurrentCarLoanAmt",
            skipIfZero: true},
        {textLine: "New Car Loan Monthly Pay Amount", plusMinus: "", amount: "VARmonthlyLoanAmt", skipIfZero: false}
        ]
    },
    {type: "CarCashAmts", textLineArr: [
        {textLine: "Current Cash Amt", plusMinus: "", amount: "VARcurrentCash", skipIfZero: false},
        {textLine: "Loan Processing Fee", plusMinus: "-", amount: "VARloanProcessAmt", 
            skipIfZero: false, classCond: true},
        {textLine: "Cash Amount Down", plusMinus: "-", amount: "VARdownPaymentAmt", skipIfZero: false,
            varClass: "text-danger", classCond: true},
        {textLine: "Current Car Trade-In Amt", plusMinus: "+", amount: "VARrefundAmt", skipIfZero: true},
        {textLine: "Total Change to Cash", plusMinus: "=", amount: "VARchangeCashAmt", skipIfZero: false},
        ]

    }
]
export default CarChangeDisplayAmts;