import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import ConfigFile from '../../datasets/config_file';
import CreditCardPaymentPolicy from './datasets/credit_card_payment_policy';
import CreditCardParameters from './datasets/credit_card_parameters';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import DollarAmountCard from '../getting-started/dollar_amount_card';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import setModalSize from '../../objects/CommonUse/set_modal_size';

class CreditCardStatementModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                rerender: false,
                initialize: true,
                defaultClass: "font-weight-bold ",
           }   
           this.statmentDate = "";
           this.currentPeriodDate = "";
           this.prevPeriodDate = "";
           this.getStatementDates();
    }

    getStatementDates(){
        this.statementDateArr = [];
        let periodCharges = this.gameInfo.creditCardInfo.periodCreditCharges;
        for (let i=0; i<periodCharges.length; i++){
             //  skip first occurence if it is not the first month the credit card was activated
             //  because cannot determine previous payment or previous month balance
             //  also only allow statements to be viewed for last 3 months
            let nbrMonthsMaintained = +ConfigFile.unlockedFeatures.periodsCreditHistory;
            if ((periodCharges[i].bgnDate === this.gameInfo.creditCardInfo.creditCardStartDate &&
                periodCharges.length < nbrMonthsMaintained) || i>=0){
                let optionObj = {index: i, value: periodCharges[i].endDate, selection: "Statement date " +
                    periodCharges[i].endDate };
                this.statementDateArr.push(optionObj);
            }
        }

    }

    processStatementDate(dateSlct){
        this.statementDate = dateSlct;
        this.initializeFields();
    }
    
    initializeFields(){
        this.prePopulateAmounts();
        this.displayMonth = true;
        this.findDisplayCurrentPeriodStatement();
        this.state.initialize = false;
    }

    prePopulateAmounts(){
        this.currentOutstandingBalance = +this.gameInfo.creditCardInfo.totalOutstandingBalance;
        this.paymentPolicyCode = this.gameInfo.creditCardInfo.paymentPolicyCode;
        let paymentPolicyObj = 
                CreditCardPaymentPolicy.find(elem => elem.paymentPolicyCode === this.paymentPolicyCode);
        if (paymentPolicyObj === undefined){
            this.paymentPolicy = "none";
        }else{
            this.paymentPolicy = paymentPolicyObj.desc;
        }
    
        let minPayPct = +ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
        let nbrMonths = 1;
        if (this.gameInfo.timeWizardInfo.gameID !== undefined &&
            this.gameInfo.timeWizardInfo.status !== "inactive"){
              nbrMonths = this.gameInfo.timeWizardInfo.monthsPerTurn;
        }
        this.minimumPaymentAmt = this.currentOutstandingBalance * minPayPct * nbrMonths;
         //   this.creditCardInterest = this.gameInfo.creditCardInfo.totalIntBilled;
        let unlockedFeature = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "creditCard");
        this.cardLevel = +unlockedFeature.level;
        this.currentCreditLimitAmt = CreditCardParameters[this.cardLevel - 1].maxCreditCardAmt;
        this.creditCardInterest = +this.gameInfo.creditCardInfo.totalIntBilled;
    }

    findDisplayCurrentPeriodStatement(){
        let periodCharges = this.gameInfo.creditCardInfo.periodCreditCharges;
        let indx = 0;
        for (let i=0; i<periodCharges.length; i++){
            if (periodCharges[i].endDate === this.statementDate){
                indx = i;
                break;
            }
        }
        this.currentPeriodDate = 
            JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.periodCreditCharges[indx].endDate));
        this.currentChargesArr = [];
      
        if (indx > 0){
            let prevIndx = indx - 1;
            this.previousPeriodDate = 
                JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.periodCreditCharges[prevIndx].endDate));
            const creditCardCharges = this.gameInfo.creditCardInfo.periodCreditCharges[prevIndx];
            this.addToChargesArr(+creditCardCharges.periodBilledAmt, "Previous period", 
                "Amount Billed for Previous Period", "text-danger", true);
            this.addToChargesArr(+creditCardCharges.periodAmtPaid, "Previous Payment", 
                "Thank You For Your Payment")
            this.addToChargesArr(+creditCardCharges.periodIntAmt, "Interest Billed", 
                "Interest Charged on Previous Balance", "text-danger");
        }else{
            let previousBalance = 0;
            this.previousPeriodDate = 
                JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo.periodCreditCharges[0].endDate));
            this.addToChargesArr(+previousBalance, "Previous Period", "Amount Billed for Previous Period",
                "text-danger", true);
        }
     
        if (indx >= 0){
            const currentCreditCharges = this.gameInfo.creditCardInfo.periodCreditCharges[indx];
            if (+currentCreditCharges.periodLateFee > 0){
                this.addToChargesArr(+currentCreditCharges.periodLateFee, "Late Fee Charged",
                    "No Payment Received for Previous Period", "text-danger");
            }
            this.currentMonthCharges(indx);
            if (this.displayMonth === true){
                this.addToChargesArr(+currentCreditCharges.periodBilledAmt, "Current Period",
                    "Total Amount Billed for Current Period", "text-danger");
                this.addToChargesArr(+currentCreditCharges.periodAmtPaid, "Current Payment",
                    "Thank You For Your Payment");
                this.addToChargesArr(+currentCreditCharges.periodIntAmt, "Interest Billed",
                    "Interest Charged on Current Balance", "text-danger");
            }else{
                this.addToChargesArr(+currentCreditCharges.periodAmtPaid, "Current Payment",
                    "Thank You For Your Payment");
            }
        }
    }

    currentMonthCharges(indx){
        let selectedPeriod = this.gameInfo.creditCardInfo.periodCreditCharges[indx];
        //   need to add/display the total interest billed for the month
        this.creditCardBeginDate = selectedPeriod.bgnDate;
        this.creditCardEndDate = selectedPeriod.endDate;
        if (selectedPeriod.creditChargesArr.length > 0){
            let creditCardItems = selectedPeriod.creditChargesArr;
            for (let i=0; i<creditCardItems.length; i++){
                this.addToChargesArr(+creditCardItems[i].amount, creditCardItems[i].category,
                    creditCardItems[i].desc, "text-danger");
            }
        }else{
            this.addToChargesArr(0, "", "No charges on the credit card for this period", "", true);
            this.displayMonth = false;
        }
       
    }

    addToChargesArr(amount, item, desc, className, force){
        if (amount === 0 && force !== true){
            //  do not add to the charges array
        }else{
            if (className === undefined){
                className="";
            }
            const chargeObj = {item: item, desc: desc, amount: amount, className: className};
            this.currentChargesArr.push(chargeObj);
        }
    }
     
    render() {
        if (this.statementDateArr.length !== 0){
            this.statementDateOptions = this.statementDateArr.map((optionsList,index) =>
                <DropDownOptionCard options={optionsList} key={index} />
            );
        }
     
        if (this.currentChargesArr != undefined){
            this.periodChargeList = this.currentChargesArr.map((creditAmt, index) =>
                <DollarAmountCard  amtType="general" amountInfo={creditAmt} 
                    key={index}
                    className={this.currentChargesArr[index].className}
                    gameDate={this.gameInfo.gameData.currentGameDate}
                />
            );
        }
        this.gutters = setModalSize(this.props.screenSize);
                    
    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
            dialogClassName={this.gutters} animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">View Credit Card Statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span className="ml-4">
                <span>
                <div className="col-sm-10 text-left">Statement Date to View:</div>
                    <div className="col-sm-2 text-right">
                        <select onChange={(val) => this.processStatementDate(val.target.value)} 
                            className="btn btn-sm btn-outline-secondary dropdown-toggle">
                                <option value="0">no option slctd</option>
                                {this.statementDateOptions}
                        </select>
                    </div>
                {!this.state.initialize ?
                    <span>
                    <table className="mt-2">
                        <tbody>
                            <tr>
                                <th className="col-sm-4 ml-2">Current Outstanding Amt:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.currentOutstandingBalance)}
                                </td>
                                <th className="col-sm-4">Credit Card Maximum:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.currentCreditLimitAmt)}
                                </td>
                            </tr>
                            <tr>
                                <th className="col-sm-4">Statement Period:</th>
                                {this.creditCardBeginDate === this.creditCardEndDate ?
                                    <td className="col-sm-3 text-right">    
                                    {reformatDateToDisplay(this.creditCardBeginDate)}
                                    </td>
                                :
                                    <td className="col-sm-3 text-right">    
                                        {reformatDateToDisplay(this.creditCardBeginDate)}&nbsp;-&nbsp;
                                        {reformatDateToDisplay(this.creditCardEndDate)}</td>
                                }
                                <th className="col-sm-4 ml-2">Estimated Min Pay Amt:</th>
                                <td className="col-sm-3 text-right">
                                    {doubleToStringMonthly(this.minimumPaymentAmt)}</td> 
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-sm-3">Payment Policy:</th>
                                <td className="col-sm-9">{this.paymentPolicy}</td>
                            </tr>
                        </tbody>
                    </table>
             
                    <div className="ml-3 mt-4">
                        <div className="font-weight-bold text-center">
                            ------------------------Current Period Charges---------------------- </div>  
                        <table>
                            <tbody>
                                <tr>
                                    <th>Charge Category</th>
                                    <th>Charge Description</th>
                                    <th>Charge Amount</th>
                                </tr>
                                    {this.periodChargeList}
                            </tbody>
                        </table>
                    </div>
                </span>
                :
                    null
                }
                    </span>
                </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div>{this.message}</div>
                {+this.gameInfo.creditCardInfo.totalOutstandingBalance > 0 ?
                    <Button variant="info"  onClick={() => this.props.onChangePolicy("payment")} >
                        Make a Payment
                    </Button>
                :
                    null
                }
                <Button variant="secondary"  onClick={() => this.props.onChangePolicy("policy")} >
                    Change Credit Card Policy
                </Button>
                       
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                     Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }

}

export default CreditCardStatementModal;