import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import VolunteerFriendInitialText from './text/volunteer_friend_intro_text';
import HaveFunFriendInitialText from './text/have_fun_friend_intro_text';
import VacationFriendInitialText from './text/vacation_friend_intro_text';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import retrieveDetailActivityDialog from './objects/retrieve_detail_activity_dialog';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class SelectFriendForActivity extends React.Component {
    constructor(props) {
        super(props);
        props.onOpen();
        this.activityInfo = this.props.activityInfo();
        this.state = {
            headers: setHeadersForFetch(),
            loadingFriends: true,
            friendArrLoading: true,
            openModal: false,
            subProcess: "selectActivity",
            environment: Config.dataURL.currentEnv,
        }    
        this.friendData = null;
        this.activityCategory = "";
        this.activityName = "";    
        this.loadInitialData();
    }

    loadInitialData(){
        this.introText = "";
        this.activityCategory  = this.activityInfo.slctActivity.activityCategory;
        this.activityName = this.activityInfo.slctActivity.shortName;
        switch (this.activityInfo.slctActivity.activityCategory){
            case "Volunteer":
                this.introText = VolunteerFriendInitialText;
                break;
            case "HaveFun":
                this.introText = HaveFunFriendInitialText;
                break;
            case "Vacation":
                this.introText = VacationFriendInitialText;
                break;
            default: 
                break;
        }
        
        if (this.state.loadingFriends === true){
            this.loadFriends();
        }
        this.defaultImgSrc = "./assets/Activities/" + this.activityInfo.slctActivity.activityCategory + "/" +
            this.activityInfo.slctActivity.imageName;
    }
 
    loadFriends() {
        const funcName = "loadFriends";
        let friendList = this.activityInfo.gameData.friendList;
        let friendArr = [];
        for (let i=0; i<friendList.length; i++){
            if (friendList[i].introDate !==  ""){
                let friendName = friendList[i].name;
                friendArr.push(friendName);
            }
        }
        const friendParm = friendArr.join(',');
        const urlParm = "?friendArr=" + friendParm;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.friendData = output.body;
                        this.setState({loadingFriends: false});
                        this.createActivityPreferenceTable();
                    }else{
                        if (statusCode === 250){
                            // no friend records found for this player
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                });
            };
        });
       
    }

    createActivityPreferenceTable(){
        this.friendInfoArr = [];
        for (let i=0; i<this.friendData.length; i++){
            let activityPreferenceList = this.friendData[i].activityPreference
            let preferenceObj = activityPreferenceList.find(elem => elem.type === this.activityCategory );
            let activityPreference = 0;
            if (preferenceObj !==  undefined){
                  activityPreference = preferenceObj.rating;
            }
            let friendImgURL = "./assets/friends/" + this.friendData[i].friendImg;
            let friendInfoObj = {friendName: this.friendData[i].friendName, 
                friendImgURL: friendImgURL, activityPreference: activityPreference};
            this.friendInfoArr.push(friendInfoObj);
        }
        this.props.setActivityInfo(this.friendInfoArr, "friendInfo");
    }

    openFriendDisplayModal(){
        this.askAFriendInfo = {topic: "activity", activityCategory: this.activityCategory ,
                nbrDetailComments: "5"}
        this.setState({openModal: true});
    }

    closeFriendDisplayModal(){
        this.setState({openModal: false});
    }

    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
        this.className = screenParms.columnClass;
              
    return <>
        <div className={this.className}>
        <div className="card mt-3 rounded-corners-lg text-center activity-board height-max">
        <h2 className="mt-2 text-center">Activities</h2>
        <h4 className="mx-2 mt-0 text-center">Choose a Friend - {this.activityInfo.slctActivity.activityName} </h4>
       
        <div className="mx-4">
                <div className="p-0 text-center">
                    <img src={this.defaultImgSrc} className="activity_start_page_img" alt="activityPicture"/>
                </div>
                <div className="mx-4 my-2 text-left">
                     {this.introText} 
                </div>
        </div>
        {this.state.loadingFriends ?
           <WaitTimeSpinner />
        :
            <span>
                <div className="text-center">
                    <Button variant="primary"  onClick={() => this.openFriendDisplayModal()}>
                        {this.activityCategory } With A Friend
                    </Button>
                </div>
                <div className="text-center mt-4">
                    <Button variant="primary"  onClick={() => this.friendSelected(999)}>
                        {this.activityCategory } Without a Friend
                    </Button>
                </div>
            </span>
        }

        {this.state.openModal  ?
            <FriendDisplayModal  show={this.state.openModal} 
                 gameProcess="Activity"
                 title={this.activityInfo.slctActivity.activityName}
                 askAFriendInfo={this.askAFriendInfo}
                 onClose={() => this.closeFriendDisplayModal()} 
                 onSelectFriend={(friendNbr) => this.friendSelected(friendNbr)}
                 gameInfo = {() => this.props.activityInfo()}
                 />
        :
            null
        }
        </div>
        </div>
      
  </>
}

  friendSelected(friendIdx){
        let friendInfo = {};
        if (friendIdx === 999){
            friendInfo = "";
        }else{
            let selectFriendInfo = this.activityInfo.friendInfo[friendIdx];
            let friendCommentTable = retrieveDetailActivityDialog(this.activityCategory , 
                selectFriendInfo.activityPreference, this.activityName, 5);
            friendInfo = {...selectFriendInfo, commentTable: friendCommentTable};
        }
        this.props.onComplete();
        this.props.setActivityInfo(friendInfo, 'friendInfo');
        this.props.history.push('/start-activity');
  }
  

}

export default withRouter(SelectFriendForActivity);