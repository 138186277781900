const FunStuffDataset =[
       {level: "1",  type: "Adopt a Pet", imageURL: "UnlockedFeatures/FunStuffLevel1.jpg", input: "name", 
              expItem: "Pet Expenses", titleType: "animal",
              options:  [{ category: "Dog", imageURL: "UnlockedFeatures/dogs.jpg", 
                            list: [{type: "Black Lab", image: "UnlockedFeatures/dogLabrador.png", 
                            imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is a fun-loving dog that loves people, especially children.  " +
                                   "The dog has a lot of energy.  This dog loves to swim, especially on a hot day.  " +
                                   "The dog does sheds quite a bit, especially at certain times of the year. ",
                            ongoingCosts: {dogFood: 60},
                            onetimeCosts: {adoptFee: 300, dogSupplies: 120},
                            returnAmounts: {returnShelter: 100, returnDogFood: 60},
                            },
                            {type: "Mixed Breed", image: "UnlockedFeatures/dogMixedBreed.png", 
                            imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is a mix of several different breeds.  As you may guess " +
                                   "by the bone in it's mouth, this dog loves to play. The dog has a fairly " +
                                   "easy temperament and enjoys being around people.",
                            ongoingCosts: {dogFood: "40"},
                            onetimeCosts: {adoptFee: "300", dogSupplies: 120},
                            returnAmounts: {returnShelter: 100, returnDogFood: 60},
                            },
                            {type: "Beagle", image: "UnlockedFeatures/dogBeagle.png", 
                            imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is so cute!  It would be a great pet for a family.  The beagle " +
                                   "has a lot of energy and will enjoy play time.  If left alone, this dog may " +
                                   "become destructive or howl.  This dog will bark at strangers at first but " +
                                   "will likely end up greeting the strangers with a tail wag",
                            ongoingCosts: {dogFood: 40},
                            onetimeCosts: {adoptFee: 300, dogSupplies: 120},
                            returnAmounts: {returnShelter: 100, returnDogFood: 60},
                            },
                            {type: "Chihuahua", image: "UnlockedFeatures/dogChihuahua.png", 
                            imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is small but mighty.  The dog enjoys company and attention. " +
                            "They are a great lap-dog but also have a lot of energy and need time " +
                            "to run around and play.  This dog tends to bark at strangers and may " +
                            "snip at people if they become frightened.",
                            ongoingCosts: {dogFood: 35},
                            onetimeCosts: {adoptFee: 300, dogSupplies:  120},
                            returnAmounts: {returnShelter: 100, returnDogFood: 60},
                     } 
                     ],
              },
              { category: "Cat", imageURL: "UnlockedFeatures/cats.jpg", 
                     list:  [{type: "Siamese", image: "UnlockedFeatures/catSiamese.png", 
                     imageClass: "friend-game-img",
                     condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                     label: "Cat",
                     text: "This siamese cat is very intelligent and has been taught to walk on a " +
                            "leash.  The cat enjoys being around people and likes being groomed. " +
                            "This cat tends to over-eat so be sure to monitor the amount that it is fed.",
                     ongoingCosts: {catFood: 60},
                     onetimeCosts: {adoptFee: 100, catVet: 360, catSupplies: 115},
                     returnAmounts: {returnShelter: 100, returnCatFood: 60},
                     },
                     {type: "Black Cat", image: "UnlockedFeatures/catBlack.png", imageClass: "friend-game-img",
                     condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                     label: "Cat",
                     text: "This cat is very friendly and playful. It enjoys being outside but is also " +
                            "very happy to be indoors.  The cat enjoys cuddling sometimes but also " +
                            "likes to just rest in a sunny spot.",
                     ongoingCosts: {catFood: 63},
                     onetimeCosts: {adoptFee: 100, catVet: 360, catSupplies: 115},
                     returnAmounts: {returnShelter: 100, returnCatfood: 60},
                     },
                     {type: "Persian", image: "UnlockedFeatures/catPersian.png", imageClass: "friend-game-img",
                     condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                     label: "Cat",
                     text: "This persian cat is very gentle and loving.  The cat loves to be petted while  " +
                            "sitting on your lap.  They do not enjoy rough play.  They are an indoor cat " +
                            "as they tend to get overheated when outdoors.", 
                     ongoingCosts: {catFood: 53},
                     onetimeCosts: {adoptFee: 100, catVet: 360, catSupplies: 115},
                     returnAmounts: {returnShelter: 100, returnCatFood: 60},
                     },
                     {type: "Tabby", image: "UnlockedFeatures/catTabby.png", imageClass: "friend-game-img",
                     condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                     label: "Cat",
                     text: "This tabby cat is intelligent and playful.  It is a tad picky about people.  " +
                            "It may be more affecitionate with some people than others.  It needs plenty of " +
                            "exercise and enjoys playtime." ,
                     ongoingCosts: {catFood: 70},
                     onetimeCosts: {adoptFee: 100, catVet: 360, catSupplies: 115},
                     returnAmounts: {returnShelter: 100, returnCatFood: 60},
                     } 
                     ],
              },
       ]
       },  
       {level: "2",  type: "Buy a Horse", imageURL: "UnlockedFeatures/FunStuffLevel2.jpg", input: "none", 
              expItem: "Horse Expenses", titleType: "animal", depreciationVar: "sellHorse",
              options:  [{ category: "Horse", imageURL: "UnlockedFeatures/horses.jpg", 
              
              list: [{type: "Apaloosa", image: "UnlockedFeatures/horse1.png", imageClass: "horse-game-img",
                     condName: "own-horse-apaloosa", backgroundURL: "/conditionalBackground/horse-apaloosa.jpg",
                     label: "Horse",
                     text: "",
                     purchasePrice: "5500",
                     ongoingCosts: {horseShelter: 500},
                     onetimeCosts: {horsePurchasePrice: 5500, horseSupplies: 300, horseVet: 300},
                     returnAmounts: {sellHorse: 5500, sellTackle: 200},
                     },
                     {type: "Bay", image: "UnlockedFeatures/horse2.png", imageClass: "horse-game-img",
                     condName: "own-horse-bay", backgroundURL: "/conditionalBackground/horse-bay.jpg",
                     label: "Horse",
                     text: "",
                     purchasePrice: "6000",
                     ongoingCosts: {horseShelter: 560},
                     onetimeCosts: {horsePurchasePrice: 6000, horseSupplies: 390, horseVet: 336},
                     returnAmounts: {sellHorse: 6000, sellTackle: 200},
                     },
                     {type: "Patches", image: "UnlockedFeatures/horse3.png", imageClass: "horse-game-img",
                     condName: "own-horse-patches", backgroundURL: "/conditionalBackground/horse-patches.jpg",
                     label: "Horse",
                     text: "",
                     purcasePrice: "4750",
                     ongoingCosts: {horseShelter: 475},
                     onetimeCosts: {horsePurchasePrice: 4750, horseSupplies: 380, horseVet: 300},
                     returnAmounts: {sellHorse: 4750, sellTackle: 200},
                     },
                     {type: "Brown", image: "UnlockedFeatures/horse4.png", imageClass: "horse-game-img",
                     condName: "own-horse-brown", backgroundURL: "/conditionalBackground/horse-brown.jpg",
                     label: "Horse",
                     text: "",
                     purchasePrice: "7000",
                     ongoingCosts: {horseShelter: 700},
                     onetimeCosts: {horsePurchasePrice: 7000, horseSupplies: 450, horseVet: 380},
                     returnAmounts: {sellHorse: 7000, sellTackle: 225},
                     },
                     ],
              },

       ]
       },
       //  note, when the item cost is more than $10000, then amount is entered as 'purchasePrice'
       //  instead of being part of the onetimeCosts array
       {level: "3",  type: "Buy a Boat", imageURL: "UnlockedFeatures/FunStuffLevel3.jpg", input: "",
       shortType: "Boat",
       expItem: "Boat Expenses", titleType: "object", loanType: "Boat Loan", calcDepreciation: true,
       options:  [{ category: "Boat", imageURL: "UnlockedFeatures/boats.jpg", 
       
       list: [{type: "Fishing Boat", image: "UnlockedFeatures/boatFishing.png", imageClass: "",
              condName: "own-a-fishing-boat",  backgroundURL: "/conditionalBackground/Lake-FishingBoat.png",
              label: "FishingBoat",
              text: "",
              purchasePrice: "35000",
              ongoingCosts: {boatStorage: 150, boatIns: 43.75},
              onetimeCosts: {boatSafety: 1160, boatDeckLines: 225},
              returnAmounts: {},
              },
              {type: "Sail Boat", image: "UnlockedFeatures/boatSail.png", imageClass: "",
              condName: "own-a-sail-boat",  "backgroundURL": "/conditionalBackground/Lake-SailBoat.png",
              label: "SailBoat",
              text: "",
              purchasePrice: "38000",
              ongoingCosts: {boatStorage: 150, boatIns: 47.50},
              onetimeCosts: {boatSafety: 1160, boatDeckLines: 225},
              returnAmounts: {},
              },
              {type: "Speed Boat", image: "UnlockedFeatures/boatSpeed.png", imageClass: "",
              condName: "own-a-speed-boat",  "backgroundURL": "/conditionalBackground/Lake-SpeedBoat.png",
              label: "SpeedBoat",
              text: "",
              purchasePrice: "39000",
              ongoingCosts: {boatStorage: 150, boatIns: 48.75},
              onetimeCosts: {boatSafety: 1160, boatDeckLines: 225},
              returnAmounts: {},

              }],
       },
       ]
}              

]
export default FunStuffDataset;