import React from 'react';
import { withRouter } from "react-router";
import './GameViews/gameViews.css'
import LinkCard from '../shared-components/link_card';
import {Link} from "react-router-dom";
import MonthlyStatusModal from './monthly_status_modal';
import LeaderBoardModal from './leader_board_modal';
import FutureViewModal from './future_view_modal';
import MoodRetirementStatusModal from './mood_retirement_status_modal';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class StatusDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.props.onOpen();
        this.gameInfo = this.props.gameInfo();
        this.state = {
            nextProcess: "",
            modalOpen: false,
            allowed: true,
            firstTime: true,
        }    
    }

selectMonthlyStatus() {
    this.state.nextProcess = "monthlyStatus";
    this.state.modalOpen = true;
    
}

selectLeaderBoard() {
    this.state.nextProcess = "leaderBoard";
    if (this.props.accountNbr == undefined){
        this.accountNbr = "game";
    }else{
        this.accountNbr = this.props.accountNbr;
    }
    this.state.modalOpen = true;
}

selectFutureView() {
    this.state.nextProcess = "futureView";
    this.state.modalOpen = true;
}

selectMoodRetirementStatus() {
    this.state.nextProcess = "moodRetirementStatus";
    this.state.modalOpen = true;
}

closeModal() {
    this.state.modalOpen = false;
    this.state.nextProcess = "";
    let prevProcess = this.props.prevProcess;
    this.props.clearPrevProcess();
    if (prevProcess == "ChangeGameChoices"){
        this.props.history.push("game-board");
    }
}

  render() {
        if ((this.props.prevProcess == "ChangeGameChoices" && this.state.modalOpen == false) ||
            (this.props.selected == "monthlyStatus" && this.state.firstTime == true)){
            if (this.props.prevProcess == "ChangeGameChoices"){
                alert("Good job!! The change has been fully processed.  " +
                    "Review changes to current cash and expenses. " +
                    "Then click on the Close button to continue playing the game.");
            }
            this.selectMonthlyStatus();
            this.state.firstTime = false;
        }     
        if (this.props.prevProcess == "ParentPage"){
             this.title = "Display " + this.props.playerName + " Game Status"; 
        }else{
             this.title = "How Am I Doing?"
        }
        if (this.state.nextProcess != ""){
            sessionStorage.setItem("GameViewSubProcess", this.state.nextProcess)
        }
       
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.className = screenParms.columnClass;
        this.linkSize = screenParms.linkSize;
        
  return <>
        
           <div className={this.className}>
           <div className="card mt-3 mr-2 rounded-corners-lg text-center game-board height-max">

               <h3 className="mt-2 text-center">{this.title}</h3>
           
            <span>
                <span className="row w-100 mx-0 ml-4 text-pointer">
                    <div className="col-sm-6 mt-4" onClick={() => this.selectMonthlyStatus()}>
                            <LinkCard name="Monthly Status" imgSrc='./assets/GameStatus/Monthly.png'
                                inSize={this.linkSize} alt="" 
                            />
                      
                    </div>
                    <div className="col-sm-6 mb-2 mt-4" onClick={() => this.selectLeaderBoard()}>
                            <LinkCard name="Leader Board" imgSrc='./assets/GameStatus/leader.png' 
                                inSize={this.linkSize} alt="" />
                    </div> 
                </span>
                <span className="row w-100 mx-0 ml-4 text-pointer">
                   
                    <div className="col-sm-6 mt-2 mb-3" onClick={() => this.selectFutureView()}>
                           <LinkCard name="Future View" imgSrc='./assets/GameStatus/Future.png' 
                                inSize={this.linkSize} alt="" />
                        
                    </div>
                    <div className="col-sm-6 mt-2 mb-3" onClick={() => this.selectMoodRetirementStatus()}>
                           <LinkCard name="Mood and Retirement Status" imgSrc='./assets/GameStatus/moodRetirementStatus.png' 
                                inSize={this.linkSize} alt="" />
                        
                    </div>
                </span>
            </span>
            {this.state.nextProcess == "monthlyStatus" ?
                <MonthlyStatusModal modalOpen={this.state.modalOpen} 
                    screenSize={this.props.screenSize}
                    onClose={() => this.closeModal()}
                    gameInfo = {() => this.props.gameInfo()}
                />
            :
                <div></div>
            }
             {this.state.nextProcess == "leaderBoard" ?
                <LeaderBoardModal modalOpen={this.state.modalOpen} 
                    playerSelected={true}
                    accountNbr={this.accountNbr}
                    onClose={() => this.closeModal()} 
                    gameInfo = {() => this.props.gameInfo()}
                />
            :
                <div></div>
            }
             {this.state.nextProcess == "futureView" ?
                <FutureViewModal modalOpen={this.state.modalOpen} 
                    onClose={() => this.closeModal()}
                    restartSw = {this.changeProcessSw}
                    gameInfo = {() => this.props.gameInfo()}
                />
            :
                <div></div>
            }

            {this.state.nextProcess == "moodRetirementStatus" ?
                <MoodRetirementStatusModal 
                    screenSize={this.props.screenSize}
                    modalOpen={this.state.modalOpen} 
                    onClose={() => this.closeModal()}
                    gameInfo = {() => this.props.gameInfo()}
                />
            :
                <div></div>
            }       

           
        </div>
        
        {this.props.prevProcess == "ParentPage" ?
                <div>
                    <button className="btn done-button-placement btn-primary ml-3 mt-4" onClick={() => this.props.onDone()}>
                            Done
                    </button>
                </div>
            :
                null
            }
            
       
    </div>   
</>
  }
}

export default withRouter(StatusDisplay);