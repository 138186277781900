export const loanBalanceCalcFunction = (baseAmt, interest, payment, loanTerm, monthsRemaining) => {

     /*
          FV = PV(1+r)^n - P((1+r)^n - 1 / r)
          where:
               FV = remaining balance of loan
               PV = original amount of loan (baseAmt)
               P = monthly payment (payment)
               r = interest rate - (interest)
               n = number of payments made   ((loan Term * 12) - monthsRemaining) */
          
        let outstandingLoanAmt = 0;
        if (baseAmt !== undefined)
        {    let monthlyInterest = parseFloat(interest) / 12;
             const nbrMonths = (loanTerm * 12) - monthsRemaining;
             let interimCalc = Math.pow((1 + monthlyInterest), nbrMonths);
             let term1 = baseAmt * interimCalc;
             let term2 = payment * ((interimCalc - 1) / monthlyInterest);
             outstandingLoanAmt = (term1 - term2).toFixed(2);
        }
        return outstandingLoanAmt;
 }
