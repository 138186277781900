const ReasonForGameEndText =[
    {reason: "PlayersChoice", title: "Choosing To End Your Game", text:  
        "You have selected to finish the game. If you continue, your current game " +
        "will be cleared and you will need to restart a new game. " +
        "Click the 'End My Game' button to continue with the 'finish game' process. " +
        "Click the 'Back to GameBoard' button to go back to playing your current game. "
    },
    {reason: "NegativeCash", title: "Cash Amount is Less Than Zero", text:
        "Your game has ended because you do not have enough cash to continue. " +
        "Eventhough you do not have any cash, you can celebrate that you played " +
        "the game and learned a bit about money management along the way. Now " +
        "that you know more, we hope you will play again with more success. "
    },
    {reason: "NegativeMoodPoints", title: "Mood Points are Less Than Zero", text: 
        "Your game has ended because you do not have enough mood points to continue. " +
        "Eventhough you do not have any mood points, you can celebrate that you played " +
        "the game and learned a bit about money management along the way. Now " +
        "hat you know more, we hope you will play again with more success."
    },
    {reason: "TimeElapsed", title: "Congratulations!  You have played until the end of the game.", text: 
        "Your game has ended because you played the game for ||totalNbrGameYears|| years which is the time " +
        "that has been alotted.  Time to celebrate!! We hope that you " +
        "have learned about money management and we invite you to play the game " +
        "again."}
]   

export default ReasonForGameEndText;