const FunStuffDisplayAmts = [
       {type: "DogMonthlyAmts", textLineArr: [
              {textLine: "Current Monthly Net Income", plusMinus: "", amount: "VARmonthIncome", 
                     skipIfZero: false},
              {textLine: "Dog Food", plusMinus: "-", amount: "VARdogFood", skipIfZero: true, classCond: true},
       ]},       
       {type: "DogOnetimeCosts", textLineArr: [
              {textLine: "Current Cash", plusMinus: "", amount: "VARcurrentCash", skipIfZero: false},
              {textLine: "Adoption Fee", plusMinus: "-", amount: "VARadoptFee", skipIfZero: true,
                     classCond: true},
              {textLine: "Dog Bed@ Leash Collar@ Tag@ Comb/Brush@ Toys", plusMinus: "-", 
                     amount: "VARdogSupplies", skipIfZero: true, classCond: true},
       ]},
       {type: "DogReturnCosts", textLineArr: [
              {textLine: "Return Dog to a Shelter", plusMinus: "-", amount: "VARreturnShelter",
                     skipIfZero: true, classCond: true},
              {textLine: "Excess Dog Food", plusMinus: "-", amount: "VARreturnDogFood",
                     skipIfZero: true, classCond: true}
       ]},
     
       {type: "CatMonthlyAmts", textLineArr: [
              {textLine: "Current Monthly Net Income", plusMinus: "", amount: "VARmonthIncome", 
                     skipIfZero: false},
              {textLine: "Cat Food and Litter", plusMinus: "-", amount: "VARcatFood", skipIfZero: true,
                     classCond: true},
       ]},
       {type: "CatOnetimeCosts", textLineArr: [
              {textLine: "Current Cash", plusMinus: "", amount: "VARcurrentCash", skipIfZero: false},
              {textLine: "Adoption Fee", plusMinus: "-", amount: "VARadoptFee", skipIfZero: true,
                     classCond: true},
              {textLine: "Vaccinations@ Initial Veterinarian Services", plusMinus: "-", 
                     amount: "VARcatVet", skipIfZero: true, classCond: true},
              {textLine: "Bedding@ Litter Box@ Carry Case@ Food & Water Bows@ Toys", plusMinus: "-",
                     amount: "VARcatSupplies", skipIfZero: true, classCond: true },
       ]},
       {type: "CatReturnCosts", textLineArr: [
              {textLine: "Return Cat to a Shelter", plusMinus: "-", amount: "VARreturnShelter",
                     skipIfZero: true, classCond: true},
              {textLine: "Excess Cat Food and Litter", plusMinus: "-", amount: "VARreturnCatFood",
                     skipIfZero: true, classCond: true},
       ]},
                   
       {type: "HorseMonthlyAmts", textLineArr: [
              {textLine: "Current Monthly Net Income", plusMinus: "", amount: "VARmonthIncome", 
                     skipIfZero: false},
              {textLine: "Boarding Cost (Feed@ Stable@ Grooming)", plusMinus: "-", amount: "VARhorseShelter",
                     skipIfZero: true, classCond: true},
       ]},
       {type: "HorseOnetimeCosts", textLineArr: [
              {textLine: "Current Cash", plusMinus: "", amount: "VARcurrentCash", skipIfZero: false},
              {textLine: "Purchase Price", plusMinus: "-", amount: "VARhorsePurchasePrice", skipIfZero: true,
                     classCond: true},
              {textLine: "Saddle@ Bridle@ Blanket@ Comb/Brush@ Hoof Pick", plusMinus: "-", 
                     amount: "VARhorseSupplies", skipIfZero: true, classCond: true},
              {textLine: "Pre-Purchase Vet Exam", plusMinus: "-", amount: "VARhorseVet", skipIfZero: true,
                     classCond: true},
       ]},
       {type: "HorseReturnCosts", textLineArr: [
              {textLine: "Sell Horse", plusMinus: "+", amount: "VARsellHorse", skipIfZero: true},
              {textLine: "Sell Tackle (saddle@ bridle etc.)", plusMinus: "+", amount: "VARsellTackle",
                     skipIfZero: true}
       ]},
      
       {type: "BoatMonthlyAmts", textLineArr: [
              {textLine: "Current Monthly Net Income", plusMinus: "", amount: "VARmonthIncome", 
                     skipIfZero: false},
              {textLine: "Loan Payment", plusMinus: "-", amount: "VARloanPayment", skipIfZero: true,
                     classCond: true},
              {textLine: "Dry Stack Storage", plusMinus: "-", amount: "VARboatStorage", skipIfZero: true,
                     classCond: true},
              {textLine: "Boat Insurance", plusMinus: "-", amount: "VARboatIns", skipIfZero: true,
                     classCond: true}
       ]},
       {type: "BoatOnetimeCosts", textLineArr: [
              {textLine: "Current Cash", plusMinus: "", amount: "VARcurrentCash", skipIfZero: false},
              {textLine: "Boat Safety Equipment (life jacket@ fire extinguisher@ etc.) ", plusMinus: "-",
                     amount: "VARboatSafety", skipIfZero: true, classCond: true},
              {textLine: "Deck Lines", plusMinus: "-", amount: "VARboatDeckLines", skipIfZero: true,
                     classCond: true},
              {textLine: "Downpayment", plusMinus: "-", amount: "VARdownPayment", 
                     skipIfZero: true, classCond: true},
              {textLine: "Pay Off Balance of Current Loan", plusMinus: "-", amount: "VARloanOutstandingAmt",
                     skipIfZero: true, classCond: true}
       ]},
       {type: "BoatReturnCosts", textLineArr: [
              {textLine: "Sell Current Boat", plusMinus: "+", amount: "VARsellPrice", skipIfZero: true}
       ]},
       {type: "BoatLoanAmts", textLineArr: [
              {textLine: "Boat Purchase Price", plusMinus: "", amount: "VARboatPrice", skipIfZero: true},
              {textLine: "DownPayment Amount", plusMinus: "-", amount: "VARdownPayment", 
                     skipIfZero: true, classCond: true},
              {textLine: "Total Loan Amount", plusMinus: "=", amount: "VARtotalLoanAmt"},
       ]}
                            
]
export default FunStuffDisplayAmts;