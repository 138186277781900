const DiscussionGameData =[
  {
    "_id": "63c9ad338e7586c66cbd67e0",
    "assetTable": [
      {
        "assetItem": "Job Salary",
        "assetDesc": "Electrical Engineer --> Annual Salary: $71,991.00",
        "assetAmt": "5999.27",
        "assetStartDate": "2023-01",
        "assetNbrMonths": "-1"
      }
    ],
    "expenseTable": [
      {
        "expItem": "Payroll taxes",
        "expDesc": "Federal, FICA (Social Security)",
        "expAmount": 1151.42,
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Apartment Rent",
        "expDesc": "Sunnyside-1 bedroom, 0 roommates",
        "expAmount": 885,
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Health Insurance",
        "expDesc": "Health Insurance 70% Paid by Employer",
        "expAmount": "111.00",
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Insurance for car repair or replacement",
        "expAmount": 75,
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Renter's Insurance",
        "expDesc": "Insurance for furniture and other personal items",
        "expAmount": 30,
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Transportation",
        "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
        "expAmount": "144.44",
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Food, Clothes, Personal Items, etc.",
        "expAmount": 515,
        "expStartDate": "2023-01",
        "expNbrMonths": "-1"
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Auto Insurance Claim Increase - Traffic Ticket",
        "expAmount": "12.75",
        "expStartDate": "2028-10",
        "expNbrMonths": 30
      }
    ],
    "loanTable": [
      {
        "loanType": "College Loan",
        "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
        "loanAmt": "474.65",
        "loanStartDate": "2023-01",
        "loanNbrMonths": 45,
        "loanTotAmt": "48000.00",
        "loanIntRate": 0.035,
        "loanTerm": 10
      },
      {
        "loanType": "Other Loan",
        "loanDesc": "Loan for a Speed Boat",
        "loanAmt": "1441.87",
        "loanStartDate": "2029-01",
        "loanNbrMonths": 69,
        "loanTotAmt": "77830",
        "loanIntRate": 0.1,
        "loanTerm": 6
      }
    ],
    "skillSurvey": [
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5",
      "5"
    ],
    "conditionsTable": [
      {
        "condName": "car-gt-10-years-old",
        "conditionClass": "car"
      },
      {
        "condName": "car-gt-5-years-old",
        "conditionClass": "car"
      },
      {
        "condName": "homeType-apartment",
        "conditionClass": "home"
      },
      {
        "condName": "electrical-engineer",
        "conditionClass": "career"
      },
      {
        "condName": "1-year-elapsed-in-game",
        "conditionClass": "game"
      },
      {
        "condName": "1-year-after-job-start",
        "conditionClass": "job"
      },
      {
        "condName": "current-cash-large",
        "conditionClass": "cash"
      },
      {
        "condName": "car-gt-15-years-old",
        "conditionClass": "car"
      },
      {
        "condName": "5-years-after-job-start",
        "conditionClass": "job"
      },
      {
        "condName": "bad-wiper-blades",
        "conditionClass": "car",
        "duration": -1
      },
      {
        "condName": "auto-insurance-increase-ticket",
        "conditionClass": "game",
        "duration": 34
      },
      {
        "condName": "own-a-boat",
        "conditionClass": "boat",
        "duration": -1
      }
    ],
    "unlockedFeatures": [
      {
        "featureName": "savings",
        "setup": "none",
        "level": 1,
        "lastNotifyDate": "2028-10"
      },
      {
        "featureName": "creditCard",
        "setup": "none",
        "level": 4,
        "lastNotifyDate": "2028-10"
      }
    ],
    "friendList": [
      {
        "name": "Charlie",
        "introDate": "2024-07",
        "dialogIndx": 2,
        "rltnshpScore": 13,
        "totalLoanAmt": "0"
      },
      {
        "name": "Cameron",
        "introDate": "2023-01",
        "dialogIndx": 4,
        "rltnshpScore": 5,
        "totalLoanAmt": "0"
      },
      {
        "name": "Jennifer",
        "introDate": "2024-01",
        "dialogIndx": 8,
        "rltnshpScore": -1,
        "totalLoanAmt": "0"
      },
      {
        "name": "Cindy",
        "introDate": "2023-07",
        "dialogIndx": 7,
        "rltnshpScore": 2,
        "totalLoanAmt": "0"
      }
    ],
    "user": "student50",
    "jobInfo": {
      "careerID": "61310cff2afc7f761668ac09",
      "healthInsInd": "true",
      "jobRank": "8",
      "nbrYearsCollege": "4",
      "nbrSkillSurvey": "1",
      "jobStartGameMonth": 360
    },
    "homeInfo": {
      "homeID": "61323ec697d929c9a8cf8706",
      "homeType": "apartment",
      "roommates": "0",
      "bedrooms": "1"
    },
    "furnitureID": "6132a56597d929c9a8cf871b",
    "carInfo": {
      "carID": "6132c0cb97d929c9a8cf871f",
      "carAge": 221,
      "carYear": "2010",
      "carType": "100"
    },
    "startingCash": "-3535.00",
    "currentCash": "127344.98",
    "currentMoodPoints": "48189",
    "startGameDate": "2023-01",
    "currentGameDate": "2029-04",
    "gameNbrMonths": "295",
    "nbrMonthsNegative": "0",
    "nbrMonthsMoodNegative": "0",
    "spendingHistory": {
      "currentMonthSpendAmt": 0,
      "current10YearSpendAmt": 265757.2,
      "current10YearMonths": 66,
      "previous10YearSpendAmt": 0,
      "previous10YearMonths": 0
    },
    "moodPointHistory": {
      "currentMonthMoodPoints": 0,
      "totalMoodPoints": 48189,
      "moodPointMonths": 66
    },
    "iconTable": [],
    "dateAdded": "2023/01/19",
    "dateUpdated": "2023/01/19",
    "__v": 38,
    "hand": [],
    "pendingJob": null
    },    
    {
      "_id": "63c99468b9028e86152afb2a",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
          "assetAmt": "5333.33",
          "assetStartDate": "2023-01",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "904.33",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Sunnyside-1 bedroom, 0 roommates",
          "expAmount": 885,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 386.25,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": 200,
          "expStartDate": "2023-08",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - Brand New",
          "loanAmt": "525.10",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 34,
          "loanTotAmt": 28344,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 82,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "ok-manager",
          "conditionClass": "manager",
          "duration": -1
        },
        {
          "condName": "1-year-elapsed-in-game",
          "conditionClass": "game"
        },
        {
          "condName": "1-year-after-job-start",
          "conditionClass": "job"
        },
        {
          "condName": "current-cash-large",
          "conditionClass": "cash"
        }
      ],
        "unlockedFeatures": [
        {
          "featureName": "savings",
          "setup": "none",
          "level": 1,
          "lastNotifyDate": "2026-03"
        },
        {
          "featureName": "creditCard",
          "setup": "none",
          "level": 2,
          "lastNotifyDate": "2026-03"
        }
      ],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2024-07",
          "dialogIndx": 2,
          "rltnshpScore": 6,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2023-10",
          "dialogIndx": 1,
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-01",
          "dialogIndx": 3,
          "rltnshpScore": 1,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-04",
          "dialogIndx": 4,
          "rltnshpScore": 4,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student51",
      "jobInfo": {
        "careerID": "61310cff2afc7f761668ac09",
        "healthInsInd": "true",
        "jobRank": "8",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "61323ec697d929c9a8cf8706",
        "homeType": "apartment",
        "roommates": "0",
        "bedrooms": "1"
      },
      "furnitureID": "6132a4c097d929c9a8cf8719",
      "carInfo": {
        "carID": "6132c54897d929c9a8cf8729",
        "carAge": 38,
        "carYear": "2023",
        "carType": "200"
      },
      "startingCash": "-575.00",
      "currentCash": "12500.00",
      "currentMoodPoints": "15000",
      "startGameDate": "2023-01",
      "currentGameDate": "2026-03",
      "gameNbrMonths": "322",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "1",
      "spendingHistory": {
        "currentMonthSpendAmt": 0,
        "current10YearSpendAmt": 148205.37,
        "current10YearMonths": 39,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": "300",
        "totalMoodPoints": -100,
        "moodPointMonths": 39
      },
      "iconTable": [],
      "dateAdded": "2023/01/19",
      "dateUpdated": "2023/01/19",
      "__v": 30,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63cdd95d2904c3c3801b3dfe",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Electrical Engineer --> Annual Salary: $67,917.00",
          "assetAmt": "5659.78",
          "assetStartDate": "2023-01",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 1027.92,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Brookside-2 bedrooms, 1 roommate",
          "expAmount": 550,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": "78.75",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "133.33",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 650,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "11.25",
          "expStartDate": "2023-02",
          "expNbrMonths": 4
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Basic Package($)",
          "expAmount": 50,
          "expStartDate": "2023-05",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Increase - Car Accident",
          "expAmount": "16.54",
          "expStartDate": "2024-10",
          "expNbrMonths": 24
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Full Data Plan",
          "expAmount": "100",
          "expStartDate": "2025-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Pet Expenses",
          "expDesc": "Dog Food (Mixed Breed)",
          "expAmount": "40",
          "expStartDate": "2023/03/16",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "SUV - 3 to 5 Years Old",
          "loanAmt": "361.81",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 39,
          "loanTotAmt": 19530,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 87,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "4",
        "3",
        "5",
        "4",
        "3",
        "5",
        "5",
        "5",
        "2",
        "4",
        "4",
        "3"
      ],
      "conditionsTable": [
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": 4
        },
        {
          "condName": "speeding-ticket",
          "conditionClass": "car",
          "duration": -1
        },
        {
          "condName": "1-year-elapsed-in-game",
          "conditionClass": "game"
        },
        {
          "condName": "1-year-after-job-start",
          "conditionClass": "job"
        },
        {
          "condName": "current-cash-large",
          "conditionClass": "cash"
        },
        {
          "condName": "auto-insurance-increase-accident",
          "conditionClass": "game",
          "duration": 24
        },
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "broken-refrigerator",
          "conditionClass": "home",
          "duration": -1
        },
        {
          "condName": "good-manager",
          "conditionClass": "manager",
          "duration": -1
        },
        {
          "condName": "auto-accident",
          "conditionClass": "car",
          "duration": -1
        },
        {
          "condName": "ok-roommate",
          "conditionClass": "roommate",
          "duration": -1
        },
        {
          "condName": "own-a-dog",
          "conditionClass": "game",
          "duration": -1
        }
      ],
       "unlockedFeatures": [
        {
          "featureName": "savings",
          "setup": "inactive",
          "level": 1,
          "lastNotifyDate": "2025-10"
        },
        {
          "featureName": "creditCard",
          "setup": "none",
          "level": 1,
          "lastNotifyDate": "2025-10"
        },
        {
          "featureName": "funStuff",
          "setup": "none",
          "level": 2,
          "lastNotifyDate": "2025-10"
        }
      ],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-04",
          "dialogIndx": 1,
          "rltnshpScore": 23,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2023-07",
          "dialogIndx": 2,
          "rltnshpScore": 4,
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2024-07",
          "dialogIndx": 2,
          "rltnshpScore": 14,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-01",
          "dialogIndx": 5,
          "rltnshpScore": 45,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student52",
      "jobInfo": {
        "careerID": "61310cff2afc7f761668ac09",
        "healthInsInd": "true",
        "jobRank": "7",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "6132415297d929c9a8cf870c",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a4c097d929c9a8cf8719",
      "carInfo": {
        "carID": "6132c65697d929c9a8cf872d",
        "carAge": 69,
        "carYear": "2020",
        "carType": "300"
      },
      "startingCash": "-240.00",
      "currentCash": "48047.8",
      "currentMoodPoints": "32202",
      "startGameDate": "2023-01",
      "currentGameDate": "2025-10",
      "gameNbrMonths": "327",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 0,
        "current10YearSpendAmt": 121434.64,
        "current10YearMonths": 34,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 0,
        "totalMoodPoints": 31932,
        "moodPointMonths": 34
      },
      "iconTable": [],
      "dateAdded": "2023/01/22",
      "dateUpdated": "2023/03/16",
      "__v": 172,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63d46666d4429d7996a09c60",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Police Officer --> Annual Salary: $60,593.00",
          "assetAmt": "5049.41",
          "assetStartDate": "2023-01",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 904.33,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Countryside-2 bedrooms, 1 roommate",
          "expAmount": 1737,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "114.33",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": "86.82",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "133.33",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 515,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": 200,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "17.32",
          "expStartDate": "2023-03",
          "expNbrMonths": 20
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Full Data Plan",
          "expAmount": 100,
          "expStartDate": "2023-05",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Small Car - Brand New",
          "loanAmt": "444.62",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 54,
          "loanTotAmt": 24000,
          "loanIntRate": 0.1,
          "loanTerm": 6
        }
      ],
      "skillSurvey": [
        "4",
        "5",
        "5",
        "4",
        "5",
        "1",
        "3",
        "1",
        "4",
        "4",
        "4",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "police-officer",
          "conditionClass": "career"
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": 20
        },
        {
          "condName": "1-year-elapsed-in-game",
          "conditionClass": "game"
        },
        {
          "condName": "1-year-after-job-start",
          "conditionClass": "job"
        },
        {
          "condName": "level1-promotion",
          "conditionClass": "careerRelated",
          "duration": -1
        },
        {
          "condName": "good-roommate",
          "conditionClass": "roommate",
          "duration": -1
        }
      ],
        "unlockedFeatures": [
        {
          "featureName": "savings",
          "setup": "none",
          "level": 1,
          "lastNotifyDate": "2024-07"
        }
      ],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-07",
          "dialogIndx": 2,
          "rltnshpScore": 6,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2024-01",
          "dialogIndx": 0,
          "rltnshpScore": 7,
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-01",
          "dialogIndx": 2,
          "rltnshpScore": 11,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-04",
          "dialogIndx": 1,
          "rltnshpScore": 6,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student53",
      "jobInfo": {
        "careerID": "61311b4b2afc7f761668ac1b",
        "healthInsInd": "true",
        "jobRank": "1",
        "nbrYearsCollege": "0",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "6132427d97d929c9a8cf8710",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a56597d929c9a8cf871b",
      "carInfo": {
        "carID": "6132c34097d929c9a8cf8723",
        "carAge": 18,
        "carYear": "2023",
        "carType": "100"
      },
      "startingCash": "15613.00",
      "currentCash": "22723.21",
      "currentMoodPoints": "14169",
      "startGameDate": "2023-01",
      "currentGameDate": "2024-07",
      "gameNbrMonths": "342",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 348.48,
        "current10YearSpendAmt": 82905.74,
        "current10YearMonths": 19,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": -550,
        "totalMoodPoints": 14719,
        "moodPointMonths": 19
      },
      "iconTable": [],
      "dateAdded": "2023/01/27",
      "dateUpdated": "2023/02/2",
      "__v": 106,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63d2f6cf81495b90eda39961",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Cashier --> Annual Salary: $22,000.00",
          "assetAmt": "1833.33",
          "assetStartDate": "2023-01",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "190.83",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Windridge-2 bedrooms, 1 roommate",
          "expAmount": 1250,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 100% paid by employee",
          "expAmount": 370,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "138.67",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": "435.02",
          "expStartDate": "2023-01",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Feed the World Charity",
          "expAmount": "50",
          "expStartDate": "2023-01",
          "expNbrMonths": 10
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": "200",
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Sports Car - 3 to 5 Years Old",
          "loanAmt": "972.51",
          "loanStartDate": "2023-01",
          "loanNbrMonths": 70,
          "loanTotAmt": 52495,
          "loanIntRate": 0.1,
          "loanTerm": 6
        }
      ],
      "skillSurvey": [
        "3",
        "3",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "sports-car",
          "conditionClass": "car"
        },
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "cashier",
          "conditionClass": "career"
        },
        {
          "condName": "feed-the-world",
          "conditionClass": "game",
          "duration": 10
        },
        {
          "condName": "auto-accident",
          "conditionClass": "car",
          "duration": -1
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-01",
          "dialogIndx": 0,
          "rltnshpScore": 7,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student55",
      "jobInfo": {
        "careerID": "613105ca2afc7f761668ac03",
        "healthInsInd": "false",
        "jobRank": "2",
        "nbrYearsCollege": "0",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "613241f197d929c9a8cf870e",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a56597d929c9a8cf871b",
      "carInfo": {
        "carID": "621ffc35ddfe59be064afcde",
        "carAge": 50,
        "carYear": "2019",
        "carType": "400"
      },
      "startingCash": "16100.00",
      "currentCash": "11864.84",
      "currentMoodPoints": "16010",
      "startGameDate": "2023-01",
      "currentGameDate": "2023-03",
      "gameNbrMonths": "358",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 950,
        "current10YearSpendAmt": 13951.82,
        "current10YearMonths": 3,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 710,
        "totalMoodPoints": 15300,
        "moodPointMonths": 3
      },
      "iconTable": [],
      "dateAdded": "2023/01/26",
      "dateUpdated": "2023/02/9",
      "__v": 12,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63e56bc083cbe24175c2f8cd",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Marketing Specialist --> Annual Salary: $52,000.00",
          "assetAmt": "4333.33",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "678.33",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Windridge-2 bedrooms, 1 roommate",
          "expAmount": 1250,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "173.33",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Save the Earth Charity",
          "expAmount": "50",
          "expStartDate": "2023-02",
          "expNbrMonths": 11
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - More than 5 Years Old",
          "loanAmt": "122.27",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 71,
          "loanTotAmt": 6600,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Business Degree-Marketing(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 119,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "3",
        "4",
        "4",
        "3",
        "2",
        "2",
        "5",
        "4",
        "3",
        "3",
        "4",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "cheap-furniture",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-dining-chairs",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-bed",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-lounge-chair",
          "conditionClass": "furniture"
        },
        {
          "condName": "marketing-specialist",
          "conditionClass": "career"
        },
        {
          "condName": "save-the-earth",
          "conditionClass": "game",
          "duration": 11
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-02",
          "dialogIndx": 0,
          "rltnshpScore": 1,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ],
      "user": "student56",
      "jobInfo": {
        "careerID": "613116c22afc7f761668ac15",
        "healthInsInd": "true",
        "jobRank": "6",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "613241f197d929c9a8cf870e",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a41a97d929c9a8cf8717",
      "carInfo": {
        "carID": "6132c3f897d929c9a8cf8725",
        "carAge": 97,
        "carYear": "2015",
        "carType": "200"
      },
      "startingCash": "-160.00",
      "currentCash": "828.75",
      "currentMoodPoints": "15800",
      "startGameDate": "2023-02",
      "currentGameDate": "2023-03",
      "gameNbrMonths": "359",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 0,
        "current10YearSpendAmt": 6504.58,
        "current10YearMonths": 2,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 0,
        "totalMoodPoints": 15800,
        "moodPointMonths": 2
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/03/2",
      "__v": 7,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63e569522cbc3405dc7bf616",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Restaurant Cook --> Annual Salary: $26,000.00",
          "assetAmt": "2166.66",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 269,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Windridge-2 bedrooms, 1 roommate",
          "expAmount": 1250,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": "391.40",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Basic Plan",
          "expAmount": "35",
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "16.50",
          "expStartDate": "2023-04",
          "expNbrMonths": "36"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Small Car - 3 to 5 Years Old",
          "loanAmt": "280.57",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 70,
          "loanTotAmt": 15145,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Associates Degree-Culinary School(2 years)",
          "loanAmt": "118.66",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 118,
          "loanTotAmt": 12000,
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "4",
        "4",
        "5",
        "4",
        "3",
        "5",
        "3",
        "5",
        "3",
        "5",
        "3",
        "3"
      ],
      "conditionsTable": [
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "restaurant-cook",
          "conditionClass": "career"
        },
        {
          "condName": "auto-accident",
          "conditionClass": "car",
          "duration": -1
        },
        {
          "condName": "ok-manager",
          "conditionClass": "manager",
          "duration": -1
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": "36"
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-02",
          "dialogIndx": 0,
          "rltnshpScore": 4.75,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ],
      "user": "student57",
      "jobInfo": {
        "careerID": "61312efd5a67295865b80c72",
        "healthInsInd": "true",
        "jobRank": "5",
        "nbrYearsCollege": "2",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "613241f197d929c9a8cf870e",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a4c097d929c9a8cf8719",
      "carInfo": {
        "carID": "6132c17c97d929c9a8cf8721",
        "carAge": 50,
        "carYear": "2019",
        "carType": "100"
      },
      "startingCash": "9060.00",
      "currentCash": "8121.34",
      "currentMoodPoints": "16128",
      "startGameDate": "2023-02",
      "currentGameDate": "2023-04",
      "gameNbrMonths": "358",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": -911,
        "current10YearSpendAmt": 10222.98,
        "current10YearMonths": 3,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 878,
        "totalMoodPoints": 15250,
        "moodPointMonths": 2
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/03/2",
      "__v": 21,
      "hand": [],
      "pendingJob": null
    },{
      "_id": "63e56b6683cbe24175c2f7eb",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Electrical Engineer --> Annual Salary: $73,830.00",
          "assetAmt": "6152.53",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 1151.42,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Windridge-2 bedrooms, 1 roommate",
          "expAmount": 1250,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "137.24",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "133.33",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 765,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "11.25",
          "expStartDate": "2023-08",
          "expNbrMonths": 7
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": 200,
          "expStartDate": "2024-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Full Data Plan",
          "expAmount": "100",
          "expStartDate": "2024-09",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "SUV - 3 to 5 Years Old",
          "loanAmt": "361.81",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 37,
          "loanTotAmt": 19530,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 85,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "5",
        "5",
        "3",
        "5",
        "5",
        "5",
        "5",
        "5",
        "3",
        "5",
        "5",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": 7
        },
        {
          "condName": "ok-manager",
          "conditionClass": "manager",
          "duration": -1
        },
        {
          "condName": "1-year-elapsed-in-game",
          "conditionClass": "game"
        },
        {
          "condName": "1-year-after-job-start",
          "conditionClass": "job"
        },
        {
          "condName": "ok-roommate",
          "conditionClass": "roommate",
          "duration": -1
        },
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "current-cash-large",
          "conditionClass": "cash"
        },
        {
          "condName": "level1-promotion",
          "conditionClass": "careerRelated",
          "duration": -1
        }
      ],
       "unlockedFeatures": [
        {
          "featureName": "savings",
          "setup": "none",
          "level": 1,
          "lastNotifyDate": "2026-01"
        },
        {
          "featureName": "creditCard",
          "setup": "none",
          "level": 1,
          "lastNotifyDate": "2026-01"
        }
      ],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-08",
          "dialogIndx": 3,
          "rltnshpScore": 12,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2023-05",
          "dialogIndx": 1,
          "rltnshpScore": 27.75,
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2024-02",
          "dialogIndx": 3,
          "rltnshpScore": 11,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2024-08",
          "dialogIndx": 2,
          "rltnshpScore": 5.75,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student58",
      "jobInfo": {
        "careerID": "61310cff2afc7f761668ac09",
        "healthInsInd": "true",
        "jobRank": "6",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "613241f197d929c9a8cf870e",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a56597d929c9a8cf871b",
      "carInfo": {
        "carID": "6132c65697d929c9a8cf872d",
        "carAge": 71,
        "carYear": "2020",
        "carType": "300"
      },
      "startingCash": "-3900.00",
      "currentCash": "35806.08",
      "currentMoodPoints": "24091",
      "startGameDate": "2023-02",
      "currentGameDate": "2026-01",
      "gameNbrMonths": "325",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 57,
        "current10YearSpendAmt": 155012.87,
        "current10YearMonths": 36,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 500,
        "totalMoodPoints": 23591,
        "moodPointMonths": 34
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/03/2",
      "__v": 107,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id":  "64011299baeeb5722ca9db76",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Registered Nurse --> Annual Salary: $77,418.00",
          "assetAmt": "6451.50",
          "assetStartDate": "2023-03",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 1275,
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Sunnyside-1 bedroom, 0 roommates",
          "expAmount": 885,
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "117.66",
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 643.75,
          "expStartDate": "2023-03",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Increase - Car Accident",
          "expAmount": "13.50",
          "expStartDate": "2023-11",
          "expNbrMonths": 5
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Full Data Plan",
          "expAmount": 100,
          "expStartDate": "2023-12",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": "200",
          "expStartDate": "2024-05",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "16.50",
          "expStartDate": "2024-11",
          "expNbrMonths": 17
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
          "loanAmt": "300.21",
          "loanStartDate": "2023-03",
          "loanNbrMonths": 33,
          "loanTotAmt": 16205,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Nursing(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-03",
          "loanNbrMonths": 81,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "1",
        "5",
        "5",
        "4",
        "2",
        "4",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "registered-nurse",
          "conditionClass": "career"
        },
        {
          "condName": "broken-refrigerator",
          "conditionClass": "home",
          "duration": -1
        },
        {
          "condName": "auto-insurance-increase-accident",
          "conditionClass": "game",
          "duration": 5
        },
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "1-year-elapsed-in-game",
          "conditionClass": "game"
        },
        {
          "condName": "1-year-after-job-start",
          "conditionClass": "job"
        },
        {
          "condName": "level1-promotion",
          "conditionClass": "careerRelated",
          "duration": -1
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": 17
        },
        {
          "condName": "current-cash-large",
          "conditionClass": "cash"
        }
      ],
       "unlockedFeatures": [
        {
          "featureName": "savings",
          "setup": "active",
          "level": 1,
          "lastNotifyDate": "2024-04"
        },
        {
          "featureName": "creditCard",
          "setup": "none",
          "level": 2,
          "lastNotifyDate": "2025-04"
        }
      ],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-06",
          "dialogIndx": 2,
          "rltnshpScore": 11,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2024-06",
          "dialogIndx": 2,
          "rltnshpScore": 2,
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2024-03",
          "dialogIndx": 2,
          "rltnshpScore": 1,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-03",
          "dialogIndx": 3,
          "rltnshpScore": 14,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student59",
      "jobInfo": {
        "careerID": "61311e102afc7f761668ac1f",
        "healthInsInd": "true",
        "jobRank": "7",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "61323ec697d929c9a8cf8706",
        "homeType": "apartment",
        "roommates": "0",
        "bedrooms": "1"
      },
      "furnitureID": "6132a4c097d929c9a8cf8719",
      "carInfo": {
        "carID": "6132c4a797d929c9a8cf8727",
        "carAge": 87,
        "carYear": "2019",
        "carType": "200"
      },
      "startingCash": "-575.00",
      "currentCash": "43260.35",
      "currentMoodPoints": "24125",
      "startGameDate": "2023-03",
      "currentGameDate": "2026-06",
      "gameNbrMonths": "321",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 0,
        "current10YearSpendAmt": 157337.44,
        "current10YearMonths": 40,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 0,
        "totalMoodPoints": 24125,
        "moodPointMonths": 34
      },
      "iconTable": [],
      "dateAdded": "2023/03/2",
      "dateUpdated": "2023/03/2",
      "__v": 69,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63e56b0f83cbe24175c2f6db",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Medical Assistant --> Annual Salary: $31,620.00",
          "assetAmt": "2635.00",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        },
        {
          "assetItem": "Job Salary",
          "assetDesc": "On-the-job Injury",
          "assetAmt": "0.00",
          "assetStartDate": "2023-08",
          "assetNbrMonths": "2"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 350.83,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Windridge-2 bedrooms, 1 roommate",
          "expAmount": 1250,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "129.47",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": "77.25",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 500,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Save the Earth Charity",
          "expAmount": "50",
          "expStartDate": "2023-02",
          "expNbrMonths": 6
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Feed the World Charity",
          "expAmount": "50",
          "expStartDate": "2023-03",
          "expNbrMonths": 7
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Basic Package($)",
          "expAmount": 50,
          "expStartDate": "2023-05",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Auto Insurance Claim Increase - Speeding Ticket",
          "expAmount": "11.59",
          "expStartDate": "2023-08",
          "expNbrMonths": "36"
        },
        {
          "expItem": "Phone",
          "expDesc": "Phone - Basic Plan",
          "expAmount": "35",
          "expStartDate": "2023-08",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
          "loanAmt": "300.21",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 66,
          "loanTotAmt": 16205,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Associates Degree-Medical Assistant(2 years)",
          "loanAmt": "118.66",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 114,
          "loanTotAmt": 12000,
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "3",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "4"
      ],
      "conditionsTable": [
        {
          "condName": "roommate",
          "conditionClass": "home"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "medical-assistant",
          "conditionClass": "career"
        },
        {
          "condName": "save-the-earth",
          "conditionClass": "game",
          "duration": 6
        },
        {
          "condName": "feed-the-world",
          "conditionClass": "game",
          "duration": 7
        },
        {
          "condName": "auto-insurance-increase-ticket",
          "conditionClass": "game",
          "duration": "36"
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "2023-08",
          "dialogIndx": 0,
          "rltnshpScore": 2,
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-02",
          "dialogIndx": 1,
          "rltnshpScore": 18,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "2023-05",
          "dialogIndx": 0,
          "rltnshpScore": 4,
          "totalLoanAmt": "0"
        }
      ],
      "user": "student60",
      "jobInfo": {
        "careerID": "613118c12afc7f761668ac17",
        "healthInsInd": "true",
        "jobRank": "10",
        "nbrYearsCollege": "2",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "613241f197d929c9a8cf870e",
        "homeType": "apartment",
        "roommates": "1",
        "bedrooms": "2"
      },
      "furnitureID": "6132a56597d929c9a8cf871b",
      "carInfo": {
        "carID": "6132c4a797d929c9a8cf8727",
        "carAge": 54,
        "carYear": "2019",
        "carType": "200"
      },
      "startingCash": "6100.00",
      "currentCash": "2511.67",
      "currentMoodPoints": "15794",
      "startGameDate": "2023-02",
      "currentGameDate": "2023-08",
      "gameNbrMonths": "354",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 80.05,
        "current10YearSpendAmt": 26160.03,
        "current10YearMonths": 7,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": -1050,
        "totalMoodPoints": 16844,
        "moodPointMonths": 7
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/02/9",
      "__v": 38,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63e567a59d562d2c49272c3b",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Network Administrator --> Annual Salary: $71,400.00",
          "assetAmt": "5950.00",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": 1151.42,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Sunnyside-1 bedroom, 0 roommates",
          "expAmount": 885,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "117.66",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "144.44",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 795,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Streaming Movies",
          "expDesc": "Movies/Streaming - Deluxe Package",
          "expAmount": "200",
          "expStartDate": "2023-05",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Small Car - More than 5 Years Old",
          "loanAmt": "85.13",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 66,
          "loanTotAmt": 4595,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Information Technology(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 114,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "5",
        "4",
        "5",
        "4",
        "4",
        "3",
        "4",
        "3",
        "5",
        "5",
        "4",
        "4"
      ],
      "conditionsTable": [
        {
          "condName": "car-gt-10-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "cheap-furniture",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-dining-chairs",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-bed",
          "conditionClass": "furniture"
        },
        {
          "condName": "network-administrator",
          "conditionClass": "career"
        },
        {
          "condName": "broken-refrigerator",
          "conditionClass": "home",
          "duration": -1
        },
        {
          "condName": "bad-manager",
          "conditionClass": "manager",
          "duration": -1
        },
        {
          "condName": "quit-job",
          "conditionClass": "job",
          "duration": -1
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-05",
          "dialogIndx": 0,
          "rltnshpScore": 1,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "2023-02",
          "dialogIndx": 1,
          "rltnshpScore": -1,
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ],
      "user": "student61",
      "jobInfo": {
        "careerID": "61311a372afc7f761668ac19",
        "healthInsInd": "true",
        "jobRank": "6",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "61323ec697d929c9a8cf8706",
        "homeType": "apartment",
        "roommates": "0",
        "bedrooms": "1"
      },
      "furnitureID": "6132a41a97d929c9a8cf8717",
      "carInfo": {
        "carID": "6132c0cb97d929c9a8cf871f",
        "carAge": 162,
        "carYear": "2010",
        "carType": "100"
      },
      "startingCash": "205.00",
      "currentCash": "8156.76",
      "currentMoodPoints": "15402",
      "startGameDate": "2023-02",
      "currentGameDate": "2023-08",
      "gameNbrMonths": "354",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 1434,
        "current10YearSpendAmt": 24710.14,
        "current10YearMonths": 7,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 450,
        "totalMoodPoints": 14952,
        "moodPointMonths": 7
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/02/9",
      "__v": 30,
      "hand": [],
      "pendingJob": null
    },
    {
      "_id": "63e56ac783cbe24175c2f592",
      "assetTable": [
        {
          "assetItem": "Job Salary",
          "assetDesc": "Registered Nurse --> Annual Salary: $66,000.00",
          "assetAmt": "5500.00",
          "assetStartDate": "2023-02",
          "assetNbrMonths": "-1"
        }
      ],
      "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "1027.92",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Sunnyside-1 bedroom, 0 roommates",
          "expAmount": 885,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "144.44",
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2023-02",
          "expNbrMonths": "-1"
        }
      ],
      "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Small Car - More than 5 Years Old",
          "loanAmt": "85.13",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 70,
          "loanTotAmt": 4595,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Nursing(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2023-02",
          "loanNbrMonths": 118,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
      "skillSurvey": [
        "2",
        "3",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5",
        "5"
      ],
      "conditionsTable": [
        {
          "condName": "car-gt-10-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "car-gt-5-years-old",
          "conditionClass": "car"
        },
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "cheap-furniture",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-dining-chairs",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-bed",
          "conditionClass": "furniture"
        },
        {
          "condName": "cheap-lounge-chair",
          "conditionClass": "furniture"
        },
        {
          "condName": "registered-nurse",
          "conditionClass": "career"
        }
      ],
      "unlockedFeatures": [],
      "friendList": [
        {
          "name": "Charlie",
          "introDate": "2023-02",
          "dialogIndx": 0,
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cindy",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ],
      "user": "student62",
      "jobInfo": {
        "careerID": "61311e102afc7f761668ac1f",
        "healthInsInd": "true",
        "jobRank": "9",
        "nbrYearsCollege": "4",
        "nbrSkillSurvey": "1",
        "jobStartGameMonth": 360
      },
      "homeInfo": {
        "homeID": "61323ec697d929c9a8cf8706",
        "homeType": "apartment",
        "roommates": "0",
        "bedrooms": "1"
      },
      "furnitureID": "6132a41a97d929c9a8cf8717",
      "carInfo": {
        "carID": "6132c0cb97d929c9a8cf871f",
        "carAge": 158,
        "carYear": "2010",
        "carType": "100"
      },
      "startingCash": "205.00",
      "currentCash": "4778.72",
      "currentMoodPoints": "15000",
      "startGameDate": "2023-02",
      "currentGameDate": "2023-04",
      "gameNbrMonths": "358",
      "nbrMonthsNegative": "0",
      "nbrMonthsMoodNegative": "0",
      "spendingHistory": {
        "currentMonthSpendAmt": 0,
        "current10YearSpendAmt": 9221.28,
        "current10YearMonths": 3,
        "previous10YearSpendAmt": 0,
        "previous10YearMonths": 0
      },
      "moodPointHistory": {
        "currentMonthMoodPoints": 0,
        "totalMoodPoints": 15000,
        "moodPointMonths": 3
      },
      "iconTable": [],
      "dateAdded": "2023/02/9",
      "dateUpdated": "2023/02/9",
      "__v": 1,
      "hand": [],
      "pendingJob": null
    }
   
   ]

export default DiscussionGameData