import {Component} from 'react';
import ConfigFile from '../../datasets/config_file';
import "./GameViews/gameViews.css";

class TimelineHorizontal extends Component {
    constructor(props) {
      super(props); 
      this.state={
        innerWidth: 0,
        rerender: false,
        screenWidth: 0,
        nbrMonths: 0,
      }
      this.resize();
      this.determineNumberOfLineMarkers();
    }

    resize() {
      window.addEventListener("resize", this.resize.bind(this))
      this.state.innerWidth = window.innerWidth;
    }

    determineNumberOfLineMarkers(){
        this.gameNbrYears = (+this.props.totalNbrGameMonths / 12);
        this.nbrLineMarkers = +(this.gameNbrYears / 5).toFixed(0) + 1;
        switch (this.gameNbrYears){
            case 30:
                this.leftAdjust = -11;
                this.lineSpacing = 15;
                this.offset = 1;
                break;
            case 20:
                this.leftAdjust = -18;
                this.lineSpacing = 22;
                this.offset = 1;
                break;
            case 10:
                this.leftAdjust = -40;
                this.lineSpacing = 44;
                this.offset = 1;
                break;
            default:
                break;
        }
    }
  
    setLineMarkers(){
    
      this.bgnTextClass = "timeline-bgn-game-text";
      this.endTextClass = "timeline-end-game-text";
      if (this.props.screenSize !== "wide"){
           this.bgnTextClass = this.bgnTextClass + " small-text";
           this.endTextClass = this.endTextClass + " small-text";
      }
              
      this.lineLeftArr = [];
      this.numberLeftArr = [];
      for (let i=1; i<=this.nbrLineMarkers; i++){
          const lineLeft = (this.lineSpacing * i) + this.offset + this.leftAdjust + "%";
          this.lineLeftArr.push(lineLeft);
          const numberLeft = (this.lineSpacing * i) + this.leftAdjust + "%";
          this.numberLeftArr.push(numberLeft);
      }
    }

    determinePctComplete(){  
        let initialOffset = 0;
        let dynamicAdjust = 0;
        if (this.props.screenSize == "wide"){
            initialOffset = 3;
            // wide screen max is 1536
            dynamicAdjust = ((1536 - this.state.innerWidth) / 100 ).toFixed(2);
    
        }else{
            // narrow screen max is 999
            dynamicAdjust = ((999 - this.state.innerWidth) / 90).toFixed(2);
            initialOffset = 5;
        }
        let totalGameMonths = this.props.totalNbrGameMonths;
        let monthsPlayed = totalGameMonths - +this.props.gameNbrMonths;
      //  monthsDiff = 120;
      switch (this.gameNbrYears){
        case 30:
            this.varLength = Math.trunc((monthsPlayed * .25) - initialOffset   
              - dynamicAdjust) + "%";
            break;
        case 20:
            this.varLength = Math.trunc((monthsPlayed * .37) - initialOffset   
              - dynamicAdjust) + "%";
            break;
        case 10:
            this.varLength = Math.trunc((monthsPlayed * .74) - initialOffset   
              - dynamicAdjust) + "%";
            break;
        default:
            break;
    }
        
        let test = ((monthsPlayed * .25) - initialOffset   
            - dynamicAdjust) + "%";
        this.state.nbrMonths = monthsPlayed;
        this.state.screenWidth = this.state.innerWidth;
    }

    determineIfNewYear(){
      let numerator = +this.state.nbrMonths;
      let remainder = 99;
      if (this.props.monthsPerTurn === 1){
          remainder = numerator % 12;
      }else{
          //  this logic determines if a new year was encountered during 'monthsPerTurn'
          let indxStopValue = this.props.monthsPerTurn;
          for (let i=0; i<indxStopValue; i++){
              remainder = numerator % 12;
              if (remainder === 0){
                  break;
              }
              numerator -=  1;
          }
      }
    
      this.year = false;
      if (remainder === 0){
        this.class += " spinTrainAround";
        const nbrYears = numerator / 12;
        let sText = " Has";
        if (nbrYears === 0){
           this.timeText = "No Time Has Passed In the Game";
        }else{
            if (nbrYears > 1){
              sText = "s Have";
            }
            this.timeText = nbrYears + " Game Year" + sText + " Passed";
        }
        this.year = true;
        let lowYear = 0;
        let highYear = 0;
        switch (this.gameNbrYears){
          case 30:
              lowYear = 12;
              highYear = 22;
              break;
          case 20:
              lowYear = 9;
              highYear = 15;
              break;
          case 10:
              lowYear = 5;
              highYear = 6;
              break;
          default:
              break;
        }
        if (nbrYears < lowYear || nbrYears > highYear){
          this.trainTextClass = "train-text-center font-weight-bold";
        }else{
          this.trainTextClass = "train-text-left font-weight-bold";
        }
        
      }
    }

    render() { 
      if (this.state.innerWidth != this.state.screenWidth ||
          this.state.nbrMonths != this.monthsDiff){
            this.setLineMarkers();
            this.class = "timeline-train-position timeline-train-img";
            this.determinePctComplete();
            this.determineIfNewYear();
          }
                       
    return <>
            <div className="">
                <div className={this.bgnTextClass}>Nbr Years Elapsed</div>
                <div className="horizontal-timeline" ></div>
                <div className="timelineNumberPlacement text-center" 
                    style={{left: this.numberLeftArr[0]}}>0</div>
                <div className="timelineVerticalLine" style={{left: this.lineLeftArr[0]}}></div>
                <div className="timelineNumberPlacement text-center" 
                    style={{left: this.numberLeftArr[1]}}>5</div>
                <div className="timelineVerticalLine" style={{left: this.lineLeftArr[1]}}></div>
                <div className="timelineNumberPlacement text-center" 
                    style={{left: this.numberLeftArr[2]}}>10</div>
                <div className="timelineVerticalLine" style={{left: this.lineLeftArr[2]}}></div>
            {this.nbrLineMarkers > 3 ?
                <span>
                    <div className="timelineNumberPlacement text-center" 
                        style={{left: this.numberLeftArr[3]}}>15</div>
                    <div className="timelineVerticalLine" 
                        style={{left: this.lineLeftArr[3]}}></div>
                    <div className="timelineNumberPlacement text-center" 
                        style={{left: this.numberLeftArr[4]}}>20</div>
                    <div className="timelineVerticalLine" 
                        style={{left: this.lineLeftArr[4]}}></div>
                </span>
            :
              null
            }
            {this.nbrLineMarkers > 5 ?
                <span>
                    <div className="timelineNumberPlacement text-center" 
                        style={{left: this.numberLeftArr[5]}}>25</div>
                    <div className="timelineVerticalLine" 
                        style={{left: this.lineLeftArr[5]}}></div>
                    <div className="timelineNumberPlacement text-center" 
                        style={{left: this.numberLeftArr[6]}}>30</div>
                    <div className="timelineVerticalLine" 
                        style={{left: this.lineLeftArr[6]}}></div>
                </span>
            :
              null
            }
                <img src="./assets/timeline-train.png" style={{left: this.varLength}}
                          className={this.class} alt="train"
                    />
                <div className={this.endTextClass}>End Game</div>
                                     
            </div>
            {this.year ?
              <div className={this.trainTextClass}>
                   {this.timeText}
              </div>
            :
              null
            }
    </>
  }

}

//<div className="timelineFill" style={{width: this.varLength}} ></div>

 
export default TimelineHorizontal;