
import {Link} from "react-router-dom";
import React from 'react';
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import LinkTab from '../components/shared-components/link_tab';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import SelectActivity from '../components/game-board-activities/select_activity';
import SelectFriendForActivity from '../components/game-board-activities/select_friend_for_activity';
import StartActivity from '../components/game-board-activities/start_activity';
import FinishActivity from "../components/game-board-activities/finish_activity";
import CommentModal from '../components/shared-components/comment_modal';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';

class Activities extends React.Component {
    constructor(props) {
        super(props);
               
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        this.setState({inputArr: this.state.inputArr});  
        
    };

     this.openCommentModal = this.openCommentModal.bind(this);
     this.closeCommentModal = this.closeCommentModal.bind(this);
     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
           
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        activityCategory: "",
        commentIcon: "./assets/ContactUs/pencilPaper.png",
        commentModalOpen: false,
        fromProcessName: "Activities",
        screenSize: sessionStorage.getItem("GameScreenSize"),
        headers: setHeadersForFetch(),
        environment: Config.dataURL.currentEnv,
        subProcess: "ActivitiesMain",
        activityInfo: {
            loading: true,
            loadingGame: true,
            gameData: {},
            slctActivity: {},
            friendInfo: {},
            activityStarted: false,
            activityScore: {},
            activityTracker: "",
            addActivityTracker: false,
            slctCategory: "",
            gameNbrMonths: "",
        }
    };

    switch(props.selected) {
        case "selectActivity":
            this.inputSelected(0);
            break;
        case "chooseAFriend":
            this.inputSelected(1);
             break;
        case "startActivity":
            this.inputSelected(2);
            break;
        case "finishActivity":
            this.inputSelected(3);
            break;
     
    }
};
    
    render(){
        if (this.state.activityCategory == ""){
            this.state.activityCategory = this.props.location.state.detail
        }
        this.className = "mt-5 mx-3";
        if (this.state.screenSize == "wide"){
                this.className = "col-sm-2 " + this.className;
                this.commentIconClass = "comment-icon-position comment-img";
        }else{
            this.className = "col-sm-1 " + this.className;
            this.commentIconClass = "comment-icon-position-medium comment-img"; 
        }
        
    return <>
        <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
                    {this.state.screenSize == "wide" ?
                        <div className={this.className}>
                            <div>
                                <img className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                    alt="MoneyTrain Picture"/>
                            </div>
                        </div>
                     :
                        null
                    }
                        <div>
                            <a target="_blank" data-tip="Click to provide feedback about game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className={this.commentIconClass} alt="comment"
                                    onClick={this.openCommentModal}/>
                            </a>
                        </div>

                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getActivityInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }
                        {this.props.selected === "selectActivity" ? 
                        <SelectActivity
                            screenSize = {this.state.screenSize}
                            onOpen ={()=> this.inputSelected(0)}
                            onClose={()=>this.backToGameBoard()}
                            activityInfo = {() => this.getActivityInfo()} 
                            onComplete={() => this.inputCompleted(0)}
                            setActivityInfo = {(value, propertyName) => this.setActivityInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                        : <span/>}
                         {this.props.selected === "chooseAFriend" ? 
                        <SelectFriendForActivity
                            screenSize = {this.state.screenSize}
                            onOpen ={()=> this.inputSelected(2)}
                            onClose={()=>this.backToGameBoard()}
                            activityInfo = {() => this.getActivityInfo()} 
                            onComplete={() => this.inputCompleted(1)}
                            setActivityInfo = {(value, propertyName) => this.setActivityInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                        : <span/>} 
                        {this.props.selected === "startActivity" ? 
                        <StartActivity
                            screenSize = {this.state.screenSize}
                            onOpen ={()=> this.inputSelected(2)}
                            onClose={()=>this.backToGameBoard()}
                            activityInfo = {() => this.getActivityInfo()} 
                            onComplete={() => this.inputCompleted(2)}
                            setActivityInfo = {(value, propertyName) => this.setActivityInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                        : <span/>} 
                         {this.props.selected === "finishActivity" ? 
                        <FinishActivity
                            screenSize = {this.state.screenSize}
                            onOpen ={()=> this.inputSelected(3)}
                            onClose={()=>this.backToGameBoard()}
                            activityInfo = {() => this.getActivityInfo()} 
                            onComplete={() => this.inputCompleted(3)}
                            setActivityInfo = {(value, propertyName) => this.setActivityInfo(value, propertyName)}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                        : <span/>} 
                     <div className="col-sm-2 ml-5"> 

                         {this.state.activityInfo.activityStarted ?  
                            <LinkTab inputArr={this.state.inputArr[0]} name="Select Activity"
                                screenSize={this.state.screenSize}/>
                        :
                            <Link to="select-activity" onClick={() => this.handleClick(0)}>
                                <LinkTab inputArr={this.state.inputArr[0]} name="Select Activity" validToComplete="true"
                                  screenSize={this.state.screenSize}/>
                            </Link>
                        }
               
                        {this.state.activityCategory != "SideHustle" ?
                            <div>
                                {this.state.inputArr[0].completed && !this.state.activityInfo.activityStarted ?
                                    <Link to="choose-a-friend" onClick={() => this.handleClick(1)}>
                                        <LinkTab inputArr={this.state.inputArr[1]} name="Choose a Friend" validToComplete="true"
                                          screenSize={this.state.screenSize}/>
                                    </Link>
                                    :
                                    <LinkTab inputArr={this.state.inputArr[1]} name="Choose a Friend"  
                                        screenSize={this.state.screenSize}/>
                                }
                            </div>
                        :
                            null
                        }

                        {!this.state.inputArr[2].completed &&
                            (this.state.inputArr[1].completed ||
                            (this.state.inputArr[0].completed == true && this.state.activityCategory == "SideHustle"))  ?
                            <Link to="start-activity" onClick={() => this.handleClick(2)}>
                                <LinkTab inputArr={this.state.inputArr[2]} name="Start Activity" validToComplete="true"
                                  screenSize={this.state.screenSize}/>
                            </Link>
                        :
                            <LinkTab inputArr={this.state.inputArr[2]} name="Start Activity"  
                                screenSize={this.state.screenSize}/>
                        }

                        {this.state.inputArr[2].completed ?
                        <div>
                        <a target="_blank" data-tip="Click finish button that is to the left (in the bedroom picture)." rel="noopener noreferrer">
                            <ReactTooltip globalEventOff="click" />
                                <LinkTab inputArr={this.state.inputArr[3]} name="Finish Activity" validToComplete="true" 
                                    screenSize={this.state.screenSize}/>
                            </a>
                        </div>
                                
                        :
                            <LinkTab inputArr={this.state.inputArr[3]} name="Finish Activity" 
                                screenSize={this.state.screenSize} />
                         }
                        </div>     
                </span>
        </span>             
    </>
}

    backToGameBoard(){
        this.props.history.push("/game-board");
    }

    openCommentModal(){
        this.state.commentModalOpen = true;
    }

    closeCommentModal(){
        this.state.commentModalOpen = false;
        this.setState({commentModalOpen: false});
    }

    handleClick = index => {
        this.state.inputSelected(index);
    }
  
    getActivityInfo()
    {
        return this.state.activityInfo;
    }

    setActivityInfo(value, propertyName = null)
    {
        if (propertyName)
        {
            this.state.activityInfo[propertyName] = value;
            this.setState({activityInfo: this.state.activityInfo});
        }
        else this.setState({activityInfo: value});
    }

    processErrorReturn(errorObj){
        this.addRecordToLogfile(errorObj);
        if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
            alert ("Uh oh!  Something unexpected has occurred " +
                "error has been logged and will be addressed. " +
                "For now click OK, then return to the process " +
                "where you were and try again.");
            this.props.history.push('/');
        }
    }

    addRecordToLogfile(errorObj){
        const headers=setHeadersForFetch();  
        if (errorObj.status == 250){
            errorObj.message = "No record found";
        }
        const logFileBody =  {
            mainProcess: "Activities",
            subProcess: errorObj.subProcess,
            function: errorObj.function,
            status: errorObj.status,
            message: errorObj.message
        };
        console.log("logFileBody is: ", logFileBody)
        const urlParm = "";
        const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
        fetch (uri,  
            {method:'POST',
                headers: headers,
                body: JSON.stringify(logFileBody)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            console.log("logfile create was successful");
                        }else{
                            alert ("Response from save log file not valid!" +
                                " status is: " + statusCode +
                                " message is: " + output.body.message)
                            
                        }
                });
            }
        });
      
    }

}
export default withRouter(Activities);