
export const endOfMonthSpendHistory = (monthlyExpenses, monthlyChargeExpenses, monthlyLoanAmts, spendHistory,
        nbrMonthsPerTurn) => {
    //  this field is named currentMonthSpendAmt, but it is actually 'currentTurnSpendAmt' (if turn is 3 months
    //  long, then this field contains amounts spent during the 3 month turn)
    //  the "monthly" amounts have already been multiplied by nbrMonthsPerTurn before entering this process
    //  except the "currentMonthSpendAmt"     
    spendHistory.currentMonthSpendAmt = ((+spendHistory.currentMonthSpendAmt * nbrMonthsPerTurn) + 
            +monthlyExpenses + +monthlyChargeExpenses + +monthlyLoanAmts).toFixed(2);

    if (spendHistory.current10YearMonths >= 120){
        spendHistory.previous10YearSpendAmt = +spendHistory.current10YearSpendAmt;
        spendHistory.previous10YearMonths = +spendHistory.current10YearMonths;
        spendHistory.current10YearSpendAmt = 0;
        spendHistory.current10YearMonths = 0;
    }
    spendHistory.current10YearSpendAmt = +spendHistory.current10YearSpendAmt + +spendHistory.currentMonthSpendAmt;
    spendHistory.current10YearSpendAmt = (+spendHistory.current10YearSpendAmt).toFixed(2);
    spendHistory.current10YearMonths += nbrMonthsPerTurn;
    spendHistory.currentMonthSpendAmt = 0;
    return spendHistory;
   
}
