const unlockedFeaturesDataset =[
  {featureName: "funStuff", minNbrMonths: 1, minCashAmt: 100, minMonthIncome: 100, level: 1},
  {featureName: "funStuff", minNbrMonths: 20, minCashAmt: 8000, minMonthIncome: 1000, level: 2},
  {featureName: "funStuff", minNbrMonths: 45, minCashAmt: 20000, minMonthIncome: 1500, level: 3},
  {featureName: "savings", minNbrMonths: 6, minCashAmt: 5000, minMonthIncome: 500, level: 1},
  {featureName: "creditCard", minNbrMonths: 12, minCashAmt: 5000, minMonthIncome: 200, level: 1},
  {featureName: "creditCard", minNbrMonths: 18, minCashAmt: 7500, minMonthIncome: 500, level: 2},
  {featureName: "creditCard", minNbrMonths: 24, minCashAmt: 10000, minMonthIncome: 750, level: 3},
  {featureName: "creditCard", minNbrMonths: 30, minCashAmt: 15000, minMonthIncome: 1000, level: 4},
  {featureName: "creditCard", minNbrMonths: 36, minCashAmt: 20000, minMonthIncome: 1250, level: 5},
  {featureName: "creditCard", minNbrMonths: 42, minCashAmt: 24000, minMonthIncome: 1750, level: 6},
  {featureName: "creditCard", minNbrMonths: 48, minCashAmt: 30000, minMonthIncome: 2500, level: 7},
  {featureName: "timeWizard", minNbrMonths: 24, minCashAmt: 10000, minMonthIncome: 1000, level: 1},
  {featureName: "timeWizard", minNbrMonths: 48, minCashAmt: 20000, minMonthIncome: 2000, level: 2},
  {featureName: "timeWizard", minNbrMonths: 64, minCashAmt: 50000, minMonthIncome: 2500, level: 3},
  {featureName: "investments", minNbrMonths: 900, minCashAmt: 10000, minMonthIncome: 1000, level: 1}
];

export default unlockedFeaturesDataset;