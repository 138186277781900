import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import initializeErrorArray from "./objects/initialize_error_array";
import { monthlyLoanAmt } from "../../objects/AssetsExpenses/loanAmtCalc_function";
import { calculatePMITerm } from "../game-views/objects/calculate_PMI_term";

class LoanAndPMICalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.state={
        parametersEntered: false,
        validSw: true,
      }   
      this.errArray = initializeErrorArray(4); 
      this.dataValidSw = true;
}

processLoanParameters(){
     this.getFormFields();
     this.editFormFields();
     if (this.dataValidSw === true){
        this.calculateLoanAmts();
        this.setState({parametersEntered: true})
     }else{
        this.setState({validSw: false});
     }
}

getFormFields(){
    this.totalAmt = document.getElementById("totalAmt").value;
    this.downPayment = document.getElementById("downPayment").value;
    this.loanIntRate = document.getElementById("loanIntRate").value;
    this.PMIRate = document.getElementById("PMIRate").value;
}

editFormFields(){
    this.dataValidSw = true;
    this.errArray = initializeErrorArray(4);
    if (+this.totalAmt > 0){
        // all is OK
    }else{
        this.errorMsg = "Total Loan Amt is required and cannot be 0";
        this.checkErrorArray(0);
    }
    if (+this.downPayment > 0){
        // all is OK
    }else{
        this.errorMsg = "Down Payment Amt is required and cannot be 0";
        this.checkErrorArray(1);
    }
    if (+this.loanIntRate <= 0 || +this.loanIntRate > 10){
        this.errorMsg = "Loan Interest Rate must be gt 0 and lt 10";
        this.checkErrorArray(2);
    }
    if (+this.PMIRate <= 0 || +this.PMIRate > 10){
        this.errorMsg = "PMI Rate must be gt 0 and lt 10";
        this.checkErrorArray(3);
    }
}
    
checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
         this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
        this.dataValidSw = false;
    }
  }

calculateLoanAmts(){
    //  term of loan 30 years
    this.loanAmt = +this.totalAmt - +this.downPayment;
    this.monthlyPayAmt = monthlyLoanAmt(this.loanAmt, this.loanIntRate, 30);
    let PMIAnnual = (this.loanAmt * this.PMIRate/100).toFixed(2);
    this.PMIMonthly = (PMIAnnual / 12).toFixed(2);
    this.PMINbrMonths = +calculatePMITerm(this.monthlyPayAmt, this.loanIntRate, 
        this.loanAmt, 200);
    this.PMINbrYears = (this.PMINbrMonths / 12).toFixed(1);
}
   
render() {
                      
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Loan and PMI Calculator
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
            <div>
                <div className= "text-left">
                   <label className="font-weight-bold" htmlFor="totalAmt">
                        Total Amount:&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="totalAmt" name="totalAmt"></input> 
                   <span className={ this.errArray[0].textType}>
                          &nbsp;{ this.errArray[0].message}</span>
                </div>
                <div className="text-left">
                   <label className="font-weight-bold" htmlFor="downPayment">
                        Down Payment:&nbsp;&nbsp;</label>
                    <input type="text" className="col-med" id="downPayment" name="downPayment"></input> 
                    <span className={ this.errArray[1].textType}>
                          &nbsp;{ this.errArray[1].message}</span>
                </div>
                <div className="text-left">
                   <label className="font-weight-bold" htmlFor="loanIntRate">
                        Loan Interest Pct (enter with decimal):&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="loanIntRate" name="loanIntRate"></input> 
                   <span className={ this.errArray[2].textType}>
                          &nbsp;{ this.errArray[2].message}</span>
                </div>
                <div className="text-left">
                   <label className="font-weight-bold" htmlFor="PMIRate">
                        PMI Rate (enter with decimal):&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="PMIRate" name="PMIRate"></input> 
                   <span className={ this.errArray[3].textType}>
                          &nbsp;{ this.errArray[3].message}</span>
                </div>
            </div>
            {this.state.parametersEntered ?
              <div className="mt-4">
                    <div className="font-weight-bold">
                        Total Loan Amt (total minus downPayment): &nbsp;                        <span>{this.loanAmt}</span> 
                    </div>
                    <div className="font-weight-bold">
                        Monthly Payment: &nbsp;&nbsp;&nbsp;&nbsp;
                        <span> {this.monthlyPayAmt}</span>
                    </div>
                    <div className="font-weight-bold">
                        PMI Amount: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span> {this.PMIMonthly}</span>
                    </div>
                    <div className="font-weight-bold">
                        Nbr Months PMI: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                        <span>{this.PMINbrMonths} - ({this.PMINbrYears} Years)</span>
                    </div>
                    
              </div>
            :
                null
            }
                         
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
                <Button variant="secondary"  onClick={() => this.processLoanParameters()}>
                   Calculate
                </Button>
                <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
                </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }



}

export default LoanAndPMICalculatorModal;