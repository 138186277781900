import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import replaceTextInString from '../../objects/CommonUse/replace_text_in_string';
import CreditCardSetupIntro from './text/credit_card_setup_intro_text';
import ConfigFile from '../../datasets/config_file';
import CreditCardPaymentPolicy from './datasets/credit_card_payment_policy';
import CreditCardParameters from './datasets/credit_card_parameters';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {findDataObject} from '../../objects/CommonUse/find_data_object';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import { rollForwardDate } from './objects/roll_forward_date_function';

class SetupCreditCardModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                headers: setHeadersForFetch(),  
                updateEnabled: false,   
                step: "display",
                displayInfo: true,
                defaultClass: "font-weight-bold ",
                creditCardOptionArr: [],
                creditCardStatus: "",
                openFriendModal: false,
                subProcess: "setupCreditCardModal",
                environment: Config.dataURL.currentEnv,
              }   
        this.initializeFields();
    }
    
    initializeFields(){
        this.prePopulateAmounts();
        this.introText();
        if (this.gameInfo.creditCardInfo.totalOutstandingBalance != undefined){
            this.state.displayInfo = false;
        }
        this.acceptDeclineClass = this.state.defaultClass;
        this.paymentPolicyClass = this.state.defaultClass;
        this.paymentAmountClass = this.state.defaultClass;
        switch (this.props.changeType){
            case "payment":
                this.title = "Make a Payment";
                this.state.step = "payment";
                break;
            case "policy": 
                this.title = "Change Credit Card Policy";
                this.state.step = "change";
                break;
            case "newLevel":
                this.title = "Accept/Decline New Credit Limit & Review Policy"
                this.state.step = "display";
                break; 
        }
    }

    prePopulateAmounts(){
        let unlockedFeature = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "creditCard");
        this.creditCardSetup = unlockedFeature.setup;
     
        this.newCardLevel = +unlockedFeature.level;
        if (this.gameInfo.creditCardInfo.gameID == undefined || 
            this.gameInfo.creditCardInfo.currentlyActive == false){
                this.creditCardAlreadySetup = false;
                this.currentOutstandingBalance = 0;
                this.paymentPolicy = "No payment policy has been set."; 
                this.state.creditCardStatus = "newCreditCard";
                this.currentCardLevel = this.newCardLevel; 
                let indx = this.newCardLevel - 1;   
                this.newCreditLimitAmt = CreditCardParameters[indx].maxCreditCardAmt;       
        }else{
                this.creditCardAlreadySetup = true;
                this.currentCardLevel = +this.gameInfo.creditCardInfo.cardLevel;
                if (this.currentCardLevel == this.newCardLevel){
                    this.state.creditCardStatus = "cardActive";
                }else{
                    let indx = this.newCardLevel - 1;
                    this.newCreditLimitAmt = CreditCardParameters[indx].maxCreditCardAmt; 
                    this.state.creditCardStatus = "newLevel";
                    this.newCreditLimitLit = "Congratulations!  You have been approved for a new credit limit. " +
                    "The new credit limit is " + doubleToStringMonthly(this.newCreditLimitAmt);
                }
        }
        this.populateCreditCardInfoFields();
        this.currentCreditLimitAmt = CreditCardParameters[this.currentCardLevel - 1].maxCreditCardAmt;
        this.setCreditCardOptions();
    }

    setCreditCardOptions(){
        this.state.creditCardOptionArr = [{value: "none", selection: "no option selected"}];
        var newObj;
        switch (this.state.creditCardStatus){
            case "newCreditCard":
                this.btnText = "Accept/Decline Credit Card";
                newObj = {value: "accept", selection: "Activate the Credit Card"};
                this.state.creditCardOptionArr.push(newObj);
                newObj = {value: "decline", selection: "Decline the Credit Card"};
                this.state.creditCardOptionArr.push(newObj);
                break;
            case "cardActive":
                this.btnText = "Change Credit Card Policy";
                newObj = {value: "retain", selection: "Retain the Credit Card"};
                this.state.creditCardOptionArr.push(newObj);
                newObj = {value: "decline", selection: "De-activate the Credit Card"};
                this.state.creditCardOptionArr.push(newObj);
                break;
            case "newLevel":
                this.btnText = "Accept/Decline Credit Card";
                newObj = {value: "accept", selection: "Activate New Level"};
                this.state.creditCardOptionArr.push(newObj);
                newObj = {value: "retain", selection: "Keep Current Card, Decline New Level"};
                this.state.creditCardOptionArr.push(newObj);
                newObj = {value: "decline", selection: "De-activate Credit Card, Decline New Level" }
                this.state.creditCardOptionArr.push(newObj);
                break;
        }
    }    

    populateCreditCardInfoFields(){
        if (this.gameInfo.creditCardInfo.totalOutstandingBalance == undefined){
            this.currentOutstandingBalance = 0;
        }else{
            this.currentOutstandingBalance = (+this.gameInfo.creditCardInfo.totalOutstandingBalance).toFixed(2);
        }
        this.initialAcceptDecline = this.creditCardSetup;
        this.paymentPolicyCode = this.gameInfo.creditCardInfo.paymentPolicyCode;
        let paymentPolicyObj = 
                CreditCardPaymentPolicy.find(elem => elem.paymentPolicyCode === this.paymentPolicyCode);
        if (paymentPolicyObj == undefined){
            this.paymentPolicy = "none";
        }else{
            this.paymentPolicy = paymentPolicyObj.desc;
        }
        let minPayPct = +ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
        this.minimumPaymentAmt = this.currentOutstandingBalance * minPayPct;
    }

    introText(){
        this.introText1 = CreditCardSetupIntro[0];
        let introText2 = CreditCardSetupIntro[1];
        this.creditCardInterest = (ConfigFile.unlockedFeatures.creditCardInterest * 100) + "%";
        let replaceObj = {amount: this.currentCreditLimitAmt, number: this.creditCardInterest};
        this.introText2 = replaceTextInString(introText2, replaceObj);
    }
        
    displayFriendModal(){
        this.state.openFriendModal = true;
        this.askAFriendInfo = {topic: "setupCreditCard"}
    }

    closeFriendModal(){
        this.state.openFriendModal = false;
    }

    collapseInfoDisplay(){
        this.state.displayInfo = false;
    }

    expandInfoDisplay(){
        this.state.displayInfo = true;
    }

    changeCreditCardPolicy(){
        this.state.step = "change";
        this.title = "Setup Or Change Credit Card Policy";
        this.state.displayInfo = false;
    }

    goBackToPaymentOption(){
         this.setState({step: "payment"})
         this.title = "Make a Payment";
         this.state.displayInfo = false;
    }

    editFormFields(){
        this.validEntries = true;
        this.getFormEntries();
        // edits done in reverse display order so messages will come out in correct order
       
        this.editPaymentPolicy();
        this.editCreditCardSetup();
        if (this.currentOutstandingBalance > 0){
            this.editPaymentAmount();
        }
        this.confirmChangeWasMade();
             
        if (this.validEntries == true){
            this.setNewPaymentPolicy();
            if (this.state.step === "payment"){
                this.setState({step: "paymentConfirm"});
            }else{
                this.setState({step: "confirm"});
            }
            this.outStandingMsg = "";
            if (this.acceptDecline == "retain"){
                this.newCreditLimitAmt = this.currentCreditLimitAmt;
            }else{
                if (this.acceptDecline == "decline"){
                    this.newCreditLimitAmt = 0;
                    this.newPaymentPolicy = "Credit Card will be declined/cancelled. " 
                    if (this.currentOutstandingBalance > 0 && 
                        this.paymentAmount < this.currentOutstandingBalance){
                       this.outStandingMsg = "The Outstanding Balance (" + 
                            doubleToStringMonthly(this.currentOutstandingBalance) 
                            + ") on Card Must be Paid or Late Fees will be charged."
                    }else{
                        this.outStandingMsg = "";
                    }
                }
            }
            this.state.updateEnabled = true;
            this.creditCardPolicyComplete = true;
            this.message = "Entries valid.  Click Confirm Change to save changes."
            this.msgClass = "";
        }else{
            this.msgClass = this.state.defaultClass + " text-danger";
        }
    }

    getFormEntries(){
        if (this.state.step != "payment" && this.state.step !== "paymentConfirm"){
            this.acceptDecline = document.getElementById("acceptDecline").value;
        }else{
            if (this.gameInfo.creditCardInfo.currentlyActive == true){
                this.acceptDecline = "retain"; 
            }else{
                this.acceptDecline = "decline";
            }
        }
        if (this.props.changeType == "policy" || "newLevel"){
            if (this.props.changeType == "newLevel"){
                this.cardLit = "Do you want to activate the New Credit Card?: "
            }else{
                this.cardLit = "Do you want to keep the Credit Card?: "
            }
            if (this.acceptDecline == "accept"){
                this.setupCreditCardSw = true;
                this.creditCardSetup = "Yes"
            }else{
                this.setupCreditCardSw = false;
                if (this.acceptDecline == "decline"){
                    this.creditCardSetup = "No, Cancel credit card."
                }else{
                    this.creditCardSetup = "Yes, Retain the Current Credit Card Limit";
                }
            }
        }
        if (this.state.step != "payment"){
            this.newPaymentPolicyCode = document.getElementById("paymentPolicy").value;
        }else{
            this.newPaymentPolicyCode = this.paymentPolicyCode;
        }
        if (this.currentOutstandingBalance > 0){
            this.paymentAmount = document.getElementById("payAmt").value;
        }else{
            this.paymentAmount = 0;
        }
    }

    editCreditCardSetup(){
        this.acceptDeclineClass = this.state.defaultClass;
        if (this.acceptDecline == "none"){
             this.validEntries = false;
             this.acceptDeclineClass = this.state.defaultClass + "text-danger";
             this.message = "Must accept or decline Credit Card offer."
        }
        if (this.acceptDecline == "retain" && this.creditCardAlreadySetup == false){
            this.validEntries = false;
            this.acceptDeclineClass = this.state.defaultClass + "text-danger";
            this.message = "Current credit card policy has not been setup - cannot select 'keep' option."
        }
    }
    
    editPaymentPolicy(){
        if (this.acceptDecline == "retain" && this.gameInfo.creditCardInfo.paymentPolicyCode != "" 
            && this.newPaymentPolicyCode == "none"){
                this.newPaymentPolicyCode = this.gameInfo.creditCardInfo.paymentPolicyCode;
        }
        if (this.acceptDecline != "decline" && this.newPaymentPolicyCode == "none"){
            this.validEntries = false;
            this.paymentPolicyClass = this.state.defaultClass + "text-danger";
            this.message = "Must select a payment policy."
        }
        if (this.acceptDecline == "decline" && this.newPaymentPolicyCode != "none"){
            this.validEntries = false;
            this.paymentPolicyClass = this.state.defaultClass + "text-danger";
            this.message = "Cannot select payment policy if declining the credit card."
        }
    }

    editPaymentAmount(){
        this.paymentAmountClass = this.state.defaultClass;
        this.paymentAmount = +this.paymentAmount;
        this.oneTimeTextType = "";
        if (this.paymentAmount > this.currentOutstandingBalance){
                this.validEntries = false;
                this.message = "Payment Amt cannot be greater than the current Outstanding Amt.";
                this.paymentAmountClass = this.state.defaultClass + " text-danger";
        }   
        if (this.state.step == "payment" && this.paymentAmount == 0){
                this.validEntries = false;
                this.message = "Payment amount of $0 is not valid when making a payment.";
                this.paymentAmountClass = this.state.defaultClass + " text-danger";
        }     
    }

    confirmChangeWasMade(){
        if (+this.paymentAmount == 0 && this.newPaymentPolicyCode == this.gameInfo.creditCardInfo.paymentPolicyCode &&
            this.acceptDecline == "retain"){
                this.validEntries = false;
                this.message = "No Change has been made to the Credit Card Policy."
        }
    }

    setNewPaymentPolicy(){
        let paymentPolicyObj = 
            CreditCardPaymentPolicy.find(elem => elem.paymentPolicyCode === this.newPaymentPolicyCode);
        if (paymentPolicyObj != undefined){
            this.newPaymentPolicy = paymentPolicyObj.desc;
        }
    }
  
    render() {
        this.creditCardOptions = this.state.creditCardOptionArr.map((optionsList,index) =>
            <DropDownOptionCard options={optionsList} key={index} />
        );
        this.gutters = setModalSize(this.props.screenSize);
                        
    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
            dialogClassName={this.gutters} animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">{this.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span>
                 {this.state.displayInfo ?
                    <span>
                        <div className="mx-4">
                            {this.introText1}
                        </div>
                        <div className="mx-4 mt-2">
                            {this.introText2}
                        </div>
                    </span>
                :
                    <div>Credit cards give you more flexibility in managing your money.</div>
                }
                {this.state.displayInfo ?
                    <div className="p-0 text-right">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.collapseInfoDisplay()}>
                            Collapse 
                        </Button>
                    </div>
                :
                    <div className="p-0 text-right">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.expandInfoDisplay()}>
                              Learn More
                        </Button>
                    </div>
                }    
                <span className="row-100">
                    <h5>Current Credit Card Information</h5>
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-md-4">Statement Date:</th>
                                <td className="col-md-3 text-right">    
                                    {reformatDateToDisplay(this.gameInfo.gameData.currentGameDate)}</td>
                                <th className="col-md-6">Outstanding Credit Card Amt:</th>
                                <td className="col-md-3 text-right">
                                    {doubleToStringMonthly(this.currentOutstandingBalance)}</td>
                            </tr>
                            <tr>
                                <th className="col-md-4">Current Cash Amount:</th>
                                <td className="col-md-3 text-right">
                                    {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}</td>
                                <th className="col-md-6">Mininum Payment Amount:</th>
                                <td className="col-md-3 text-right">
                                    {doubleToStringMonthly(this.minimumPaymentAmt)}</td> 
                            </tr>
                            <tr>
                                <th className="col-md-4">Credit Limit:</th>
                                <td className="col-md-3 text-right">
                                    {doubleToStringMonthly(this.currentCreditLimitAmt)}</td>
                                <th className="col-md-6">Fee Charged on Unpaid Amt:</th>
                                <td className="col-md-3 text-right">
                                    {this.creditCardInterest}</td> 
                            </tr>

                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <th className="col-md-4">Current Payment Policy:</th>
                                <td className="col-md-8">{this.paymentPolicy}</td>
                            </tr>
                        </tbody>
                    </table>
                    {this.state.step != "confirm" ?
                        <h6 className="mt-2 text-success">{this.newCreditLimitLit}</h6>
                    :
                        null
                    }
                </span>
                              
                {this.state.step == "change"  || this.state.step == "payment" ?
                    <span>
                        {this.currentOutstandingBalance > 0 ?
                            <div className="ml-4 mt-4">
                                <label className={this.paymentAmountClass} htmlFor="payAmt">
                                    Amount of Balance to Pay:&nbsp;&nbsp;</label>
                                <input type="text" className="col-med" id="payAmt"
                                     name="payAmt"></input> 
                            </div>
                        :
                            null
                        }
                        {this.props.changeType == "policy" || this.props.changeType == "newLevel" ?
                        <span>
                            <div className="font-weight-bold mt-2 text-center">
                                ------------------------------------- Credit Card Policy ---------------------------------------
                            </div>   
                            <div className="mt-2 mx-4">
                                <label className={this.acceptDeclineClass} htmlFor="acceptDecline">
                                    Do you want to accept the Credit Card? :&nbsp;&nbsp;</label>
                                <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                    id="acceptDecline">
                                   {this.creditCardOptions}
                                </select>
                            </div> 
                            <div className="mt-4 mx-4">
                                <label className={this.paymentPolicyClass} htmlFor="paymentPolicy">
                                    Select the preferred payment policy:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                    id="paymentPolicy">
                                    <option value="none">No payment policy selected</option>
                                    <option value="autoPayFull">Automatically pay the full balance each month</option>
                                    <option value="autoPayMin">Automatically pay the minimum balance each month</option>
                                    <option value="manualPay">Make manual payments on the card</option>
                                </select>
                            </div>                     
                        </span>
                        :
                            null
                        }
                    </span>
                :
                    null
                }

                {this.state.step == "confirm"  || this.state.step === "paymentConfirm" ?
                    <span className="ml-4 mt-4">
                        {this.currentOutstandingBalance > 0 ?
                            <div className="row ml-2">
                                <div className={this.paymentAmountClass}>
                                    Amount of Balance to Pay:</div>
                                <div className="col-sm-2">{doubleToStringMonthly(this.paymentAmount)}</div>
                            </div>
                        :
                            null
                        }
                        <span>
                            {this.props.changeType == "policy"  || this.props.changeType == "newLevel" ?
                            <span>
                                <div className="font-weight-bold mt-2 text-center">
                                ------------------------------------- Credit Card Policy ---------------------------------------
                                </div>   
                       
                                <div className="ml-4 mt-2"><b>{this.cardLit}</b> 
                                    {this.creditCardSetup}
                                </div>
                                <div className="ml-4 mt-2"><b>New Credit Limit:</b> &nbsp;
                                    {doubleToStringMonthly(this.newCreditLimitAmt)}</div>
                                <div className="ml-4 mt-2"><b>Credit Card Policy Selected:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {this.newPaymentPolicy}
                                </div>
                                <div className="ml-4 mt-2 font-weight-bold text-warning">
                                    {this.outStandingMsg}
                                </div>
                            </span>
                            :
                                null
                            }
                        </span>
                    </span>
                :
                    null
                }
                         
            {this.state.openFriendModal ?
                <FriendDisplayModal  show={this.state.openFriendModal} 
                    title={this.state.newCarDesc}
                    gameProcess="GameViews"
                    askAFriendInfo={this.askAFriendInfo}
                    friendData={this.state.emptyArr}
                    onClose={() => this.closeFriendModal()} 
                    gameInfo = {() => this.props.gameInfo()}
                    />
            :
                null
            }
         
            </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div className={this.msgClass}>{this.message}</div>
            {this.state.step == "display" ?
                <span>
                    <Button variant="secondary"  onClick={() => this.changeCreditCardPolicy()} >
                        {this.btnText}
                    </Button>
                </span>
            :
                null
            }
            {this.state.step == "payment" ?
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.editFormFields()} >
                        Submit Change
                    </Button> 
                </span>
            :
                null
            }
            {this.state.step == "change" ?
                <span>
                    <Button className="mr-2" variant="info"  onClick={() => this.displayFriendModal()} >
                        Ask a Friend
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.editFormFields()} >
                        Submit Change
                    </Button> 
                </span>
            :
                null
            }
            {this.state.step == "confirm" ?
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.saveCreditCardPolicy()} >
                        Confirm Change
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.changeCreditCardPolicy()} >
                        Change Policy
                    </Button>
                </span>
            :
                null
            }
             {this.state.step == "paymentConfirm" ?
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.saveCreditCardPolicy()} >
                        Confirm Change
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.goBackToPaymentOption()} >
                        Change Pay Amt
                    </Button>
                </span>
            :
                null
            }
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                     Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }
    
saveCreditCardPolicy(){
    this.updateUnlockedFeatures();
    this.updateCreditCardInfoData();
    this.props.checkUnlockedFeatures();
    this.props.onClose("complete");
}

updateUnlockedFeatures(){  
    this.gameInfo.gameData.currentCash = (+this.gameInfo.gameData.currentCash - this.paymentAmount).toFixed(2);
    let index = this.gameInfo.gameData.unlockedFeatures.findIndex( element => {
        if (element.featureName === 'creditCard') {
            return true;
        }
    });
    let unlockedFeaturesObj = this.gameInfo.gameData.unlockedFeatures[index];
    let activeInactive = "inactive";
    if (this.acceptDecline == "accept" || this.acceptDecline == "retain"){
          activeInactive = "active";
    }
    unlockedFeaturesObj.setup = activeInactive;

    let iconTable = this.gameInfo.gameData.iconTable;
    let startIdx = iconTable.length - 1;
    for (let i=startIdx; i>=0; i--){
        if (iconTable[i].iconName == "creditCardUnlocked" ||
            iconTable[i].iconName == "creditCardNewLevel" ||
            iconTable[i].iconName == "creditLimitExceeded" ||
            iconTable[i].iconName == "creditCardReminder"){
            iconTable.splice(i,1);
            break;
        }
    }
 
    this.gameInfo.gameData.unlockedFeatures[index] = unlockedFeaturesObj;
    this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
    this.bodyObj = {
        "currentCash": this.gameInfo.gameData.currentCash,
        "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
        "iconTable": iconTable
    };
    const funcName = "updateUnlockedFeatures";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'PUT', headers: this.state.headers,
       body: JSON.stringify(this.bodyObj)})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                 // "update of unlocked features was successful";
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: this.bodyObj };
                this.props.processError(errorObj);
            }
        });
        }
    });
}

updateCreditCardInfoData(){
    if (this.gameInfo.creditCardInfo.gameID == undefined){
        this.newCreditCardEntry = true;
        var conditionIndx = this.gameInfo.gameData.conditionsTable.findIndex(p => p.condName == "credit-card-unlocked");
        if (conditionIndx != -1){
            let newConditionsTable = []; 
            //  tried to use splice (instead of for loop) but it was not working.  It was returning only the one
            //  that I wanted to remove (format was .splice(conditionIndx, 1)
            for (let i=0; i<this.gameInfo.gameData.conditionsTable.length; i++){
                if (i != conditionIndx){
                    let conditionObj = this.gameInfo.gameData.conditionsTable[i];
                    newConditionsTable.push(conditionObj);
                }
            }        
            this.gameInfo.gameData.conditionsTable = newConditionsTable;
            this.updateConditionsTable();
        }
    }else{
        this.newCreditCardEntry = false;
    }
    this.determineCreditCardInfo();
    this.setBodyObj();
    this.updateCreditCardInfo();
}

updateConditionsTable() {
    // remove credit-card-unlocked condition 
    this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
    const funcName = "updateConditions";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    // Update conditions table in Mongo
    const bodyObj = { "conditionsTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.conditionsTable)) };
    fetch(uri,
        {
            method: 'PUT', headers: this.state.headers,
            body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
              response.json().then(data => {
              const output = JSON.parse(data);
              const statusCode = output.statusCode;
              if (output.statusCode == 200){ 
                  // updateConditions Table was successful;
              }else{
                  const errorObj={function: funcName, subProcess: this.state.subProcess, 
                      status: statusCode, message: output.body.message, errorObject: bodyObj};
                  this.props.processError(errorObj);
              }
          });
        }
    });

    return;
}

determineCreditCardInfo(){
    this.gameID = this.gameInfo.gameData._id;
    if (this.newCreditCardEntry == true){
        let creditCardObj = findDataObject("creditCardInfo").creditInfo;
        creditCardObj.cardLevel = this.newCardLevel;
        let periodCreditObj = findDataObject("periodCreditCharges").creditInfo;
        periodCreditObj.bgnDate = this.gameInfo.gameData.currentGameDate;
        periodCreditObj.endDate = periodCreditObj.bgnDate;
        if (this.gameInfo.timeWizardInfo.gameID !== undefined &&
            this.gameInfo.timeWizardInfo.status !== "inactive"){
                const nbrMonths = +this.gameInfo.timeWizardInfo.monthsPerTurn - 1;
                periodCreditObj.endDate = 
                    rollForwardDate(this.gameInfo.gameData.currentGameDate, nbrMonths);
        }
        creditCardObj.periodCreditCharges.push(periodCreditObj);
        this.gameInfo.creditCardInfo = creditCardObj;
    }
    let currentlyActive = false;
    if (this.acceptDecline == "accept" || this.acceptDecline == "retain"){
        currentlyActive = true;
        if (this.gameInfo.creditCardInfo.currentlyActive == false){
            this.startDate = this.gameInfo.gameData.currentGameDate;
        }else{
            this.startDate = this.gameInfo.creditCardInfo.creditCardStartDate;
        }
    }
    if (this.paymentAmount > 0){
        const indx = this.gameInfo.creditCardInfo.periodCreditCharges.length - 1;
        this.gameInfo.creditCardInfo.periodCreditCharges[indx].periodAmtPaid = 
            +this.paymentAmount + +this.gameInfo.creditCardInfo.periodCreditCharges[indx].periodAmtPaid;
        this.gameInfo.creditCardInfo.totalOutstandingBalance = 
            (+this.gameInfo.creditCardInfo.totalOutstandingBalance - this.paymentAmount).toFixed(2);
    }
    this.gameInfo.creditCardInfo.paymentPolicyCode = this.newPaymentPolicyCode;
    this.gameInfo.creditCardInfo.currentlyActive = currentlyActive;
    if (this.acceptDecline == "accept"){
        this.gameInfo.creditCardInfo.creditCardStartDate = this.startDate;
        this.gameInfo.creditCardInfo.cardLevel = this.newCardLevel;
        this.gameInfo.creditCardInfo.creditMaxAmount = this.newCreditLimitAmt;
    }
  
}

setBodyObj(){
    let dateTime = getCurrentDateTime();
    var createDate;
    if (this.gameInfo.creditCardInfo.dateAdded == undefined || this.gameInfo.creditCardInfo.dateAdded == ""){
          createDate = dateTime.date
    }else{
          createDate = this.gameInfo.creditCardInfo.dateAdded;
    }
    this.creditCardObj = {
        "gameID": this.gameID,
        "paymentPolicyCode": this.gameInfo.creditCardInfo.paymentPolicyCode,
        "currentlyActive": this.gameInfo.creditCardInfo.currentlyActive,
        "creditCardStartDate": this.gameInfo.creditCardInfo.creditCardStartDate,
        "creditMaxAmount": this.gameInfo.creditCardInfo.creditMaxAmount,
        "totalOutstandingBalance": this.gameInfo.creditCardInfo.totalOutstandingBalance,
        "totalIntBilled": this.gameInfo.creditCardInfo.totalIntBilled,
        "cardLevel": this.gameInfo.creditCardInfo.cardLevel,
        "lateFeeCount": this.gameInfo.creditCardInfo.lateFeeCount,
        "periodCreditCharges": this.gameInfo.creditCardInfo.periodCreditCharges,
        "dateAdded": createDate,
        "dateUpdated": dateTime.date,
    };
    this.gameInfo.creditCardInfo = this.creditCardObj;
    this.props.setGameInfo(this.gameInfo.creditCardInfo, "creditCardInfo");
}

updateCreditCardInfo(){
    const funcName = "updateCreditCardInfo";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.creditCardObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                     // "update of credit card Info was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.creditCardObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}
    
}

export default SetupCreditCardModal;