const easyGameChoiceData = 
{
    "user": "student3",
    "jobInfo": {
      "careerID": "61310cff2afc7f761668ac09",
      "healthInsInd": "true",
      "jobRank": "10",
      "nbrYearsCollege": "4",
      "nbrSkillSurvey": "1",
      "jobStartGameMonth": 360
    },
    "homeInfo": {
      "homeID": "6132415297d929c9a8cf870c",
      "homeType": "apartment",
      "roommates": "1",
      "bedrooms": "2"
    },
    "furnitureID": "6132a41a97d929c9a8cf8717",
    "carInfo": {
      "carID": "6132c17c97d929c9a8cf8721",
      "carAge": 48,
      "carYear": "2020",
      "carType": "100",
      "carGameDate": "2024-12",
    },
    "startingCash": "540.00",
    "currentCash": "540.00",
    "currentMoodPoints": "14000",
    "startGameDate": "2024-12",
    "currentGameDate": "2024-12",
    "assetTable": [
      {
        "assetItem": "Job Salary",
        "assetDesc": "Electrical Engineer --> Annual Salary: $63,999.96",
        "assetAmt": "5333.33",
        "assetStartDate": "2024-12",
        "assetNbrMonths": "-1"
      }
    ],
    "expenseTable": [
      {
        "expItem": "Payroll taxes",
        "expDesc": "Federal, FICA (Social Security)",
        "expAmount": "904.33",
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Apartment Rent",
        "expDesc": "Brookside-2 bedrooms, 1 roommate",
        "expAmount": 550,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      },
      {
        "expItem": "Health Insurance",
        "expDesc": "Health Insurance 70% Paid by Employer",
        "expAmount": "111.00",
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Automobile Insurance",
        "expDesc": "Insurance for car repair or replacement",
        "expAmount": 75,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Renter's Insurance",
        "expDesc": "Insurance for furniture and other personal items",
        "expAmount": 30,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": false
      },
      {
        "expItem": "Transportation",
        "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
        "expAmount": "115.56",
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      },
      {
        "expItem": "Misc Expenses",
        "expDesc": "Food, Clothes, Personal Items, etc.",
        "expAmount": 380,
        "expStartDate": "2024-12",
        "expNbrMonths": "-1",
        "chargeCreditCard": true
      }
    ],
    "loanTable": [
      {
        "loanType": "Car Loan",
        "loanDesc": "Small Car - 3 to 5 Years Old",
        "loanAmt": "280.57",
        "loanStartDate": "2024-12",
        "loanNbrMonths": 72,
        "loanTotAmt": 15145,
        "loanIntRate": 0.1,
        "loanTerm": 6
      },
      {
        "loanType": "College Loan",
        "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
        "loanAmt": "474.65",
        "loanStartDate": "2024-12",
        "loanNbrMonths": 120,
        "loanTotAmt": "48000.00",
        "loanIntRate": 0.035,
        "loanTerm": 10
      }
    ],
    "skillSurvey": [
      "4",
      "5",
      "4",
      "5",
      "4",
      "5",
      "4",
      "5",
      "4",
      "5",
      "4",
      "5"
    ],
    "conditionsTable": [
      {
        "condName": "roommate",
        "conditionClass": "home"
      },
      {
        "condName": "homeType-apartment",
        "conditionClass": "home"
      },
      {
        "condName": "cheap-furniture",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-dining-chairs",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-bed",
        "conditionClass": "furniture"
      },
      {
        "condName": "cheap-lounge-chair",
        "conditionClass": "furniture"
      },
      {
        "condName": "electrical-engineer",
        "conditionClass": "career"
      },
      {
        "condName": "no-december-holiday-selected",
        "conditionClass": "game"
      },
      {
        "condName": "no-friend-purchases",
        "conditionClass": "game"
      }
    ],
    "gameNbrMonths": "360",
    "nbrMonthsNegative": "0",
    "nbrMonthsMoodNegative": "0",
    "unlockedFeatures": [],
    "friendList": [
      {
        "name": "Charlie",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Cameron",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Jennifer",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      },
      {
        "name": "Cindy",
        "introDate": "",
        "dialogIndx": "firstTime",
        "rltnshpScore": "0",
        "totalLoanAmt": "0"
      }
    ],
    "spendingHistory": {
      "currentMonthSpendAmt": 0,
      "current10YearSpendAmt": 2460,
      "current10YearMonths": 1,
      "previous10YearSpendAmt": 0,
      "previous10YearMonths": 0
    },
    "moodPointHistory": {
      "currentMonthMoodPoints": 0,
      "totalMoodPoints": 0,
      "moodPointMonths": 1
    },
    "iconTable": [
      {
        "iconName": "gameBasicHelp",
        "iconCategory": "information",
        "className": "small_sign_thumbnail_img information-sign-position",
        "imageSrc": "gameHelp.png",
        "validInd": true,
        "firstTime": true,
        "additionalInfo": ""
      }
    ],
    "badgesEarned": [],
    "prevGameMonths": "41",
    "prevNbrGames": "2",
    "gameTemplateName": "easy",
    "hand": [],
}
      
export default easyGameChoiceData;
