import ConfigFile from "../../../datasets/config_file";
import { calculatePMITerm } from "../objects/calculate_PMI_term";

export const calculateMortgageInsurance = (loanIntRate, baseLoanAmt, mortgageAmt, gameDate, 
        homeFullCost, homeName, loanTable) => {
      //  calculate PMI
      // pmi annual = loan amount * interest rate
      // pmi monthly = annual / 12
      // when your loan balance is less than 20% of home value, then pmi goes away
      let PMIRate = ConfigFile.homeItems.PMIRate;
      let PMIAnnual = (homeFullCost * PMIRate/100).toFixed(2);
      let PMIMonthly = +PMIAnnual / 12;
      // % paid off * total Loan amt / payAmt= nbrMonths
      const PMINbrMonths = +calculatePMITerm(mortgageAmt, loanIntRate, 
          baseLoanAmt, 200);
      let totalPMIAmt = (PMIMonthly * PMINbrMonths).toFixed(2);
      const PMIYears = (PMINbrMonths / 12).toFixed(1);
      let PMILoanObj = {loanType: "Mortgage Insurance (PMI)", 
          loanDesc: "Mortgage Insurance for " + homeName,
          loanAmt: PMIMonthly, loanStartDate: gameDate,
          loanNbrMonths: PMINbrMonths, loanTotAmt: totalPMIAmt, 
          loanIntRate: loanIntRate, loanTerm: PMIYears}
      loanTable.push(PMILoanObj);
      const returnObj = {PMIMonthly: PMIMonthly, loanTable: loanTable};
      return returnObj;
}

export default calculateMortgageInsurance;