import {Component} from 'react';
import TableDisplayLineCard from './table_display_line_card';

class TableDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }
    
    
    render() {
      this.tableLineOut = this.props.outInfo.tableLine.map((tableInfo,key) =>
          <TableDisplayLineCard key={key} infoType="label-number" outInfo={tableInfo}  />
          );
                     
    return <>
        <tr>
            {this.tableLineOut}
        </tr>
           
    </>
    }
}

export default TableDisplayCard;