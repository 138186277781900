import EventOnLitList from "../../admin/datasets/event_onLit_dataset"; 
export const checkIfCreditCard = (chargeAmt, creditCardInfo, objectName, objectType) => {
   
      let useCreditCard = false;
      const onLitObj = EventOnLitList.find(elem => elem.value === objectName );
      if (onLitObj !== undefined && onLitObj.creditCard === true){
          if (objectType === "Expense"){
              chargeAmt = +chargeAmt * -1;
          }
          if (creditCardInfo.currentlyActive === true &&
              +chargeAmt < -5){
                if (+creditCardInfo.totalOutstandingBalance < 
                    (+creditCardInfo.creditMaxAmount + +chargeAmt)){
                        useCreditCard = true;
                }else{
                    useCreditCard = false;
                }
          }
      }
              
    return useCreditCard;
   
}
