import { applyToCreditCard } from "./apply_to_credit_card";
import { calcNbrMonthsBtwnDates } from "../../../objects/CommonUse/nbr_months_btwn_dates_calc";

export const applyMonthlyMiscExpToCreditCard = (expenseTable, creditCardInfo, nbrMonths) => {
           
     let nbrPeriods = creditCardInfo.periodCreditCharges.length;
     // calculate average credit card unplanned amounts accrued for all prior periods...  this will be used
     // to determine the amount accrued for a month when period is more than 1 month long.
     let totalUnplannedAmt = 0;
     let totalMonthlyAmt = 0;
     //  if only 1 month in the period then do not need to calculate or use an estimated unplanned amount.
     if (nbrMonths > 1){ 
          for (let i=0; i<nbrPeriods; i++){
               const periodCharges = creditCardInfo.periodCreditCharges[i];
               const nbrMonths = +calcNbrMonthsBtwnDates(periodCharges.bgnDate, periodCharges.endDate) + 1;
               for (let j=0; j<periodCharges.creditChargesArr.length; j++){
                    const creditCharge = periodCharges.creditChargesArr[j]; 
                    if (creditCharge.chgType === "unplanned"){
                         totalUnplannedAmt = totalUnplannedAmt + +creditCharge.amount;
                    }
               }
               const monthlyUnplannedAmt = totalUnplannedAmt / nbrMonths;
                   totalMonthlyAmt = totalMonthlyAmt + monthlyUnplannedAmt;
          }
          if (totalMonthlyAmt > 0){
               // the estUnplannedAmt is an estimated amount per month.  Actuals have already been posted
               // for current month, so this 
               const estUnplannedAmt = (totalMonthlyAmt / nbrPeriods * nbrMonths).toFixed(2);
               creditCardInfo = applyToCreditCard(creditCardInfo, estUnplannedAmt,  
                    "Non-budgeted (unplanned) Expenses", "Misc Expenses", "estimatedUnplanned");
          }
     }

    for (let i=0; i<expenseTable.length; i++){
          if (expenseTable[i].chargeCreditCard !== undefined &&
              expenseTable[i].chargeCreditCard === true ){
                let expAmount = expenseTable[i].expAmount * nbrMonths;
                creditCardInfo = applyToCreditCard(creditCardInfo, expAmount, expenseTable[i].expDesc,
                    expenseTable[i].expItem, "planned");
          }
     }  
     return creditCardInfo;
   
}

