const BadgesInfoDataset =[
  {badgeCode: "MMNT1", badgeName: "Money Management #1", 
      title: "Good Money Management Practices",
      nbrColumns: 2, awardType: "cash", awardAmount: 500, moodPoints: 200,
      pictureName: "moneyManagement.png", badgeEarned: false},
  {badgeCode: "MMNT2", badgeName: "Money Management #2",
      title: "Reasons to Create Monthly Budget",
      nbrColumns: 2, awardType: "cash", awardAmount: 750, moodPoints: 250,
      pictureName: "moneyManagement.png", badgeEarned: false},
  {badgeCode: "PRIOR1", badgeName: "Priorities #1", 
      title: "Prioritize Purchases",
      nbrColumns: 1, awardType: "salary", awardAmount: 75, moodPoints: 300,
      pictureName: "priorities.png", badgeEarned: false},
  {badgeCode: "PRIOR2", badgeName: "Priorities #2",
      title: "Prioritize Within Budget",
      nbrColumns: 2, awardType: "salary", awardAmount: 100, moodPoints: 350,
      pictureName: "priorities.png", badgeEarned: false}, 
  {badgeCode: "CHCAR1", badgeName: "Choosing A Career #1",
      title: "Matching People to Jobs Based on Interests/Skills", 
      nbrColumns: 5, awardType: "cash", awardAmount: 750, moodPoints: 400,
      pictureName: "chooseCareer.png", badgeEarned: false}, 
  {badgeCode: "CHCAR2", badgeName: "Choosing A Career #2",
      title: "Factors to Consider When Choosing a Career",
      nbrColumns: 1, awardType: "cash", awardAmount: 1000, moodPoints: 500,
      pictureName: "chooseCareer.png", badgeEarned: false},
  {badgeCode: "LBYM1", badgeName: "Living Below Your Means #1",
      title:  "Reasons to Spend Less Than you Earn",
      nbrColumns: 2, awardType: "cash", awardAmount: 1250, moodPoints: 600,
      pictureName: "livingBelowMeans.png", badgeEarned: false},
  {badgeCode: "LBYM2", badgeName: "Living Below Your Means #2",
      title:  "Live Below Your Means - Things to Avoid and Good Ideas",
      nbrColumns: 3, awardType: "cash", awardAmount: 1500, moodPoints: 700,
      pictureName: "livingBelowMeans.png", badgeEarned: false},  
  {badgeCode: "Saving1", badgeName: "Saving Money #1",
      title: "Understanding Savings Over Time",
      nbrColumns: 5, awardType: "salary", awardAmount: 150, moodPoints: 800,
      pictureName: "savingMoney.png", badgeEarned: false},
  {badgeCode: "Saving2", badgeName: "Saving Money #2",
      title: "Key Things to Know About Saving Money",
      nbrColumns: 3, awardType: "salary", awardAmount: 200, moodPoints: 900,
      pictureName: "savingMoney.png", badgeEarned: false},      
  {badgeCode: "LLOAN1", badgeName: "Learning About Loans #1",
      title: "Understanding the Key Factors of a Loan",
      nbrColumns: 5, awardType: "salary", awardAmount: 225, moodPoints: 1000,
      pictureName: "learningAboutLoans.png", badgeEarned: false},
  {badgeCode: "LLOAN2", badgeName: "Learning About Loans #2", 
      title: "Circumstances that Impact Loan Approval",
      nbrColumns: 2, awardType: "salary", awardAmount: 250, moodPoints: 1100,
      pictureName: "learningAboutLoans.png", badgeEarned: false}, 
   
];

export default BadgesInfoDataset;