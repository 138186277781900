import unlockedFeaturesDataset from "../datasets/unlocked_features_dataset";

export const reconcileUnlockedFeatures = (gameMonths, cashAmt, monthlyIncome, unlockedFeatures, activeFeatureArr,
    totalNbrGameMonths) => {
    
    let deleteFeatureArr = [];
    let unlockedFeaturesList = JSON.parse(JSON.stringify(unlockedFeaturesDataset));
    unlockedFeaturesList = (unlockedFeaturesList).sort((a, b) => 
        (a.featureName + a.level > b.featureName + b.level) ? -1 : 1);
    let gameMonthsPlayed = +totalNbrGameMonths - +gameMonths;
    let changed = false;  
    for (let i=0; i<unlockedFeaturesList.length; i++){
        let featureName = unlockedFeaturesList[i].featureName; 
        let unlockedFeature = unlockedFeatures.find(elem => elem.featureName === featureName);
              
        if (gameMonthsPlayed >= unlockedFeaturesList[i].minNbrMonths){
            if(cashAmt >= unlockedFeaturesList[i].minCashAmt &&
                monthlyIncome >= unlockedFeaturesList[i].minMonthIncome){
                    //   this means the feature has been earned
                    if (unlockedFeature === undefined){
                        const newUnlockedFeature = {featureName: unlockedFeaturesList[i].featureName, 
                            setup: "none", level: unlockedFeaturesList[i].level}
                        changed = true;
                        unlockedFeatures.push(newUnlockedFeature);
                    }else{
                        if (unlockedFeaturesList[i].level > unlockedFeature.level){
                            changed = true;
                            // if the previous level of the feature has never been accepted, then change level number
                            //      but no new notification will happen
                            // if the previous level of the feature has been accepted, the level will change and it will 
                            //      setup will be set to "none" - this will force a notification but will not affect the
                            //      previous level's information.
                            unlockedFeature.level = unlockedFeaturesList[i].level;
                            unlockedFeature.setup = "none";
                        }
                    }
                }else{
                    //  if player is no longer eligible for this feature/level and the feature
                    //  has not already been activated, then downgrade level or remove the table entry
                    const activeFeatureObj = activeFeatureArr.find(elem => elem.featureName === featureName);
                    let activeFeatureLevel = 0;
                    if (activeFeatureObj !== undefined){
                        activeFeatureLevel = activeFeatureObj.level;
                    }  
                    if (unlockedFeature !== undefined &&
                        +activeFeatureLevel === unlockedFeature.level){
                         // no change is required
                         if (unlockedFeature.setup !== "active"){
                            unlockedFeature.setup = "active";
                            changed = true;
                         }
                    }
                    if (unlockedFeature !== undefined &&
                        unlockedFeaturesList[i].level === unlockedFeature.level &&
                        unlockedFeature.setup !== "active"){
                            changed = true;
                            if (unlockedFeature.level === 1){
                                //  this feature should be deleted from unlockedFeatures table
                                deleteFeatureArr.push(featureName);
                            }else{      
                                if (activeFeatureObj !== undefined && 
                                    activeFeatureObj.setup !== "active"){
                                        unlockedFeature.setup = "active";
                                }
                                unlockedFeature.level = +unlockedFeature.level - 1;
                            }
                    }
                        
                }
        }
    }
  
    if (deleteFeatureArr.length !== 0){
        const startIdx = unlockedFeatures.length - 1;
        for (let i=startIdx; i>=0; i--){
             const featureName = unlockedFeatures[i].featureName;
             if (deleteFeatureArr.includes(featureName)){
                 unlockedFeatures.splice(i,1);
             }
        }
 }
    
    let featureObj = {unlockedFeatures: unlockedFeatures, changed: changed};
    return featureObj;
   
}

export default reconcileUnlockedFeatures;
