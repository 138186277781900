const UnlockedFeatureText =[
    {feature: "funStuff", 
        text: "This feature allows you to add fun things to your game such as " +
              "a pet, horse, boat and other items."  },
    {feature: "savings",
        text: "Saving money is an important part of money management.  If you " +
               "activate this feature, you can save money each month and " +
               "the game will add money (interest) each month to increase " +
               "your savings amount...so you can watch your money grow!!"  },
    {feature: "creditCard",
        text: "Credit cards are almost essential in today's world.  If you " +
              "activate a credit card, then you can charge amounts paid in the " +
              "game to the credit card and pay off the card at the end of the month " +
              "or when you can. The credit card maximum amount will change with each " +
              "level."  },
    {feature: "timeWizard",
        text: "This feature allows you to control the time in the game. " +
              "You can decide how many months pass on each turn.  In addition, you can " +
              "determine how many years you will play before the game ends.  The " +
              "options that you have for each of these change with each level."   },
    {feature: "investments",
        text: "This feature is not yet active in the game." },
]

export default UnlockedFeatureText;    