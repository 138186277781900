import { addChangeSalaryAsset } from "./add_change_salary_asset";

export const getBadgesEarnedForNewGame = (badgesEarnedArr, assetArr, currentYYYYMM) => {
    let newBadgesEarnedArr = [];
    let totalSalaryIncrease = 0;
    let totalCashIncrease = 0;
    let oneTimeDesc = "Badges Earned - ";
    for (let i=0; i<badgesEarnedArr.length; i++){
        if (badgesEarnedArr[i].awardType !== undefined){
            if (badgesEarnedArr[i].awardType == "salary"){
                let processName = badgesEarnedArr[i].badgeName;
                let salaryIncrease = +badgesEarnedArr[i].awardAmount;
                let assetArrObj = addChangeSalaryAsset(assetArr, "BadgeBonus", currentYYYYMM,
                  salaryIncrease, processName); 
                assetArr = assetArrObj.assetTable;
                totalSalaryIncrease = +totalSalaryIncrease + +salaryIncrease;
                const salaryChangeObj = {processName: processName, assetType: "salary", 
                  amount: salaryIncrease};
                newBadgesEarnedArr.push(salaryChangeObj);
            }else{
                totalCashIncrease += +badgesEarnedArr[i].awardAmount; 
                let processName = badgesEarnedArr[i].badgeName;
                oneTimeDesc +=  processName + ", ";
            }
         }
    }
    const returnObj = {totalSalaryIncrease: totalSalaryIncrease, totalCashIncrease: totalCashIncrease, 
          oneTimeDesc: oneTimeDesc, assetArr: assetArr};
    return returnObj;
}
export default getBadgesEarnedForNewGame;