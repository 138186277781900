import { findRemoveTableItem } from "../../../objects/AssetsExpenses/find_remove_table_item";
import { createNewRentExpense } from "./create_new_rent_expense";
import { createNewLoanObj } from "./create_new_loan_obj";
import { createNewHomeInsObj } from "./create_new_home_ins_obj";
import { createTableItem } from "../../../objects/AssetsExpenses/create_table_item";
import { closeOutCurrentHomeLoan } from "./close_out_current_home_loan";
import { getCurrentRoommateRent } from "./get_current_roommate_rent";
import { createNewAssetItem } from "./create_new_asset_item";
import ConfigFile from "../../../datasets/config_file";

export const calculateNewMonthlyPaymentAmt = (changeType, startGameDate, currentCash, 
    currentHome, newHome, newExpenseTable, newLoanTable, newAssetTable) => {
      currentHome.MortgageAmt = 0;
      if (newHome.DownPaymentAmt === undefined){
            newHome.DownPaymentAmt = 0;
      }  
      newHome.DownPaymentPct = 0;
            
      if (changeType === "apartment-apartment"){
            let currentRentObj = findRemoveTableItem(newExpenseTable, "rent", true);
            if (currentRentObj.expAmount !== undefined){
                currentHome.FullRentAmt = +currentRentObj.expAmount * 
                        (+currentHome.Roommates + 1);
                currentHome.RoommateRentAmt = +currentHome.FullRentAmt - 
                         +currentRentObj.expAmount;
            }else{
                currentHome.FullRentAmt = 0;
            }
            newExpenseTable = createNewRentExpense(currentHome, newHome, newExpenseTable);
      }
       
      if (changeType === "apartment-house"){
            // leave following in place, currentRentObj is not being used but the findRemoveTable is required
            const currentRentObj = findRemoveTableItem(newExpenseTable, "rent", true);
            const currentInsObj = findRemoveTableItem(newExpenseTable, "rentersIns", true);
            currentHome.RentInsAmt = currentInsObj.expAmount;
            const loanObj = createNewLoanObj(newHome.DownPaymentAmt, currentHome.GameDate, newHome.MortgageAmt, 
                newHome.HomeFullCost, newHome.HomeName, newLoanTable);
            newHome.PMIMonthly = loanObj.PMIMonthly;
            newHome.MortgageAmt = loanObj.newMortgageAmt;
            newHome.DownPaymentPct = loanObj.downPaymentPct;
            const homeInsObj = createNewHomeInsObj(newHome.HomeFullCost, currentHome.GameDate, newExpenseTable);
            newHome.HomeInsAmt = homeInsObj.homeInsAmt;
            if (newHome.Roommates > 0){
                const assetObj = createNewAssetItem(newHome.Roommates, currentHome.GameDate, startGameDate,
                    newAssetTable);
                newHome.RoommateShareOfMortgage = assetObj.roommateShareOfMortgage;
            }
            if (newHome.HomeHOA > 0){
                let expHOAObj = createTableItem("homeHOA", "", currentHome.GameDate, newHome.HomeHOA);
                newExpenseTable.push(expHOAObj);
            }
      }

      if (changeType === "house-house"){
            const currentHomeLoanObj = closeOutCurrentHomeLoan(newLoanTable);
            currentHome.MortgageAmt = currentHomeLoanObj.mortgageAmt;
            currentHome.HomeFullCost = currentHomeLoanObj.homeFullCost;
            currentHome.PMIMonthly = currentHomeLoanObj.PMIMonthly;
            let currentInsObj = findRemoveTableItem(newExpenseTable, "homeIns", true);
            currentHome.HomeInsAmt = currentInsObj.expAmount;
            let currentHOAObj = findRemoveTableItem(newExpenseTable, "homeHOA", true);
            if (currentHOAObj.expAmt !== undefined){
                currentHome.HomeHOAAmt = currentHOAObj.expAmount;
            }else{
                currentHome.HomeHOAAmt = 0;
            }
          const loanObj = createNewLoanObj(newHome.DownPaymentAmt, currentHome.GameDate, newHome.MortgageAmt, 
            newHome.HomeFullCost, newHome.HomeName, newLoanTable);
          newHome.PMIMonthly = loanObj.PMIMonthly;
          newHome.MortgageAmt = loanObj.newMortgageAmt;
          newHome.DownPaymentPct = loanObj.downPaymentPct;
          const homeInsObj = createNewHomeInsObj(newHome.HomeFullCost, currentHome.GameDate, newExpenseTable);
          newHome.HomeInsAmt = homeInsObj.homeInsAmt;
        
            const currentRoommateObj = getCurrentRoommateRent(currentHome.Roommates,newAssetTable);
            currentHome.RoommateShareOfMortgage = currentRoommateObj.roommateShareOfMortgage;
            if (newHome.Roommates > 0){
                const assetObj = createNewAssetItem(newHome.Roommates, currentHome.GameDate, startGameDate,
                    newAssetTable);
                newHome.RoommateShareOfMortgage = assetObj.roommateShareOfMortgage;
            }
            if (newHome.HomeHOA > 0){
                let expHOAObj = createTableItem("homeHOA", "", currentHome.GameDate, newHome.HomeHOA);
                newExpenseTable.push(expHOAObj);
            }
      }

      if (changeType === "house-apartment"){
         const currentHomeLoanObj = closeOutCurrentHomeLoan(newLoanTable);
         currentHome.MortgageAmt = currentHomeLoanObj.mortgageAmt;
         currentHome.HomeFullCost = currentHomeLoanObj.homeFullCost;
         currentHome.PMIMonthly = currentHomeLoanObj.PMIMonthly;
         let currentInsObj = findRemoveTableItem(newExpenseTable, "homeIns", true);
         currentHome.HomeInsAmt = currentInsObj.expAmount;
         currentHome.FullRentAmt = "";
         const currentRoommateObj = getCurrentRoommateRent(currentHome.Roommates,newAssetTable);
         currentHome.RoommateShareOfMortgage = currentRoommateObj.roommateShareOfMortgage;
         newExpenseTable = createNewRentExpense(currentHome, newHome, newExpenseTable);
         newHome.RentInsAmt = ConfigFile.insurance.rentersInsAmt;
         let rentInsObj = createTableItem("rentersIns", "", currentHome.GameDate, 
            newHome.RentInsAmt, "", false);
         newExpenseTable.push(rentInsObj);
         currentHome.RentAmt = +currentHome.MortgageAmt - 
                +currentHome.RoommateShareOfMortgage + +currentHome.PMIMonthly + +currentHome.HomeInsAmt;
      }
      if (changeType === "roommate-change"){
         newHome.CashAmt = +currentCash;
         if (currentHome.HomeType === "apartment"){  
            let currentRentObj = findRemoveTableItem(newExpenseTable, "rent", true);
            newHome.RentAmt = +currentRentObj.expAmount *
                 (+currentHome.Roommates + 1);
            newExpenseTable = createNewRentExpense(currentHome, newHome, newExpenseTable);
         }else{
            const currentRoommateObj = getCurrentRoommateRent(currentHome.Roommates,newAssetTable);
            currentHome.RoommateShareOfMortgage = currentRoommateObj.roommateShareOfMortgage;
            let currentHOAObj = findRemoveTableItem(newExpenseTable, "homeHOA", false);
            if (currentHOAObj.expAmt !== undefined){
                  currentHome.HomeHOAAmt = currentHOAObj.expAmount;
            }else{
                  currentHome.HomeHOAAmt = 0;
            }
            let mortgageObj = findRemoveTableItem(newLoanTable, "mortgage", false);
            currentHome.MortgageAmt = mortgageObj.loanAmt;
            newHome.HomeHOAAmt = currentHome.HomeHOAAmt;
            newHome.MortgageAmt = mortgageObj.loanAmt;
            if (newHome.Roommates > 0){
                const assetObj = createNewAssetItem(newHome.Roommates, currentHome.GameDate, startGameDate,
                    newAssetTable);
                newHome.RoommateShareOfMortgage = assetObj.roommateShareOfMortgage;
            }else{
                newHome.RoommateShareOfMortgage = 0;
            }
         }
      }
}

export default calculateNewMonthlyPaymentAmt;