export const removeInvalidIcons = (iconTable) => {
 
    let startIdx = iconTable.length - 1;
    for (let i=startIdx; i>=0; i--){
          if (iconTable[i].validInd === false && iconTable[i].firstTime === false){
                iconTable.splice(i,1);
          }
    }
               
    return iconTable;
   
}





