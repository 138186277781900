const NotificationTextDataset =[
       {notificationType: "gameBasicHelp",  
              modalTitle: "Game Help - Phase 1",
              notificationImg: "",
              modalSize: "md",
              textArr: "GameElementHelp",
              text: [ "In this phase of the game, you will be dealt a hand of cards " +
                     "for each turn.  1 month will pass with each turn. " + 
                     "Click on a train card to display the next event.  " +
                     "The box in the upper right displays your current cash, mood score, " +
                     "and game date.  These will change to reflect the events " +
                     "in your game.  Here are some things you might also want to " +
                     "try or want to know about: " ] 
       },
       {notificationType: "gameBasicHelp2",  
              modalTitle: "Game Help - Phase 2",
              notificationImg: "",
              modalSize: "md",
              textArr: "GameElementHelp",
              text: [ "You are now in Phase 2 of the game.  In this phase " +
                     "3 months pass with each turn.  Click on the 'Spin' button (center of " +
                     "the wheel) to display the next event.  " +
                     "The box in the upper right displays your current cash, mood score, " +
                     "and game date.  These will change to reflect the events " +
                     "in your game.  Here are some things you might also want to " +
                     "try or want to know about: " ] 
       },
       {notificationType: "jobChange",  
              modalTitle: "Job Change",
              notificationImg: "",
              textArr: "",
              text:  ["Congratulations!!!  You have completed the college requirements. " + 
                     "You will now begin your new career as a(n) ||CareerName||.  " +
                     "You can view your new salary and other financial information " +
                     "by going to 'Monthly Status'. (Click on the link below or the 'How am I Doing' button that " +
                     "is on the right.)" ], 
              link: "monthly-status" 
       },
       {notificationType: "pendingJob",
              modalTitle: "Pending Job",
              textArr: "",
              notificationImg: "CollegeSign.png",
              firstTimeText: "There is no immediate change to your job or salary. " + 
                     "Once you complete the required college, then your job and salary will change.  ",
              text: [ "You have a new job pending the completion of additional training and/or "+
                     "education.  The new job is ||CareerName|| and it is scheduled to start " + 
                     "||StartDate||." ] 
       },
       {notificationType: "negativeNetIncome",
              modalTitle: "Monthly Amount is Negative",
              textArr: "WaysToIncreaseCash",
              notificationImg: "",
              text:  [ "You are currently losing money each month.  Here are some things you might want to do:" ]
       },
       {notificationType: "negativeCash",
              modalTitle: "Current Cash Amount is Negative",
              textArr: "WaysToIncreaseCash",
              notificationImg: "",
              text:    ["You currently have a negative cash amount.  After 3 turns of negative " +
                     "cash, your game will end.  Here are some things you might want to do:" ]
       },
       {notificationType: "negativeMoodPoints",
              modalTitle: "Current Mood Points are Negative",
              textArr: "",
              notificationImg: "",
              text:   [ "Your mood points are currently less than zero.  After 3 turns of negative " +
                     "mood points, your game will end.  The best ways to increase your mood points " +
                     "are to Volunteer, Do Something Fun, or go on a Vacation.  If you invite the " +
                     "right friend to join you, your mood points will increase even more. "]
       },
       {notificationType: "savingsUnlocked",
              modalTitle: "Savings Unlocked",
              textArr: "",
              notificationImg:  "savings.png",
              text: [ "The savings feature of this game is now available for you.  Please click " +
                     "the 'link' below (or click the Unlocked Features button to the right) to learn more about this feature " +
                     "and setup (or decline) a savings policy. " ],
              link: "setup-savings"
       },
       {notificationType: "savingsGTIncome",
              modalTitle: "Monthly Savings Issue",
              textArr: "",
              notificationImg:  "savings.png",
              text:  ["The amount you have set for monthly savings is greater than " +
                     "your monthly net income.  Please click the 'Unlocked Features' button to the right " +
                     "and change your savings policy to correct this issue."],
              link: "setup-savings"
       },
       {notificationType: "savingsTransfer",
        modalTitle: "Savings Transfer to Correct Negative Cash",
        notificationImg: "", 
        textArr: "",
        text: [ "You did not have enough cash to pay for some amounts in the previous month.  As " +
               "a result, the amount of cash is currently negative.  If this is not corrected  " +
               "within the next 3 months, your game will end.  You have money in your 401K savings " +
               "that can be transfered to cover the negative amount.  You may also want to review " +
               "your savings policy to ensure that you leave enough in your cash account to cover " +
               "unexpected expenses. To transfer money or change your savings policy, go to Unlocked " + 
               "Features and click on the Savings plan option."  ]
       },
       {notificationType: "creditCardUnlocked",
              modalTitle: "Credit Card Unlocked",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "The credit card feature of this game is now available for you.  Please click " +
                     "on the 'link' below (or click the Unlocked Features button to the right) to learn more about this feature " +
                     "and setup (or decline) a credit card policy. " ],
              link: "setup-credit-card"
       },
       {notificationType: "creditCardNewLevel",
              modalTitle: "Credit Card Higher Credit Limit",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "Congratulations!  You have been approved for a larger line of credit.  " +
                     "Please click on the 'link' below (or click the Unlocked Features button to the right) to learn more about " +
                     "this feature and accept (or decline) the new credit card limit. " ],
              link: "setup-credit-card"
       },
       {notificationType: "creditLimitExceeded",
              modalTitle: "Credit Card Limit Exceeded",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "The current outstanding balance on your credit card is greater than the maximum allowed. " +
                     "Additional charges will be denied.  Amounts to be paid going forward will need to be paid " +
                     "by cash.  You can correct this by paying down the amount owed on your credit card.  Click " +
                     "the link below or (select the Credit Card option in Unlocked Features)." ],
              link: "setup-credit-card"
       },
       {notificationType: "creditCardDenied",
              modalTitle: "Credit Card Denied - Card Limit Exceeded",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "Your credit card has been denied because the amount of this charge added to your " +
                     "current balance exceeds the credit card limit.  You will need to pay cash for this " +
                     "item...bummer! You can prevent this from recurring " +
                     "by paying down the amount owed on your credit card.  Click the link below or " +
                     "(select the Credit Card option in Unlocked Features)." ],
              link: "setup-credit-card"
       },
       {notificationType: "insufficientCash",
              modalTitle: "Insufficient Cash For Credit Card AutoPay Option",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "You have selected an auto-pay option for your credit card but the payment amount " +             
                     "exceeds your current cash.  The credit card payment amount was reduced to " +
                     "be an amount you can afford.  You will need to change your credit card policy and/or " +
                     "find a way to increase your cash.  You might try earning badges or try some extra " +
                     "credit exercises to earn some extra money.  Click on the link below to change your " +
                     "credit card policy." ],
              link: "setup-credit-card"
       },
       {notificationType: "purchaseAHome",
              modalTitle: "Purchase A Home",
              textArr: "",
              notificationImg:  "house.png",
              text:  ["You have saved enough to be able to afford the downpayment for a home.  Also, your " +
                     "monthly net income is large enough to cover the monthly home mortgage (loan) amount. " +
                     "If you want to buy a home, click on the link below, or click on the 'Change Game Choices' " +
                     "button and choose a different home.",
                     "Before you purchase the home, you may want to transfer money from " +
                     "your 401k savings account so that you can cover the down payment.... OR ....  you " +
                     "may want to reduce your monthly savings amount and delay purchase for several months " +
                     "so that you can have enough in your bank account to cover the down payment." ],
              link: "change-home",
       },
    
       {notificationType: "savingsReminder",
              modalTitle: "Savings Reminder",
              textArr: "",
              notificationImg:  "savings.png",
              text: [ "Don't forget, the savings feature has been unlocked and you can setup a savings policy. " +
                     "Click the link below (or click the Unlocked Features button to the right) to learn more about this feature " +
                     "and setup a savings policy if you wish." ],
              link: "setup-savings"
       },
       {notificationType: "creditCardReminder",
              modalTitle: "Credit Card Reminder",
              textArr: "",
              notificationImg:  "creditCard.png",
              text: [ "Don't forget, a credit card feature has been unlocked and you can take advantage of this feature. " +
                     "Click the 'link' below (or click the Unlocked Features button to the right) to learn more about this feature " +
                     "and setup a credit card policy if you wish. " ],
              link: "setup-credit-card"
       },
       {notificationType: "funStuffUnlocked",
              modalTitle: "Fun Stuff Unlocked",
              textArr: "",
              notificationImg:  "",
              text: [ "The Fun Stuff feature of this game is now unlocked and you can adopt a cat or a dog. " +
                     "Click the 'link' below (or click the Unlocked Features button to the right) to choose your pet."],
              link: "setup-fun-stuff"
       },
       {notificationType: "funStuffNewLevel",
              modalTitle: "Additional Fun Stuff Items Available",
              textArr: "",
              notificationImg:  "",
              text: [ "Congratulations!  You are now eligible to get additional fun stuff.  " +
                     "Please click on the 'link' below (or click the Unlocked Features button to the right) to check out " +
                     "the new options that are available for you. " ],
       link: "setup-fun-stuff"
       },
       {notificationType: "funStuffReminder",
              modalTitle: "Fun Stuff Reminder",
              textArr: "",
              notificationImg:  "",
              text: [ "Don't forget, the Fun Stuff feature of this game has been unlocked. " +
                     "Click the 'link' below (or click the Unlocked Features button to the right) to check out " +
                     "the options that are available to you."],
              link: "setup-fun-stuff"
       },
       {notificationType: "takeMoneyMgmtTest",
              modalTitle: "Would You Take the Money Management Test?",
              textArr: "",
              notificationImg:  "takingTest.png",
              text: [ "It seems that you haven't taken the basic money managment test yet. " +
                     "It is only 10 questions long and it would be great if you would click the 'link' below " +
                     "in order to take the test.  When you are done, you can return to the game."],
              link: "money-mgmt-test"
       },
       {notificationType: "timeWizardUnlocked",
              modalTitle: "Time Wizard Unlocked",
              textArr: "",
              notificationImg:  "timeWizard.png",
              text: [ "The time wizard feature of this game is now available for you.  Please click " +
                     "the 'link' below (or click the Unlocked Features button to the right) to learn more about this feature " +
                     "and setup a new game time policy. " ],
              link: "setup-time-wizard"
                   },
       {notificationType: "timeWizardNewLevel",
              modalTitle: "Time Wizard New Level Unlocked",
              textArr: "",
              notificationImg:  "timeWizard.png",
              text: [ "Congratulations!  You have earned a new level of the Time Wizard feature. " +
                     "Please click the 'link' below (or click the Unlocked Features button to the right) " +
                     "to learn more about this feature and accept (or decline) the new time wizard level. " ],
              link: "setup-time-wizard"
       },

]
export default NotificationTextDataset;