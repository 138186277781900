import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import DollarAmountCard from '../getting-started/dollar_amount_card';
import StatusDisplayItemOrder from '../../datasets/status_display_item_order';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import { rollForwardDate } from './objects/roll_forward_date_function';
import FinancialAdvisorModal from '../shared-components/financial_advisor_modal';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import financialAdvisorListStatus from '../shared-components/Datasets/financial_advisor_list_status';
import EventOnLitList from '../admin/datasets/event_onLit_dataset';

class MonthlyStatusModal extends Component {
        constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state={
                data: null,
                loading: true,
                gameAmounts: {},
                gameAssets: {},
                expenseObj: {},
                readySw: false,
                pendingJob: "",
                expandLoans: false,
                expandExpenses: false,
                expandSavings: false,
                allowAssetExpand: false,
                openFinModal: false,
        }
        this.populateAmounts();
        this.selectAdvisor();
    }
      
    populateAmounts() {
        this.currentGameDate = this.gameInfo.gameData.currentGameDate;
        this.reorderLineAmounts();
        this.calculateMonthlyTotal();
        this.checkIfPendingJob();
        if (this.gameInfo.savingsInfo != undefined){
            this.buildSavingsArr();
        }
        this.reformatGameDate = reformatDateToDisplay(this.gameInfo.gameData.currentGameDate);
    }
      
    selectAdvisor(){
        this.advisorList = financialAdvisorListStatus;
        const advisorNbr = calcRandomNumberWithMax(8,true,1);
        const advisorName = this.advisorList[advisorNbr].name;
        const introText = this.advisorList[advisorNbr].introText;
        const advisorURL = './assets/MonthlyStatus/' + this.advisorList[advisorNbr].imageURL;
        this.advisorObj = {advisorNbr: advisorNbr, advisorName: advisorName, 
            introText: introText, advisorURL: advisorURL};
    }

    reorderLineAmounts(){
        this.statusDisplayItemOrder = StatusDisplayItemOrder
        var tableItem = "";
        this.assetMaxNbr = 0;
        this.expenseMaxNbr = 0;
        this.loanMaxNbr = 0;

        this.assetTable = [];
        if (this.gameInfo.gameData.assetTable.length > 1){
              this.state.allowAssetExpand = true;
        }
        for (let i=0; i<this.gameInfo.gameData.assetTable.length; i++){
             tableItem = this.gameInfo.gameData.assetTable[i].assetItem;
             this.assignOrderNbr(tableItem,  i);
             let assetObj = {...this.gameInfo.gameData.assetTable[i], order: this.lineOrder};
             this.assetTable.push(assetObj);
        }
        this.assetTable.sort((a, b) => (a.order > b.order) ? 1 : -1);
        this.expenseTable = [];
        for (let i=0; i<this.gameInfo.gameData.expenseTable.length; i++){
            tableItem = this.gameInfo.gameData.expenseTable[i].expItem;
            this.assignOrderNbr(tableItem, i);
            let expenseTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable[i]));
            this.insertExpenseEndDate(expenseTable);
            let expenseObj = {...expenseTable, order: this.lineOrder};
            this.expenseTable.push(expenseObj);
        }
        this.expenseTable.sort((a, b) => (a.order > b.order) ? 1 : -1);
        this.expenseTable = this.checkIfCombine(this.expenseTable);
        this.loanTable = [];
        for (let i=0; i<this.gameInfo.gameData.loanTable.length; i++){
            tableItem = this.gameInfo.gameData.loanTable[i].loanType;
            this.assignOrderNbr(tableItem, i);
            let loanTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.loanTable[i]))
            this.insertLoanEndDate(loanTable);
            let loanObj = {...loanTable, order: this.lineOrder};
            this.loanTable.push(loanObj);
        }
        this.loanTable.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }

    insertExpenseEndDate(expenseTable){
        if (+expenseTable.expNbrMonths === -1 ||
            +expenseTable.expNbrMonths === 0){
               // do not add end date
        }else{
            let expenseNbrMonths = +expenseTable.expNbrMonths;
            let loanEndDate = rollForwardDate(this.gameInfo.gameData.currentGameDate, expenseNbrMonths);
            expenseTable.expDesc = expenseTable.expDesc + "- Expense Will End " + reformatDateToDisplay(loanEndDate);
        }
    }

    insertLoanEndDate(loanTable){
        if (loanTable.loanDesc.includes ("Loan Fully Paid")){
               // do not add end date a second time
        }else{
            let loanNbrMonths = (loanTable.loanTerm * 12).toFixed(0);
            let loanEndDate = rollForwardDate(loanTable.loanStartDate, loanNbrMonths);
            loanTable.loanDesc = loanTable.loanDesc + "- Loan Fully Paid by " + reformatDateToDisplay(loanEndDate);
        }
    }

    assignOrderNbr(amountType, indx){
        const orderObj = this.statusDisplayItemOrder.find(elem => elem.item === amountType);
        if (orderObj !== undefined){
            this.lineOrder = orderObj.order + indx;
        }else{
            this.lineOrder = 9999 - indx;
        }
    }

    checkIfCombine(inputTable){
        let outputTable = [];
        let combined = false;
        let saveInitialIndx = 0;
        let writeSw = false;
        for (let i=0; i<inputTable.length; i++){
            let prev = outputTable.length - 1;
            if (i > 0 && inputTable[i].expItem === outputTable[prev].expItem &&
                outputTable[prev].expNbrMonths !== 0){
                let onLitObj = EventOnLitList.find(elem => elem.value === inputTable[i].expItem);
                if (onLitObj !== undefined && onLitObj.creditCard === false){
                    if (saveInitialIndx === 0){
                        saveInitialIndx = prev;
                        combined = true;
                    }
                    outputTable[saveInitialIndx].expAmount = +outputTable[saveInitialIndx].expAmount + +inputTable[i].expAmount;
                }else{
                    writeSw = true;
                }
            }else{
                writeSw = true;
            }
            if (writeSw === true){
                if (combined === true){
                    combined = false;
                    saveInitialIndx = 0;
                }        
                outputTable.push(inputTable[i]);
                writeSw = false;
            }
        }
        return outputTable;
    }

    calculateMonthlyTotal() {
        this.monthlyIncomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
        this.monthlyTotalAmt = this.monthlyIncomeObj.income
        
    }

    checkIfPendingJob(){
        this.pendingJob = false;
        if (this.gameInfo.gameData.pendingJob != undefined && 
            Object.keys(this.gameInfo.gameData.pendingJob).length != 0){
            this.pendingJob = true;
            let pendingJob = this.gameInfo.gameData.pendingJob;
            let reformatDate = reformatDateToDisplay(pendingJob.jobStartDate);
            let pendingJobObj = {textLine: pendingJob.jobInfo.careerName, startDate: reformatDate};
            this.state.pendingJob = pendingJobObj;
        }
    }

    buildSavingsArr(){
        this.savingsArr = []
        let savingsObj = {item: "Monthly Savings Amt", desc: "Amount deposited into savings each month",
                amount: this.gameInfo.savingsInfo.monthlySavingsAmt, className: ""};
        this.savingsArr.push(savingsObj);
        savingsObj = {item: "Total Deposits", desc: "Total amount added to savings", 
                amount: this.gameInfo.savingsInfo.totalDeposits, className: ""}
        this.savingsArr.push(savingsObj);
        savingsObj = {item: "Total Withdrawals", desc: "Total amount taken out of savings",
                amount: this.gameInfo.savingsInfo.totalWithdrawals, className: "text-danger"};
        let overDraftFees = +this.gameInfo.savingsInfo.totalOverDraftFees;
        if (isNaN(overDraftFees)){
              overDraftFees = 0;
        }
        this.savingsArr.push(savingsObj);
                savingsObj = {item: "Total Transfer Fees", desc: "Total amount charged for transfers",
            amount: overDraftFees, className: "text-danger"};
        this.savingsArr.push(savingsObj);
        savingsObj = {item: "Total Interest Amt", desc: "Total amount of interest earned",
            amount: this.gameInfo.savingsInfo.totalIntAmt, className: ""};
        this.savingsArr.push(savingsObj);
    }
  
    toggleIncomeType(incomeType){
        switch (incomeType) {
            case "assets":
                this.setState({expandAssets: !this.state.expandAssets});
                break;
            case "expenses":
                this.setState({expandExpenses: !this.state.expandExpenses});
                break;
            case "loans":
                this.setState({expandLoans: !this.state.expandLoans});
                break;
            case "savings":
                this.setState({expandSavings: !this.state.expandSavings});
                break;
        }
    }  
    
    openFinancialAdvisorModal(){
        this.state.openFinModal = true;
        this.setState({openFinModal: true});
    }

    closeFinancialAdvisorModal(){
        this.state.openFinModal = false;
    }

    
    render() {
    
        this.savingsAccount = false;
        if (this.gameInfo.savingsInfo != undefined) 
              if (this.gameInfo.savingsInfo.monthlySavingsAmt != undefined &&
                  this.gameInfo.savingsInfo.monthlySavingsAmt > 0  || 
                  this.gameInfo.savingsInfo.totalSavings != undefined &&
                  this.gameInfo.savingsInfo.totalSavings > 0){
                        this.savingsAccount = true;
        }  
        if (this.state.expandAssets == true){
            this.assetList = this.assetTable.map((assetAmt,key) =>
                <DollarAmountCard  key={key}  amtType="asset" amountInfo={assetAmt} className = ""
                        gameDate={this.gameInfo.gameData.currentGameDate}
                />
            );
        }
        if (this.state.expandExpenses == true){
            this.expenseList = this.expenseTable.map((expenseAmt,key) =>
                <DollarAmountCard  key={key}  amtType="expense" amountInfo={expenseAmt} className = "text-danger"
                    gameDate={this.gameInfo.gameData.currentGameDate}
                />
            );
        }

       if (this.state.expandLoans == true){ 
            this.loanList = this.loanTable.map((loanAmt,key) =>
                <DollarAmountCard  key={key}  amtType="loan" amountInfo={loanAmt} className = "text-danger"
                    gameDate={this.gameInfo.gameData.currentGameDate}
                />
            );
       }
       if (this.state.expandSavings == true && this.savingsAccount == true){
            this.savingsList = this.savingsArr.map((savingsAmt, index) =>
            <DollarAmountCard  key={index} amtType="general" amountInfo={savingsAmt} 
                className={this.savingsArr[index].className}
                gameDate={this.gameInfo.gameData.currentGameDate}
            />
            );
       }
       this.gutters = setModalSize(this.props.screenSize);
          
    return <>
      
        <Modal show={this.props.modalOpen}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
            dialogClassName={this.gutters} className="modal-border" animation={false}>
            <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title className="ml-4">Monthly Status - Current Game Date &nbsp;
                    {reformatDateToDisplay(this.gameInfo.gameData.currentGameDate)}
                    <div >
                        Player:&nbsp;&nbsp; {this.gameInfo.gameData.user}
                    </div>
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-border">
            <span>                
                <table className="table table-hover">
                    <thead>
                        <tr className="thead-light">
                            <th className="p-0 col-sm-4" scope="col">Item</th>
                            <th className="p-0 col-sm-5" scope="col">Description</th>
                            <th className="p-0 col-sm-3 text-right" scope="col">Amount</th>
                        </tr>
                    </thead>
                </table>
                {this.state.expandAssets ?
                    <table className="table table-hover">
                        <tbody>
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("assets")}>
                                <th className="p-0 col-sm-4" scope="col">Total Income&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" /> </th>
                                <th className="p-0 col-sm-5"></th>
                                <th className="p-0 col-sm-3"></th>
                            </tr>
                            {this.assetList}
                            <tr className="thead-light" onClick={() => this.toggleIncomeType("assets")}>
                                <th className="p-0 col-sm-4 " scope="col">Total Income (Salary & Other)</th>
                                <th className="p-0 col-sm-5 " scope="col"></th>
                                <th className="p-0 col-sm-3 text-right" scope="col">
                                                {doubleToStringMonthly(this.monthlyIncomeObj.assets)}</th>
                            </tr>
                        </tbody>
                    </table>
                :
                    <table className="table table-hover">
                        <tbody>
                            <tr className="thead-light" onClick={() => this.toggleIncomeType("assets")}>
                                <th className="p-0 col-sm-4" scope="col">Total Income&nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" /> 
                                    </th>
                                <th className="p-0 col-sm-5" scope="col"> </th>
                                
                                <th className="p-0 col-sm-3 text-right" scope="col">
                                        {doubleToStringMonthly(this.monthlyIncomeObj.assets)}</th>
                            </tr>
                        </tbody>
                    </table>
                }
              
             {this.state.expandExpenses ?
                <table className="table table-hover">
                    <tbody>
                    <tr className="thead-light" onClick={() => this.toggleIncomeType("expenses")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Expenses&nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" /> </th>
                            <th className="p-0 col-sm-5"></th>
                             <th className="p-0 col-sm-3"></th>
                        </tr>
                         {this.expenseList}
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("expenses")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Expenses</th>
                            <th className="p-0 col-sm-5 text-danger" scope="col"></th>
                             <th className="p-0 col-sm-3 text-danger text-right" scope="col">
                                            {doubleToStringMonthly(this.monthlyIncomeObj.expenses)}</th>
                        </tr>
                    </tbody>
                </table>
            :
                <table className="table table-hover">
                    <tbody>
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("expenses")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Expenses&nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" /> 
                               </th>
                            <th className="p-0 col-sm-5 text-danger" scope="col"> </th>
                              
                            <th className="p-0 col-sm-3 text-danger text-right" scope="col">
                                    {doubleToStringMonthly(this.monthlyIncomeObj.expenses)}</th>
                        </tr>
                    </tbody>
                </table>
            }
            {this.state.expandLoans ?
                <table className="table table-hover">
                    <tbody>
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("loans")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Loans &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" /> </th>
                            <th className="p-0 col-sm-5 text-danger" scope="col"></th>
                            <th className="p-0 col-sm-3 text-danger text-right" scope="col"></th>
                        </tr>
                        {this.loanList}
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("loans")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Loans</th>
                            <th className="p-0 col-sm-5 text-danger" scope="col"></th>
                            <th className="p-0 col-sm-3 text-danger text-right" scope="col">
                                    {doubleToStringMonthly(this.monthlyIncomeObj.loans)}</th>
                        </tr>
                    </tbody>
                </table>
            :
                <table className="table table-hover">
                    <tbody>
                        <tr className="thead-light" onClick={() => this.toggleIncomeType("loans")}>
                            <th className="p-0 col-sm-4 text-danger" scope="col">Total Loans &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" /> </th>
                            <th className="p-0 col-sm-5 text-danger" scope="col"></th>
                            <th className="p-0 col-sm-3 text-danger text-right" scope="col">
                                    {doubleToStringMonthly(this.monthlyIncomeObj.loans)}</th>
                        </tr>
                    </tbody>
                </table>
            }
                    
            {this.monthlyTotalAmt < 0 ?    
                <table className="table table-hover">
                    <tbody>      
                        <tr className="text-danger">
                            <th className="p-0" scope="row" colSpan="2">Monthly Total</th>
                            <th className="col-sm-6 p-0">Expenses are greater than income</th>
                            <th className="p-0 text-right">- {doubleToStringMonthly(this.monthlyTotalAmt * -1)}</th>
                        </tr>
                    </tbody>
                </table>
            :
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th className="col-sm-4 p-0" scope="row" colSpan="2">Monthly Total</th>
                            <th className="col-sm-6 p-0">Amount of income each month</th>
                            <th className="col-sm-2 p-0 text-right">{doubleToStringMonthly(this.monthlyTotalAmt)}</th>
                        </tr>
                    </tbody>
                </table>
            }
            {this.gameInfo.gameData.currentCash > 0 ?
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th className="col-sm-4 p-0" scope="row" colSpan="2">Current Cash Total</th>
                            <td className="col-sm-6 p-0"></td>
                            <td className="col-sm-2 p-0 text-right">
                                {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}</td>
                        </tr>
                    </tbody>
                </table>
            :
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th className="col-sm-4 p-0 text-danger" scope="row" colSpan="2">Current Cash Total</th>
                            <td className="col-sm-6 p-0 text-danger">Current Game Date - {this.reformatGameDate}</td>
                            <td className="col-sm-2 p-0 text-danger text-right">
                                {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}</td>
                        </tr>
                    </tbody>
                </table>
            }
            {this.savingsAccount ?
                <span>
                    <h5 className="text-center">----------Savings Account Information----------</h5>
                    {this.state.expandSavings ?
                                     
                        <table className="table table-hover p-0">
                            <tbody>
                                <tr className="thead-light" onClick={() => this.toggleIncomeType("savings")}>
                                    <th className="col-sm-4 p-0" scope="col">Total Savings Amt &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" /> </th>
                                    <th className="col-sm-5 p-0"></th>
                                    <th className="col-sm-3 text-right p-0"></th>
                                </tr>
                            {this.savingsList}
                                <tr className="thead-light" onClick={() => this.toggleIncomeType("savings")}>
                                    <th className="col-sm-4 p-0" scope="col">Total Savings Amt</th>
                                    <th className="col-sm-5 p-0"> </th>
                                    <th className="col-sm-3 text-right p-0">
                                            {doubleToStringMonthly(+this.gameInfo.savingsInfo.totalSavings)}</th>
                                </tr>
                                </tbody>
                            </table> 
                        :
                            <table className="table table-hover p-0">
                                <tbody>
                                    <tr className="thead-light" onClick={() => this.toggleIncomeType("savings")}>
                                        <th className="col-sm-4 p-0" scope="col">Total Savings Amt &nbsp;&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" /> </th>
                                        <th className="col-sm-5 p-0"></th>
                                        <th className="col-sm-3 p-0 text-right">
                                            {doubleToStringMonthly(+this.gameInfo.savingsInfo.totalSavings)}</th>
                                    </tr>
                                </tbody>
                            </table>
                        }  
                    </span>
                :
                        null
                }
                      
                <table className="table table-hover">
                    <tbody>
                        {this.pendingJob ?
                            <tr className="row-100">
                                <th className="col-sm-4 p-0">Pending Job</th>
                                <th className="col-sm-6 p-0 text-left">{this.state.pendingJob.textLine}</th>
                                <td className="col-sm-2 p-0 text-right">Start Dt: {this.state.pendingJob.startDate}</td>
                            </tr>
                        :
                            null
                        }
                    
                    </tbody>
               </table>
            </span>
            {this.state.openFinModal  ?
                <FinancialAdvisorModal 
                    modalOpen = {this.state.openFinModal}
                    gameInfo = {() => this.props.gameInfo()}
                    adviceType = {"status"}
                    advisorObj = {this.advisorObj}
                    monthlyTotalAmt ={this.monthlyTotalAmt}
                    onClose = {() => this.closeFinancialAdvisorModal()}
                />
            :
                null
            }
           
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div>
                <img className="monthly-status-chat-icon" onClick={() => this.openFinancialAdvisorModal()} 
                    src={'./assets/MonthlyStatus/chatIcon.png'}  alt="chatIcon"/>
            </div>
            <Button variant="primary"  onClick={() => this.props.onClose()}>
                      Close
            </Button>
        </Modal.Footer>
        </Modal>
       
        </>
    }

   
    backToOptionSelection() {
        this.props.history.push('/statusDsply');
    }

}

export default MonthlyStatusModal;