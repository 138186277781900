import calcRandomNumberWithMax from "../../../objects/CommonUse/calc_random_number_with_max";
import JokeListDatasetPhase1 from "../datasets/joke_list_dataset_phase1";
import JokeListDatasetPhase2 from "../datasets/joke_list_dataset_phase2";


export const buildJokeCard = (gamePhase) => {
    let jokeCardObj = "";
    let responseArr = [];
        
    // select a joke
    let jokeList = [];
    if (gamePhase == "1"){
        jokeList = JokeListDatasetPhase1.jokes;
    }else{
        jokeList = JokeListDatasetPhase2.jokes;
    }
    let nbrOfJokes = jokeList.length;
    let selectedJokeIndx = calcRandomNumberWithMax(nbrOfJokes, true);
    let selectedJokeText = jokeList[selectedJokeIndx];
 
    // build response
    let cashAmt = 0;
    let responseObj =  {btnText: "Do Tell", rltnshpScore: +0,
        closureText: selectedJokeText.response, category: 'cash', on: 'cash', type: 'flat', 
        itemDesc: "",  duration: '1', replaceAmtSw: 'false', triggerCondition: "", deleteCondition: "",
        deleteClass: "", moodPoints: +1,
            value: cashAmt};
    responseArr.push(responseObj); 
    let cardText = "||friendName|| tells you a joke: " + selectedJokeText.question;
    //  cardType set to "joke" to prevent gameboard from doing a removeCardFromHand
    jokeCardObj = {cardType: "joke", imageArr: [], selectMonthArr: [], _id: '',
        responses: responseArr, title: "Just a Laugh", description: cardText,
        frequency: "83", conditionConnector: 'AND', conditionArr: [],  skipConditionArr: [], 
        backgroundType: 'home', imageDir: "friends", dateUpdated: '2022/10/29',  gamePhase: gamePhase, careerTag: '', 
        eventCategory: 'joke'};
           
    return jokeCardObj;
   
}
export default buildJokeCard;

