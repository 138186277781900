import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import unlockedFeaturesDataset from './datasets/unlocked_features_dataset';
import unlockedFeaturesFullNames from './datasets/unlocked_features_full_names';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import NumberedListFancyCard from '../shared-components/numbered_list_fancy_card';
import UnlockedFeatureText from './text/unlocked_feature_text';

class UnlockedFeaturesCriteriaModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                  unlockedFeatureText: UnlockedFeatureText,
                  unlockedFeaturesCriteria: unlockedFeaturesDataset,
                  unlockedFeaturesFullName: unlockedFeaturesFullNames,
              } 
              this.featureTextArr = [];
              this.featureFound = false;
              this.none = "none";
              this.determineFeaturesText();  
    }
    
    determineFeaturesText(){
        this.featureDescription = "";
        let featureTextObj = this.state.unlockedFeatureText.find(elem => elem.feature === this.props.featureName);
        if (featureTextObj !== undefined){
            this.featureDescription = featureTextObj.text;
        }
        this.getCriteria(this.props.featureName, this.props.featureLevel, this.props.featureStatus);
        let featureObj = this.state.unlockedFeaturesFullName.find(elem => elem.featureName === this.props.featureName);
        const fullFeatureName = featureObj.fullName;
        this.headerText = fullFeatureName + " Criteria";
        this.currentLevelText = "";
        if (this.currentLevel != 0){
            this.currentLevelText = "You have earned and activated Level " + this.currentLevel + " of this feature. " +
                "You can click on the " + fullFeatureName + " button to change your choices.";
        }
        if (this.featureFound === true){
            this.featureFoundText(fullFeatureName);
        }else{
             this.featureNotFoundText(fullFeatureName);
        }
    }
    
    getCriteria(featureName, currentLevel, featureStatus){
        this.currentLevel = 0;
        for (let i=0; i<this.state.unlockedFeaturesCriteria.length; i++){
            const unlockedFeatureCriteria = this.state.unlockedFeaturesCriteria[i];
            if (featureName === unlockedFeatureCriteria.featureName) {
                if (currentLevel === unlockedFeatureCriteria.level && featureStatus === "active"){
                    this.currentLevel = currentLevel;
                }
                if ((currentLevel === unlockedFeatureCriteria.level && featureStatus !== "active") ||
                        currentLevel < unlockedFeatureCriteria.level) {
                    this.featureCriteria = unlockedFeatureCriteria;
                    this.featureFound = true;
                    this.featureEarnedLevel = unlockedFeatureCriteria.level;
                    break;
                }
            }
        }
    }

    featureFoundText(fullFeatureName){
        this.featureText = "In order to be eligible for level " + this.featureCriteria.level +
            " of the " + fullFeatureName + " feature, you must meet all of the following criteria." 
        let featureBulletText = "";
        this.eligible = true;
        let monthLit1 = " months."
        let monthLit2 = " months."
        if (this.featureCriteria.minNbrMonths === 1){
             monthLit1 = " month."
        }
        if (this.props.gameNbrMonths === 1){
             monthLit2 = " month."
        }
        featureBulletText = "Played the game for " + this.featureCriteria.minNbrMonths +  monthLit1 +
          " You have played the game for " + this.props.gameNbrMonths + monthLit2;
        this.addTextToTable(featureBulletText, this.featureCriteria.minNbrMonths, this.props.gameNbrMonths);
        featureBulletText = "Current cash must be at least " + 
            doubleToStringMonthly(this.featureCriteria.minCashAmt) + "." + 
            " You currently have " + doubleToStringMonthly(this.props.currentCash) + "."
        this.addTextToTable(featureBulletText, this.featureCriteria.minCashAmt, this.props.currentCash);
        featureBulletText = "Monthly Income must be at least " + 
            doubleToStringMonthly(this.featureCriteria.minMonthIncome) + "." +
            " Your current monthly income is " + doubleToStringMonthly(this.props.monthlyIncome) + ".";
        this.addTextToTable(featureBulletText, this.featureCriteria.minMonthIncome, this.props.monthlyIncome);
        if (this.eligible === true){
            if (this.props.featureLevel === this.featureEarnedLevel){
                this.eligibleText = "This feature has been unlocked but you are not currently taking advantage of " +
                    "this feature.";
            }else{
                this.eligibleText = "This feature has been earned, it will be unlocked at the end of your next " +
                    "turn in the game";
            }
        }else{
            this.eligibleText = "";
        }
    }

    addTextToTable(textLine, unlockedValue, playerValue){
        let textColor = "black";
        if (+unlockedValue <= +playerValue){
            textColor = "green";
        }else{
            this.eligible = false;
        }
        const textFeatureObj = {textLine: textLine, textColor: textColor};
        this.featureTextArr.push(textFeatureObj);
    }

    featureNotFoundText(fullFeatureName){
        this.currentLevelText = "";
        this.featureText = "You have earned and activated all levels of this feature. " +
            "Click on the " + fullFeatureName + " button if you would like to change the setup values."

    }



    render() {
      this.numberedList = this.featureTextArr.map((textLine, key) =>
          <NumberedListFancyCard key={key} text={textLine} />
        );


    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="md" scrollable="true"
            dialogClassName={this.gutters} animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">{this.headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  <div>{this.featureDescription}</div>
                    <div className="mt-2">  
                        {this.currentLevelText}
                    </div>
                    <div className="mt-2">
                        {this.featureText}
                    <div className="mt-1 mx-0 " >
                            <ul className="text-left mr-0">
                                {this.numberedList}
                            </ul>
                        </div>  
                    <div>{this.eligibleText}</div>
                  </div>
        </Modal.Body>
        </Modal>
        </>
    }

    
}

export default UnlockedFeaturesCriteriaModal;