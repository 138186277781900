import { rollForwardDate } from "./roll_forward_date_function";
import ConfigFile from "../../../datasets/config_file";
import findDataObject from "../../../objects/CommonUse/find_data_object";
import { applyMonthlyMiscExpToCreditCard } from "./apply_monthly_misc_exp_to_credit_card";
import { calcNbrMonthsBtwnDates } from "../../../objects/CommonUse/nbr_months_btwn_dates_calc";

export const payMonthlyCreditCardAmount = 
     (creditCardInfo, currentCash, currentGameDate, nbrMonths, expenseTable) => {
   
     //  the following adds on the ongoing monthly costs to the credit card and
     //  an estimated unplanned amount (based on average unplanned for current and
     //  prior months);
     creditCardInfo = applyMonthlyMiscExpToCreditCard(expenseTable, 
          creditCardInfo, +nbrMonths);

     let totalOutstandingBalance = +creditCardInfo.totalOutstandingBalance;
     let outstandingAmt = +creditCardInfo.totalOutstandingBalance;
            
     // update late fee if no payment was made for the previous period 
     let lateFee = 0;
     let indx = creditCardInfo.periodCreditCharges.length - 2;
     if (indx > 0){
          // update late fee if no payment was made for the previous period 
          if (creditCardInfo.periodCreditCharges[indx].periodBilledAmt > 0 &&
              creditCardInfo.periodCreditCharges[indx].periodAmtPaid == 0){
               lateFee = (+ConfigFile.unlockedFeatures.creditCardLateFee * +nbrMonths);
          }
          creditCardInfo.periodCreditCharges[indx + 1].periodLateFee = lateFee;
          totalOutstandingBalance += lateFee;
     }
     if (lateFee > 0){
          creditCardInfo.lateFeeCount = +creditCardInfo.lateFeeCount + 1;
     }else{
          creditCardInfo.lateFeeCount = 0;
     }
      
     let totalInterestCharge = 0;
         
     //  if it is NOT autopayfull then add interest amount before comparing to max credit amt
     //  only pay interest on unpaid balance so if balance is paid in full then no interest is calculated
     if (+totalOutstandingBalance > 0 && creditCardInfo.paymentPolicyCode != "autoPayFull"){
          let creditCardIntRate = +(ConfigFile.unlockedFeatures.creditCardInterest/12).toFixed(6);
          creditCardIntRate = creditCardIntRate * nbrMonths;
          let periodInterestCharge = totalOutstandingBalance * creditCardIntRate;
          totalInterestCharge = +totalInterestCharge + +periodInterestCharge;
          creditCardInfo.totalIntBilled = (+creditCardInfo.totalIntBilled + +totalInterestCharge).toFixed(4);
          totalOutstandingBalance += totalInterestCharge;
     }
     // calculate the minimum payment    
     let minimumPaymentAmt = 0 ;
     let cashError = false;
     let minPaymentPercent = ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
     if (creditCardInfo.paymentPolicyCode == "autoPayMin"){
          minimumPaymentAmt = (+totalOutstandingBalance * minPaymentPercent).toFixed(2);
          if (minimumPaymentAmt > 0){
               if (totalOutstandingBalance > +creditCardInfo.creditMaxAmount){
                    let differenceAmt = +totalOutstandingBalance - +creditCardInfo.creditMaxAmount;
                    // difference amount is the amount over the max credit card allowed 
                    if (differenceAmt > minimumPaymentAmt){
                         minimumPaymentAmt = differenceAmt + lateFee;
                    }
               }
               // determine previous and current outstanding balance, and new cash amount
               // minimumPaymentAmt = minimumPaymentAmt * +nbrMonths;
          }
     }else{
          if(creditCardInfo.paymentPolicyCode == "autoPayFull"){
               minimumPaymentAmt = +totalOutstandingBalance;
          }
     }
     let newCashAmt = +currentCash;
     if (+minimumPaymentAmt > 0){
          if (+currentCash < +minimumPaymentAmt){
               //  cashError set to true will cause a notification in game-board
               cashError = true;
               minimumPaymentAmt = +currentCash;
          }
          newCashAmt -= +minimumPaymentAmt;
          totalOutstandingBalance = 
               (+totalOutstandingBalance - +minimumPaymentAmt).toFixed(2);
     }
           
     // update outstanding amt, interest amt and amount paid in the current month
     indx = creditCardInfo.periodCreditCharges.length - 1;
     if (indx >= 0){
          // confirm that periodEndDate is consistent with period length using nbrMonths
          const nbrMonthsDiff = calcNbrMonthsBtwnDates(creditCardInfo.periodCreditCharges[indx].bgnDate,
               creditCardInfo.periodCreditCharges[indx].endDate) + 1;
          if (nbrMonthsDiff !== nbrMonths){
               const rollForwardMonths = +nbrMonths - 1;
               creditCardInfo.periodCreditCharges[indx].endDate = 
                    rollForwardDate(creditCardInfo.periodCreditCharges[indx].bgnDate, rollForwardMonths);
          }
          // outstanding amount is amount before current month's charges-adding lateFee since that is
          // for the previous month also adding back in the amount paid as that should not be 
          // included in billed amount (Amt paid is the amount paid before the end of period.  It will
          // usually be $0 unless player has made a manual payment during the period.)
          creditCardInfo.periodCreditCharges[indx].periodBilledAmt = (+outstandingAmt + lateFee +
               +creditCardInfo.periodCreditCharges[indx].periodAmtPaid).toFixed(2);
          creditCardInfo.periodCreditCharges[indx].periodIntAmt = totalInterestCharge.toFixed(4);
          // adding to period amt paid in case player has made manual payments to card that were
          // applied in the unlocked features part of the game.
          creditCardInfo.periodCreditCharges[indx].periodAmtPaid = (+minimumPaymentAmt + 
               +creditCardInfo.periodCreditCharges[indx].periodAmtPaid).toFixed(2);
          const creditCard = creditCardInfo.periodCreditCharges[indx];
     }
        
     // create new month values
     let newMonthCreditObj = JSON.parse(JSON.stringify(findDataObject("periodCreditCharges"))).creditInfo;
     newMonthCreditObj.bgnDate = currentGameDate;
     if (+nbrMonths === 1){
          newMonthCreditObj.endDate = newMonthCreditObj.bgnDate;
     }else{
          const rollForwardMonths = +nbrMonths - 1;
          newMonthCreditObj.endDate = rollForwardDate(currentGameDate, rollForwardMonths);
     }
     newMonthCreditObj.periodBilledAmt = 0;
     newMonthCreditObj.periodAmtPaid = 0;
     newMonthCreditObj.periodIntAmt = 0;
     newMonthCreditObj.creditChargesArr = [];
     newMonthCreditObj.periodLateFee = lateFee;
     creditCardInfo.periodCreditCharges.push(newMonthCreditObj);
     creditCardInfo.totalOutstandingBalance = totalOutstandingBalance;

     // save # of periods of credit card history as specified in the config table
     let nbrPeriodsHistory = ConfigFile.unlockedFeatures.periodsCreditHistory;
     if (creditCardInfo.periodCreditCharges.length > nbrPeriodsHistory){
         let nbrToRemove = creditCardInfo.periodCreditCharges.length - nbrPeriodsHistory; 
         creditCardInfo.periodCreditCharges.splice(0,nbrToRemove);
     }
     let creditCardInfoObj = {creditCardInfo: creditCardInfo, cashAmt: newCashAmt, cashError: cashError};
  
    return creditCardInfoObj;
   
}





