const veryDifficultGameChoiceData = 
{
  "user": "student6",
  "jobInfo": {
    "careerID": "613114bf2afc7f761668ac11",
    "healthInsInd": "false",
    "jobRank": "8",
    "nbrYearsCollege": "0",
    "nbrSkillSurvey": "1",
    "jobStartGameMonth": 360
  },
  "homeInfo": {
    "homeID": "6132402497d929c9a8cf870a",
    "homeType": "apartment",
    "roommates": "0",
    "bedrooms": "1"
  },
  "furnitureID": "6132a56597d929c9a8cf871b",
  "carInfo": {
    "carID": "621ffc5bddfe59be064afcdf",
    "carAge": 0,
    "carYear": "2024",
    "carType": "400",
    "carGameDate": "2024-12"
  },
  "startingCash": "14535.00",
  "currentCash": "14535.00",
  "currentMoodPoints": "24000",
  "startGameDate": "2024-12",
  "currentGameDate": "2024-12",
  "assetTable": [
    {
      "assetItem": "Job Salary",
      "assetDesc": "Food Preparation Worker --> Annual Salary: $18,999.96",
      "assetAmt": "1583.33",
      "assetStartDate": "2024-12",
      "assetNbrMonths": "-1"
    }
  ],
  "expenseTable": [
    {
      "expItem": "Payroll taxes",
      "expDesc": "Federal, FICA (Social Security)",
      "expAmount": "161.42",
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Apartment Rent",
      "expDesc": "Oxford Heights-1 bedroom, 0 roommates",
      "expAmount": 2815,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    },
    {
      "expItem": "Health Insurance",
      "expDesc": "Health Insurance 100% paid by employee",
      "expAmount": 370,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Automobile Insurance",
      "expDesc": "Insurance for car repair or replacement",
      "expAmount": 75,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Renter's Insurance",
      "expDesc": "Insurance for furniture and other personal items",
      "expAmount": 30,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Transportation",
      "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
      "expAmount": "182.46",
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    },
    {
      "expItem": "Misc Expenses",
      "expDesc": "Food, Clothes, Personal Items, etc.",
      "expAmount": 380,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    }
  ],
  "loanTable": [
    {
      "loanType": "Car Loan",
      "loanDesc": "Sports Car - Brand New",
      "loanAmt": "1146.75",
      "loanStartDate": "2024-12",
      "loanNbrMonths": 72,
      "loanTotAmt": 61900,
      "loanIntRate": 0.1,
      "loanTerm": 6
    },
    {
      "loanType": "College Loan",
      "loanDesc": "No college is required for the job that you selected",
      "loanAmt": "0.00",
      "loanStartDate": "2024-12",
      "loanNbrMonths": 0,
      "loanTotAmt": "0.00",
      "loanIntRate": 0.035,
      "loanTerm": 0
    }
  ],
  "skillSurvey": [
    "4",
    "4",
    "5",
    "4",
    "4",
    "4",
    "5",
    "4",
    "5",
    "4",
    "5",
    "4"
  ],
  "conditionsTable": [
    {
      "condName": "sports-car",
      "conditionClass": "car"
    },
    {
      "condName": "homeType-apartment",
      "conditionClass": "home"
    },
    {
      "condName": "food-preparation-worker",
      "conditionClass": "career"
    },
    {
      "condName": "no-december-holiday-selected",
      "conditionClass": "game"
    },
    {
      "condName": "no-friend-purchases",
      "conditionClass": "game"
    }
  ],
  "gameNbrMonths": "360",
  "nbrMonthsNegative": "0",
  "nbrMonthsMoodNegative": "0",
  "unlockedFeatures": [],
  "friendList": [
    {
      "name": "Charlie",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cameron",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Jennifer",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cindy",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    }
  ],
  "spendingHistory": {
    "currentMonthSpendAmt": 0,
    "current10YearSpendAmt": 8465,
    "current10YearMonths": 1,
    "previous10YearSpendAmt": 0,
    "previous10YearMonths": 0
  },
  "moodPointHistory": {
    "currentMonthMoodPoints": 0,
    "totalMoodPoints": 0,
    "moodPointMonths": 1
  },
  "iconTable": [
    {
      "iconName": "gameBasicHelp",
      "iconCategory": "information",
      "className": "small_sign_thumbnail_img information-sign-position",
      "imageSrc": "gameHelp.png",
      "validInd": true,
      "firstTime": true,
      "additionalInfo": ""
    }
  ],
  "badgesEarned": [],
  "prevGameMonths": "0",
  "prevNbrGames": "0",
  "hand": [ ],
  "gameTemplateName": "very-difficult",
  "dateAdded": "2024/12/21",
  "dateUpdated": "2024/12/21",
  "__v": 0
}
export default veryDifficultGameChoiceData;