import { createIconTableObj } from "./create_icon_table_obj";
import {checkIfPurchaseAHomeIcon} from "./check_if_purchase_a_home_icon";
import { calcNbrMonthsBtwnDates } from "../../../objects/CommonUse/nbr_months_btwn_dates_calc";
import unlockedFeaturesDataset from "../datasets/unlocked_features_dataset";

export const checkIconTableEntries = (iconTable, currentCash, savingsInfo, monthlyIncome,
        moodPoints, pendingJob, creditCardInfo, conditionsTable, gameNbrMonths, 
        unlockedFeatures, currentGameDate, rentExpense, noMoneyMgmtTest,
        totalNbrGameMonths) => {

    let notifyArr = [];
    if (iconTable == undefined){
        iconTable = [];
    }

    let updateUnlockedFeatures = false;
    
    //  set valid indicator to false for all entries on iconTable
    //  will reset valid to true if warning/goodNews still exists
    //  skip the first entry because it is the general 'help' icon
    for (let i=1; i<iconTable.length; i++){
         iconTable[i].validInd = false;
    }

    if (currentCash < 0){
        let cashCheckAmt = -1 * currentCash;
        if (savingsInfo.totalSavings != undefined &&
            savingsInfo.totalSavings > cashCheckAmt){
                iconTable = createIconTableObj("savingsTransfer", iconTable);            
        }else{  
                iconTable = createIconTableObj("negativeCash", iconTable);
        }
    }else{
        if (monthlyIncome < 0){
            iconTable = createIconTableObj("negativeNetIncome", iconTable);
        }
    }

    if (moodPoints < 0){
            iconTable = createIconTableObj("negativeMoodPoints", iconTable);
    }
    
    if (pendingJob != undefined && 
        Object.keys(pendingJob).length != 0){
            iconTable = createIconTableObj("pendingJob", iconTable);
     }

    if (savingsInfo.monthlySavingsAmt != undefined &&
        +savingsInfo.monthlySavingsAmt > 0 &&
        +savingsInfo.monthlySavingsAmt > +monthlyIncome){
          iconTable = createIconTableObj("savingsGTIncome", iconTable);
    }

    if (creditCardInfo.gameID != undefined){
        if ( +creditCardInfo.totalOutstandingBalance >= +creditCardInfo.creditMaxAmount){
            iconTable = createIconTableObj("creditLimitExceeded", iconTable);
        }else{
            if (+currentCash <= +creditCardInfo.totalOutstandingBalance &&
                creditCardInfo.paymentPolicyCode === "autoPayFull"){
                iconTable = createIconTableObj("insufficientCash", iconTable);
            }
        }
    }

    if (rentExpense !== undefined){
        let purchaseAHome = checkIfPurchaseAHomeIcon(conditionsTable,
            gameNbrMonths, currentCash, monthlyIncome, savingsInfo, rentExpense,
            totalNbrGameMonths);
        if (purchaseAHome == true){
            iconTable = createIconTableObj("purchaseAHome", iconTable);
        }
    }

    // check unlocked features table create iconTable if unlocked feature has not been activated
    // or if it has been inactive for more than 12 months and player currently eligible for feature.
    let unlockedFeaturesArr = unlockedFeaturesDataset;
    for (let i=0; i<unlockedFeatures.length; i++){
   
        if (unlockedFeatures[i].setup == "none"){
            let iconName = "";
            if (+unlockedFeatures[i].level == 1){
                iconName = unlockedFeatures[i].featureName  + "Unlocked";
            }else{
                iconName = unlockedFeatures[i].featureName  + "NewLevel";
            }
            iconTable = createIconTableObj(iconName, iconTable);
            unlockedFeatures[i].lastNotifyDate = currentGameDate;
            updateUnlockedFeatures = true;
        }else{
            if (unlockedFeatures[i].setup == "inactive"){
                let featureName = unlockedFeatures[i].featureName;
                let unlockedFeatureRule = unlockedFeaturesArr.find(elem => elem.featureName === featureName);
                if (currentCash > unlockedFeatureRule.minCashAmt &&
                    monthlyIncome >= unlockedFeatureRule.minMonthIncome){
                        const nbrMonthsSinceNotify = 
                            calcNbrMonthsBtwnDates(unlockedFeatures[i].lastNotifyDate, currentGameDate);
                        if (nbrMonthsSinceNotify > 12){
                            let iconName = featureName + "Reminder";
                            iconTable = createIconTableObj(iconName, iconTable);
                            unlockedFeatures[i].lastNotifyDate = currentGameDate;
                            updateUnlockedFeatures = true;
                        }
                }
            }
        }
    }

    if (noMoneyMgmtTest === true){
        const iconName = "takeMoneyMgmtTest";
        iconTable = createIconTableObj(iconName, iconTable);
    }

    let additionalInfo = "";
    if (notifyArr.length != 0){
        for (let i=0; i<notifyArr.length; i++){
            if (notifyArr[i].additionalInfo != undefined){
                additionalInfo = notifyArr[i].additionalInfo;
            }
            iconTable = createIconTableObj(notifyArr[i].type, iconTable, 
                additionalInfo);
        }
    }
    let returnObj = {iconTable: iconTable, updateUnlockedFeatures: updateUnlockedFeatures, 
        unlockedFeatures: unlockedFeatures};

    return returnObj
 
}





