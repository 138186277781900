import React from "react";
import { withRouter } from "react-router";
import "./GameViews/gameViews.css";
import "../special/Special/special.css";
//  Datasets
import ConfigFile from "../../datasets/config_file";
import EventCategoryList from "./datasets/event_category_list";
import Config from '../../config';

//  Components
import CashMoodStatusBox from "./cash_mood_status_box";
import FriendDialogDisplay from "./friend_dialog_display";
import DisplayPurchasesModal from "./display_purchases_modal";
import GameBoardImageDisplay from "./game_board_image_display";
import GameCardFaceDown from "./game_card_face_down";
import GameCardFaceUp from "./game_card_face_up";
import GameSpinner from "./game_spinner";
import IconDisplayCard from "./icon_display_card";
import NotificationModal from "./notification_modal";
import SpinnerLegend from "./spinner_legend";
import TimelineHorizontal from "./timelineHorizontal";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";

// functions
import { applyToCreditCard } from "./objects/apply_to_credit_card";
import {buildUrl } from '../../objects/CommonUse/build_URL';
import { calcInflationAmt } from "../../objects/CommonUse/calc_inflation_amt";
import { calcMonthlyIncome } from "../../objects/CommonUse/calcMonthlyIncome";
import { calcNbrFriendsMet } from "./objects/calc_nbr_friends_met";
import { calculateMoodRetirementScores } from "./objects/calculate_mood_retirement_scores";
import { calculateResponseAmount } from './objects/calculate_response_amount';
import { calcTaxAmt } from '../../objects/AssetsExpenses/taxAmtCalc_function';
import { checkIfCreditCard } from "./objects/check_if_credit_card";
import { checkForNewUnlockedFeatures } from "./objects/check_for_new_unlocked_features";
import { checkNotifications } from "./objects/check_notifications";
import { checkIconTableEntries } from "./objects/check_iconTable_entries";
import { createIconTableObj } from "./objects/create_icon_table_obj";
import { createTaxAmtExpense } from "../../objects/AssetsExpenses/create_tax_amt_expense";
import { dateStrToPlainText, cashStrMask } from "./objects/game_view_mask_functions";
import { determineIfTimeToMeetNewFriend } from "./objects/determine_if_time_to_meet_new_friend";
import { endOfMonthSpendHistory } from "./objects/end_of_month_spend_history";
import { findRemoveTableItem } from "../../objects/AssetsExpenses/find_remove_table_item";
import { getCurrentDateTime } from "../../objects/CommonUse/get_current_date_time";
import { lifestyleCard } from "./objects/lifestyle_card";
import { monthlyLoanAmt } from "../../objects/AssetsExpenses/loanAmtCalc_function";
import { moveFromCashToSavings } from "./objects/move_from_cash_to_savings";
import { payMonthlyCreditCardAmount } from "./objects/pay_monthly_credit_card_amount";
import { processConditionsEndOfTurn } from "./objects/process_conditions_end_of_turn";
import { processEventChangesToConditionsTable } from "./objects/process_event_changes_to_conditions_table";
import { processPendingJob } from "./objects/process_pending_job"
import { processTickForRecurringGameDataObjects } from "./objects/process_tick_for_recurring_gamedata_objects";
import { removeInvalidIcons } from "./objects/remove_invalid_icons";
import { rollForwardCash } from './objects/roll_forward_cash_function';
import { rollForwardDate } from "./objects/roll_forward_date_function";
import { setEventTrackerObj } from "../../objects/CommonUse/set_event_tracker_obj";
import { setHeadersForFetch } from "../../objects/CommonUse/set_headers_for_fetch";
import { setNbrMonthsNegative } from "./objects/set_nbr_months_negative";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import { shuffleArray } from '../../objects/CommonUse/shuffleArray';
import { updateRecurringGameDataObjects } from "./objects/update_recurring_gamedata_objects";

// objects
import buildBadgeCard from "./objects/build_badge_card";
import buildCardFromDialog from "./objects/build_card_from_dialog";
import buildJokeCard from "./objects/build_joke_card";
import calcRandomNumberWithMax from "../../objects/CommonUse/calc_random_number_with_max";
import determineDecorationImageURL from "./objects/determine_decoration_image_URL";
import determineImageURL from "./objects/determine_image_URL";
import determineWhichFriendToDisplay from "./objects/determine_which_friend_to_display";
import replaceTextInString from "../../objects/CommonUse/replace_text_in_string";
import retrieveGameDialog from "./objects/retrieve_game_dialog";
import updateGamePurchaseArr from "./objects/update_game_purchase_arr";
import lastCardDataset from "./datasets/last_card_dataset";

class GameBoard extends React.Component {
    gameInfo = null;
    _isMounted = false;
    constructor(props) {
        super(props)
        this.gameInfo = this.props.gameInfo();
        props.onOpen();
        this.trainVideoDone = this.trainVideoDone.bind(this);
        this.state = {
            siteURL: ConfigFile.general.siteURL,
            nextTurn: false,
            lastCard: false,
            chargeItemToCreditCard: false,
            openWarningModal: false,
            openNotificationModal: false,
            cardDisabled: false,
            eventLogging: ConfigFile.gameBoard.eventLogging,
            loggingMax: ConfigFile.gameBoard.loggingMax,
            headers: setHeadersForFetch(),
            dateTime: getCurrentDateTime(),
            notificationArr: [],
            cardCount: 0,
            iconTableProcessed: false,
            dialogImgURL: "",
            friendDisplayType: "",
            nbrFriendsMet: 0,
            friendImgUrl: "",
            count: 0,
            spinnerDisabled: false,
            eventCategories: EventCategoryList,
            displayLegend: true,
            nextEventNbr: '',
            jokeCardBeginMonth: +this.props.totalNbrGameMonths - 5,
            moodPointMultiplier: +ConfigFile.gameBoard.moodPointMultiplier,
            gameDataLoaded: false,
            relatedDataLoaded: false,
            friendIndxList: [],
            funStuffObj: {},
            totalGameMonths: this.props.totalNbrGameMonths,
            timeout: 0,
            timeout2: 0,
            timeout3: 0,
            timeout4: 0,
            clickImageStartSw: false,
            validCardTypes: ["card", "badge", "joke"],
            subProcess: "GameBoard",
            environment: Config.dataURL.currentEnv,
            subProcess: "gameView",
            displayPurchases: false,
        }
        this.purchasesThisTurnArr = [];
        this.eventCount = 0;
        this.eventArr = [];
    }
     
    // Lifecycle events
    render() {
        this.purchasesExist = false;
        if (this.gameInfo.gamePurchases.purchaseTable != undefined && 
            this.gameInfo.gamePurchases.purchaseTable.length > 0){
                this.purchasesExist = true;
        }

        if (this.gameInfo.loading == false) {
            this.icons = [];
            let activeIconArr = [];
            for (let i = 0; i < this.gameInfo.gameData.iconTable.length; i++) {
                let iconObj = this.gameInfo.gameData.iconTable[i];
                if (iconObj.validInd == true) {
                    activeIconArr.push(iconObj);
                }
            }
            this.icons = activeIconArr.map((iconInfo, key) =>
                <IconDisplayCard key={key} iconList={iconInfo}
                    onClick={(iconName) => this.openNotificationModal(iconName)} />
            );
        }

        //  the first couple of times this is executed before GameChoicePictures but it works eventually
        this.backgroundURL = './assets/homes/bg_photo.jpg';
        if (this.gameInfo.pictureInfo.livingRoomImg != undefined) {
            if (this.gameInfo.backgroundURL == undefined ||
                this.gameInfo.backgroundURL == "") {
                console.log("resetting backgroundURL to living room")
                this.backgroundURL = this.gameInfo.pictureInfo.livingRoomImg;
            } else {
                this.backgroundURL = this.gameInfo.backgroundURL;
            }
        }
        if (this.processAsCard === true && this.gameInfo.currentCard.title != undefined) {
            this.state.spinnerDisabled = true;
        };
         
        this.setScreenSizeParms();
         
        let cardList = [];
        console.log("gamePhase: ", this.gameInfo.gamePhase, 
            " cardList: ", this.gameInfo.cardList);
        if (this.gameInfo.gamePhase == "1" && this.gameInfo.cardList.length > 0 ) {
                cardList = this.gameInfo.cardList.map((key, index, card) =>
                    <GameCardFaceDown key={index} cardDetails={card} index={index}
                        nbrCards={this.gameInfo.cardList.length}
                        cardDisabled={this.state.cardDisabled}
                        screenSize={this.props.screenSize}
                        onClick={(_id) => this.processNextCard(_id)} 
                        lastCard={(positionObj) => this.saveLastCardPosition(positionObj)}
                    />
                );
                this.cardsReady = true;
        };
        if (this.gameInfo.loading === true || this.gameInfo.pictureInfo.livingRoomImg === undefined ||
            this.state.nextTurn === true ||  (this.gameInfo.gamePhase === "1" && this.cardsReady === false)){
                this.waitTimer = true;
        }else{
                this.waitTimer = false;
        }
        console.log("cardInProcess is: ", this.gameInfo.cardInProcess,
            " currentCardType: ", this.gameInfo.currentCard.cardType, " cardTypeValid? ",
            this.state.validCardTypes.includes(this.gameInfo.currentCard.cardType));     
                  
        return <>
           <div className={this.className}>
                <div className={this.gameBoardClass}
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL})` }}>

                    {this.waitTimer  ?
                        <span className="game-board-rectangle">
                        <h3 className="mt-2 text-center ml-5 game-board-title-background">Game Board</h3>
                            {this.state.nextTurn ?
                                <div className="trainPosition">
                                    <img src="./assets/Events/MoneyTrainReverse.png"
                                        onAnimationEnd={this.trainVideoDone}
                                        className="money-train-info move-train-on-track" alt="moneyTrain" />
                                    <img src="./assets/Events/TrainMovingBackground.jpg"
                                        className="train-end-of-turn" alt="trainStationTrack" />
                                    <div className="decorative begin-month-text text-white 
                                        font-weight-bold">{this.prevMonth}</div>
                                    <div className="decoratve end-month-text text-white 
                                        font-weight-bold">{this.currentMonth}</div>
                                    </div>
                            :        
                                    <WaitTimeSpinner />
                            }
                        </span>
                    :
                        <span>

                            <h3 className="mt-2 text-center ml-5 game-board-title-background">Game Board</h3>

                            <TimelineHorizontal
                                totalNbrGameMonths={this.props.totalNbrGameMonths}
                                gameNbrMonths={this.gameInfo.gameData.gameNbrMonths}
                                monthsPerTurn={this.monthsPerTurn}
                                screenSize={this.props.screenSize}
                                newMonth={this.nextTurn}
                            />
                            <img className="game-view-icons" src="./assets/Icons/largeRectangle.jpg" 
                                    alt="iconSpace"
                                />
                            <div className="text-center icon-text">
                                Click Picture to Learn More
                            </div>
                            <div className="ml-3">
                                 {this.icons}
                            </div>
                    
                            <div className="game-view-info py-2 px-3 font-weight-bold">
                                <CashMoodStatusBox
                                    gameDate={this.gameInfo.gameData.currentGameDate}
                                    currentCash={this.gameInfo.gameData.currentCash}
                                    currentMoodPoints={this.gameInfo.gameData.currentMoodPoints}
                                    moodEmoji={this.props.moodEmoji}
                               />
                              
                            </div>
                            {this.purchasesExist ?
                                <div>
                                    <img className="treasure-chest-picture" 
                                        src="./assets/treasureChest.png" alt="treasureChest"
                                        onClick ={()=> this.displayPurchases("open")}
                                    />
                                </div>
                            :
                                null
                            }

                            {this.gameInfo.gamePhase == "1" ?
                                <span>
                                    {cardList}
                                </span>
                            :
                                null
                            }

                            {this.state.clickImageStartSw  ?
                                <div  style={{position: "absolute", right: this.right, bottom: this.bottom}}>
                                    <img src="./assets/Icons/clickImg.jpg" 
                                        className="click-image-icon"
                                        alt="clickImageIcon" />
                                </div>
            
                            :
                                null
                            }

                            {this.state.openNotificationModal ?
                                <NotificationModal show={this.state.openNotificationModal}
                                    notifyInfo={this.state.notificationArr[0]}
                                    gameInfo={() => this.props.gameInfo()}
                                    onClose={() => this.closeNotificationModal()} />
                                :
                                null
                            }

                            {this.gameInfo.gamePhase == "2" && !this.gameInfo.loading ?
                                <span>
                                    <GameSpinner
                                        spinnerDisabled={this.state.spinnerDisabled}
                                        nextEventNbr={this.state.nextEventNbr}
                                        onSpin={() => this.nextSpin()}
                                    />
                                    <SpinnerLegend
                                        nbrSpins={this.gameInfo.cardList.length} 
                                        eventCategories={this.state.eventCategories}
                                        jobName={this.gameInfo.pictureInfo.jobName}
                                        screenSize={this.props.screenSize}
                                    />
                                </span>
                                :
                                null
                            }

                            {this.gameInfo.cardInProcess === true &&
                             this.gameInfo.currentCard.cardType !== undefined &&
                                (this.state.validCardTypes.includes(this.gameInfo.currentCard.cardType) ||
                                    this.gameInfo.currentCard.cardType === "Activity") ?
                                <div>
                                    <GameCardFaceUp currentCard={this.gameInfo.currentCard}
                                        screenSize={this.props.screenSize}
                                        itemName={this.gameInfo.cardImageObj.itemName}
                                        onLastCard={(selectedType, activity) => this.lastCardDisplayed(selectedType, activity)}
                                        onComplete={(selectedInd) => this.resolveDisplayedCard(selectedInd)}
                                        calcResponseValue={(response) => this.calculateResponseValue(response)}
                                        onResultDisplay={() => this.onResultDisplay()} />
                                </div>
                                :
                                null
                            }

                            {this.gameInfo.cardImageObj.cardImage ?
                                <GameBoardImageDisplay
                                    imageName={this.gameInfo.cardImageObj.imageName}
                                    imageNameClass={this.gameInfo.cardImageObj.imageNamePosition}
                                    imageClass={this.gameInfo.cardImageObj.imagePosition}
                                    pictureSrc={this.gameInfo.cardImageObj.cardImageURL}
                                    imageClass2={this.gameInfo.cardImageObj.imageClass}
                                />
                            :
                                null
                            }

                            {this.gameInfo.cardInProcess === true &&
                             this.gameInfo.currentCard.cardType !== undefined &&
                                this.gameInfo.currentCard.cardType === "dialog" ?
                                <FriendDialogDisplay
                                    screenSize={this.props.screenSize}
                                    currentCard={this.gameInfo.currentCard}
                                    calcResponseValue={(response) => this.calculateResponseValue(response)}
                                    onResultDisplay={() => this.onResultDisplay()}
                                    onComplete={(selectedInd) => this.resolveDisplayedCard(selectedInd)}
                                />
                                :
                                null
                            }

                            {this.holidayDecoration ?
                                <GameBoardImageDisplay
                                    imageName={this.holidayObj.imageName}
                                    imageNameClass={""}
                                    imageClass={this.holidayObj.imagePosition}
                                    pictureSrc={this.holidayObj.imageURL}
                                    imageClass2={this.holidayObj.imageClass}
                                />
                            :
                                null
                            }

                            {this.state.displayPurchases   ?
                                <DisplayPurchasesModal
                                    show={this.state.displayPurchases}
                                    purchaseTable={this.purchaseTable}
                                    onClose={() => this.displayPurchases("close")}
                                />
                            :
                                null
                            }

                        </span>
                    }
                </div>
            </div>
        </>
    }

    componentDidMount() {
        this.activitySelected = false;
        this.turnCount = +(sessionStorage.getItem("TurnCount"));
        console.log("componentDidMount turnCount is: ", this.turnCount);
        if (this.turnCount === null || this.turnCount === 0){
            this.turnCount = 1;
        }
        if (this.gameInfo.timeWizardInfo.gameID === undefined || 
            this.gameInfo.timeWizardInfo.status === "inactive"){
                this.monthsPerTurn = 1;
        }else{
                this.monthsPerTurn = +this.gameInfo.timeWizardInfo.monthsPerTurn;
        }
        console.log("componentDidMount monthsPerTurn: ", this.monthsPerTurn);
        this.determineIfBadgeMonth();
        this.specialCardCount = 1;
        //  this happens before the gamePictureInfo storage is created by GameChoicePictures
        this.determineIfEndGame();
        this.setGamePhase("onMount"); 
        //  cardListObj is required if individual has left GameView
        const cardListObj = JSON.parse(sessionStorage.getItem("gameCardList"));
        sessionStorage.removeItem("gameCardList");
        this.cardsReady = false;
        if (cardListObj !== null){
            this.gameInfo.cardList = cardListObj.cardList;
            this.gameInfo.cardInProcess = cardListObj.cardInProcess;
            if (this.gameInfo.cardInProcess === true){
                this.gameInfo.currentCard = this.gameInfo.cardList.pop();
            }
            this.gameInfo.cardImageObj = cardListObj.cardImgObj;
            this.gameInfo.backgroundURL = cardListObj.backgroundURL;
            this.props.setGameInfo(this.gameInfo.cardList, "cardList");
            this.props.setGameInfo(this.gameInfo.currentCard, "currentCard");
            this.props.setGameInfo(this.gameInfo.cardImageObj, "cardImageObj");
            this.props.setGameInfo(this.gameInfo.backgroundURL, "backgroundURL");
        }
        if (this.gameInfo.gamePhase === "1") {
            if (this.gameInfo.cardList == undefined || this.gameInfo.cardList.length == 0 ){
                if (this.gameInfo.currentCard.title == undefined){
                    this.getNextEvents();
                }else{
                    this.cardsReady = true;
                }
            }else{
                if (this.gameInfo.cardList.length > 1){
                    this.checkCardList();
                    //   the following triggers the click finger on the card hand
                    if(this.state.timeout) clearTimeout(this.state.timeout);
                        this.state.timeout = setTimeout(() => {
                        this.setState({clickImageStartSw: true});
                        }, 7000);
                }
            }
            this.props.setGameInfo(false, 'loading');
        } else {
            this.determineNextSpinnerEvent();
            this.props.setGameInfo(false, 'loading');
        }
        if (this.gameInfo.timeWizardInfo.gameID === undefined ||
            this.gameInfo.timeWizardInfo.status === "inactive"){
            this.rollForwardMonths = 1;
        }else{
            this.rollForwardMonths = +this.gameInfo.timeWizardInfo.monthsPerTurn;
        }
      
        if (this.props.location.state != undefined &&
            this.props.location.state.detail == "returning-from-activity"){
               this.setState({nextTurn: true});
               let gameMonthObj = JSON.parse(sessionStorage.getItem("gameBeforeAfterMonth"));
               this.prevMonth = gameMonthObj.prevMonth;
               this.currentMonth = gameMonthObj.currentMonth;
        }else{
            let currentMonthDate = dateStrToPlainText(this.gameInfo.gameData.currentGameDate);
            let currentMonthDateArr = currentMonthDate.split(" ");
            this.currentMonth = currentMonthDateArr[0];
        }
       
        this.createNotifications("update");
        if (this.state.notificationArr.length > 0) {
            this.openNotificationModal();
        }

        // this code is used to get the funStuff item image name
        this.backgroundName = "home";
        if (this.gameInfo.cardImageObj.cardImage === undefined){
            this.buildCardImageObj();
        }
        this.checkForHolidayDecorations();
    }

    checkCardList(){
        //  check all cards in the cardlist to ensure that the condition is still valid, 
        //  if not substitute card with a joke card.
        let newCardList = [];
        for (let i=0; i<this.gameInfo.cardList.length; i++){
            let card = this.gameInfo.cardList[i];
            let allConditions = true;
            if (card.conditionArr == undefined){
                // no conditions so card s/b selected
            }else{
                for (let j=0; j<card.conditionArr.length; j++){
                    let condition = card.conditionArr[j];
                    const conditionCheck = 
                            this.gameInfo.gameData.conditionsTable.find(elem => elem.condName === condition);
                    if (conditionCheck == undefined){
                        //  condition for card is no longer valid for this game
                        allConditions = false;
                        break;
                    }
                }   
            }             
            if (allConditions == true){
                newCardList.push(card);
            }else{
                let jokeCard = buildJokeCard(this.gameInfo.gamePhase);
                newCardList.push(jokeCard);
            }
        }
        this.gameInfo.cardList = JSON.parse(JSON.stringify(newCardList));
        this.props.setGameInfo(this.gameInfo.cardList, 'cardList');
    }

    componentWillUnmount() {
        clearInterval(this.state.timeout);
        console.log("turnCount in unmount is: ", this.turnCount);
        sessionStorage.setItem("TurnCount", this.turnCount);
        //  this needs to done to save the last index for each friend!!!
        if (this.gameInfo.loading == false) {
            this.updateFriendList();
        }
        if (this.eventArr.length > 0 && this.state.eventLogging == true) {
            this.saveEventTracker();
            this.eventArr = [];
        }
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    // SECTION:  general processes

    setScreenSizeParms(){
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard", "mt-5");
        this.className = screenParms.columnClass;
        this.gameBoardClass = 'card mt-3 mr-3 rounded-corners-lg text-center ';
        switch (this.props.screenSize){
            case "narrow":
                this.gameBoardClass = this.gameBoardClass + "game-board-narrow";
                break;
            case "medium":
                this.gameBoardClass = this.gameBoardClass + "game-board-medium";
                break;
            default:
                this.gameBoardClass = this.gameBoardClass + "game-board"
                break;
        }
    }

    saveLastCardPosition(positionObj){
        this.right = +positionObj.right  - 205 + "px";
        this.bottom = +positionObj.bottom  + 3 + "px";
    }

    trainVideoDone(){
        this.setState({nextTurn: false});
    }
   
    determineIfEndGame() {
        let endGameReason = "";
        if (this.gameInfo.gameData.nbrMonthsNegative >= ConfigFile.gameBoard.nbrMonthsNegative) {
            endGameReason = "NegativeCash";
        }
        if (this.gameInfo.gameData.nbrMonthsMoodNegative >= ConfigFile.gameBoard.nbrMonthsMoodNegative) {
            endGameReason = "NegativeMoodPoints";
        }
        if (this.gameInfo.gameData.gameNbrMonths <= 0) {
            endGameReason = "TimeElapsed";
        }
        if (endGameReason != "") {
            this.props.onEnd(endGameReason);
        }
    }

    setGamePhase(calledBy) {
        let changeGamePhase = false;
        let gameMonth = this.props.totalNbrGameMonths - +this.gameInfo.gameData.gameNbrMonths;
        if (calledBy === "lastCard"){
           gameMonth = +gameMonth - this.monthsPerTurn;
        }
        // gamePhase is always spaces onMount
        if (this.gameInfo.gamePhase === "") {
            if (gameMonth < 60) {
                this.gameInfo.gamePhase = "1";
            } else {
                this.gameInfo.gamePhase = "2";
                //  if title is not undefined, then the hand is 'cards' instead of numbers
                //  and needs to be cleared before continuing
                if (this.gameInfo.gameData.hand[0].title !== undefined){
                    changeGamePhase = true;
                }
            }
        } else {
            if (this.gameInfo.gamePhase === "1" && gameMonth >= 60) {
                changeGamePhase = true;
                this.gameInfo.gamePhase = "2";
            }
        }
        if (changeGamePhase === true) {
            this.props.setGameInfo(this.gameInfo.gamePhase, "gamePhase");
            if (this.gameInfo.gamePhase === "2" &&
                this.gameInfo.gameData.iconTable[0].iconName === "gameBasicHelp") {
                    this.updateHelpIcon = true;
                }
            this.gameInfo.gameData.hand.splice(0);
            this.props.setGameInfo(this.gameInfo.gameData, "gameData");
        }
    }

    createNotifications(updateInd) {
        this.gameInfo.gameData.iconTable = removeInvalidIcons(this.gameInfo.gameData.iconTable);
        let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable, this.gameInfo.gameData.expenseTable,
            this.gameInfo.gameData.loanTable);
        let monthlyIncome = incomeObj.income;
        let cashAmt = this.gameInfo.gameData.currentCash;
        //  parse and stringify creates a new copy of the iconTable (instead of using same dataSpace)
        let iconTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.iconTable));
        //  the following just retrieves the rent expense and does not remove it (last parm is false)
        let rentExpenseObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, "rent", false);
        let returnObjs = checkIconTableEntries(iconTable, cashAmt, this.gameInfo.savingsInfo,
            monthlyIncome, this.gameInfo.gameData.currentMoodPoints, this.gameInfo.gameData.pendingJob,
            this.gameInfo.creditCardInfo, this.gameInfo.gameData.conditionsTable,
            this.gameInfo.gameData.gameNbrMonths, this.gameInfo.gameData.unlockedFeatures,
            this.gameInfo.gameData.currentGameDate, rentExpenseObj.expAmount, 
            this.props.noMoneyMgmtTest, this.props.totalNbrGameMonths);
        this.gameInfo.gameData.iconTable = returnObjs.iconTable;

        if (this.updateHelpIcon === true) {
            this.gameInfo.gameData.iconTable[0].iconName = "gameBasicHelp2";
            this.gameInfo.gameData.iconTable[0].firstTime = true;
            this.gameInfo.gameData.iconTable[0].dateAdded = this.state.dateTime.date;
            this.updateHelpIcon = false;
        }
        if (updateInd == "update") {
            if (returnObjs.updateUnlockedFeatures == true) {
                this.gameInfo.gameData.unlockedFeatures = returnObjs.unlockedFeatures;
                this.updateUnlockedFeatures();
                this.updateIconTable();
            } else {
                this.updateIconTable();
            }
            this.props.setGameInfo(this.gameInfo.gameData, "gameData");
            this.state.iconsProcessed = false;
        }
        this.state.notificationArr = checkNotifications(this.gameInfo.gameData.iconTable);
       
    }

    openNotificationModal(iconName) {
        if (iconName != undefined) {
            this.state.notificationArr = [];
            for (let i = 0; i < this.gameInfo.gameData.iconTable.length; i++) {
                let iconTable = this.gameInfo.gameData.iconTable[i];
                if (iconTable.iconName == iconName) {
                    let notificationObj = {
                        category: iconTable.iconCategory,
                        type: iconTable.iconName, additionalInfo: iconTable.additionalInfo,
                        imageSrc: iconTable.imageSrc
                    };
                    this.state.notificationArr.push(notificationObj);
                    break;
                }
            }
        }
        this.state.openNotificationModal = true;
    }

    closeNotificationModal() {
        this.state.notificationArr.splice(0, 1);
        if (this.state.notificationArr.length == 0) {
            this.state.openNotificationModal = false;
        } else {
            this.state.openNotificationModal = true;
        }
    }

    displayPurchases(openClose){
        if (openClose === "open"){
            this.addTotalsLineToPurchaseTable();
            this.setState({displayPurchases: true});
        }else{
            this.setState({displayPurchases: false});
        }
    }

    addTotalsLineToPurchaseTable(){
        this.purchaseTable = JSON.parse(JSON.stringify(this.gameInfo.gamePurchases.purchaseTable));
        this.totalItemCost = 0;
        for (let i=0; i<this.purchaseTable.length; i++){
              let purchase = this.purchaseTable[i];
              this.totalItemCost += +purchase.itemCost;
        }
        const purchaseObj = {imageURL: "dollarSign.png", itemCost: this.totalItemCost, itemName: "Total Cost of Purchases"};
        this.purchaseTable.push(purchaseObj);
    }

    processNextCard() {
        clearInterval(this.state.timeout);
        this.setState({clickImageStartSw: false});
        if (this.state.cardDisabled == false) {
            if (this.gameInfo.cardList.length === 1 &&
                this.gameInfo.currentCard.cardType === "Activity")
            {
                this.checkForInSchoolCondition();
            }
            this.setState({cardDisabled: true});
            this.displayNextCard();
        }
    }

    nextSpin() {
        this.state.spinnerDisabled = true;
        this.gameInfo.cardInProcess = true;
        this.props.setGameInfo(this.gameInfo.cardInProcess, "cardInProcess");
        this.setCurrentCard();
        if (this.state.nextEventNbr == 8) {
            if (this.gameInfo.currentCard.lastCardButtons != undefined
                && this.gameInfo.currentCard.lastCardButtons[0].type == "Activity") {
                this.checkForInSchoolCondition();
            }
        }
        const eventListObj = this.state.eventCategories.find(elem => elem.number === +this.state.nextEventNbr);
        if (this.nextCard !== undefined && this.nextCard.eventCategory !== undefined &&
            eventListObj !== undefined &&
            this.nextCard.eventCategory === eventListObj.category){
                this.props.setGameInfo(this.nextCard, "currentCard");
                this.displayNextCard();
        }else{
                // this adds time to wait for the getNextSpinnerItem to return with the next event
                if (this.state.timeout4) clearTimeout(this.state.timeout4);
                    this.state.timeout4 = setTimeout(() => {
                        this.props.setGameInfo(this.nextCard, "currentCard");
                        this.displayNextCard();
                        }, 500);
        }
    }

    displayNextCard() {
        if (this.gameInfo.gamePhase === "1") {
            this.state.cardCount += 1;
            if (this.gameInfo.cardList.length === 1){
                this.lastCard = this.gameInfo.cardList[0];
            }
            this.gameInfo.currentCard = this.gameInfo.cardList.pop();
            this.props.setGameInfo(this.gameInfo.currentCard, 'currentCard');
            this.gameInfo.cardInProcess = true;
            this.props.setGameInfo(this.gameInfo.cardInProcess, "cardInProcess");
            if (this.gameInfo.currentCard.cardType == "dialog") {
                this.writeFriendIndexToGeneral();
                this.getFriendURL();
            }
            this.props.setGameInfo(this.gameInfo.cardList, 'cardList');
        } else {
            if (this.state.nextEventNbr === 4) {
                this.getFriendURL();
                this.writeFriendIndexToGeneral();
            }
        }
        let cardType = "card";
        if (this.gameInfo.currentCard.cardType === undefined){
            console.log("!!!!  card type on this card is undefined - title: ", 
                this.gameInfo.currentCard.title);
        }else{
               cardType = this.gameInfo.currentCard.cardType;
        }
        this.getCardImage();
    }

    writeFriendIndexToGeneral() {
        let friendIndxArr = [];
        for (let i = 0; i < this.gameInfo.gameData.friendList.length; i++) {
            let friendObj = "friendName: " + this.gameInfo.gameData.friendList[i].name +
                " - index: " + this.gameInfo.gameData.friendList[i].dialogIndx;
            friendIndxArr.push(friendObj);
        }
        let friendIndxItem = friendIndxArr.join("|");
        this.state.friendIndxList.push(friendIndxItem);
    }

    getFriendURL() {
        let playerFriendInfo = this.gameInfo.friendInfo;
        const playerFriend = 
            playerFriendInfo.find(elem => elem.friendName === this.gameInfo.currentCard.friendName);
        this.gameInfo.currentCard.friendImgURL = playerFriend.friendImgURL;
    }

    checkForInSchoolCondition() {
        let noActivityOptions = false;
        const conditionObj = this.gameInfo.gameData.conditionsTable.find(elem => elem.class === "college");
        if (conditionObj !== undefined){
            noActivityOptions = true;
        }
        if (noActivityOptions == true) {
            let descriptionText =
                "You are in college and have an upcoming exam or term paper " +
                "that is due.  You will not be able to do any extra activities " +
                "at this time.";
            let lastCardButtons = [{ type: "Regular", btnText: "Continue the Game" }];

            if (this.gameInfo.gamePhase == "1") {
                this.gameInfo.cardList[0].description = descriptionText;
                this.gameInfo.cardList[0].lastCardButtons = lastCardButtons;

            } else {
                this.gameInfo.currentCard.description = descriptionText;
                this.gameInfo.currentCard.lastCardButtons = lastCardButtons;
            }
        }
    }

    setCurrentCard() {
        let index = this.gameInfo.cardList.length - 1;
        this.gameInfo.cardList.splice(index, 1);
        this.props.setGameInfo(this.gameInfo.cardList, "cardList");
        switch (this.state.nextEventNbr) {
            case 4:
                this.selectAFriend();
                break;
            case 6:
                let jokeCard = buildJokeCard(...this.gameInfo.gamePhase);
                this.nextCard = { ...jokeCard, cardImage: false };
                break;
            case 8:
                this.determineLastCardInHand();
                break;
            default:
                this.state.spinnerDisabled = true;
                if (this.badgeMonth == true && this.state.nextEventNbr == 7){
                       this.createBadgeCard();
                       this.setState({ spinnerDisabled: this.state.spinnerDisabled });
                }else{
                    //  read of event was done previously
                    //  value of card is in this.nextCard
                }
                break;
        }
    }

    setNextSpinnerCardList(){
       
        if (this.gameInfo.cardList == undefined || this.gameInfo.cardList.length == 0) {
            if (this.gameInfo.cardList.length == 0){
                this.setState({nextTurn: true});
            }
            let nbrCards = calcRandomNumberWithMax(3, false);
            nbrCards = +nbrCards + 3;
            let nbrArray = [1, 2, 3, 5, 6, 7];
            let randomArr = shuffleArray(nbrArray);
            let eventNbrArr = [8];
            for (let i = 0; i < nbrCards; i++) {
                eventNbrArr.push(randomArr[i]);
            }
            if (this.badgeMonth == true){
                // add badge event ("7") to the array
                if (eventNbrArr.includes(7)){
                   //  no action required, 7 is already in the event array  
                }else{
                   let index = eventNbrArr.length - 2; 
                   eventNbrArr.splice(index, 0, 7);  
                }
            }
            // adding friend event ("4") to the array in a random place
            let randomNbr = calcRandomNumberWithMax(nbrCards, false);
            if (randomNbr == 0) {
                randomNbr = 1;
            }
            eventNbrArr.splice(randomNbr, 0, 4);
            this.gameInfo.cardList = eventNbrArr;
            this.props.setGameInfo(this.gameInfo.cardList, "cardList");
            this.gameInfo.gameData.hand = this.gameInfo.cardList;
        }
    }

    determineNextSpinnerEvent(){
        if (this.gameInfo.cardList == undefined ||
            this.gameInfo.cardList.length <= 0) {
                this.setNextSpinnerCardList();
        }
        let eventIdx = this.gameInfo.cardList.length - 1;
        this.state.nextEventNbr = +this.gameInfo.cardList[eventIdx];
        if (this.state.nextEventNbr === "8"){
            // skip this event as it is end of turn event
        }else{
            this.getNextSpinnerItem();
        }
        this.state.spinnerDisabled = false;
        if (this.state.nextTurn == true){
            // the following will force process to do a render
            this.setState({nextTurn: true});
        }
    }

    getCardImage() {
        let backgroundName = "";
        switch (this.gameInfo.currentCard.backgroundType) {
            case "home":
                backgroundName = "livingRoomImg";
                this.checkForHolidayDecorations();
                break;
            case "work":
                backgroundName = "jobBackground";
                this.holidayDecoration = false;
                this.gameInfo.cardImageObj.cardImage = false;
                break;
            default:
                backgroundName = this.gameInfo.currentCard.backgroundType;
                this.holidayDecoration = false;
                break;
        }
        // the following sets the value of backgroundURL to the variableName label in the pictureInfo
        this.gameInfo.backgroundURL = this.gameInfo.pictureInfo[backgroundName];
        this.props.setGameInfo(this.gameInfo.backgroundURL, "backgroundURL");
        if (this.gameInfo.currentCard.description.includes("||level2Job") ||
            this.gameInfo.currentCard.description.includes("||level3Job")) {
                this.insertJobLevelTextInCard();
        }
        this.backgroundName = this.gameInfo.currentCard.backgroundType;
        this.buildCardImageObj();
        
    }

    buildCardImageObj(){
        let mainDirectory = "";
        if (this.gameInfo.currentCard.imageDir != undefined && this.gameInfo.currentCard.imageDir.length > 0){ 
            const imageDirArr = this.gameInfo.currentCard.imageDir.split('-');
            mainDirectory = imageDirArr[0];
        }
        if (mainDirectory === "friends"){
            this.gameInfo.cardImageObj = determineWhichFriendToDisplay(this.gameInfo.gameData.friendList,
                this.gameInfo.friendInfo, this.gameInfo.currentCard, this.gameInfo.gameData.currentGameDate,
                this.props.screenSize, this.gameInfo.gamePurchases.purchaseTable);
            this.gameInfo.currentCard = {...this.gameInfo.currentCard, 
                friendName: this.gameInfo.cardImageObj.friendName};
        }else{
            this.gameInfo.cardImageObj = determineImageURL(this.gameInfo.currentCard, 
            this.gameInfo.funStuffInfo.itemArr, this.backgroundName, this.props.screenSize, 
            this.gameInfo.gamePurchases.purchaseTable);
        }
        this.props.setGameInfo(this.gameInfo.cardImageObj, "cardImageObj");
    }

    insertJobLevelTextInCard() {
        let searchString = "";
        let stringData = "";
        if (this.gameInfo.currentCard.description.includes("||level2Job")) {
            searchString = "level2Job";
            stringData = this.gameInfo.pictureInfo.jobLevel2;
        } else {
            searchString = "level3Job";
            stringData = this.gameInfo.pictureInfo.jobLevel3;
        }
        let replaceObj = { string: stringData };
        this.gameInfo.currentCard.description =
            replaceTextInString(this.gameInfo.currentCard.description, replaceObj, searchString, "string");
    }

    lastCardDisplayed(selectedType, selectedActivity) {
        this.prevMonth = "";
        let prevMonthDate = dateStrToPlainText(this.gameInfo.gameData.currentGameDate);
        let prevMonthDateArr = prevMonthDate.split(" ");
        this.turnCount += 1;
        this.currentMonth = "";
        this.gameInfo.cardInProcess = false;
        this.props.setGameInfo(this.gameInfo.cardInProcess, "cardInProcess");
        this.updateEventArr("LastCard", -1);
        if (selectedType == "LifeStyle") {
            this.setLevelLivingExpenses(selectedActivity);
        }
        //  it looks like the gamePhase has to be set before the incrementDate is done....????
        this.setGamePhase("lastCard");
        //  this is working except...  when the event requires a fun-stuff display
        //  such as dog & name on the doghouse, the images do not go away on the 
        //  next turn.... looks sort of bad!
        this.incrementDate();
        this.props.setGameInfo(this.gameInfo.cardImageObj, "cardImageObj");
        // remove all remaining cards from cardList
        this.gameInfo.cardList.splice(0);
        //  the following is done to remove the last card from 'hand' of gamedata
        this.gameInfo.gameData.hand.splice(0);
        this.props.setGameInfo(this.gameInfo.gameData, "gameData");
        this.gameInfo.cardImageObj = determineImageURL("",this.gameInfo.funStuffInfo.itemArr, "home", 
            this.props.screenSize);
     
      
        
        if (this.gameInfo.gamePhase === "1") { 
            this.determineIfSpecialCardMonth();
            this.getNextEvents();
        } else {
            this.determineNextSpinnerEvent();
        }
        this.state.cardCount = 0;
        this.prevMonth = prevMonthDateArr[0];
        let currentMonthDate = dateStrToPlainText(this.gameInfo.gameData.currentGameDate);
        let currentMonthDateArr = currentMonthDate.split(" ");
        this.currentMonth = currentMonthDateArr[0];
        let gameMonthsObj = {prevMonth: this.prevMonth, currentMonth: this.currentMonth};
        //  the following storage is used when returning from an 'activity'
        sessionStorage.setItem("gameBeforeAfterMonth", JSON.stringify(gameMonthsObj));
        this.setState({ nextTurn: true });
        this.cardsReady = false;
        if (selectedType == "Activity") {
            // if game phase is 2, then last card in cardList must be cleared before 
            // going to activity...
            if (this.gameInfo.gamePhase == "2"){
                let index = this.gameInfo.cardList.length - 1;
                this.gameInfo.cardList.splice(index, 1);
                this.props.setGameInfo(this.gameInfo.cardList, "cardList");
            }
            this.activitySelected = true;
            this.props.history.push({
                pathname: '/select-activity',
                search: '?query=abc',
                state: { detail: selectedActivity, totalNbrGameMonths: this.props.totalNbrGameMonths }
            })
        }
    }
    
    determineIfSpecialCardMonth(){
        var remainder;
        var divisor;
        this.friendMonth = false;
        if (this.monthsPerTurn === 1 || this.monthsPerTurn === 2 ||
            this.monthsPerTurn === 3){
            // friend will occur every other turn
            divisor = 2;
            remainder = +this.turnCount % divisor;
            if (remainder == 0) {
                this.friendMonth = true;
                this.specialCardCount += 1;
            }
        }else{
            // friend will occur every turn
            this.friendMonth = true;
            this.specialCardCount += 1;
        }
           
        if (+this.turnCount < this.state.jokeCardBeginMonth) {
            let remainder = +this.turnCount % 4;
            // joke card every 4th turn
            if (remainder == 0) {
                this.jokeCard = true;
                this.specialCardCount += 1;
            }
        }
    }

    setLevelLivingExpenses(selectedActivity) {
        let baseLineExpenses = ConfigFile.gameBoard.baseLineExpenses;
        let annualBaseLine = baseLineExpenses * 12;
        let nbrMonthsPlayed = +this.state.totalGameMonths - +this.gameInfo.gameData.gameNbrMonths;
        let nbrYearsInGame = (nbrMonthsPlayed / 12).toFixed(0);
        let inflatedAnnualBaseLine = calcInflationAmt(annualBaseLine, nbrYearsInGame);
        let monthlyBaseLine = (inflatedAnnualBaseLine / 12).toFixed(2);
        let baselineMultiplier = 1;
        let additionalMoodPoints = 2;
        switch (selectedActivity) {
            case 4:
                baselineMultiplier = 1.5;
                additionalMoodPoints = 8;
                break;
            case 3:
                baselineMultiplier = 1.25;
                additionalMoodPoints = 4;
                break;
            case 2:
                baselineMultiplier = 1.00;
                additionalMoodPoints = 0;
                break;
            case 1:
                baselineMultiplier = .75;
                additionalMoodPoints = -4;
                break;
        }
        let expDesc = "Food, Clothes, Personal Items, etc."
        let estimatedMonthlyExp = monthlyBaseLine * baselineMultiplier;
        this.updateExpense("Misc Expenses", "flat", estimatedMonthlyExp, "-1", "", "", expDesc, true);
        // update mood points as a result of lifestyle choice
        this.updateMoodPoints(additionalMoodPoints);
        let lifeStyleEventObj = {
            category: "cash", on: "cash", type: "flat", duration: 1, value: 0,
            moodPoints: additionalMoodPoints
        };

        this.eventCount += 1;
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, lifeStyleEventObj,
            "LifeStyle Choice", this.eventCount);
        this.eventArr.push(eventObj);
    }

    resolveDisplayedCard(selectedInd) {
        // selectedInd is the number of the button that has been selected
        if (this.gameInfo.currentCard.cardType != undefined && 
            (this.state.validCardTypes.includes(this.gameInfo.currentCard.cardType) ||
             this.gameInfo.currentCard.cardType === "dialog")) {
            if (selectedInd != -1) {
                let response = this.gameInfo.currentCard.responses[selectedInd];
                this.responseValue = response.value;
                switch (response.category) {
                    case "cash":
                        if ((response.on && +response.value != 0) || (!isNaN(+response.value))) {
                            let response = this.gameInfo.currentCard.responses[selectedInd];
                            // if response value is 0, then it means the player decided not to buy the item
                            if (this.gameInfo.cardImageObj.purchaseItem === true &&
                                +response.value !== 0){
                                this.purchasedAnItemThisTurn = true;
                                this.gamePurchaseArr = updateGamePurchaseArr(this.gameInfo.cardImageObj.itemName, 
                                    this.gameInfo.currentCard.imageDir, response.value,
                                    this.gameInfo.gameData.currentGameDate, this.gameInfo.gamePurchases.purchaseTable,
                                );
                                response.itemDesc = "Purchased a " + this.gameInfo.cardImageObj.itemName;
                                this.updateGamePurchases();  
                                this.gameInfo.cardImageObj.purchaseItem = false;                             
                            }
                            this.determineObjectDesc(response.itemDesc, response.title, response.btnText);
                            this.applyCashOrCredit(response.value, this.objectDesc, response.on);
                        }
                        break;
                    case "asset":
                        this.updateAsset(response.on, response.type, response.value, response.duration,
                            response.btnText, response.itemDesc, this.gameInfo.currentCard.title, response.replaceAmtSw);

                        if (response.on == "Job Salary" &&
                            response.duration == "-1") {
                            this.checkIfMaxSalary();
                            let newTaxAmt = calcTaxAmt(this.gameInfo.gameData.assetTable);
                            let taxDesc = "Federal, FICA (Social Security)"
                            this.updateExpense("Payroll taxes", "flat", newTaxAmt, "-1", "", "", taxDesc, true);
                        }
                        break;
                    case "expense":
                        this.updateExpense(response.on, response.type, response.value, response.duration,
                            response.btnText, response.itemDesc, this.gameInfo.currentCard.title, response.replaceAmtSw);
                        break;
                    case "loan":
                        this.updateLoan(response.on, response.type, response.value, response.duration,
                            response.btnText, response.itemDesc, this.gameInfo.currentCard.Title, response.replaceAmtSw);
                        break;
                    default:
                        let errorDesc = "Event does not have a valid on-field value";
                        this.generalErrorLog(errorDesc);
                        break;
                }
                if (response.rltnshpScore != undefined) {
                    let friendName = this.gameInfo.currentCard.friendName;
                    for (let i = 0; i < this.gameInfo.gameData.friendList.length; i++) {
                        let friendEntry = this.gameInfo.gameData.friendList[i];
                        if (friendName == friendEntry.name) {
                            let newRltnshpScore = +this.gameInfo.gameData.friendList[i].rltnshpScore +
                                +response.rltnshpScore;
                            this.gameInfo.gameData.friendList[i].rltnshpScore = newRltnshpScore;
                            break;
                        }
                    }
                    this.updateFriendList();

                }
                if (response.moodPoints != undefined) {
                    // update mood points as a result of an event
                    this.updateMoodPoints(response.moodPoints);
                }
                // determine if process needs to update the conditionsTable
                let checkResponse = response;
                if ((checkResponse.triggerCondition == undefined || checkResponse.triggerCondition == "") &&
                    (checkResponse.deleteCondition == undefined || checkResponse.deleteCondition == "") &&
                    (checkResponse.deleteClass == undefined || checkResponse.deleteClass == "") &&
                     this.purchasedAnItemThisTurn === false) {
                    // no condition table update is required
                } else {
                    let imageDir = "";
                    if (this.purchasedAnItemThisTurn === true){
                        imageDir = this.gameInfo.currentCard.imageDir;
                        this.purchasedAnItemThisTurn = false;
                    }
                    this.gameInfo.gameData.conditionsTable =
                        processEventChangesToConditionsTable(this.gameInfo.gameData.conditionsTable,
                            checkResponse.triggerCondition, checkResponse.duration, checkResponse.deleteCondition,
                            checkResponse.deleteClass, this.gameInfo.eventConditions, 
                            this.gameInfo.gamePurchases.purchaseTable, imageDir);
                    this.updateConditionTable();
                }
                this.updateEventArr("RegularCard", selectedInd);
            }
            if (isNaN(+this.gameInfo.gameData.currentCash)) {
                let errorDesc = "Cash is NaN in resolveDisplayedCard process. Response selected: " + selectedInd;
                this.generalErrorLog(errorDesc);
            }
          
            if (isNaN(+this.responseValue)) {
                let errorDesc = "On value is not numeric...  Response selected: " + selectedInd;
                this.generalErrorLog(errorDesc);
            }
                      
            if (this.gameInfo.currentCard.responses[selectedInd].linkTo == undefined ||
                this.gameInfo.currentCard.responses[selectedInd].linkTo == "") {
                if (this.gameInfo.gamePhase == "2") {
                    this.determineNextSpinnerEvent();
                }else{
                    this.setState({cardDisabled: false});
                }
            } else {
                let linkName = this.gameInfo.currentCard.responses[selectedInd].linkTo;
                this.props.history.push({
                    pathname: linkName,
                    search: '?query=abc',
                    state: { detail: "fromGameBoard" }});
            }
        }
        if (this.gameInfo.cardImageObj.cardImage === true && 
            this.gameInfo.cardImageObj.cardImageType != "funStuff") {
                this.gameInfo.cardImageObj = {};
                this.gameInfo.cardImageObj.cardImage = false;
        }
        this.gameInfo.cardInProcess = false;
        this.props.setGameInfo(this.gameInfo.cardInProcess, "cardInProcess");
    }

    checkIfMaxSalary() {
        if (this.saveNewSalary > this.gameInfo.pictureInfo.jobMaxSalary) {
            let maxSalaryCondition = this.gameInfo.gameData.conditionsTable.find(elem => elem.condName === "max-salary");
            if (maxSalaryCondition == undefined) {
                let conditionObj = { condName: "max-salary", class: "career" };
                this.gameInfo.gameData.conditionsTable.push(conditionObj);

            }
        }
    }

    updateEventArr(fromProcess, selectedInd) {
        this.eventCount += 1;
        let eventTitle = this.gameInfo.currentCard.title;
        let eventObj = "";
        let response = "";
        if (fromProcess == "RegularCard") {
            response = this.gameInfo.currentCard.responses[selectedInd];
            eventObj = setEventTrackerObj(this.gameInfo.gameData, response, eventTitle, this.eventCount);
        } else {
            response = {
                category: "cash", on: "cash", type: "flat", duration: 1, value: 0,
                moodPoints: 0
            };
            eventObj = setEventTrackerObj(this.gameInfo.gameData, response, eventTitle, this.eventCount);
        }
        this.eventArr.push(eventObj);
        if (this.eventArr.length >= this.state.loggingMax && this.state.eventLogging == true) {
            this.saveEventTracker();
            this.eventArr = [];
        }
    }

    applyCashOrCredit(spendAmt, eventDesc, onValue) {
        if (isNaN(+spendAmt)) {
            spendAmt = 0;
            let errorDesc = "spendAmt is NaN";
            this.generalErrorLog(errorDesc);
        }
        let spendAmtChange = +spendAmt;
        if (spendAmt < 0){
            spendAmtChange = +spendAmt * -1;
        }   
        this.gameInfo.gameData.spendingHistory.currentMonthSpendAmt += spendAmtChange;
        this.updateSpendingHistory();
        this.state.chargeItemToCreditCard = false;
        this.state.chargeItemToCreditCard = 
            checkIfCreditCard(spendAmt, this.gameInfo.creditCardInfo, onValue, "cash");
        if (this.state.chargeItemToCreditCard == true) {
            this.gameInfo.creditCardInfo = applyToCreditCard(this.gameInfo.creditCardInfo,
                spendAmt, eventDesc);
            this.gameInfo.creditCardInfo.dateUpdated = this.state.dateTime.date;
            this.updateCreditCard();
        } else {
            let cashAmt = +this.gameInfo.gameData.currentCash + +spendAmt;
            if (isNaN(cashAmt)) {
                cashAmt = +this.gameInfo.gameData.currentCash;
                let errorDesc = "cash in NaN in applyCashOrCredit, spendAmt is: " + spendAmt;
                this.generalErrorLog(errorDesc);
            }
            this.updateCash(cashAmt);
        }
    }

    createInitialEventArr() {
        let initialEventObj = {
            category: "cash", on: "cash", type: "flat", duration: 1, value: 0,
            moodPoints: 0
        };
        this.eventCount += 1;
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, initialEventObj,
            "Start of GameBoard", this.eventCount);
        this.eventArr.push(eventObj);
    }

    determineObjectDesc(itemDesc, eventTitle, btnText) {
        this.objectDesc = "";
        if (itemDesc != undefined && itemDesc != "") {
            this.objectDesc = itemDesc;
        } else {
            if (btnText == "OK" || btnText == "") {
                this.objectDesc = eventTitle;
            } else {
                this.objectDesc = btnText;
            }
        }
    }

    determineLastCardInHand() {
        this.specialCardCount = 1;
        var remainder;
        var divisor;
        if (this.monthsPerTurn <= 3){
            //  next activity will occur every 3 months
            divisor = 3;
        }else{
            //  alternate the lifestyle and activity end of turn cards
            divisor = 2;
        }
        remainder = +this.turnCount % divisor;
        let lastCard = "";
        let lastCardObj = "";
        if (remainder == 1) {
            lastCard = lifestyleCard();
        } else {
            if (remainder == 0) {
                lastCardObj = lastCardDataset.find(elem => elem.lastCardType === "ActivityCard");
                lastCard = lastCardObj.lastCardValues;
            } else {
                lastCardObj = lastCardDataset.find(elem => elem.lastCardType === "NextTurn");
                lastCard = lastCardObj.lastCardValues;
            }
        }
        if (this.gameInfo.gamePhase == "1") {
            this.gameInfo.cardList.push(lastCard);
        } else {
            this.nextCard = { ...lastCard, cardImage: false, eventCategory: "endTurn" };
        }
    }

    createExtraCards() {
        if (this.jokeCard === true){
            let jokeCard = buildJokeCard(this.gameInfo.gamePhase);
            this.insertCardInHand(jokeCard);
            this.jokeCard = false;
        }
        if (this.friendMonth == true) {
            this.selectAFriend();
        }
        if (this.badgeMonth === true){
            this.createBadgeCard();
        }
       
    }

    selectAFriend() {
        let friendsTable = this.gameInfo.gameData.friendList;
        this.state.nbrFriendsMet = calcNbrFriendsMet(this.gameInfo.gameData.friendList);
        if (this.state.nbrFriendsMet < friendsTable.length) {
            this.slctFriendIdx = determineIfTimeToMeetNewFriend(friendsTable, this.state.nbrFriendsMet,
                this.gameInfo.gameData.currentGameDate);
        } else {
            this.slctFriendIdx = calcRandomNumberWithMax(this.state.nbrFriendsMet, true);
        }
        let friendName = friendsTable[this.slctFriendIdx].name;
        if (friendsTable[this.slctFriendIdx].introDate == "") {
            friendsTable[this.slctFriendIdx].introDate = this.gameInfo.gameData.currentGameDate;
            friendsTable[this.slctFriendIdx].dialogIndx = "firstTime";
        }
        let friendDialogIndx = friendsTable[this.slctFriendIdx].dialogIndx;
        let dialogItem = retrieveGameDialog(friendName, friendDialogIndx, this.gameInfo.gameData.conditionsTable);
        let currentCard = buildCardFromDialog(friendName, dialogItem);
        if (this.gameInfo.gamePhase == "1" && this.gameInfo.cardList.length > 1) {
            this.insertCardInHand(currentCard);
        } else {
            const currentCardFull = { ...currentCard, cardImage: false };
            //this.props.setGameInfo(this.gameInfo.currentCard, "currentCard");
            this.nextCard = currentCardFull;
        }
        friendsTable[this.slctFriendIdx].dialogIndx = dialogItem.dialogIndx;
        this.gameInfo.gameData.friendList = friendsTable;
        this.props.setGameInfo(this.gameInfo.gameData, "gameData");
    }

    createBadgeCard() {
        // phase 1 months --> 3, 16, 29, 42, 55
        // phase 2 months --> 68, 107, 146, 185, 224, 263,
        // badge number of 0 means all badges have been earned
        if (this.props.nextBadge != 0) {
            let badgeCard = buildBadgeCard(this.props.nextBadge);
            if (this.gameInfo.gamePhase == "1"){
                this.insertCardInHand(badgeCard);
            }else{
                this.nextCard = badgeCard;
            }
        }
    }

    insertCardInHand(currentCard) {
        // subtract 2 to ensure the card is at least 2 card prior to last card
        // that way if go out and come back in, it won't skip the last card
        let totalCards = this.gameInfo.cardList.length - 2;
        let indx = calcRandomNumberWithMax(totalCards, true) + 1;
        this.gameInfo.cardList.splice(indx, 0, currentCard);
        this.props.setGameInfo(this.gameInfo.cardList, "cardList");
    }

    incrementDate() {
        if (isNaN(+this.gameInfo.gameData.currentCash)) {
            let errorDesc = "Cash is NaN first of increment Date process"
            this.generalErrorLog(errorDesc);
        }
        this.gameInfo.gameData.currentGameDate =
            rollForwardDate(this.gameInfo.gameData.currentGameDate, this.rollForwardMonths);
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        this.updateGameDate();
     
        // Update cash based on monthly income/expenses and subtract 1 from nbrMonths for asset, expense, loan table items
        let creditCardActive = false;
        if (this.gameInfo.creditCardInfo.currentlyActive !== undefined &&
            this.gameInfo.creditCardInfo.currentlyActive === true){
                creditCardActive = true;
            }
        let monthlyAmounts = rollForwardCash(this.gameInfo.gameData.assetTable, this.gameInfo.gameData.expenseTable,
            this.gameInfo.gameData.loanTable, this.rollForwardMonths, this.gameInfo.gameData.currentGameDate,
            creditCardActive);
        this.monthlyIncome = (monthlyAmounts.totalNetAmt / this.rollForwardMonths).toFixed(2);
        this.spendingHistory = endOfMonthSpendHistory(monthlyAmounts.totalExpCashAmt, monthlyAmounts.totalExpChargeAmt,
            monthlyAmounts.totalLoanPayments, this.gameInfo.gameData.spendingHistory, this.rollForwardMonths);
        this.gameInfo.gameData.spendingHistory = this.spendingHistory;

        this.calculateMoodPoints(0, true);

        // Using the unary operator + will automatically convert strings to numbers (floats)
        // totalCashChange does not include any cash amounts that will be paid by credit card but it does
        //   include total salary, total 'cash' expenses and total loan amount.
        // audit ca$h  ("cash amount at end of turn: ", this.gameInfo.gameData.currentCash);
        // audit ca$h  ("cash change from tables: ", monthlyAmounts.totalCashChange);
        let cashAmt = (+this.gameInfo.gameData.currentCash + +monthlyAmounts.totalCashChange).toFixed(2);
                 
        this.cashAmt = cashAmt;
        // audit ca$h  ("cash amt after monthly amts: ", this.cashAmt);
        let accountType = sessionStorage.getItem("accountType");
        if (accountType == "teacher") {
            this.updateLeaderBoard();
        }
        this.updateCash(this.cashAmt);
        // audit ca$h  ('final cash amt: ', this.cashAmt);
        this.newMonthEventArr();
        this.processNextTick();
        this.setState({cardDisabled: false});
        // this.checkIfTimeForBreak();
    }

    calculateMoodPoints(chgMoodPoints, periodEnd) {
        if (periodEnd != undefined && periodEnd == true) {
            this.gameInfo.gameData.moodPointHistory.totalMoodPoints =
                +this.gameInfo.gameData.moodPointHistory.totalMoodPoints +
                +this.gameInfo.gameData.moodPointHistory.currentMonthMoodPoints;
            this.gameInfo.gameData.moodPointHistory.moodPointMonths =
                +this.gameInfo.gameData.moodPointHistory.moodPointMonths + 1;
            this.gameInfo.gameData.moodPointHistory.currentMonthMoodPoints = 0;
        }
        this.fullMoodPoints = +chgMoodPoints * this.state.moodPointMultiplier;
        this.gameInfo.gameData.currentMoodPoints = +this.gameInfo.gameData.currentMoodPoints + +this.fullMoodPoints;
        this.gameInfo.gameData.moodPointHistory.currentMonthMoodPoints =
            +this.gameInfo.gameData.moodPointHistory.currentMonthMoodPoints + +this.fullMoodPoints;
    }

    newMonthEventArr() {
        this.eventCount += 1;
        let monthResponse = {
            category: "cash", on: "cash", type: "flat", duration: 1, value: this.monthlyIncome,
            moodPoints: 0
        };
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, monthResponse, "New Month", this.eventCount);
        this.eventArr.push(eventObj);
        if (isNaN(this.cashAmt)) {
            let errorDesc = "Cash is NaN in increment Date process"
            this.generalErrorLog(errorDesc);
        }
    }

    processUnlockedFeatures() {
        let cashAmt = +this.gameInfo.gameData.currentCash;
        if (this.gameInfo.savingsInfo != undefined &&
            this.gameInfo.savingsInfo.totalSavings != undefined) {
            cashAmt += +this.gameInfo.savingsInfo.totalSavings;
        }
        // the following function has been changed to 'downgrade' a feature if the player is no 
        // longer eligible
        this.unlockedFeaturesObj = checkForNewUnlockedFeatures(this.gameInfo.gameData.gameNbrMonths,
            cashAmt, this.monthlyIncome, this.gameInfo.gameData.unlockedFeatures, this.props.totalNbrGameMonths);
        if (this.unlockedFeaturesObj.changed == true) {
            this.gameInfo.gameData.unlockedFeatures = this.unlockedFeaturesObj.unlockedFeatures;
            this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
            if (this.unlockedFeaturesObj.newFeature == "creditCard"){ 
                  const conditionObj={condName: "credit-card-unlocked", conditionClass: "game",
                    duration: -1};
                  this.gameInfo.gameData.conditionsTable.push(conditionObj);
            }
            this.updateUnlockedFeatures();
            this.state.iconsProcessed = false;
        }

        let feature = this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "savings");
        if (feature != undefined) {
            // even if the savings feature is not active, still need to update the interest earned
            if (feature.setup == "active" ||
                (this.gameInfo.savingsInfo.totalSavings != undefined &&
                    this.gameInfo.savingsInfo.totalSavings > 0)) {
                const gameDate = JSON.parse(JSON.stringify(this.gameInfo.gameData.currentGameDate));
                // audit ca$h ("cash amt before savings: ", this.cashAmt);
                let savingsObj = moveFromCashToSavings(this.gameInfo.savingsInfo,
                    this.cashAmt, gameDate, feature.setup, this.rollForwardMonths);
                // audit ca$h  ("cash after savings: ", savingsObj.cashAmt);
                this.cashAmt = (+savingsObj.cashAmt.toFixed(2));
                this.gameInfo.savingsInfo = savingsObj.savingsInfo;
                this.gameInfo.savingsInfo.dateUpdated = this.state.dateTime.date;
                this.gameInfo.gameData.currentCash = (savingsObj.cashAmt.toFixed(2)).toString();
                this.updateSavings();
            }
        }

        // need to revise the following so will work for multiple months.... especially for
        // individuals who opt to only pay the minimum balance - spread charged amount across months 
        feature = this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "creditCard");
        if (feature != undefined) {
            if (this.gameInfo.creditCardInfo.currentlyActive === true) {
                // audit ca$h  ("cash before credit card: ", this.cashAmt)
                let creditCardObj = payMonthlyCreditCardAmount(this.gameInfo.creditCardInfo,
                    this.cashAmt, this.gameInfo.gameData.currentGameDate,
                    this.rollForwardMonths, this.gameInfo.gameData.expenseTable);
                if (creditCardObj.cashError === true){
                    let iconTable = createIconTableObj("insufficientCash", this.gameInfo.gameData.iconTable);
                    this.gameInfo.gameData.iconTable = iconTable;
                    this.props.setGameInfo(this.gameInfo.gameData.iconTable, "gameInfo");
                }
                // audit ca$h  ("cash after credit card: ", creditCardObj.cashAmt);    
                this.cashAmt = (+creditCardObj.cashAmt.toFixed(2));
                this.gameInfo.gameData.currentCash = (creditCardObj.cashAmt.toFixed(2)).toString();
                this.gameInfo.creditCardInfo = creditCardObj.creditCardInfo;
                this.gameInfo.creditCardInfo.lastUpdatedDate = this.state.dateTime.date;
                this.updateCreditCard();
            }
        }
    }

    checkForHolidayDecorations(){
        const dateArr = this.gameInfo.gameData.currentGameDate.split('-');
        const gameMonth = dateArr[1];
        this.holidayDecoration = false;
        if (gameMonth == "12"){
                this.holidayObj = determineDecorationImageURL(this.gameInfo.gameData.conditionsTable);
                if (this.holidayObj.decorationFound == true){
                    this.holidayDecoration = true;
                }
        }
    }

    processNextTick() {
        // Process tick for assetTable
        this.gameInfo.gameData.assetTable =
            processTickForRecurringGameDataObjects("asset", this.gameInfo.gameData.assetTable);

        // Process tick for expenseTable
        this.gameInfo.gameData.expenseTable =
            processTickForRecurringGameDataObjects("expense", this.gameInfo.gameData.expenseTable);

        // Process tick for loanTable
        this.gameInfo.gameData.loanTable =
            processTickForRecurringGameDataObjects("loan", this.gameInfo.gameData.loanTable);

        // Check to see if any jobs are in the pending job table that should be activated
        if (this.gameInfo.gameData.pendingJob != undefined &&
            Object.keys(this.gameInfo.gameData.pendingJob).length != 0) {
            let pendingJob = this.gameInfo.gameData.pendingJob;
            if (pendingJob.jobStartDate <= this.gameInfo.gameData.currentGameDate) {
                this.activatePendingJob(pendingJob);
            } else {
                this.checkPendingJobLoans(pendingJob);
            }
        }
      
        this.gameInfo.gameData.conditionsTable = 
            processConditionsEndOfTurn(this.gameInfo.eventConditions, this.gameInfo.gameData, 
                this.monthsPerTurn);
              
        // remove current payroll tax and add new payroll tax expense
        let revisedExpenseTable = createTaxAmtExpense(this.gameInfo.gameData.expenseTable, 
            this.gameInfo.gameData.assetTable, 
            this.gameInfo.gameData.currentGameDate);
        this.gameInfo.gameData.expenseTable = revisedExpenseTable;

        // Process tick for ageNbr for the car
        this.gameInfo.gameData.carInfo.carAge = +this.gameInfo.gameData.carInfo.carAge + this.monthsPerTurn;

        this.processTickForGameNbrMonths();
        this.setNbrMonthsNegative();
        this.processUnlockedFeatures();
        // setting to NoUpdate because the updateGamesCollection updates unlockedFeatures and iconTable
        this.createNotifications("NoUpdate");
        this.updateGamesCollection();
        if (this.state.notificationArr.length > 0) {
            this.openNotificationModal();
        }
    }

    activatePendingJob(pendingJob) {
        this.newJobObjects = processPendingJob(pendingJob, this.gameInfo.gameData.currentGameDate,
            this.gameInfo.gameData.assetTable, this.gameInfo.gameData.expenseTable,
            this.gameInfo.gameData.loanTable, this.gameInfo.gameData.conditionsTable,
            this.gameInfo.pictureInfo, this.gameInfo.gameData.startGameDate, this.gameInfo.gameData.iconTable);
        this.gameInfo.gameData.assetTable = this.newJobObjects.assetTable;
        this.gameInfo.gameData.expenseTable = this.newJobObjects.expenseTable;
        this.gameInfo.gameData.conditionsTable = this.newJobObjects.conditionsTable;
        this.gameInfo.gameData.loanTable = this.newJobObjects.loanTable;
        this.gameInfo.pictureInfo = this.newJobObjects.pictureInfo;
        let jobInfo = this.newJobObjects.jobInfo;
        this.gameInfo.gameData.jobInfo = {
            careerID: jobInfo.careerID, healthInsInd: jobInfo.healthInsInd,
            jobRank: jobInfo.jobRank, nbrYearsCollege: jobInfo.nbrYearsCollege,
            nbrSkillSurvey: this.gameInfo.gameData.jobInfo.nbrSkillSurvey,
            prevJobArr: jobInfo.prevJobArr,
            jobStartGameMonth: this.gameInfo.gameData.gameNbrMonths
        };
        this.gameInfo.gameData.iconTable = this.newJobObjects.iconTable;
        this.gameInfo.gameData.pendingJob = {};
    }

    checkPendingJobLoans(pendingJob) {
        var deleteLoanIdx = [];
        for (let i = 0; i < pendingJob.loanTable.length; i++) {
            if (pendingJob.loanTable[i].loanStartDate <= this.gameInfo.gameData.currentGameDate) {
                this.gameInfo.gameData.loanTable.push(pendingJob.loanTable[i]);
                deleteLoanIdx.push(i);
            }
        }
        let startIdx = deleteLoanIdx.length - 1;
        for (let i = startIdx; i >= 0; i--) {
            pendingJob.loanTable.splice(deleteLoanIdx[i], 1);
        }
    }

    processTickForGameNbrMonths() {
        if (this.gameInfo.timeWizardInfo.gameID === undefined || 
            this.gameInfo.timeWizardInfo.status === "inactive"){
                this.gameInfo.gameData.gameNbrMonths = +this.gameInfo.gameData.gameNbrMonths - 1;
        }else{
            this.gameInfo.gameData.gameNbrMonths = 
                +this.gameInfo.gameData.gameNbrMonths - +this.gameInfo.timeWizardInfo.monthsPerTurn;
        }
      
        if (+this.gameInfo.gameData.gameNbrMonths <= 0) {
            let endGameReason = "TimeElapsed";
            this.gameInfo.gameData.gameNbrMonths = "0";
            this.props.onEnd(endGameReason);
        }
        this.determineIfBadgeMonth();
    }

    setNbrMonthsNegative() {
        // Process tick for end condition on three months in a row with negative balance
        let monthsNegativeObj = setNbrMonthsNegative(this.gameInfo.gameData.currentCash,
            this.gameInfo.gameData.nbrMonthsNegative,
            this.gameInfo.gameData.currentMoodPoints, 
            this.gameInfo.gameData.nbrMonthsMoodNegative);
        this.gameInfo.gameData.nbrMonthsNegative = monthsNegativeObj.nbrMonthsCashNegative;
        this.gameInfo.gameData.nbrMonthsMoodNegative = monthsNegativeObj.nbrMonthsMoodNegative;
        if (monthsNegativeObj.endGameReason != "") {
            this.props.onEnd(monthsNegativeObj.endGameReason);
        }
    }

    determineObjectDesc(itemDesc, eventTitle, btnText) {
        this.objectDesc = "";
        if (itemDesc != undefined && itemDesc != "") {
            this.objectDesc = itemDesc;
        } else {
            if (btnText == "OK" || btnText == "") {
                this.objectDesc = eventTitle;
            } else {
                this.objectDesc = btnText;
            }
        }
    }

    applyCardUpdate(updateType, updateValue, duration, objectDesc, replaceAmtSw, intRate, totalLoanAmt,
        loanTerm, downPayment) {
        let revisedTableObj = updateRecurringGameDataObjects(this.tableType, this.updateName,
            updateType, updateValue, duration, objectDesc, replaceAmtSw, this.tableToUpdate,
            this.gameInfo.gameData.currentCash, this.gameInfo.gameData.currentGameDate,
            this.gameInfo.creditCardInfo, intRate, loanTerm, totalLoanAmt, downPayment);
        this.tableToUpdate = revisedTableObj.objectTable;
        if (revisedTableObj.applyType == "cash" || revisedTableObj.applyType == "credit") {
            if (revisedTableObj.applyType == "cash") {
                this.newCashAmt = Number(revisedTableObj.newCashAmt).toFixed(2);
                if (isNaN(this.newCashAmt)) {
                    let errorDesc = "NewCashAmt is NaN after updateRecurring value is: " + updateValue +
                        " updateType is: " + updateType;
                    this.generalErrorLog(errorDesc);
                } else {
                    this.gameInfo.gameData.currentCash = this.newCashAmt;
                }
            } else {
                this.gameInfo.creditCardInfo = revisedTableObj.creditCardInfo;
                this.gameInfo.creditCardInfo.dateUpdated = this.state.dateTime.date;
                this.updateCreditCard();
            }
        }
        this.saveNewSalary = revisedTableObj.newSalaryAmt;
        if (revisedTableObj.spendAmt != 0) {
            let spendAmt = revisedTableObj.spendAmt;
            if (spendAmt < 0){
                spendAmt = -1 * revisedTableObj.spendAmt;
            }
            this.gameInfo.gameData.spendingHistory.currentMonthSpendAmt += spendAmt;
            this.updateSpendingHistory();
        }
    }

    calculateResponseValue(response) {
        let returnValue = calculateResponseAmount(this.gameInfo.currentCard, response, this.gameInfo.gameData);
        sessionStorage.setItem("gameBtnText", this.gameInfo.currentCard.responses[response].btnText);
        return returnValue;
    }
    
    determineIfBadgeMonth(){
        this.badgeMonth = false;
        let badgeAlreadyEarnedForThisMonth = false;
        if (this.gameInfo.gameData.badgesEarned !== undefined && this.gameInfo.gameData.badgesEarned.length > 0){
            let badgeIndx = this.gameInfo.gameData.badgesEarned.length - 1;
            if (this.gameInfo.gameData.badgesEarned[badgeIndx].dateEarned === 
                this.gameInfo.gameData.currentGameDate){
                    // If the most recent badge was earned in the current game month, then badgeMonth s/b false.
                    // This might happen if a person goes from the gameBoard to another process in the middle of a
                    // turn and the current month is a badge month.
                   badgeAlreadyEarnedForThisMonth = true;
            }
        }

        if (badgeAlreadyEarnedForThisMonth === false){
            // divisor is how many months (turns) should pass before a badge card is displayed
            let divisor = 1;
            if (this.monthsPerTurn <= 2){
                    divisor = 6;  
            }else{
                if (this.monthsPerTurn <= 4){
                    divisor = 3;
                }else{
                    if (this.monthsPerTurn <= 8){
                        divisor = 2;
                    }
                }
            }
            let remainder = this.turnCount % divisor;
            console.log("divisor is: ", divisor, " turn count: ", this.turnCount,
                " months per turn: ", this.monthsPerTurn, "  remainder: ", remainder);
            if (remainder === 0){
                this.specialCardCount += 1;
                this.badgeMonth = true;
            }
        }
    }

    //   SECTION:  fetch processes

    async getNextEvents() {
        this.createConditionsUrlParm();
        const conditionParm = (this.conditionArr).join(',');
        const gameDateArr = this.gameInfo.gameData.currentGameDate.split('-');
        const currentGameMonth = gameDateArr[1];
        const funcName = "dealNewHand";
        const urlParm = "?conditionsTable=" + conditionParm + "&career=" + this.career + 
            "&currentGameMonth=" + currentGameMonth + "&specialCardCount=" + this.specialCardCount;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'GET', headers: this.state.headers })
            .then(response => { 
                if (response.ok){
                  response.json().then(data => {
                      const output = JSON.parse(data);
                      const statusCode = output.statusCode;
                      if (statusCode == 200){
                        this.determineLastCardInHand();                 
                        for (const card of output.body.cardArr) {
                            this.gameInfo.cardList.push(card);
                        }
                        sessionStorage.setItem("gameBoardStatus", "cardFaceDown");
                        let currentMonthDate = dateStrToPlainText(this.gameInfo.gameData.currentGameDate);
                        let currentMonthDateArr = currentMonthDate.split(" ");
                        this.currentMonth = currentMonthDateArr[0];
                        this.createExtraCards();
                        this.props.setGameInfo(this.gameInfo.cardList, 'cardList'); 
                        //  the following waits 7 seconds and then displays the click image (finger pointer)
                        if(this.state.timeout) clearTimeout(this.state.timeout);
                            this.state.timeout = setTimeout(() => {
                            this.setState({clickImageStartSw: true});
                            }, 7000);
                       
                      }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                      }
                  });
              };
        });      
          
    }

    getNextSpinnerItem() {
        // ????  it looks like process is coming here to do getNextSpinner even when it doesn't need to???
        //  sets eventCategory to "skip"... see note below about updating 'hand', perhaps a good idea?
        this.createConditionsUrlParm();
        const conditionParm = (this.conditionArr).join(',');
        const gameDateArr = this.gameInfo.gameData.currentGameDate.split('-');
        const currentGameMonth = gameDateArr[1];
        const funcName = "getNextSpinnerCard";
        let cardList = (this.gameInfo.cardList).join(',');
        let nonEventArr = [4,6,8];
        let eventCategory = "";
        if (nonEventArr.includes(this.state.nextEventNbr)){
            // event is either a friend, joke or end of turn
            eventCategory = "skip";
        }else{
            const eventListObj = 
                this.state.eventCategories.find(elem => elem.number === +this.state.nextEventNbr);
            if (eventListObj === undefined){
                eventCategory = "skip"
            }else{
                eventCategory = eventListObj.category;
            }
        }
        const urlParm = "?&career=" + this.career + "&currentGameMonth=" + currentGameMonth + 
            "&eventCategory=" + eventCategory + "&cardList=" + cardList + "&conditionsTable=" + conditionParm ;
        this.inputParm={career: this.career, currentGameMonth: currentGameMonth,
            eventCategory: eventCategory, cardList: cardList, conditionsTable: conditionParm};
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
               { method: 'GET', headers: this.state.headers })
               .then(response => {
                  if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (output.statusCode == 200){ 
                        if (eventCategory === "skip"){
                           //  this updates 'hand' for the current game but only returns msg
                        }else{
                            this.nextCard = {...output.body, cardImage: false};
                        }
                        this.props.setGameInfo(false, 'loading');
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.inputParm};
                        this.props.processError(errorObj);
                    }
                });
            };
      });      
    }

    createConditionsUrlParm(){
        let conditionsTable = this.gameInfo.gameData.conditionsTable;
        this.conditionArr = [];
        for (let i=0; i<conditionsTable.length; i++){
            if (conditionsTable[i].conditionClass == "career"){
                this.career = conditionsTable[i].condName;
            }else{
                this.conditionArr.push(conditionsTable[i].condName);
            }
        }
    }

    //   SECTION:  update and save processes 

    updateGameDate() {
        const funcName = "setDate";
        const urlParm = "?date=" + this.gameInfo.gameData.currentGameDate 
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
        // Update date value in Mongo
            { method: 'PUT', headers: this.state.headers })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                     // setDate was successful;
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateCash(newAmt) {
        if (isNaN(newAmt)) {
            newAmt = this.gameInfo.gameData.currentCash;
        }
        const funcName = "setCash";
        this.gameInfo.gameData.currentCash = Number(newAmt).toFixed(2);
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const urlParm = "?cash=" + this.gameInfo.gameData.currentCash; 
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri, 
            { method: 'PUT', headers: this.state.headers })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // setCash was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }
       
    updateUnlockedFeatures() {
        const funcName = "updateUnlockedFeatures";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        if (this.gameInfo.gameData.iconTable == undefined) {
            this.gameInfo.gameData.iconTable = [];
        }
        this.bodyObj = {
            "currentCash": this.gameInfo.gameData.currentCash,
            "iconTable": this.gameInfo.gameData.iconTable,
            "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures
        };
        fetch (uri, 
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // successful update
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateIconTable() {
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateIconTable";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        const bodyObj = { 
            "iconTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.iconTable)) };
        fetch (uri, 
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // update of Icon table was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });

        return;
    }

    updateFriendList() {
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updatePlayerFriendList";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        this.bodyObj = {
            "friendList": this.gameInfo.gameData.friendList,
        };
        fetch (uri, 
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // update of friend list was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateMoodPoints(chgMoodPoints) {
        this.calculateMoodPoints(chgMoodPoints, false);
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateMoodPointInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        
        this.bodyObj = {
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "moodPointHistory": this.gameInfo.gameData.moodPointHistory,
        }
        fetch (uri, 
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                     // update mood points successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateSpendingHistory() {
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateSpendingHistory";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        this.bodyObj = {
            "spendingHistory": this.gameInfo.gameData.spendingHistory,
        };
        fetch (uri, 
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // update Spending history was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateGamesCollection() {
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateAll";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        const bodyObj = {
            "assetTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.assetTable)),
            "expenseTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable)),
            "loanTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.loanTable)),
            "conditionsTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.conditionsTable)),
            "iconTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.iconTable)),
            "pendingJob": this.gameInfo.gameData.pendingJob,
            "gameNbrMonths": this.gameInfo.gameData.gameNbrMonths,
            "nbrMonthsNegative": this.gameInfo.gameData.nbrMonthsNegative,
            "nbrMonthsMoodNegative": this.gameInfo.gameData.nbrMonthsMoodNegative,
            "carInfo": this.gameInfo.gameData.carInfo,
            "jobInfo": this.gameInfo.gameData.jobInfo,
            "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
            "spendingHistory": this.gameInfo.gameData.spendingHistory,
            "currentCash": this.gameInfo.gameData.currentCash,
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "moodPointHistory": this.gameInfo.gameData.moodPointHistory,
            "dateUpdated": this.state.dateTime.date,
        };
        
        fetch (uri, 
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // update games collection
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    updateAsset(assetName, updateType, updateValue, duration, btnText, itemDesc, eventTitle, replaceAmtSw) {
        this.determineObjectDesc(itemDesc, eventTitle, btnText);
        this.tableType = "Asset";
        this.tableToUpdate = this.gameInfo.gameData.assetTable;
        this.updateName = assetName;
        this.applyCardUpdate(updateType, updateValue, duration, this.objectDesc, replaceAmtSw);
        this.gameInfo.gameData.assetTable = this.tableToUpdate;
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateAssets";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        // Update asset table in Mongo
        const bodyObj = { "assetTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.assetTable)) };
        fetch(uri,
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                     // update assets was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });

        return;
    }

    updateExpense(expenseName, updateType, updateValue, duration, btnText, itemDesc, eventTitle, replaceAmtSw) {
        this.determineObjectDesc(itemDesc, eventTitle, btnText);
        this.tableType = "Expense";
        this.tableToUpdate = this.gameInfo.gameData.expenseTable;
        this.updateName = expenseName;
        this.applyCardUpdate(updateType, updateValue, duration, this.objectDesc, replaceAmtSw);
        this.gameInfo.gameData.expenseTable = this.tableToUpdate;
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateExpenses";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        // Update expense table in Mongo
        const bodyObj = { "expenseTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable)) };
        fetch(uri,
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                     // update expenses was successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });

        return;
    }

    updateLoan(loanName, updateType, updateValue, duration, btnText, itemDesc, eventTitle, replaceAmtSw) {
        let intRate = ConfigFile.gameBoardLoans.intRate;
        let loanTerm = ConfigFile.gameBoardLoans.loanTerm;
        let downPaymentPct = ConfigFile.gameBoardLoans.pctDownPayment;
        let downPaymentAmt = +(+downPaymentPct * +updateValue).toFixed(2);
        let loanPayment = monthlyLoanAmt(updateValue, intRate, loanTerm);

        this.determineObjectDesc(itemDesc, eventTitle, btnText);
        this.tableType = "Loan";
        this.tableToUpdate = this.gameInfo.gameData.loanTable;
        this.updateName = loanName;

        let loanMonths = +loanTerm * 12;
        this.applyCardUpdate(updateType, loanPayment, loanMonths, this.objectDesc, replaceAmtSw,
            intRate, updateValue, loanTerm, downPaymentAmt);
        this.gameInfo.gameData.loanTable = this.tableToUpdate;

        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateLoans";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        // Update loan table in Mongo
        const bodyObj = { "loanTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.loanTable)) };
        fetch(uri,
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      alert ("update loan table in game-board was successful");
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });

        return;
    }

    updateConditionTable() {
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        const funcName = "updateConditions";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        // Update conditions table in Mongo
        const bodyObj = { "conditionsTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.conditionsTable)) };
        fetch(uri,
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // updateConditions Table was successful;
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });

        return;
    }

    updateLeaderBoard() {
        let currentDateTime = getCurrentDateTime();
        let gameScores = calculateMoodRetirementScores(this.gameInfo.gameData.currentMoodPoints,
            this.cashAmt, this.gameInfo.savingsInfo.totalSavingsAmt,
            this.gameInfo.gameData.spendingHistory, this.gameInfo.gameData.gameNbrMonths,
            this.props.totalNbrGameMonths);
        let nbrGameMonths = this.props.totalNbrGameMonths - this.gameInfo.gameData.gameNbrMonths + 1;
        let account = sessionStorage.getItem("userAccountNbr");
        const funcName = "saveLeaderBoard";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        let bodyObj = {
            "user": this.gameInfo.gameData.user,
            "startGameDate": this.gameInfo.gameData.startGameDate,
            "moodScore": gameScores.moodScore,
            "retirementScore": gameScores.retirementScore,
            "combinedScore": gameScores.combinedScore,
            "account": account,
            "nbrGameMonths": nbrGameMonths,
            "dateUpdated": currentDateTime.date,
        };
        //  saveLeaderBoard is an upsert so will either update or create leaderboard for this user
        fetch(uri,
            {
                method: 'post',
                headers: this.state.headers,
                body: JSON.stringify(bodyObj)
            })
            .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                      // "update of leader board in game-board was successful"
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });
    }

    onResultDisplay() {
        //do not remove card from hand if the card that was displayed is dialog, joke, or badge
        if (this.processAsCard === true){
            const funcName = "removeCardFromHand";
            const urlParm = "";
            const uri = buildUrl(this.state.environment, funcName, urlParm);
            fetch(uri,
                { method: 'PUT', 
                headers: this.state.headers })
                .then(response => {
                    if (response.ok){
                      response.json().then(data => {
                      const output = JSON.parse(data);
                      const statusCode = output.statusCode;
                      if (output.statusCode == 200){ 
                          // remove card from hand was successful;
                      }else{
                          const errorObj={function: funcName, subProcess: this.state.subProcess, 
                              status: statusCode, message: output.body.message};
                          this.props.processError(errorObj);
                      }
                  });
                }
            });
        }
    }


    updateSavings() {
        this.props.setGameInfo(this.gameInfo.savingsInfo, 'savingsInfo');
        this.savingsObj = JSON.parse(JSON.stringify(this.gameInfo.savingsInfo));
        const funcName = "updateSavingsAmts";
        const urlParm = "?gameID=" + this.gameInfo.gameData._id;
        const uri = buildUrl(this.state.environment, funcName, urlParm);

        fetch(uri,
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.savingsObj)
            })
            .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (output.statusCode == 200){ 
                    //  update savings was successful;
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.savingsObj};
                    this.props.processError(errorObj);
                }
            });
          }
      });
    }

    updateCreditCard() {
        this.props.setGameInfo(this.gameInfo.creditCardInfo, 'creditCardInfo');
        this.creditCardObj = JSON.parse(JSON.stringify(this.gameInfo.creditCardInfo));
        const funcName = "updateCreditCardInfo";
        const urlParm = "?gameID=" + this.gameInfo.gameData._id;
        const uri = buildUrl(this.state.environment, funcName, urlParm);

        fetch(uri,
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.creditCardObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (output.statusCode == 200){ 
                        //update credit card was successful;
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.creditCardObj};
                        this.props.processError(errorObj);
                    }
                });
              }
          });
    }

    updateGamePurchases(){
        if (this.gameInfo.gamePurchases === ""){
            this.gameInfo.gamePurchases = {gameID: this.gameInfo.gameData._id,
                purchaseTable: this.gamePurchaseArr};
        }else{
            this.gameInfo.gamePurchases.purchaseTable = this.gamePurchaseArr;
        }
        this.props.setGameInfo(this.gameInfo.gamePurchases, 'gamePurchases');
        this.gamePurchasesObj = JSON.parse(JSON.stringify(this.gameInfo.gamePurchases));
        const funcName = "updateGamePurchases";
        const urlParm = "?gameID=" + this.gameInfo.gameData._id;
        const uri = buildUrl(this.state.environment, funcName, urlParm);

        fetch(uri,
            {
                method: 'PUT', 
                headers: this.state.headers,
                body: JSON.stringify(this.gamePurchasesObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (output.statusCode == 200){ 
                        //update game purchases was successful;
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.gamePurchasesObj};
                        this.props.processError(errorObj);
                    }
                });
              }
          });
    }

    saveEventTracker() {
        let dataObject = {
            player: this.gameInfo.gameData.user,
            gameID: this.gameInfo.gameData._id,
            eventType: "playerEvent",
            date: this.state.dateTime.date,
            time: this.state.dateTime.time,
            process: "GameBoard",
            events: this.eventArr,
        };
        const funcName = "saveEventTracker";
        const urlParm = ""
        const uri = buildUrl(this.state.environment, funcName, urlParm);

        fetch(uri,
            {
                method: 'post',
                headers: this.state.headers,
                body: JSON.stringify(dataObject)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (output.statusCode == 200){ 
                       //  save of event tracker was successful
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: dataObject};
                        this.props.processError(errorObj);
                    }
                });
              }
          });
    }

    generalErrorLog(errorDesc) {
        const dataObject = {currentCard: this.gameInfo.currentCard,
            currentCash: this.gameInfo.gameData.currentCash,
            assetTable: this.gameInfo.gameData.assetTable,
            expenseTable: this.gameInfo.gameData.expenseTable,
            loanTable: this.gameInfo.gameData.loanTable,
            conditionsTable: this.gameInfo.gameData.conditionsTable};
        const message = errorDesc;
        const errorObj={function: "GeneralError", subProcess: this.state.subProcess, 
            status: 999, message: message, errorObject: dataObject, silentAlert: true};
        this.props.processError(errorObj);
    }

}

export default withRouter(GameBoard);