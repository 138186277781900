import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import MoneyMgmtBasics from './money_mgmt_basics';
import Priorities from './priorities';
import ChoosingCareer from './choosing_career';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class Level1Challenges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            callRender: false,
            modalOpen: false,
            prevProcess: this.props.prevProcess,
        }    
        this.category1Name = "MoneyMgmt";
        this.category2Name = "Priorities";
        this.category3Name = "ChoosingCareer";
        this.nextProcess = "";
        this.category1Complete = false;
        this.category2Complete = false;
        this.category3Complete = false;
        this.extraCreditReward = ExtraCreditConfigFile.exercises;
        this.setCompleted();
    }

setCompleted(){
    let exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category1Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category1Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category2Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category2Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category3Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category3Complete = true;
    }
    this.setState({callRender: true});
}

selectNextChallenge(challengeName) {
    this.nextProcess = challengeName;
    this.setState({modalOpen: true});
}

closeModal(){
    this.setState({modalOpen: false});
}

  render() {
           if (this.nextProcess !== ""){
                sessionStorage.setItem("GameViewSubProcess", this.nextProcess)
           }
           this.className = "mb-5 input-page-base-card";
           let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit");
           this.className = screenParms.columnClass;
           this.linkSize = screenParms.linkSize;
          
  return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg text-center height-max">
                <button className="pages-back-button ml-4 mt-4" 
                    onClick ={()=> this.props.goBack()}>
                                    Back
                </button>

               <h3 className="mt-0 text-center">Level 1 Challenges</h3>
           
            <span>
            <span className="row w-100 ml-4 mx-0">
                <span/>
                {this.category1Complete ?
                    <span className="col-sm-5 mx-2 mt-0 buttonDisabledImage" >
                        <div>
                            <LinkCard name="Money Mgmt" imgSrc='./assets/ExtraCredit/moneyMgmt.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-0 cursor-is-pointer"
                        onClick={() => this.selectNextChallenge(this.category1Name)}>
                        <div>
                        <LinkCard name="Money Mgmt" imgSrc='./assets/ExtraCredit/moneyMgmt.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
                {this.category2Complete ?
                    <span className="col-sm-5 mx-2 mt-0 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Priorities" imgSrc='./assets/ExtraCredit/priorities.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-0 cursor-is-pointer" 
                        onClick={() => this.selectNextChallenge(this.category2Name)}>
                        <div>
                        <LinkCard name="Priorities" imgSrc='./assets/ExtraCredit/priorities.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
            </span>
            <span className="row w-100 mx-0 ml-4">
                <span/>
                {this.category3Complete ?
                    <span className="col-sm-5 mx-2 my-4 buttonDisabledImage">
                        <div>
                            <LinkCard name="Choosing a Career" imgSrc='./assets/ExtraCredit/chooseCareer.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 my-4 cursor-is-pointer"
                        onClick={() => this.selectNextChallenge(this.category3Name)}>
                        <div>
                        <LinkCard name="Choosing a Career" imgSrc='./assets/ExtraCredit/chooseCareer.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
              
            </span>
            </span>
            {this.nextProcess === this.category1Name ?
                <MoneyMgmtBasics
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category1Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
             {this.nextProcess === this.category2Name ?
               <Priorities 
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category2Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
            
            {this.nextProcess === this.category3Name ?
                <ChoosingCareer
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category3Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
        </div>
       
    </div>   
</>
  }
  
challengeCompleted(processName) {
    this.props.updateAmounts();
    const exerciseInfo = this.extraCreditReward.find(elem => elem.category === processName);
    let typeLit = "";
    if (exerciseInfo.type === "salary"){
         typeLit = "monthly salary";
    }else{
         typeLit = "current cash";
    }
    const alertText = "Congratulations!  Your " + typeLit + " has been increased by " +
            doubleToStringMonthly(exerciseInfo.amt) + ".";
    alert (alertText);
    switch (processName){
        case this.category1Name:
            this.category1Complete = true;
            break;
        case this.category2Name:
            this.category2Complete = true;
            break;
        case this.category3Name:
            this.category3Complete = true;
            break;
        default:
            break;
    }
       
    if (this.category1Complete === true && this.category2Complete === true &&
        this.category3Complete === true){
        this.props.completed(); 
        this.props.history.push('/extra-credit-main');
    }else{
        this.setState({modalOpen: false});
    }
}

}

export default withRouter(Level1Challenges);