import {Component} from 'react';
import carOption from '../../objects/dataDefinitions/carOption';
import CarSizeSelectCard from './car_size_select_card';
import CarCard from './car_card';
import { withRouter } from "react-router";
import {SingleSelectionContext} from '../../objects/Context/single_selection_context';
import {calcInflationAmt} from '../../objects/CommonUse/calc_inflation_amt';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from './wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class CarInput extends Component {
    
    constructor(props) { 
        super(props);
        this.state = {
            carArr: [
                new carOption(100, "Small Car", "small_car.png", false),
                new carOption(200, "Mid-Size Car", "mid_size.png", ["TEMP"], false),
                new carOption(300, "SUV - Sport Utility Vehicle", "SUV.png", ["TEMP"], false),
                new carOption(400, "Sports Car", "sports_car.png", ["TEMP"], false),
                new carOption(500, "Pickup Truck", "trucks.png", ["TEMP"], false),
            ],
            carOptionSelected: false,
            slctCarSize: sessionStorage.getItem("carSize"),
            inflatedCostDoneSw: false,
            selection: "",
            loading: true,
            sizeName:  "",
            yearsdiff: undefined,
            headers: setHeadersForFetch(),
            environment: Config.dataURL.currentEnv,
            subProcess: "CarInput",
        }
        this.carList = [];
        this.prepareCarList();
    } 

    prepareCarList(){
        var i;
        for (i=0; i< this.carList.length; i++){
            this.carList[i].cost = 
                +calcInflationAmt(this.carList[i].cost, this.yearsDiff);
        } 
        this.setState({inflatedCostDoneSw: true});
    }


    render() {
        if (this.state.loading === false && this.yearsDiff != 0
            && this.state.inflatedCostDoneSw != true){
           this.prepareCarList(); 
        }
        this.carOptions = this.state.carArr.map((sizeOption, key)=>
            <CarSizeSelectCard sizeInfo={sizeOption} key={key}
                onSelected={() => this.selectCarOption(sizeOption)}
                />
        );
        this.autoList = this.carList.map((automobile,key) =>
            <CarCard selectedAutomobileId={this.state.selection} key={key} 
                carInfo={automobile} 
                onSelected={() => this.onCarSelected(automobile)}
                />
            );

        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.classSize = screenParms.columnClass;
                       
        return <>
            <div className={this.classSize}>
                <div className="card mt-3 mr-3 rounded-corners-lg text-center height-max">
                    <h3 className="mt-2 p-0">Choose a Car</h3> 
                    {this.state.carOptionSelected === false ?
                        <span className="m-0 p-0 text-center overflow-scroll">
                             {this.carOptions}
                                 
                        </span>
                    :
                        <div className="overflow-scroll">
                            {this.state.loading ?
                                <WaitTimeSpinner />
                            :
                                <div className="p-0 overflow-scroll text-left">
                                    <h4 className="p-0 text-center">{this.state.sizeName}</h4>
                                        <button className="btn btn-primary ml-3" onClick={() => this.backToOptionSelection()}>
                                            Back
                                        </button>
                                        <SingleSelectionContext.Provider value={this.state}>
                                            {this.autoList}
                                        </SingleSelectionContext.Provider>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    }

    selectCarOption(sizeOption) {
        for (const option of this.state.carArr) {
            option.selected = false;
        }
        for (let i=0; i<this.state.carArr.length; i++) {
            if (this.state.carArr[i].carSize = sizeOption.carSize){
                    this.state.carArr[i].selected = true;
                    break;
            }
        }
        this.getGameYears();
        this.getCarList(sizeOption.sizeNbr);  
        this.state.carOptionSelected = true;
        this.setState({sizeName: sizeOption.sizeName});
    }

    getGameYears(){
        var yearMonthVals = 0;
        if (this.props.currentGameDate == undefined){
                let today = new Date();
                this.currentGameYear = today.getFullYear();
                this.yearsDiff = 0;      
        }else{
                yearMonthVals = this.props.currentGameDate.split('-'); 
                this.currentGameYear = +yearMonthVals[0];
                yearMonthVals = this.props.startGameDate.split('-'); 
                this.startGameYear = +yearMonthVals[0];
                this.yearsDiff = this.currentGameYear - this.startGameYear;
        }
    }

    getCarList(sizeNbr) {
        const funcName = "carList";
        const urlParm = "?sizeNbr=" + sizeNbr + "&currentGameYear=" + this.currentGameYear;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
        {method:'GET', headers: this.state.headers})
            .then(response => { 
            if (response.ok){
              response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (statusCode == 200){
                    this.carList = output.body;
                    let selectedAutomobile = sessionStorage.getItem("carChoice");
                    if (selectedAutomobile !== undefined) {
                        this.setState({selection: selectedAutomobile});
                    }
                    this.setState({loading: false});
                  }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                      this.props.processError(errorObj);
                  }
              });
          };
      });
    }

    onCarSelected(automobile) {
        let autoDesc = automobile.sizeName + " - " + automobile.ageName;
        sessionStorage.setItem("carChoice", automobile.id);
        sessionStorage.setItem("carSize", automobile.sizeNbr);
        sessionStorage.setItem("carCost", automobile.cost);
        sessionStorage.setItem("carName", autoDesc);
        sessionStorage.setItem("carYearAdj", automobile.yearAdj);
        sessionStorage.setItem("carAgeNbr", automobile.ageNbr);
        sessionStorage.setItem("carMPG", automobile.mpg);
        sessionStorage.setItem("carSizeName", automobile.sizeName);
        sessionStorage.setItem("carDirectory", automobile.directory);
        this.props.onSelected();    
    }

    backToOptionSelection()
        {
            this.setState({carOptionSelected: false});
            this.state.loading=true;
    }

}

export default withRouter(CarInput);