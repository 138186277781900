import { addChangeSalaryAsset } from "./add_change_salary_asset";
import ExtraCreditConfigFile from "../../components/extra_credit/Datasets/extra_credit_config_file";

export const getExtraCreditForNewGame = (extraCreditArr, assetArr, currentYYYYMM) => {
        let newExtraCreditArr = [];
        let totalSalaryIncrease = 0;
        let totalCashIncrease = 0;
        let oneTimeDesc = "Extra Credit - "
        let completedExercises = 0;
        if (extraCreditArr != undefined){
          for (let i=0; i<extraCreditArr.length; i++){
              if (+extraCreditArr[i].score >= 80){
                 completedExercises += 1;
              }
              if (extraCreditArr[i].assetType === "salary"){
                let processName = extraCreditArr[i].category;
                let salaryIncrease = (+extraCreditArr[i].amount).toFixed(2);
                salaryIncrease = salaryIncrease.toString();
                let assetArrObj = addChangeSalaryAsset(assetArr, "ExtraCreditBonus", currentYYYYMM,
                  salaryIncrease, processName); 
                totalSalaryIncrease = (+totalSalaryIncrease + +salaryIncrease).toFixed(2);
                assetArr = assetArrObj.assetTable;
                const salaryChangeObj = {processName: processName, assetType: "salary", 
                  amount: salaryIncrease};
                newExtraCreditArr.push(salaryChangeObj);
              }else{
                totalCashIncrease += +extraCreditArr[i].amount; 
                let processName = extraCreditArr[i].category;
                oneTimeDesc +=  processName + ", ";
            }
          }
        }
    let allExercisesComplete = false;
    if (completedExercises === ExtraCreditConfigFile.exercises.length){
        allExercisesComplete = true;
    }
    const returnObj = {totalSalaryIncrease: totalSalaryIncrease, assetArr: assetArr, 
        oneTimeDesc: oneTimeDesc, totalCashIncrease: totalCashIncrease, 
        allExercisesComplete: allExercisesComplete};
    return returnObj;
}

export default getExtraCreditForNewGame;