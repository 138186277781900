import ConfigFile from "../../../datasets/config_file";
import RetirementSavingsDataset from "../datasets/retirement_savings_dataset";

export const calculateMoodRetirementScores = (currentMoodPoints, currentCash, currentSavings, spendingHistory, 
    gameNbrMonths, totalGameMonths) => {
         
      let maxMoodPoints = ConfigFile.gameStartItems.maxMoodPoints;
      let moodPercent = (+currentMoodPoints/+maxMoodPoints) * 100;
      let moodRotation = ((9/5 * moodPercent) - 90).toFixed(2);
      if (moodRotation > 90){
           moodRotation = 90;
      }else{
          if (moodRotation < -90){
              moodRotation = -90;
          }
      }
      
      let emojiURL = ""
      if (moodRotation < -45){
            emojiURL = "./assets/moodFaceSad.png";
      }else{
        if (moodRotation >= -45 && moodRotation <= 45){
            emojiURL = "./assets/moodFaceIndifferent.png";
        }else{
            emojiURL = "./assets/moodFaceHappy.png";
        }
      }
     
      if (currentSavings == undefined){
            currentSavings = 0;
      }
      let totalSavingsAmt = +currentCash + +currentSavings;
      let nbrMonthsPlayed = +totalGameMonths - +gameNbrMonths;
      let estimatedAge = +(nbrMonthsPlayed / 12) + 22;
      let retirementSavings = RetirementSavingsDataset;
      let savingsMultiplier = 0;
      for (let i=0; i<retirementSavings.length; i++){
            if (estimatedAge <= retirementSavings[i].age){
                savingsMultiplier = retirementSavings[i].nbrYearsSalarySaved;
                break;
            }
      }
      let monthly10YearSpendingAmt = spendingHistory.current10YearSpendAmt/spendingHistory.current10YearMonths;
      let monthlyPrevious10YearSpendingAmt = 0;
      if (spendingHistory.current10YearMonths < 120){
            if (+spendingHistory.previous10YearSpendAmt > 0){
                 monthlyPrevious10YearSpendingAmt = 
                    spendingHistory.previous10YearSpendAmt/spendingHistory.previous10YearMonths;
            }
      }
      let monthlySpendingAmt = 0
      if (monthlyPrevious10YearSpendingAmt == 0){
           monthlySpendingAmt = monthly10YearSpendingAmt;
      }else{
           let previousMonths = 120 - spendingHistory.current10YearMonths;
           let currentMonths = spendingHistory.current10YearMonths;
           let totalSpendAmt = ((monthlyPrevious10YearSpendingAmt * previousMonths) + (monthly10YearSpendingAmt * currentMonths));
           monthlySpendingAmt = totalSpendAmt / 120;
      }
      let avgYearlySpendingAmt = (monthlySpendingAmt * 12).toFixed(2);
      let totalRetirementSavings = +avgYearlySpendingAmt * savingsMultiplier;

      let savingsPercent = (totalSavingsAmt/totalRetirementSavings) * 100;
      if (savingsPercent > 100){
            savingsPercent = 100;
      }
      let retirementRotation = ((9/5 * savingsPercent) - 90).toFixed(2);
      if (retirementRotation > 90){
          retirementRotation = 90;
      }else{
          if (retirementRotation < -90){
              retirementRotation = -90;
          }
      }

      // retirementBase is a multiplier that is used to turn the percentages into a number 
      let retirementBase = ConfigFile.gameBoard.retirementBase;
      let retirementScore = (+savingsPercent * retirementBase).toFixed(0);
      let moodScore = (+moodPercent * +retirementBase).toFixed(0);
      let combinedScore = ((+retirementScore + +moodScore)/2).toFixed(0);
   
     let returnObj = {moodPercent: moodPercent, moodRotation: moodRotation, emojiURL: emojiURL,
            retirementPercent:  savingsPercent, retirementRotation: retirementRotation, totalSavings: totalSavingsAmt,
            estimatedAge: estimatedAge, savingsMultiplier: savingsMultiplier, totalRetirementSavings: totalRetirementSavings,
            avgYearlySpendingAmt: avgYearlySpendingAmt,  moodScore: moodScore, retirementScore: retirementScore,
            combinedScore: combinedScore};
     return returnObj
}
