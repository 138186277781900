const friendHaveFunActivityDetailDialogDataset = [

        {activityPreference: "2", activityName: "default",
                comments: ["This place is OK, but I've definitely seen better.",
                           "I hate to say it, but I'm kind of getting bored.",
                           "Ok, well this is sort of lame don't you think?",
                           "I can't believe that other people think this is fun.",
                           "Do you think we could leave soon?", 
                ]},
        {activityPreference: "3", activityName: "default",
                comments: ["Wow!  This is really fun don't you think?", 
                           "I can't believe the time is going by so quickly.", 
                           "This is really great and fun to share it with a friend!",
                           "This might be a little expensive, but it's worth every penny!!",
                           "Thank you so much for inviting me.  This is really fun!",
                ]},
        {activityPreference: "4", activityName: "default",
                comments: ["This is a lot of fun!",
                           "I can't remember when I've had so much fun!!",
                           "I wish this would go on forever!",
                           "I hope you are enjoying this as much as I am!!",
                           "It would be really fun to do this again some time."
                ]},
              
        {activityPreference: "2", activityName: "Skiing",
                comments: ["It is really cold out here.  I know it's early, but can we just call it a day?",
                           "My ski boots are really hurting my feet.",
                           "You ski way too fast, I really can't keep up.  Would you slow down please?",
                           "I am really not having fun.  I keep falling down.",
                           "Would you please just stick to the green slopes?  I like to stay on the easy ones.", 
                ]},
        {activityPreference: "4", activityName: "Skiing",
                comments: ["I really love skiing, don't you?",
                           "This is such a wonderful day for skiing.  Blue skies and great snow!",
                           "Do you want to race me down the hill on the next run?",
                           "I wish I could ski everyday, don't you?",
                           "Well, the wind is a bit chilly today, but I am having so much fun, I hardly notice.", 
                ]},

]
 
export default friendHaveFunActivityDetailDialogDataset;