import ExtraCreditConfigFile from "../Datasets/extra_credit_config_file";

export const determineIfAllExtraCreditComplete = (extraCreditTable) => {
    let allExtraCreditCompleted = false;
    const totalChallengesCompleted = extraCreditTable.length;
    let challengePoints = ExtraCreditConfigFile.challengePoints;
    let pointsObj = challengePoints.find(elem => elem.level === "total");
    const totalPoints = pointsObj.points;
    if (totalChallengesCompleted === totalPoints) {
        allExtraCreditCompleted = true;
    }
    return allExtraCreditCompleted;
}
export default determineIfAllExtraCreditComplete;  