const updateCreditCardData = {
      gameID: "65d22010fd5d67dd2c04f374",
      cardLevel: "9",
      creditCardStartDate: "2024-02",
      creditMaxAmount: "99999",
      currentlyActive: true,
      dateCreated: "2024/02/18",
      lateFeeCount: "",
      periodCreditCharges: [
        {
          date: "2024-02",
          periodBilledAmt: 0,
          periodAmtPaid: 0,
          periodIntAmt: 0,
          periodLateFee: 0,
          creditChargesArr: []
        }
      ],
      paymentPolicyCode: "testing",
      totalIntBilled: "99999.99",
      totalOutstandingBalance: "99999.99",
      dateAdded: "2024-02-18",
      dateUpdated: "2024-02-18"
}

export default updateCreditCardData;