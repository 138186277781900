import LinkTab from '../components/shared-components/link_tab';
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";
import CommentModal from '../components/shared-components/comment_modal';
import SkillsInterestsInput from '../components/getting-started/skills_interests_input';
import HomeSelect from '../components/getting-started/home_select';
import CarSelect from '../components/getting-started/car_select';
import CareerSelect from '../components/getting-started/career_select';
import FurnitureInput from '../components/getting-started/furniture_input';
import FinalConfirmModal from '../components/getting-started/final_confirm_modal';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import {ModalContext} from '../objects/Context/modal_context';
import { setupConditionsUpdate } from '../components/game-views/objects/set_up_conditions_update_function';
import React from 'react';
import { Link } from "react-router-dom";
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import DataObjectsTemplate from '../datasets/data_object_templates';
import ConfigFile from '../datasets/config_file';
import { getCurrentDateTime } from '../objects/CommonUse/get_current_date_time';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';
import {buildUrl } from '../objects/CommonUse/build_URL';
import Config from '../config';
import setModalSize from '../objects/CommonUse/set_modal_size';
import easyGameChoiceData from '../components/getting-started/datasets/easy_game_choice_data';
import midGameChoiceData from '../components/getting-started/datasets/mid_game_choice_data';
import difficultGameChoiceData from '../components/getting-started/datasets/difficult_game_choice_data';
import getExtraCreditForNewGame from '../objects/AssetsExpenses/get_extra_credit_for_new_game';
import getBadgesEarnedForNewGame from '../objects/AssetsExpenses/get_badges_earned_for_new_game';
import veryDifficultGameChoiceData from '../components/getting-started/datasets/very_difficult_game_choice_data';

class GettingStartedDetailed extends React.Component {
    constructor(props) {
        super(props);
    
        this.inputCompleted = (inputID) => {
            this.state.inputArr[inputID].completed = true;
            this.setState({inputArr: this.state.inputArr});
            if(inputID === 4)
            {
                this.toggleFinalModal();
            }
        };
        this.inputSelected = (inputID) => {
            for (const input of this.state.inputArr) {
                input.selected = false;
            }
            this.state.inputArr[inputID].selected = true;
            this.setState({inputArr: this.state.inputArr});
        };

        this.prevIndex = 0;
        this.openCommentModal = this.openCommentModal.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);

        this.state = {
            inputArr: [
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                },
                {
                    completed: false,
                    selected: false
                }
            ],
            inputCompleted: this.inputCompleted,
            inputSelected: this.inputSelected,
            commentIcon: "./assets/ContactUs/pencilPaper.png",
            commentModalOpen: false,
            fromProcessName: "GettingStarted",
            modalOpen: false,
            loading: true,
            conditionsList: null,
            archivedGameData: null,
            archivedLoading: true,
            extraCreditData: null,
            extraCreditLoaded: false,
            data: [],
            headers: setHeadersForFetch(),
            screenSize: sessionStorage.getItem("GameScreenSize"),
            environment: Config.dataURL.currentEnv,
            subProcess: "GettingStartedDetailed",
        };

        switch(props.selected) {
            case "skillsInterests":
                this.inputSelected(0);
                break;
            case "chooseCareer":
                this.inputSelected(1);
                break;
            case "chooseHome":
                this.inputSelected(2);
                break;
            case "chooseFurniture":
                this.inputSelected(3);
                break;
            case "chooseCar":
                this.inputSelected(4);
                break;
        }

        this.checkUserInput();
        this.loadExtraCredit();
        this.startNbrGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
      }

      render() {
           
            this.leftSideClass = "mt-4 ml-0 text-center";
            if (this.state.screenSize == "wide"){
                    this.workerClass = "worker-img-wide";
                    this.leftSideClass = "col-sm-2 " + this.leftSideClass;
                    this.rowClass = "row w-100 mx-0"
                    this.buttonClass = "col-sm-2 ml-5"
            }else{
                    this.workerClass = "worker-img-narrow mt-4";
                    this.leftSideClass= "col-sm-1.5 " + this.leftSideClass;
                    this.rowClass = "row w-100 mx-0 no-gutter";
                    this.buttonClass = "col-sm-2 mx-0";
            }
                            
      return <>
            {this.state.archivedLoading ?
               <WaitTimeSpinner/>
            :
            <span className="container m-0 p-0">
                <span className={this.rowClass}>
                    <div className={this.leftSideClass}>
                        <div>
                            <img className={this.workerClass} src={'./assets/businessPerson.png'} alt="Bus Person"/>
                            <img className={this.workerClass} src={'./assets/constructionWorker.png'} alt="Construction Worker"/>
                        </div>
                        <div>
                            <img className={this.workerClass} src={'./assets/doctor.png'}  alt="Doctor"/>
                            <img className={this.workerClass} src={'./assets/chef.png'} alt="Chef"/>
                        </div>
                        <div>
                            <img className={this.workerClass} src={'./assets/worker.png'} alt="Worker"/>
                            <img className={this.workerClass} src={'./assets/policeOfficer.png'} alt="Police"/>
                        </div>
                    </div>
                    <InputCompletedContext.Provider value={this.state}>

                        <div>
                            <a target="_blank" data-tip="Click to provide feedback about the game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className="comment-icon-position comment-img" alt="comment"
                                    onClick={this.openCommentModal}/>
                            </a>
                        </div>

                        {this.state.commentModalOpen ?
                            <CommentModal modalOpen={this.state.commentModalOpen} 
                                onClose={() => this.closeCommentModal()}
                                gamePart={this.state.fromProcessName}
                                processName={this.props.selected}
                                gameInfo = {() => this.getGameInfo()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                        :
                            null
                        }

                        {this.state.inputArr[1].completed &&
                            this.state.inputArr[2].completed &&
                            this.state.inputArr[3].completed &&
                            this.state.inputArr[4].completed  ?
                           <ModalContext.Provider value={this.state}>
                               {this.state.modalOpen === true ?
                                <FinalConfirmModal modalOpen={this.state.modalOpen} 
                                    gutters={this.gutters}
                                    extraCreditData={this.state.extraCreditData}
                                    badgesEarned={this.state.archivedGameData.badgesEarned}
                                    onClose={() => this.toggleFinalModal()} 
                                    onSave={() => this.finalizeOptions()}
                                    setExtraCredit={() => this.setExtraCreditEarned()}
                                    processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                   />
                                :
                                <span/>
                               }
                           </ModalContext.Provider>
                        :
                            <p></p>
                        }
                        {this.props.selected === "skillsInterests" ? 
                            <SkillsInterestsInput 
                                screenSize={this.state.screenSize}
                                nbrGames={this.totalNbrGames}
                                goBack = {() => this.goToHomePage()}
                                completed={() => this.inputCompleted(0)}
                                onOpen ={()=> this.inputSelected(0)}
                                skillSurvey = {this.state.archivedGameData.skillSurvey}
                                createGame = {(templateName) => this.createGameFromTemplate(templateName)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}/>
                            :
                            <span/>}
                        {this.props.selected === "chooseCareer" ? 
                            <CareerSelect
                                screenSize={this.state.screenSize}
                                completed={() => this.inputCompleted(1)}
                                onOpen ={()=> this.inputSelected(1)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> : <span/>}
                        {this.props.selected === "chooseHome" ? 
                            <HomeSelect 
                                screenSize={this.state.screenSize}
                                completed={() => this.inputCompleted(2)}
                                onOpen ={()=> this.inputSelected(2)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> : <span/>}
                        {this.props.selected === "chooseFurniture" ?
                             <FurnitureInput 
                                screenSize={this.state.screenSize}
                                completed={() => this.inputCompleted(3)}
                                onOpen ={()=> this.inputSelected(3)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> : <span/>}
                        {this.props.selected === "chooseCar" ? 
                            <CarSelect 
                                screenSize={this.state.screenSize}
                                completed={() => this.inputCompleted(4)}
                                onOpen ={()=> this.inputSelected(4)}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> : <span/>}
                        <div className={this.buttonClass}>
                            <Link to="skills-interests" onClick={() => this.handleClick(0)}>
                                <LinkTab  inputArr={this.state.inputArr[0]} name="Skills / Interests" validToComplete="true"
                                      screenSize={this.state.screenSize}/>
                            </Link>
                            {this.state.inputArr[0].completed ?
                                <Link to="choose-career" onClick={() => this.handleClick(1)}>
                                    <LinkTab inputArr={this.state.inputArr[1]} name="Choose a Job" validToComplete="true"
                                          screenSize={this.state.screenSize}/>
                                </Link>
                                :
                                <LinkTab inputArr={this.state.inputArr[1]} name="Choose a Job" screenSize={this.state.screenSize}/>
                            }
                            {this.state.inputArr[1].completed ?
                                <Link to="choose-home" onClick={() => this.handleClick(2)}>
                                    <LinkTab  inputArr={this.state.inputArr[2]} name="Choose a Home" validToComplete="true"
                                          screenSize={this.state.screenSize}/>
                                </Link>
                                :
                                <LinkTab  inputArr={this.state.inputArr[2]} name="Choose a Home" screenSize={this.state.screenSize}/>
                            }
                            {this.state.inputArr[1].completed ?
                                <Link to="choose-furniture" onClick={() => this.handleClick(3)}>
                                    <LinkTab inputArr={this.state.inputArr[3]} name="Choose Furniture" validToComplete="true"
                                          screenSize={this.state.screenSize}/>
                                </Link>
                                :
                                <LinkTab inputArr={this.state.inputArr[3]} name="Choose Furniture"  screenSize={this.state.screenSize}/>
                            }
                            {this.state.inputArr[1].completed ?
                                <Link to="choose-car" onClick={() => this.handleClick(4)}>
                                    <LinkTab inputArr={this.state.inputArr[4]} name="Choose a Car" validToComplete="true"
                                          screenSize={this.state.screenSize}/>
                                </Link>
                                :
                                <LinkTab inputArr={this.state.inputArr[4]} name="Choose a Car"  screenSize={this.state.screenSize}/>
                            }
                            {this.state.inputArr[1].completed &&
                            this.state.inputArr[2].completed &&
                            this.state.inputArr[3].completed &&
                            this.state.inputArr[4].completed  ?
                            <Link to="#" onClick={() => this.toggleFinalModal()}>
                                <LinkTab inputArr={this.state.inputArr[5]} name="Play the Game" validToComplete="true"
                                      screenSize={this.state.screenSize}/>
                            </Link>
                            :
                            <LinkTab inputArr={this.state.inputArr[5]} name="Play the Game"  screenSize={this.state.screenSize}/>
                            }
                        </div>
                    </InputCompletedContext.Provider>
                </span>
            </span>
            }
        </>
    }

    componentDidMount(){
        this.loadConditions();
        this.getArchivedGameData();
    }

    loadConditions()
    {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=gameStart,jobChange,homeChange,carChange";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        if (output.body != null) {
                            this.state.conditionsList = output.body;
                        }
                        this.setState({loading: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }
    
    getArchivedGameData()
    {
        const funcName = "archivedGameData";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.archivedGameData = output.body;
                        this.getMostRecentSkillSurvey() 
                        this.setState({archivedLoading: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    getMostRecentSkillSurvey(){
        let nbrArchivedGames = this.archivedGameData.length;
        this.totalPrevGameMonths = 0;
        this.totalNbrGames = 0;
        for (let i=0; i< this.archivedGameData.length; i++){
            if (this.archivedGameData[i].gameNbrMonths !== undefined){
                const archiveGame = this.archivedGameData[i];
                let startNbrMonths = 0;
                if (archiveGame.timeWizardInfo === undefined || 
                    archiveGame.timeWizardInfo.status === undefined ||
                    archiveGame.timeWizardInfostatus === 'inactive'){
                        startNbrMonths = +this.startNbrGameMonths;
                }else{
                        startNbrMonths = +archiveGame.timeWizardInfo.gameNbrYears * 12;
                }
                this.totalPrevGameMonths += (startNbrMonths - +archiveGame.gameNbrMonths);
                this.totalNbrGames += 1;
            }
        }
        if (nbrArchivedGames != undefined && nbrArchivedGames > 0){
            let slctIndx = nbrArchivedGames - 1;
            this.state.archivedGameData = {...this.archivedGameData[slctIndx]};
            //  if the badges earned is undefined then add it to most recent game so won't be an error downstream
            if (this.state.archivedGameData.badgesEarned == undefined){
                this.state.archivedGameData = {...this.state.archivedGameData, badgesEarned: []};
            }
        }else{
            this.state.archivedGameData = {skillsSurvey: undefined};
        }
    }

    loadExtraCredit()
    {
        const funcName = "loadExtraCreditInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200 || statusCode == 250){
                        this.state.extraCreditData = output.body;
                        this.setState({extraCreditLoaded: true});
                    }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    getGameInfo(){
        return {process: "Getting Started Info"};
    }
    
    openCommentModal(){
        this.state.commentModalOpen = true;
    }

    closeCommentModal(){
        this.state.commentModalOpen = false;
        this.setState({commentModalOpen: false});
    }

    handleClick = index => {
        this.state.inputSelected(index);
    }

    toggleFinalModal() {
        for (let i = 0; i < this.state.inputArr.length; ++i) {
            if (this.state.inputArr[i].selected === true && i !== 5)
            {
                this.prevIndex = i;
                break;
            }
        }
        this.setState({modalOpen: !this.state.modalOpen});
        if (this.state.modalOpen === false) this.state.inputSelected(5);
        else this.state.inputSelected(this.prevIndex);
        this.gutters = setModalSize(this.state.screenSize);
    }

    createGameFromTemplate(templateName){
        let gameTemplate = {};
        switch (templateName){
            case "easyGame":
                gameTemplate = easyGameChoiceData;
                break;
            case "midGame":
                gameTemplate = midGameChoiceData;
                break;
            case "difficultGame":
                gameTemplate = difficultGameChoiceData;
                break;
            case "veryDifficultGame":
                gameTemplate = veryDifficultGameChoiceData;
                break;
            default:
                break;
        }
        this.setCurrentYYYYMM();
        this.determineBodyObj(gameTemplate);
        this.createGamesCollection();
    }

    setCurrentYYYYMM(){
        var today = new Date();
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        this.currentYYYYMM = `${yyyy}-${mm}`;
    }
    
    determineBodyObj(gameTemplate){
        const username = sessionStorage.getItem("username");
        this.currentCash = +gameTemplate.currentCash;
        this.addExtraCreditAndBadges(gameTemplate.assetTable);
        if (this.allExtraCreditEarned === true){
            this.createNewCondition(gameTemplate.conditionsTable);
        }
        this.currentCash = this.currentCash.toString();
        let carInfo = gameTemplate.carInfo;
        carInfo.carGameDate = this.currentYYYYMM;
        this.bodyObj = {
           "username": username,
           "jobInfo": gameTemplate.jobInfo,
           "homeInfo": gameTemplate.homeInfo,
           "furnitureID": gameTemplate.furnitureID,
           "carInfo": carInfo,
           "startingCash": this.currentCash,
           "currentCash": this.currentCash,
           "currentMoodPoints": gameTemplate.currentMoodPoints,
           "startGameDate": this.currentYYYYMM,
           "currentGameDate": this.currentYYYYMM,
           "assetTable": this.assetArr,
           "expenseTable": gameTemplate.expenseTable,
           "loanTable": gameTemplate.loanTable,
           "skillSurvey": gameTemplate.skillSurvey,
           "conditionsTable": gameTemplate.conditionsTable,
           "friendList": gameTemplate.friendList,
           "spendingHistory": gameTemplate.spendingHistory,
           "moodPointHistory": gameTemplate.moodPointHistory,
           "gameNbrMonths": gameTemplate.gameNbrMonths,
           "iconTable": gameTemplate.iconTable,
           "badgesEarned": this.badgesEarnedArr,
           "prevGameMonths": this.totalPrevGameMonths.toString(),
           "prevNbrGames": this.totalNbrGames.toString(),
           "gameTemplate": gameTemplate.gameTemplateName
       };
  //     this.loadConditions();
  //     let conditionClasses = [];
  //     this.bodyObj.conditionsTable = 
  //         setupConditionsUpdate(this.bodyObj, this.state.conditionsList, conditionClasses);
   }

   addExtraCreditAndBadges(assetTable){
        this.assetArr = assetTable;
        if (this.state.extraCreditData.user !== undefined){
            let extraCreditArr = this.state.extraCreditData.extraCreditTable;
            let extraCreditObj = getExtraCreditForNewGame(extraCreditArr, this.assetArr, this.currentYYYYMM);
            this.assetArr = extraCreditObj.assetArr;
            this.currentCash += +extraCreditObj.totalCashIncrease;
            this.allExtraCreditEarned = extraCreditObj.allExercisesComplete;
        }
        if (this.state.archivedGameData.badgesEarned !== undefined &&
            this.state.archivedGameData.badgesEarned.length != 0){
            let badgesEarnedArr = this.state.archivedGameData.badgesEarned;
            let badgesEarnedObj = getBadgesEarnedForNewGame(badgesEarnedArr, this.assetArr, this.currentYYYYMM);
            this.assetArr = badgesEarnedObj.assetArr;
            this.badgesEarnedArr = badgesEarnedArr;
            this.currentCash += +badgesEarnedObj.totalCashIncrease;    
        }else{
            this.badgesEarnedArr = [];
        }
   }

   createNewCondition(conditionTable){
        const conditionObj={condName: "all-extra-credit-complete", conditionClass: "game"};
        conditionTable.push(conditionObj);
   }
    
    finalizeOptions() {
        this.buildGamesCollection();
        this.removeSessionStorage();
        this.createGamesCollection();
    }

    buildGamesCollection(){
        // either change these values to 'this.' or put bodyObj back in this process....OR....
        const username = sessionStorage.getItem("username");
        const careerID = sessionStorage.getItem("careerChoice");
        const nbrYearsCollege = sessionStorage.getItem("jobNbrYearsCollege");
        const jobRank = sessionStorage.getItem("jobRank");
        const jobHealthInsInd = sessionStorage.getItem("jobHealthInsInd");
        const homeID = sessionStorage.getItem("homeChoice");
        const homeType = sessionStorage.getItem("homeType");
        const roommates = sessionStorage.getItem("homeRoommates");
        const bedrooms = sessionStorage.getItem("homeBedrooms");
        const furnitureID = sessionStorage.getItem("furnitureChoice");
        const carID = sessionStorage.getItem("carChoice");
        const startingCash = sessionStorage.getItem("startingCash");
        const assets = JSON.parse(sessionStorage.getItem("assetObjects", this.assetObj));
        const expenses = JSON.parse(sessionStorage.getItem("expenseObjects"))
        const loanData = JSON.parse(sessionStorage.getItem("loanDataObjects"));
        const currentYYYYMM = sessionStorage.getItem("currentYYYYMM");
        // carYear is current game year minus the yearAdj for car selected
        const yearAdj = sessionStorage.getItem("carYearAdj");
        const yearMonthVals = currentYYYYMM.split('-'); 
        const carYear = (+yearMonthVals[0] - +yearAdj).toString();
        const carAge = (+yearMonthVals[0] - carYear) * 12;
        const carType = sessionStorage.getItem("carSize");
        const skillSurvey = (sessionStorage.getItem('skillsInterestsInput')).split(",");
        const carInfo={carID: carID, carAge: carAge, carYear: carYear, carType: carType,
            carGameDate: currentYYYYMM};
        const homeInfo =  {homeID: homeID, homeType: homeType, roommates: roommates, bedrooms: bedrooms};
        const jobInfo = {careerID: careerID, healthInsInd: jobHealthInsInd, jobRank: jobRank,
            nbrYearsCollege: nbrYearsCollege, nbrSkillSurvey: "1",
            jobStartGameMonth: this.startNbrGameMonths};
        this.determineFriendList();
        const spendingHistory = JSON.parse((sessionStorage.getItem("spendingHistory")));
        const currentMoodPoints = sessionStorage.getItem("startingMoodPoints");
        const moodPointHistory = JSON.parse((sessionStorage.getItem("moodPointHistory")));
        const gameNbrMonths = this.startNbrGameMonths;
        this.createIconTableInfoEntry();
        let badgesEarned = [];
        if (this.state.archivedGameData.badgesEarned != undefined){
            badgesEarned = this.state.archivedGameData.badgesEarned;
        }
        const dateObj = getCurrentDateTime();
        const dateAdded = dateObj.date;
        const dateUpdated = dateObj.date;
        this.bodyObj = {
            "username": username,
            "jobInfo": jobInfo,
            "homeInfo": homeInfo,
            "furnitureID": furnitureID,
            "carInfo": carInfo,
            "startingCash": startingCash,
            "currentCash": startingCash,
            "currentMoodPoints": currentMoodPoints,
            "startGameDate": currentYYYYMM,
            "currentGameDate": currentYYYYMM,
            "assetTable": assets,
            "expenseTable": expenses,
            "loanTable": loanData,
            "skillSurvey": skillSurvey,
            "conditionsTable": [],
            "friendList": this.friendArr,
            "spendingHistory": spendingHistory,
            "moodPointHistory": moodPointHistory,
            "gameNbrMonths": gameNbrMonths,
            "iconTable": this.iconTable,
            "badgesEarned": badgesEarned,
            "prevGameMonths": this.totalPrevGameMonths.toString(),
            "prevNbrGames": this.totalNbrGames.toString(),
            "gameTemplate": "none",
            "dateAdded": dateAdded,
            "dateUpdated": dateUpdated,
        };
       
        this.loadConditions();
        let conditionClasses = [];
        this.bodyObj.conditionsTable = 
            setupConditionsUpdate(this.bodyObj, this.state.conditionsList, conditionClasses);
        if (this.allExtraCreditEarned === true){
            this.createNewCondition(this.bodyObj.conditionsTable);
        }
    }

    setExtraCreditEarned(){
        //  this is called by the final_confirm_modal if all extra credit exercises have been completed.
        this.allExtraCreditEarned = true;
    }
        
    
    determineFriendList(){
        let dataObj =  DataObjectsTemplate.find(elem => elem.dataType === "friendList");
       
        this.friendArr = [];
        let friendObj = JSON.parse(JSON.stringify(dataObj.friendInfo));
        friendObj.name = "Charlie";
        this.friendArr.push(friendObj);
        friendObj = JSON.parse(JSON.stringify(dataObj.friendInfo));
        friendObj.name = "Cameron";
        this.friendArr.push(friendObj);
        friendObj = JSON.parse(JSON.stringify(dataObj.friendInfo));
        friendObj.name = "Jennifer";
        this.friendArr.push(friendObj);
        friendObj = JSON.parse(JSON.stringify(dataObj.friendInfo));
        friendObj.name = "Cindy";
        this.friendArr.push(friendObj);
    }

    createIconTableInfoEntry(){
        this.iconTable = [];
        let infoIcon = { iconName: "gameBasicHelp", iconCategory: "information", 
            className: "small_sign_thumbnail_img information-sign-position",
            imageSrc: "gameHelp.png", validInd: true, firstTime: true, additionalInfo: ""};
        this.iconTable.push(infoIcon);
    }
    
    removeSessionStorage(){
        sessionStorage.removeItem("careerChoice");
        sessionStorage.removeItem("homeChoice");
        sessionStorage.removeItem("furnitureChoice");
        sessionStorage.removeItem("carChoice");
        sessionStorage.removeItem("startingCash");
        sessionStorage.removeItem("assetObjects");
        sessionStorage.removeItem("expenseObjects");
        sessionStorage.removeItem("loanDataObjects");
        sessionStorage.removeItem('skillsInterestsInput');
    }

    createGamesCollection(){    
        const funcName = "saveGameSelections";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
                method:'post',
                headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
                })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.props.history.push('/game-board');
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }

    checkUserInput()
    {
        if (sessionStorage.getItem('skillsInterestsInput')) this.state.inputArr[0].completed = true;
        if (sessionStorage.getItem('careerChoice')) this.state.inputArr[1].completed = true;
        if (sessionStorage.getItem('homeChoice')) this.state.inputArr[2].completed = true;
        if (sessionStorage.getItem('furnitureChoice')) this.state.inputArr[3].completed = true;
        if (sessionStorage.getItem('carChoice')) this.state.inputArr[4].completed = true;
        this.setState({inputArr: this.state.inputArr});
    }

    goToHomePage(){
        this.props.history.push('/');
    }
    
    processErrorReturn(errorObj){
        this.addRecordToLogfile(errorObj);
        if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
            alert ("Uh oh!  Something unexpected has occurred " +
                "error has been logged and will be addressed. " +
                "For now click OK, then return to the process " +
                "where you were and try again.");
            this.props.history.push('/');
        }      
    }

    addRecordToLogfile(errorObj){
        if (errorObj.status == 250){
            errorObj.message = "No record found";
        }
        if (errorObj.errorObject == undefined){
            errorObj.errorObject = {};
        }
        const logFileBody =  {
            mainProcess: "game",
            subProcess: errorObj.subProcess,
            function: errorObj.function,
            status: errorObj.status,
            message: errorObj.message,
            errorObj: errorObj.errorObject
        }
        console.log("logFileBody is: ", logFileBody)
        const urlParm = "";
        const funcName = "saveLogFile";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'POST',
                headers: this.state.headers,
                body: JSON.stringify(logFileBody)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (this.status = 200){
                            console.log("write to logfile was successful");
                            this.logFileWriteComplete = true;
                        }else{
                            console.log("Response from save log file not valid!" +
                            " status is: ", output.statusCode,
                            " message is: ", output.body);
                           alert ("Response from save log file not valid!");
                           
                        };
                   
                });
                this.props.history.push('/');
            }
        });
      
    }
}

export default withRouter(GettingStartedDetailed);