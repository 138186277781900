import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../shared-components/link_card';
import LivingBelowYourMeans from './living_below_your_means'
import LearningAboutLoans from './learning_about_loans';
import SavingMoney from './saving_money';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class Level2Challenges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            callRender: false,
            modalOpen: false,
            prevProcess: this.props.prevProcess,
          
                }  
        this.nextProcess = "";
        this.category1Complete = false;
        this.category2Complete = false;
        this.category3Complete = false;
        this.category1Name = "LivingBelowYourMeans";
        this.category2Name = "SavingMoney";
        this.category3Name = "LearningAboutLoans";
        this.extraCreditReward = ExtraCreditConfigFile.exercises;
        this.setCompleted();  
       
    }

setCompleted(){
    let exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category1Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category1Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category2Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category2Complete = true;
    }
    exerciseInfo = this.props.extraCreditCompleted.find(elem => elem.category === this.category3Name);
    if (exerciseInfo !== undefined && exerciseInfo.complete === true){
        this.category3Complete = true;
    }
}


selectLivingBelowChallenge() {
    if (this.category1Complete === false){
        this.nextProcess = this.category1Name;
        this.setState({modalOpen: true});
    }
}

selectSavingMoneyChallenge() {
    if (this.category2Complete === false){
        this.nextProcess = this.category2Name;
        this.setState({modalOpen: true});
    }
}

selectLearningAboutLoansChallenge() {
    if (this.category3Complete === false){
        this.nextProcess = this.category3Name;
        this.setState({modalOpen: true});
    }
}

closeModal(){
    this.setState({modalOpen: false});
}


render() {
        if (this.nextProcess !== ""){
            sessionStorage.setItem("GameViewSubProcess", this.nextProcess)
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit");
        this.className = screenParms.columnClass;
        this.linkSize = screenParms.linkSize;
        
return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg h-100 text-center">
                <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
                </button>
               <h3 className="mt-0 text-center">Level 2 Challenges</h3>
           
            <span>
            <span className="row w-100 mx-0 ml-4">
                <span/>
               
                {this.category1Complete ?
                    <span className="col-sm-5 mx-5 mt-0 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Living Below Your Means" imgSrc='./assets/ExtraCredit/livingBelowMeans.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-5 mx-5 mt-0 cursor-is-pointer" 
                        onClick={() => this.selectLivingBelowChallenge()}>
                        <div>
                        <LinkCard name="Living Below Your Means" imgSrc='./assets/ExtraCredit/livingBelowMeans.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
         
            {this.category2Complete ?
                    <span className="col-sm-5 mx-2 mt-0 buttonDisabledImage">
                        <div>
                            <LinkCard name="Saving Money" imgSrc='./assets/ExtraCredit/saveMoney.png'
                                inSize={this.linkSize} alt=""
                        />
                        </div>
                    </span>
                :
                    <span className="col-sm-5 mx-2 mt-0 cursor-is-pointer"
                        onClick={() => this.selectSavingMoneyChallenge()}>
                        <div>
                        <LinkCard name="Saving Money" imgSrc='./assets/ExtraCredit/saveMoney.png'
                            inSize={this.linkSize} alt=""
                            />
                        </div>
                    </span>
                }
            </span>
  
            <span className="row w-100 mx-0 ml-4">
            {this.category3Complete ?
                    <span className="col-sm-6 mx-5 my-4 buttonDisabledImage" >
                        <div>
                        <LinkCard name="Learning About Loans" imgSrc='./assets/ExtraCredit/learningAboutLoans.png' 
                            inSize={this.linkSize} alt=""/>
                         </div>
                    </span>
                :
                    <span className="col-sm-6 mx-5 my-4 cursor-is-pointer" 
                        onClick={() => this.selectLearningAboutLoansChallenge()}>
                        <div>
                        <LinkCard name="Learning About Loans" imgSrc='./assets/ExtraCredit/learningAboutLoans.png' 
                            inSize={this.linkSize} alt=""/>
                        </div>
                    </span>
                }
            </span>
            </span>
       
          
             {this.nextProcess === this.category1Name ?
               <LivingBelowYourMeans
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category1Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
              {this.nextProcess === this.category2Name ?
                <SavingMoney
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category2Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
            {this.nextProcess === this.category3Name ?
               <LearningAboutLoans 
                    screenSize={this.props.screenSize}
                    show={this.state.modalOpen}
                    onCompleted={() => this.challengeCompleted(this.category3Name)}
                    onClose={() => this.closeModal()}
                /> 
            :
                <div></div>
            }
        </div>
       
    </div>   
</>
  }
  
    challengeCompleted(processName) {
        this.props.updateAmounts();
        const exerciseInfo = this.extraCreditReward.find(elem => elem.category === processName);
        let typeLit = "";
        if (exerciseInfo.type === "salary"){
             typeLit = "monthly salary";
        }else{
             typeLit = "current cash";
        }
        const alertText = "Congratulations!  Your " + typeLit + " has been increased by " +
                doubleToStringMonthly(exerciseInfo.amt) + ".";
        alert (alertText);
      
        if (processName === this.category1Name){
            this.category1Complete = true;
        }
        if (processName === this.category2Name){
            this.category2Complete = true;
        }
        if (processName === this.category3Name){
            this.category3Complete = true;
        }
              
        if (this.category1Complete === true && this.category2Complete === true 
            && this.category3Complete === true){
            this.props.completed(); 
            this.props.history.push('/extra-credit-main');
        }else{
            this.setState({modalOpen: false});
        }
    }

}

export default withRouter(Level2Challenges);