import MapDataToColumnsDataset from "../../datasets/map_data_to_columns_dataset";
import createFieldValueFromObject from "./createFieldValueFromObject";
import CarChangeDisplayAmts from "../../components/game-views/datasets/car_change_display_amts";
import FunStuffDisplayAmts from "../../components/game-views/datasets/fun_stuff_display_amts";

export const buildArrayForDisplay = (process, type, object) => {
    //  this process does not currently support creating tables that have more than 6 columns
    //  process determines the name of the dataset that contains the variable names
    //  type determines the 'type' within the process dataset
    //  object contains the variable names and the values
    //  in the 'DisplayAmts' datasets, replace all commas in field names (textLine) with @
    let fieldNameValueArr = createFieldValueFromObject(object);
    let displayDataset = {};
    
    switch (process){
        case "CarChange":
            const carChangeDisplayObj = CarChangeDisplayAmts.find(elem => elem.type === type);
            displayDataset = carChangeDisplayObj.textLineArr;
            break;
        case "FunStuff":
            const funStuffDisplayObj = FunStuffDisplayAmts.find(elem => elem.type === type);
            displayDataset = funStuffDisplayObj.textLineArr;
        default:
            break;
    }
    //   note could do another find for 'type' if the process needs multiple types
    let datasetDefinition = MapDataToColumnsDataset.find(elem => elem.datasetName === process);
    if (datasetDefinition === undefined){
         datasetDefinition = MapDataToColumnsDataset.find(elem => elem.datasetName === "default");
    }
    let tableLineArr = [];
    for (let i=0; i<displayDataset.length; i++){
        const displayDatasetArr = createFieldValueFromObject(displayDataset[i]);
        const columnArr = [];
        for (let j=0; j<datasetDefinition.columnArr.length; j++){
            let varField = false;
            let columnName = datasetDefinition.columnArr[j].fieldName;
            let columnValueObj = displayDatasetArr.find(elem => elem.field === columnName);
            let columnValue = undefined;
            if (columnValueObj === undefined){
                columnValue = undefined;
            }else{
                columnValue = columnValueObj.value;
            }
            columnValue = columnValue.replace(/@/g, ",");
            if (columnValue.substring(0,3) === "VAR"){
                varField = true;
                const stringEnd = columnValue.length;
                columnName = columnValue.substring(3, stringEnd);
                const varColumnValueObj = fieldNameValueArr.find(elem => elem.field === columnName);
                if (varColumnValueObj === undefined){
                    columnValue = undefined
                }else{
                    columnValue = varColumnValueObj.value;
                }
            }
            let columnClass = "";
            const defColumnArr = datasetDefinition.columnArr[j];
            if (defColumnArr.condClass === undefined){
                if (defColumnArr.class !== undefined){
                    columnClass = defColumnArr.class;
                }
            }else{
                if (displayDataset[i].classCond === true &&
                    datasetDefinition.classCondArr.includes(defColumnArr.fieldName)){
                    columnClass = defColumnArr.condClass;
                }else{
                    columnClass = defColumnArr.class;
                }
            }
            if (varField === true && displayDataset[i].skipIfZero === true && +columnValue === 0){
                //  skip this line
                columnArr.splice(0, columnArr.length);
            }else{
                const columnObj = {fieldValue: columnValue, 
                    fieldType: datasetDefinition.columnArr[j].fieldType,
                    fieldClass: columnClass};
                columnArr.push(columnObj);
            }
        }
        // if the amount was 0 and skip this line is true, then this line will not be 
        // added to the tableLineArr
        if (columnArr.length > 0){
            let tableLineObj = {tableLine: columnArr}
            tableLineArr.push(tableLineObj);
        }
    }
    
    return tableLineArr;
 
}
export default buildArrayForDisplay;
