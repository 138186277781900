const newArchiveCreditCard = 
{
    "gameID": "88888888",
    "paymentPolicyCode": "autoPayMin",
    "currentlyActive": true,
    "creditCardStartDate": "2026-08",
    "cardLevel": "4",
    "creditMaxAmount": "10000",
    "totalOutstandingBalance": "0",
    "totalIntBilled": "0",
    "lateFeeCount": "0",
    "periodCreditCharges": [
      {
        "date": "2028-10",
        "periodBilledAmt": 9376.68,
        "periodAmtPaid": "1125.21",
        "periodIntAmt": "415.3525",
        "periodLateFee": 0,
        "creditChargesArr": [
          {
            "amount": 346.68,
            "desc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
            "category": "Transportation"
          },
          {
            "amount": 1530,
            "desc": "Food, Clothes, Personal Items, etc.",
            "category": "Misc Expenses"
          }
        ]
      },
      {
        "date": "2029-01",
        "periodBilledAmt": 10952.050000000001,
        "periodAmtPaid": 6935.68,
        "periodIntAmt": "495.8804",
        "periodLateFee": 0,
        "creditChargesArr": [
          {
            "amount": 346.68,
            "desc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
            "category": "Transportation"
          },
          {
            "amount": 1530,
            "desc": "Food, Clothes, Personal Items, etc.",
            "category": "Misc Expenses"
          }
        ]
      },
      {
        "date": "2029-04",
        "periodBilledAmt": 6876.68,
        "periodAmtPaid": "825.21",
        "periodIntAmt": "287.5605",
        "periodLateFee": 0,
        "creditChargesArr": [
          {
            "amount": 346.68,
            "desc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
            "category": "Transportation"
          },
          {
            "amount": 1530,
            "desc": "Food, Clothes, Personal Items, etc.",
            "category": "Misc Expenses"
          }
        ]
      },
      {
        "date": "2029-07",
        "periodBilledAmt": 0,
        "periodAmtPaid": 0,
        "periodIntAmt": 0,
        "periodLateFee": 0,
        "creditChargesArr": []
      }
    ],
  }

export default newArchiveCreditCard;