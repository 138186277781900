export const createFieldValueFromObject = (object) => {
    //  this function changes an object to an array where each entry in the array
    //  has an object which contains the 'field name' and the 'field value' of the object
    let fieldValueString = JSON.stringify(object);
    fieldValueString = fieldValueString.replace(/}/g, '');
    fieldValueString = fieldValueString.replace(/{/g, '');
    let fieldValueUneditedArr = fieldValueString.split(',');
    let fieldValueArr = [];
    for (let i=0; i<fieldValueUneditedArr.length; i++){
            fieldValueUneditedArr[i] = fieldValueUneditedArr[i].replace(/"/g, '');
            const fieldValue = fieldValueUneditedArr[i].split(':');
            const fieldValueObj = {field: fieldValue[0], value: fieldValue[1]};
            fieldValueArr.push(fieldValueObj)
    } 
    return fieldValueArr;
       
}
export default createFieldValueFromObject;
