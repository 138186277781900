import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import SideHustleInitialText from './text/side_hustle_intro_text';
import SelectActivityCard from './select_activity_card';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import VolunteerInitialText from './text/volunteer_intro_text';
import HaveFunInitialText from './text/have_fun_initial_text';
import VacationInitialText from './text/vacation_initial_text';
import { calcInflationAmt } from '../../objects/CommonUse/calc_inflation_amt';
import ConfigFile from '../../datasets/config_file';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class SelectActivity extends React.Component {
    constructor(props) {
        super(props);
        props.onOpen();
        this.activityInfo = this.props.activityInfo();
        this.state = {
            loadingActivities: true,
            loadingActivityTracker: true,
            cashLevelBoost: +ConfigFile.activities.levelCashBoost,
            subProcess: "selectActivity",
            environment: Config.dataURL.currentEnv,
            headers: setHeadersForFetch(),
        }  
        this.activityData = null;
        this.activityCategory = "";
        this.initializeDone = false;
        this.initializeActivityInfo();  
    }

  initializeActivityInfo(){
    if (this.props.location.state !== undefined){
        this.activityCategory = this.props.location.state.detail;
        this.gameNbrMonths = this.props.location.state.totalNbrGameMonths;
        this.props.setActivityInfo(this.gameNbrMonths, 'gameNbrMonths');
    }else{
        this.activityCategory = this.activityInfo.slctActivity.activityCategory;
        this.gameNbrMonths = this.props.activityInfo.gameNbrMonths;
    }
    sessionStorage.setItem("GameViewSubProcess", this.activityCategory);
    this.categoryFullName = this.activityCategory;
    if (this.activityCategory === "HaveFun"){
        this.categoryFullName = "Do Something Fun";
    }else{
        if (this.activityCategory === "Vacation"){
            this.categoryFullName = "Go On Vacation";
        }
    }
    this.loadGame();
    this.updateIntroText();
  }  

  updateIntroText(){
    switch(this.activityCategory) {
        case "SideHustle":
            this.introText = SideHustleInitialText;
            break;
        case "Volunteer":
            this.introText = VolunteerInitialText;
            break;
        case "HaveFun":
            this.introText = HaveFunInitialText;
            break;
        case "Vacation":
            this.introText = VacationInitialText;
            break;
        default:
            break;
    }
  }  

render() {
    if (this.initializeDone === false){
        if (this.activityInfo.loadingGame === false && this.state.loadingActivities === false &&
            this.state.loadingActivityTracker === false){
                this.initializeDone = true;
                this.calcInflationAmts();
                this.addLevelBonus();
        }
    }
        
    let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
    this.className = screenParms.columnClass;
    if (this.initializeDone === true){
        this.activityChoices = this.activityData.map((activity, key) =>
            <SelectActivityCard key={key} activityInfo={activity} 
                textSize={screenParms.textSize}
                screenSize={this.props.screenSize}
                onSelected={(activity) => this.onActivitySelected(activity)}
                />
            );
    }
                         
    return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg text-center activity-board height-max">
            <h3 className="mt-2 text-center">Activities</h3>
            <h5 className="mx-2 mt-0 text-center">{this.categoryFullName}</h5>
            <div className="overflow-scroll">
            <div className="mx-4 my-2 text-left">
                    {this.introText} 
            </div>
            {this.activityInfo.loadingGame || this.state.loadingActivities || this.state.loadingActivityTracker ?
                <WaitTimeSpinner />
            :
            <span>
                <div className="mt-4 row-70">
                    {this.activityChoices}
                </div>
                <div className="mb-2">
                    <Button variant="primary"  onClick={() => this.props.onClose()}>
                        Go Back to GameBoard
                    </Button>
                </div>
            </span>
            }
        </div>
        </div>
        </div>
    </>
  }

  componentDidMount(){
        this._isMounted = true;
  }

  componentWillUnmount(){
        this._isMounted = false;
  }

  onActivitySelected(activity){
        this.props.onComplete();
        this.props.setActivityInfo(activity, 'slctActivity');
        if (this.activityCategory === "SideHustle"){
            this.props.history.push('/start-activity');
        }else{
            this.checkFriendList();
            if (this.friendsMet === true){
                this.props.history.push('/choose-a-friend');
            }else{
                this.props.history.push('/start-activity')
            }
        }
  }

  checkFriendList(){
     this.friendsMet = false;
     let friendList = this.activityInfo.gameData.friendList;
     for (let i=0; i<friendList.length; i++){
        if (friendList[i].introDate !== undefined &&
            friendList[i].introDate !== ""){
                this.friendsMet = true;
                break;
        }
     }
  }
       
  loadGame()
  {
    const funcName = "loadGame";
    const urlParm = "?mode=fullLoad";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
            {method:'GET', headers: this.state.headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode === 200){
                            this.props.setActivityInfo(output.body, 'gameData');
                            this.props.setActivityInfo(false, 'loadingGame');
                            this.activityInfo.gameData = output.body;
                            this.loadActivities();
                            this.loadGameActivityTracker();
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }
    
  loadActivities()
  {
      let season = "all";
      let yearMonth = this.activityInfo.gameData.currentGameDate.split("-");
      let month = +yearMonth[1];
      if (month > 4 && month < 11){
           season="summer";
      }else{
           season="winter"
      }
      let sampleSize = 9999;
      if (this.activityCategory === "Vacation"){
          sampleSize = 4;
      }
    const funcName = "activityList";
    const urlParm = "?activityCategory=" + this.activityCategory + "&season=" + season +
      "&sampleSize=" + sampleSize;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
       {method:'GET', headers: this.state.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.activityData = output.body;
                    if (this._isMounted === true){
                        this.setState({loadingActivities: false});
                    }
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }
 
  loadGameActivityTracker(){
    const funcName = "loadGameActivityTracker";
    let gameID = this.activityInfo.gameData._id;
    const urlParm = "?gameID=" + gameID + "&category=" + this.activityCategory;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.noActivityTrackerData = false;
                        this.activityInfo.activityTracker = output.body;
                        this.props.setActivityInfo(output.body, "activityTracker");
                    }else{
                        if (statusCode === 250){
                            this.props.setActivityInfo("noData", "activityTracker");
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    }
                    if (this._isMounted === true){
                        this.setState({loadingActivityTracker: false});
                    }
                });
            };
      
        });
}

 
calcInflationAmts(){
    let nbrMonthsPlayed = +this.gameNbrMonths - 
        this.activityInfo.gameData.gameNbrMonths;
    let nbrYears = (nbrMonthsPlayed / 12).toFixed(0);
    let amount = 0;
    let inflatedAmt = 0;
    for (let i=0; i<this.activityData.length; i++){
        amount = +this.activityData[i].maxEarnedAmt;
        if (amount > 0){
            inflatedAmt = calcInflationAmt(amount, nbrYears);
            this.activityData[i].maxEarnedAmt = inflatedAmt;
        }
        amount = +this.activityData[i].maxCost;
        if (amount > 0){
            inflatedAmt = calcInflationAmt(amount, nbrYears);
            this.activityData[i].maxCost = inflatedAmt;
        }
       
    }
  }
  
    addLevelBonus(){
        // calculate the maximum amounts for display only (actual amts are calculated in the FinishActivity process)
        let amount = 0;
        for (let i=0; i<this.activityData.length; i++){
            amount = +this.activityData[i].maxEarnedAmt;
            if (amount > 0){
                this.getLevelNbr(this.activityData[i].shortName);
                let levelBoost = this.state.cashLevelBoost * (this.level - 1);
                this.activityData[i].maxEarnedAmt = 
                    +this.activityData[i].maxEarnedAmt + +levelBoost;
            }
            amount = +this.activityData[i].maxMoodPoints;
            this.activityData[i] = {...this.activityData[i], estMaxMoodPoints: 0};
            if (amount > 0 && this.activityCategory !== "SideHustle"){
                this.getLevelNbr(this.activityData[i].shortName);
                let levelBoost = this.state.cashLevelBoost * (this.level - 1);
                this.activityData[i].maxMoodPoints = 
                    ((+this.activityData[i].maxMoodPoints + +levelBoost)).toFixed(0);
                // multiplying by 1.5 as that is the max a player can get if they
                // invite a friend - this amount is only for display
                this.activityData[i].estMaxMoodPoints = 
                    (+this.activityData[i].maxMoodPoints * 1.5).toFixed(0);
            }
        }
    }

    getLevelNbr(shortName){
        this.level = 1;
        if (this.activityInfo.activityTracker.activityTable !== undefined){
            for (let i=0; i<this.activityInfo.activityTracker.activityTable.length; i++){
                let activityTable = this.activityInfo.activityTracker.activityTable[i];
                if (shortName === activityTable.activityName){
                    this.level = activityTable.level;
                    break;
                }               
            }
        }

    }


}

export default withRouter(SelectActivity);