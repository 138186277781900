import assetExpLoanShortnameDataset from "../../datasets/asset_exp_loan__shortname_dataset";

export const findRemoveTableItem = (table, inputName, removeSw, nameType) => {
      let itemType = "";
      let itemName = "";
      let itemDesc = "";
      let matchItemDesc = "";
      let objectFound = false;
      let tableObj = {};
      let assetExpLoanObj = {};
      const assetExpLoanType = assetExpLoanShortnameDataset;
      if (nameType !== undefined && nameType === "fullName"){
            assetExpLoanObj = assetExpLoanType.find(elem => elem.fullName === inputName);
      }else{
            assetExpLoanObj = assetExpLoanType.find(elem => elem.shortName === inputName);
      }
      if (assetExpLoanObj !== undefined){
            itemType = assetExpLoanObj.type;
            itemName = assetExpLoanObj.fullName;
            itemDesc = assetExpLoanObj.desc;
      }
      for (let i=0; i<table.length && objectFound == false; i++) {
            switch (itemType){
            case "asset":
                  if (itemDesc == ""){
                        matchItemDesc = table[i].assetDesc;
                  }else{
                        matchItemDesc = itemDesc;
                  }   
                  if (table[i].assetItem == itemName && 
                        table[i].assetDesc == matchItemDesc && 
                        table[i].assetNbrMonths != 0){
                        tableObj = JSON.parse(JSON.stringify(table[i]));
                              objectFound = true
                              if (removeSw == true){
                                    table[i].assetNbrMonths = 0;
                              }
                  }
                  break;
            case "expense":
                  if (itemDesc == ""){
                        matchItemDesc = table[i].expDesc;
                  }else{
                        matchItemDesc = itemDesc;
                  }
            
                  if (table[i].expItem == itemName && 
                        table[i].expDesc == matchItemDesc &&
                        table[i].expNbrMonths != 0){
                              tableObj = JSON.parse(JSON.stringify(table[i]));
                              objectFound = true;
                              if (removeSw == true){
                                    table[i].expNbrMonths = 0;
                              }
                  }
                  break;
            case "loan":
                  if (itemDesc == ""){
                        matchItemDesc = table[i].loanDesc;
                  }else{
                        matchItemDesc = itemDesc;
                  }
                  if (table[i].loanType === itemName && 
                        table[i].loanDesc === matchItemDesc &&
                        table[i].loanNbrMonths !== 0){
                              tableObj = JSON.parse(JSON.stringify(table[i]));
                              objectFound = true;
                              if (removeSw === true){
                                    table[i].loanNbrMonths = 0; 
                              }
                  }
                  break;
      }
          
  }
             
   return tableObj;
   
}





