import CreditCardChargesList from "../datasets/credit_card_charges_list";

export const applyToCreditCard = (creditCardInfo, cashExpense, cashDesc, cashCategory, chgType) => {
   
     if (chgType == undefined){
         chgType = "unplanned";
     }
     if (chgType == "unplanned"){ 
            cashExpense = cashExpense * -1;
     }
     creditCardInfo.totalOutstandingBalance = +creditCardInfo.totalOutstandingBalance +  +cashExpense;
     let indx = creditCardInfo.periodCreditCharges.length - 1;
     let category = cashCategory;
     if (cashCategory == undefined){
            category = "Other";
            let findCategory = CreditCardChargesList.find(elem=> elem.desc === cashDesc);
            if (findCategory != undefined){
                  category = findCategory.category;
            }
     };  
     let creditObj = {amount: cashExpense, desc: cashDesc, category: category, chgType: chgType};       
     creditCardInfo.periodCreditCharges[indx].creditChargesArr.push (creditObj);
              
    return creditCardInfo;
   
}
