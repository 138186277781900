import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class PlayGameTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         gameHelp: false
    };    
  }
  
  render() { 
      // setting animation={false} in modal to prevent the "findDOMNode is deprecated" warning msg
  return <>
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="xs"
            animation={false}>
          <Modal.Header className="gameModalHdrFtrBackground" closeButton>
            <Modal.Title>Would You Like To Try a Pre-set Game?</Modal.Title> 
          </Modal.Header>
          <Modal.Body >
                <div className="mr-2 ml-2">
                    <div>
                         If you would like to try playing a game that has already been setup,
                         choose one of the following (click on the button)....OR.... click the "Play My 
                         Own Game" button to setup your own game.
                    </div>
                </div>

                <div className="text-left ml-5 mt-1">
                    <button type="button" className="preset-game-button mt-3" 
                        onClick={() => this.props.loadTemplate("easyGame")}>
                                   Try a Game Set For Success
                    </button>
                    <button type="button" className="preset-game-button mt-3" 
                        onClick={() => this.props.loadTemplate("midGame")}>
                                   Try a Game That Is Somewhat Challenging
                    </button>
                    <button type="button" className="preset-game-button mt-3" 
                        onClick={() => this.props.loadTemplate("difficultGame")}>
                                   Try a Game That is More Challenging
                    </button>
                    <button type="button" className="preset-game-button mt-3" 
                        onClick={() => this.props.loadTemplate("veryDifficultGame")}>
                                   Try a Game That is Almost Impossible
                    </button>
                </div>
          </Modal.Body>
          <Modal.Footer className="gameModalHdrFtrBackground text-right">
              <div className="mr-5">
                  <Button variant="primary"   onClick={() => this.props.onClose()}>
                        Play My Own Game
                  </Button>
              </div>
          
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mx-0 d-block"/>
            </Modal.Footer>
          </Modal>  
    
    </>
  }

 
}
export default PlayGameTemplateModal;