const TimeWizardPolicyOptions =[
    {level: "1", type: "monthsPerTurn", optionList: [
        {index: 0, value: "none", selection: "No Change to Months Per Turn"},
        {index: 1, value: "1", selection: "1 Month (default) Per Turn"},
        {index: 2, value: "2", selection: "2 Months Per Turn"}
    ]},
    {level: "1", type: "nbrGameYears", optionList: [
        {index: 0, value: "none", selection: "No Change to Game Years"},
        {index: 1, value: "20", selection: "20 Years"},
        {index: 2, value: "30", selection: "30 Years (default)"},
    ]},
    {level: "2", type: "monthsPerTurn", optionList: [
        {index: 0, value: "none", selection: "No Change to Months Per Turn"},
        {index: 1, value: "1", selection: "1 Month (default) Per Turn"},
        {index: 2, value: "2", selection: "2 Months Per Turn"},
        {index: 3, value: "3", selection: "3 Months Per Turn"},
    ]},
    {level: "2", type: "nbrGameYears", optionList: [
        {index: 0, value: "none", selection: "No Change to Game Years"},
        {index: 1, value: "10", selection: "10 Years"},
        {index: 2, value: "20", selection: "20 Years"},
        {index: 3, value: "30", selection: "30 Years (default)"},
    ]},
    {level: "3", type: "monthsPerTurn", optionList: [
        {index: 0, value: "none", selection: "No Change to Months Per Turn"},
        {index: 1, value: "1", selection: "1 Month (default) Per Turn"},
        {index: 2, value: "3", selection: "3 Months Per Turn"},
        {index: 3, value: "6", selection: "6 Months Per Turn"},
    ]},
    {level: "3", type: "nbrGameYears", optionList: [
        {index: 0, value: "none", selection: "No Change to Game Years"},
        {index: 1, value: "10", selection: "10 Years"},
        {index: 2, value: "20", selection: "20 Years"},
        {index: 3, value: "30", selection: "30 Years (default)"},
    ]},
  
  ];
  
  export default TimeWizardPolicyOptions;